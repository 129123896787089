import React from 'react'
import TextField from 'components/Form/TextField/TextField'
import SearchIcon from 'assets/images/crm/search.svg'
import { PlusIcon } from 'modules/UmrahOperations/shared/Icons'
import Locale from 'translations'

const SearchComponent = ({ buttonAction, onChangeHandler, value, source, removeSearch }) => {
	const { CRM } = Locale

	return (
		<div className={`d-flex align-items-center crm-search-wrapper ${removeSearch ? "justify-content-end" : " justify-content-between"}`}>
			{!removeSearch &&
				<div className='custom-input-search'>
					<TextField
						type='text'
						id='statement-operation-no'
						name='statement-operation-no'
						hasLabel={false}
						placeholder={`${CRM.searchForFilter}...`}
						isImage={true}
						image={SearchIcon}
						prependImage={true}
						value={value}
						onChange={(e) => onChangeHandler ? onChangeHandler(e.target.value) : false}
					/>
				</div>
			}
			{source !== "pax" ?
				<button className='btn item-addPax active-addPax' onClick={buttonAction}>
					<PlusIcon color='#FFF' />
					<p className='text'>{CRM.add}</p>
				</button>
				:
				null
			}
		</div>
	)
}

export default SearchComponent
