import websitePlanSrc from "assets/images/dashboard/laptop.png";
import { ReactComponent as Arrow } from "assets/images/dashboard/arrow.svg"
import { ReactComponent as InfoIcon } from "assets/images/subscription-plan/info.svg"
import { ReactComponent as SafaLogoSmall } from "assets/images/dashboard/safa-offline-small-logo.svg"
import { ReactComponent as RigntIcon } from "assets/images/subscription-plan/right-icon-filled.svg"
import SafaScreenIMG from 'assets/images/subscription-plan/safa-visa-stats.png'

import { useSBSDispatch, useSBSState } from 'context/global';
import { useLocation, Link, useHistory } from 'react-router-dom';
import useNavigationBar from 'layouts/useNavigationBar';
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { LoginWithSafaForm } from 'modules/auth/Auth-v2/LoginWithSafa';
import Locale from 'translations';
import { getPermissions } from 'services/auth';
import { getToken } from 'services/visa';



export default function SubscriptionPlanDetails() {
  // ** hooks
  const { messages, subscriptionModule } = Locale;
  const location = useLocation();
  const history = useHistory();
  const { dynamicModulesRoutes, fetchDynamicNav } = useNavigationBar();
  const { dynamicNav } = useSBSState();
  const dispatch = useSBSDispatch();

  const bundleData = dynamicNav?.find(navItem => `/plan-details/${navItem?.category}` === location.pathname);
  const isSafaVisaBundle = location.pathname === `/plan-details/safa_offline`;


  // ** states
  const [safavisaLoginModal, setSafavisaLoginModal] = useState(false);

  // *** functions
  function toggleSafaVisaLoginModal() {
    setSafavisaLoginModal(!safavisaLoginModal)
  }
  async function fetchPermissions() {
    const res = await getPermissions();
    dispatch({ type: "getPermissions", payload: res?.data?.data });
  }

  async function safaLoginAfterSuccess() {
    return await Promise.all([fetchDynamicNav(), fetchPermissions()])
  }


  if (!bundleData) {
    return null
  }



  return (
    <div className="booking-engine">
      <div className="description-part">
        <div className="description-text col-6">
          <h1 className="title mb-3">{bundleData?.name}</h1>
          <p className="text-muted">

            {subscriptionModule[bundleData?.category].description}
          </p>
          <div className="d-flex gap-10">
            <button className="btn filled-btn d-flex"
              onClick={() => {
                if (isSafaVisaBundle) {
                  toggleSafaVisaLoginModal()
                  return
                }
                history.push({ pathname: '/subscription/checkout', selectedBundle: bundleData })
              }}
            >
              {isSafaVisaBundle ? subscriptionModule.ConnectSafaVisa : subscriptionModule.SubscribeNow}

              <Arrow />
            </button>

            <Link to={isSafaVisaBundle ? `/contact-us` : `/subscription/packages-plans`} className="btn outline-btn d-flex">
              {isSafaVisaBundle ? subscriptionModule.RequestSafaVisaAccount : subscriptionModule.VeiwAllPlans}

              <Arrow stroke="#D4A655" />
            </Link>
          </div>
        </div>
        <div className="col-6">
          <img src={websitePlanSrc} className="img-fluid" alt="Website Plan" />
        </div>
      </div>
      {/* package includes */}
      <div className="package-include">
        <h2 className="title">{subscriptionModule?.PackageIncludes}</h2>
        <div className="package-list-items">
          {bundleData?.modules.map(module => {
            return (
              <div className="package-item" key={module.name}>
                {module.isSubscribed &&
                  <span className="subscribed-sign">Subscribed</span>
                }
                {
                  dynamicModulesRoutes?.[module?.key]?.icon
                  ||
                  <HexagonIconWrapper icon={bundleData.icon} />
                }
                {/* {subscribedPlan?.modules?.find(subscribedModule => subscribedModule?.status === "active" && subscribedModule?.name === module.name) &&
                  <span className="plan-status">Subscribed</span>
                } */}
                <p className="item-name" style={{ textTransform: 'capitalize' }}>{module.name}</p>
              </div>
            )
          })}
        </div>
      </div>

      {/* package feature */}
      <div className="package-features">
        <div className="d-flex justify-content-start">
          <h3 className="title">{subscriptionModule?.KeyFeatures}</h3>
        </div>

        <div className="all-fetaures">
          {subscriptionModule[bundleData?.category].features?.map((feature, index) => {
            return (
              <div className="feature" key={index}>
                <NumberIcon text={index + 1} />
                <div className="feature-signification">
                  <h4 className="feature-name">
                    {feature.title}
                  </h4>
                  <p className="feature-description text-muted">
                    {feature.description}
                  </p>
                </div>
              </div>
            )
          })}
        </div>


        {/* safa visa Reports & Statistics */}
        {isSafaVisaBundle &&
          <div className='d-flex gap-24'>
            <img src={SafaScreenIMG} alt="safa visa Reports & Statistics" />
            <div className='reports-and-statistics'>
              <div className='reports-items'>
                <h4 className='reports-title'>{subscriptionModule?.ReportsStatistics}</h4>
                <div className='d-flex align-items-center gap-10'>
                  <RigntIcon />
                  <p className='report-name'>{subscriptionModule?.BusSeatingReports}</p>
                </div>
                <div className='d-flex align-items-center gap-10'>
                  <RigntIcon />
                  <p className='report-name'>{subscriptionModule?.MakkahMadinahRoomingListReport}</p>
                </div>
                <div className='d-flex align-items-center gap-10'>
                  <RigntIcon />
                  <p className='report-name'>{subscriptionModule?.ClientsVisaReports}</p>
                </div>
                <div className='d-flex align-items-center gap-10'>
                  <RigntIcon />
                  <p className='report-name'>{subscriptionModule?.BasicFinancialReports}</p>
                </div>
                <div className='d-flex align-items-center gap-10'>
                  <RigntIcon />
                  <p className='report-name'>{subscriptionModule?.FlightBookingsReports}</p>
                </div>
              </div>
            </div>
          </div>
        }

        {/* <div className="subscribe">
          <div className="subscribe-content">
            <h4 className="title">Customized for your travel agent</h4>
            <p className="sales-text">
              A Sales, Reservations, and Marketing Management
              System (SRMS) is an essential component of any
              company"s success, especially in the travel and
              tourism industry.
            </p>
          </div>

          <button className="btn subscribe-btn"
            onClick={() => {
              if (isSafaVisaBundle) {
                toggleSafaVisaLoginModal()
                return
              }
              history.push({ pathname: '/subscription/checkout', selectedBundle: bundleData })
            }}
          >
            {isSafaVisaBundle ? "Connect Safa Visa" : "Subscribe Now"}
          </button>

        </div> */}
      </div>

      {safavisaLoginModal &&
        <Modal isOpen={safavisaLoginModal} className="login-safa-modal">
          <ModalHeader toggle={toggleSafaVisaLoginModal}>
            <SafaLogoSmall className="logo" />
          </ModalHeader>
          <ModalBody className="pre-auth-layout">
            <h6 className="head">{messages.linkeyourAccount}</h6>
            <h6 className="sub-head">{messages.afterLinke}</h6>
            <div className="mt-3">
              <LoginWithSafaForm
                callbackAfterSuccess={() => {
                  safaLoginAfterSuccess().then(() => {
                    toggleSafaVisaLoginModal();
                    history.push("/safa_offline/subscription")
                  });
                }}
                actionService={getToken}
              />
            </div>
          </ModalBody>
        </Modal>
      }

    </div>
  )
}

function NumberIcon({ text }) {
  return (
    <svg width="52" height="63" viewBox="0 0 52 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52 15.5L26.2737 0L0 15.5V46.5L26.2737 62L52 46.5V15.5Z" fill="#FAF4EB" />
      <text x="48%" y="52%" fontSize="28px" dominant-baseline="middle" text-anchor="middle" fontWeight="700" te fontFamily="cairo" fill="#D4A655">{text}</text>
    </svg>
  )
}

export function HexagonIconWrapper({ icon }) {
  return (
    <span className="hexagon-alt-wrapper">
      {icon || <InfoIcon />}
    </span>
  )
}