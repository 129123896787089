import React, { useEffect, useState } from 'react'
import SelectField from 'components/Form/SelectField/SelectField'
// import { store } from 'react-notifications-component';

// import { setPackageAvailabilities } from 'services/productbuilder';
import Locale from 'translations';
import NumberField from 'components/Form/NumberField/NumberField';
import { generateHotelsPax, generateHotelsRoomTypesWithCategories, sharedPaxRowObj } from '../helpers/hotelsSharedRooms';



export default function GuestsPerPax({
	productDetails,
	hotelsSharedRooms,
	setHotelsSharedRooms,
	setReload,
	totalAdultCount,
	totalChildrenCount,
	isMarketPlace
}) {
	// hooks
	const { marketPlace, commons, CRM } = Locale;
	// variables
	const product = isMarketPlace ? productDetails : productDetails?.product;
	const productRoomsAvailabilities = isMarketPlace ? productDetails?.roomAvailabilities : productDetails?.product?.roomsAvailabilities;
	const hotelsList = product?.product_items?.filter(item => item?.item?.id === 6);
	const productPriceBreakDown = product?.product_price_type_id === 0 || product?.product_price?.product_price_type?.id === 0;
	// const productPriceFullPackage = product?.product_price_type_id === 1 || product?.product_price?.product_price_type?.id === 1;

	// states
	const [hotelsRoomsAvailabilities, setHotelsRoomsAvailabilities] = useState([]);

	// set Avalibities with backend
	// async function setPackagesRoomsAvailabilities() {
	// 	const adultsRooms = sharedPaxRooms?.adults?.map(adult => {
	// 		return {
	// 			combination_id: adult?.combination_id,
	// 			is_adult: 1,
	// 			traveler_id: isNaN(adult?.id) ? null : adult?.id
	// 		}
	// 	});
	// 	const childRooms = sharedPaxRooms?.children?.map(child => {
	// 		return {
	// 			combination_id: child?.combination_id,
	// 			is_adult: 0,
	// 			traveler_id: isNaN(child?.id) ? null : child?.id
	// 		}
	// 	});
	// 	const roomsAvailability = [...adultsRooms, ...childRooms].filter(room => room?.combination_id);
	// 	const data = {
	// 		reservation_num: productDetails?.reservation_num,
	// 		rooms_availability: roomsAvailability
	// 	}
	// 	const res = await setPackageAvailabilities(data);
	// 	if (res?.status === 200) {
	// 		store.addNotification({
	// 			title: "info!",
	// 			message: "Traveler added Successfully",
	// 			type: "success",
	// 			insert: "top",
	// 			container: "top-right",
	// 			animationIn: ["animated", "fadeIn"],
	// 			animationOut: ["animated", "fadeOut"],
	// 			dismiss: {
	// 				duration: 1500,
	// 				onScreen: true,
	// 				pauseOnHover: true,
	// 			},
	// 		})
	// 		setReload((prev) => !prev);
	// 	}
	// }


	useEffect(() => {
		let rooms = generateHotelsRoomTypesWithCategories({ hotelsList, roomsAvailabilities: productRoomsAvailabilities, category: "shared" });
		setHotelsRoomsAvailabilities(rooms)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (hotelsSharedRooms.length === 0) {
			setHotelsSharedRooms(generateHotelsPax({ hotelsList, reservedRooms: isMarketPlace ? null : productDetails?.roomAvailabilities }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function calcRoomPriceForEachPax({ hotelId, roomType, roomCategory, roomMeal, adultCount, childrenCount }) {
		let totalRoomPrice = 0;
		// let vatPercentage = +product?.product_price_combinations?.find(p => p?.pricing_for === "b2b")?.vat / 100;
		// let discountPercentage = +product?.product_price?.discounts?.find(p => p?.pricing_for === "b2b")?.discount / 100;
		if (+adultCount > 0 || +childrenCount > 0) {
			if (productPriceBreakDown) {
				let selectedHotel = hotelsList?.find(hotel => hotel?.itemable?.id === hotelId);
				let selectedRoom = selectedHotel?.prices?.find(p => p?.pricing_for === "b2b")?.rooms_prices?.find(room => room?.room_type === roomType && room?.room_category === roomCategory && room?.meal_type === roomMeal)
				let hotelAdultPrice = selectedRoom?.price_adult * (+adultCount);
				let hotelChildPrice = selectedRoom?.price_child * (+childrenCount);
				totalRoomPrice += (hotelAdultPrice + hotelChildPrice);
			}

			// // Calculate the discount amount
			// const discountedTotalPrice = totalRoomPrice - (totalRoomPrice * discountPercentage);

			// // Calculate the VAT amount
			// const vatAmount = (discountedTotalPrice * vatPercentage);

			// // Calculate the final price
			// totalRoomPrice = discountedTotalPrice + vatAmount;
		}
		return totalRoomPrice;
	}

	function handleSelectRoomType({ paxIndex, roomType }) {
		let hotelsSharedRoomsClone = [...hotelsSharedRooms];
		hotelsSharedRoomsClone = hotelsSharedRoomsClone?.map(hotel => {
			let paxList = hotel?.pax;
			paxList[paxIndex] = { ...paxList[paxIndex], roomType, roomCategory: null, roomMeal: null }

			return {
				...hotel,
				pax: paxList,
			}
		});
		setHotelsSharedRooms(hotelsSharedRoomsClone)
	}

	// user select room category based on room type
	function handleSelectRoomCategory({ roomCategory, paxIndex, hotelIndex }) {
		let hotelsSharedRoomsClone = [...hotelsSharedRooms];
		let currentPax = hotelsSharedRoomsClone[hotelIndex]?.pax[paxIndex];
		currentPax = { ...currentPax, roomCategory, roomMeal: null };
		hotelsSharedRoomsClone[hotelIndex].pax[paxIndex] = currentPax;

		setHotelsSharedRooms(hotelsSharedRoomsClone)
	}

	// user select room meal based on room type and room category
	function handleSelectRoomMeal({ roomMeal, paxIndex, hotelIndex, hotelId }) {
		let hotelsSharedRoomsClone = [...hotelsSharedRooms];
		let currentPax = hotelsSharedRoomsClone[hotelIndex]?.pax[paxIndex];
		currentPax = { ...currentPax, roomMeal };

		if (hotelIndex > 0) {
			let price = calcRoomPriceForEachPax({
				hotelId,
				roomType: currentPax?.roomType?.id,
				roomCategory: currentPax?.roomCategory?.label,
				roomMeal: currentPax?.roomMeal?.label,
				adultCount: currentPax?.adultsCount,
				childrenCount: currentPax?.childrenCount,
			});
			currentPax.price = price;
		}

		hotelsSharedRoomsClone[hotelIndex].pax[paxIndex] = currentPax;

		setHotelsSharedRooms(hotelsSharedRoomsClone);
	}

	function handleAdultsChildrenCount({ value, paxIndex, paxType }) {
		let hotelsSharedRoomsClone = [...hotelsSharedRooms];
		let paxTypeKey = paxType === "adult" ? "adultsCount" : "childrenCount"
		let price = 0;
		hotelsSharedRoomsClone = hotelsSharedRoomsClone?.map(hotel => {
			let paxList = hotel?.pax;
			if (value >= 0) {
				price = calcRoomPriceForEachPax({
					hotelId: hotel?.hotelId,
					roomType: paxList[paxIndex]?.roomType?.id,
					roomCategory: paxList[paxIndex]?.roomCategory?.label,
					roomMeal: paxList[paxIndex]?.roomMeal?.label,
					adultCount: paxType === "adult" ? +value : paxList[paxIndex]?.adultsCount,
					childrenCount: paxType === "child" ? +value : paxList[paxIndex]?.childrenCount,
				});
			}
			paxList[paxIndex] = { ...paxList[paxIndex], [paxTypeKey]: value, price: price || 0 }
			return {
				...hotel,
				pax: paxList,
			}
		});
		setHotelsSharedRooms(hotelsSharedRoomsClone);
	}

	function addPaxRow() {
		let hotelsSharedRoomsClone = [...hotelsSharedRooms];
		hotelsSharedRoomsClone = hotelsSharedRoomsClone?.map(hotel => {
			let paxList = hotel?.pax;
			paxList.push(sharedPaxRowObj)
			return {
				...hotel,
				pax: paxList,
			}
		});
		setHotelsSharedRooms(hotelsSharedRoomsClone);
	}

	function deletePaxRow(paxIndex) {
		let hotelsSharedRoomsClone = [...hotelsSharedRooms];
		hotelsSharedRoomsClone = hotelsSharedRoomsClone?.map(hotel => {
			let paxList = hotel?.pax;
			paxList.splice(paxIndex, 1);
			return {
				...hotel,
				pax: paxList,
			}
		});

		setHotelsSharedRooms(hotelsSharedRoomsClone);
	}


	return (
		<div className='crm-details-payment'>
			<div className='items-guests-per-pax'>
				{hotelsSharedRooms?.length > 0 && hotelsSharedRooms?.map((hotel, hotelIndex) => {
					let totalAdultPaxCount = hotel?.pax?.reduce((total, current) => total + +current?.adultsCount, 0);
					let isAdultCompeleted = +totalAdultPaxCount >= +totalAdultCount;

					return (
						<div key={hotel?.hotelId}>
							{/* hotel details */}
							<div className="font-weight-bold px-2 py-3 bg-gray-100 mb-3 rounded">
								{hotel?.hotelName} (Hotel {hotelIndex + 1})
							</div>
							{/* pax rows */}
							{hotel.pax?.map((paxItem, paxIndex) => {
								let roomsTypes = hotelsRoomsAvailabilities?.find(hotelRooms => hotelRooms?.hotelId === hotel?.hotelId)?.rooms || [];
								let roomsCategories = roomsTypes?.find(room => room?.id === paxItem?.roomType?.id)?.categories || [];
								let roomsMeals = roomsCategories?.find(categroy => categroy?.id === paxItem?.roomCategory?.id)?.meals || [];
								return (
									<div className='item-guests-per-pax mb-3' key={`pax-shared-room-${paxIndex}`} style={{ gap: "8px" }}>

										{/* room type */}
										<div className="col-md-3 p-0">
											<SelectField
												label={marketPlace.RoomType}
												placeholder={commons.select}
												id={`pax-${paxIndex}-room-type`}
												options={roomsTypes}
												value={paxItem?.roomType?.label}
												onChange={(e) => {
													handleSelectRoomType({ roomType: e, paxIndex })
												}}
												disabled={hotelIndex > 0 || !isMarketPlace}
											/>
										</div>

										{/* room category */}
										<div className="col-md-3 p-0">
											<SelectField
												label={CRM.roomCategory}
												placeholder={commons.select}
												id={`pax-${paxIndex}-room-category`}
												options={roomsCategories}
												value={paxItem?.roomCategory?.label}
												onChange={(e) => {
													handleSelectRoomCategory({ roomCategory: e, paxIndex, hotelIndex })
												}}
												disabled={!paxItem?.roomType || !isMarketPlace}
											/>
										</div>

										{/* room meal */}
										<div className="col-md-3 p-0">
											<SelectField
												label={CRM.mealType}
												placeholder={commons.select}
												id={`pax-${paxIndex}-room-meal`}
												options={roomsMeals}
												value={paxItem?.roomMeal?.label}
												onChange={(e) => {
													handleSelectRoomMeal({ roomMeal: e, paxIndex, hotelIndex, hotelId: hotel?.hotelId })
												}}
												disabled={!paxItem?.roomCategory || !isMarketPlace}
											/>
										</div>

										{/* adults count */}
										<div className="col-md-1 p-0">
											<NumberField
												removeArrow={true}
												label={CRM.adults}
												id={`pax-${paxIndex}-adult-count`}
												max={totalAdultCount}
												value={paxItem?.adultsCount}
												onChange={(e) => {
													let value = +e.target.value;
													let allAdultsCount = hotel?.pax?.reduce((totalAdultsCount, room, i) => (i === paxIndex ? totalAdultsCount : totalAdultsCount + +room?.adultsCount), 0) + value;
													let remainingCount = +totalAdultCount - +allAdultsCount;
													if (remainingCount < 0) {
														value = +totalAdultCount - Math.abs(+allAdultsCount - value);
													} else {
														value = +e.target.value
													}
													value = value > 0 ? value : 0;
													handleAdultsChildrenCount({ value: Number(value).toString(), paxIndex, paxType: "adult" })
												}}
												disabled={!paxItem?.roomMeal || hotelIndex > 0 || !isMarketPlace}
											/>
										</div>

										{/* children count */}
										<div className="col-md-1 p-0">
											<NumberField
												removeArrow={true}
												label={CRM.children}
												id={`pax-${paxIndex}-children-count`}
												value={paxItem?.childrenCount}
												onChange={(e) => {
													let value = +e.target.value;
													let allChildrenCount = hotel?.pax?.reduce((totalChildrenCount, room, i) => (i === paxIndex ? totalChildrenCount : totalChildrenCount + +room?.childrenCount), 0) + value;
													let remainingCount = +totalChildrenCount - +allChildrenCount;
													if (remainingCount < 0) {
														value = +totalChildrenCount - Math.abs(+allChildrenCount - value);
													} else {
														value = +e.target.value
													}
													value = value > 0 ? value : 0;
													handleAdultsChildrenCount({ value: Number(value).toString(), paxIndex, paxType: "child" })
												}}
												disabled={!paxItem?.roomMeal || hotelIndex > 0 || !isMarketPlace}
											/>
										</div>

										{/* add delete row button */}
										<div className="col-md-1 p-0 mt-3">
											{paxIndex === 0 && hotelIndex === 0 && isMarketPlace ?
												<button className="btn p-0"
													onClick={() => addPaxRow()}
													disabled={isAdultCompeleted}
												>
													<svg width="26" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M15.9998 29.3337C23.3332 29.3337 29.3332 23.3337 29.3332 16.0003C29.3332 8.66699 23.3332 2.66699 15.9998 2.66699C8.6665 2.66699 2.6665 8.66699 2.6665 16.0003C2.6665 23.3337 8.6665 29.3337 15.9998 29.3337Z" fill="#D4A655" />
														<path d="M10.6665 16H21.3332" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														<path d="M16 21.3337V10.667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													</svg>
												</button>
												:
												null
											}
											{paxIndex > 0 && hotelIndex === 0 && isMarketPlace ?
												<button className="btn p-0"
													onClick={() => deletePaxRow(paxIndex)}
												>
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047" stroke="#F53D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														<path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#F53D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														<path d="M18.8499 9.13965L18.1999 19.2096C18.0899 20.7796 17.9999 21.9996 15.2099 21.9996H8.7899C5.9999 21.9996 5.9099 20.7796 5.7999 19.2096L5.1499 9.13965" stroke="#F53D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														<path d="M10.3301 16.5H13.6601" stroke="#F53D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
														<path d="M9.5 12.5H14.5" stroke="#F53D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													</svg>
												</button>
												:
												null
											}
										</div>
										{/* <span className="font-weight-bold mt-4 text-center" style={{ fontSize: "14px" }}>{paxItem?.price} {product?.currency}</span> */}
									</div>
								)
							})}
						</div>
					)
				})}

				{/* {!isMarketPlace &&
					<div className='guests-actions justify-content-end mt-3'>
						<button className='btn btn-active'
						// onClick={setPackagesRoomsAvailabilities}
						>
							{marketPlace.SaveChanges}
						</button>
					</div>
				} */}

			</div>
		</div>
	)
}