import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import React from "react";
import ServiceCard from "./ServiceCard";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { ReactComponent as RightICon } from "assets/images/webBuilder/right.svg";
import { ReactComponent as LeftIcon } from "assets/images/webBuilder/left.svg";
import ThemeSevenServices from './Theme7';
import OurServicesThemeFour from './Theme4';
import OurServicesThemeFive from './Theme5';
import OurServicesThemeTen from './Theme10';

function OurServices() {
	const { ourServices, selectedLanguage, style } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const updateOurServices = ({ name, value, type, language }) => {
		dispatch({
			type: "updateOurServices",
			payload: {
				name,
				value,
				type,
				language: name === "visible" || name === "icon" ? null : language
			},
		});
	};

	const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
		return (
			<div className="carousel-button-group">
				<CustomLeftArrow onClick={() => previous()} />
				<CustomRightArrow onClick={() => next()} />
			</div>
		);
	};

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 4,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
		},
	};
	const CustomRightArrow = ({ onClick }) => (
		<button
			className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
			onClick={onClick}
		>
			<RightICon />
		</button>
	);

	const CustomLeftArrow = ({ onClick }) => (
		<button
			className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
			onClick={onClick}
		>
			<LeftIcon className="r-left-icon" />
		</button>
	);

	const services = Array.isArray(ourServices?.services)
		? ourServices?.services
		: [];
	const location = useLocation();
	const preview = !location.pathname.includes("edit");
	const isMobile = window.innerWidth < 550 ? true : false;
	return (
		<>
			{
				(style?.theme === 'theme-1' || style?.theme === 'theme-2' || style?.theme === 'theme-3') && (
					<div className="wb-our-service" id="our-service">
						{preview ? (
							services?.filter((res) => res.visible).length > 0 ? (
								<ServicesContent
									header={ourServices?.title[selectedLanguage]}
									body={ourServices?.body[selectedLanguage]}
									changeHeader={(value) =>
										updateOurServices({ name: "title", value, type: null, language: selectedLanguage })
									}
									changebody={(value) => updateOurServices({ name: "body", value, type: null, language: selectedLanguage })}
								/>
							) : null
						) : (
							<ServicesContent
								header={ourServices?.title[selectedLanguage]}
								body={ourServices?.body[selectedLanguage]}
								changeHeader={(value) => updateOurServices({ name: "title", value, type: null, language: selectedLanguage })}
								changebody={(value) => updateOurServices({ name: "body", value, type: null, language: selectedLanguage })}
							/>
						)}
						{(preview &&
							!isMobile &&
							services.filter((serv) => serv.visible === true)?.length > 4 &&
							ourServices?.ourServicesType === "with_images") ||
							(!preview &&
								services.length > 4 &&
								ourServices?.ourServicesType === "with_images") ? (
							<div className="all-web-builder-our-service-carousel position-relative">
								<Carousel
									responsive={responsive}
									itemClass={"service-slide"}
									className="web-builder-our-service-carousel"
									slidesToSlide={1}
									keyBoardControl={true}
									// customRightArrow={<CustomRightArrow />}
									// customLeftArrow={<CustomLeftArrow />}
									customButtonGroup={<ButtonGroup />}
									renderButtonGroupOutside={true}
									infinite={true}
									arrows={false}
								>
									{services.map((service, index) =>
										preview ? (
											(service?.visible && service?.icon !== null) ? (
												<ServiceCard
													type={service?.type}
													icon={service?.icon}
													title={service?.title}
													header={service?.header[selectedLanguage]}
													key={service?.title + "aaa" + index}
													body={service?.body[selectedLanguage]}
													visible={service?.visible}
													ourServicesType={ourServices?.ourServicesType}
													editCardDetails={(value, name) =>
														updateOurServices({ name, value, type: service?.type, language: selectedLanguage })
													}
													onChangeTitle={(value) =>
														updateOurServices({
															name: "header",
															value,
															type: service?.type,
															language: selectedLanguage
														})
													}
													onChangeBody={(value) =>
														updateOurServices({
															name: "body",
															value,
															type: service?.type,
															language: selectedLanguage
														})
													}
													onChangeVisible={(value) =>
														updateOurServices({
															name: "visible",
															value,
															type: service?.type,
														})
													}
												/>
											) : null
										) : (
											<ServiceCard
												type={service?.type}
												icon={service?.icon}
												title={service?.title}
												header={service?.header[selectedLanguage]}
												key={service?.title + "bbb" + index}
												body={service?.body[selectedLanguage]}
												visible={service?.visible}
												id={service?.id}
												ourServicesType={ourServices?.ourServicesType}
												editCardDetails={(value, name) =>
													updateOurServices({ name, value, type: service?.type, language: selectedLanguage })
												}
												onChangeTitle={(value) =>
													updateOurServices({
															name: "header",
															value,
															type: service?.type,
															language: selectedLanguage
														})
												}
												onChangeBody={(value) =>
													updateOurServices({
															name: "body",
															value,
															type: service?.type,
															language: selectedLanguage
														})
												}
												onChangeVisible={(value) =>
													updateOurServices({
															name: "visible",
															value,
															type: service?.type,
														})
												}
											/>
										)
									)}
								</Carousel>
							</div>
						) : (
							<div
								className={
									preview
										? services?.filter((res) => res.visible).length > 0
											? `web-builder-content-our-service container-fluid`
											: null
										: `web-builder-content-our-service container-fluid`
								}
								id="web-builder-our-service"
							>
								{services.map((service, index) =>
									preview ? (
										service?.visible && service?.icon !== null ? (
											<ServiceCard
												type={service?.type}
												icon={service?.icon}
												title={service?.title}
												header={service?.header[selectedLanguage]}
												key={service?.title + "aaa" + index}
												body={service?.body[selectedLanguage]}
												visible={service?.visible}
												typeTitle={service?.typeTitle}
												editCardDetails={(value, name) =>
													updateOurServices({ name, value, type: service?.type, language: selectedLanguage })
												}
												onChangeTitle={(value) =>
													updateOurServices({
														name: "title",
														value,
														type: service?.type,
														language: selectedLanguage
													})
												}
												onChangeBody={(value) =>
													updateOurServices({
														name: "body",
														value,
														type: service?.type,
														language: selectedLanguage
													})
												}
												ourServicesType={ourServices?.ourServicesType}
												onChangeVisible={(value) =>
													updateOurServices({
														name: "visible",
														value,
														type: service?.type,
													})
												}
											/>
										) : null
									) : (
										<ServiceCard
											type={service?.type}
											icon={service?.icon}
											title={service?.title}
											header={service?.header[selectedLanguage]}
											key={service?.title + "bbb" + index}
											body={service?.body[selectedLanguage]}
											visible={service?.visible}
											id={service?.id}
											typeTitle={service?.typeTitle}
											editCardDetails={(value, name) => updateOurServices({ name, value, type: service?.type, language: selectedLanguage })
											}
											onChangeTitle={(value) =>
												updateOurServices({
														name: "title",
														value,
														type: service?.type,
														language: selectedLanguage
													})
											}
											onChangeBody={(value) =>
												updateOurServices({
														name: "body",
														value,
														type: service?.type,
														language: selectedLanguage
													})
											}
											ourServicesType={ourServices?.ourServicesType}
											onChangeVisible={(value) => updateOurServices({
												name: "visible", value, type: service?.type
											})
											}
										/>
									)
								)}
							</div>
						)}
					</div>
				)
			}
			{
				style?.theme === 'theme-4' && (
					<OurServicesThemeFour
						updateOurServices={updateOurServices}
					/>
				)
			}
			{
				style?.theme === 'theme-5' && (
					<OurServicesThemeFive
						updateOurServices={updateOurServices}
					/>
				)
			}
			{
				style?.theme === 'theme-7' && (
					<ThemeSevenServices
						updateOurServices={updateOurServices}
					/>
				)
			}
			{
				style?.theme === 'theme-10' && (
					<OurServicesThemeTen
						updateOurServices={updateOurServices}
					/>
				)
			}
		</>
	);
}

export default OurServices;
