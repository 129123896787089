import ImagePlaceholder from "assets/images/image_placeholder.png";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSDispatch, useSBSState } from "context/global";
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
// import useFetchCountries from "hooks/useFetchCountries";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory,useLocation } from "react-router-dom";
import { editinvalidPassengers, listMehrem, uploadImageOnlineVisa } from 'services/VisaOnline';
import Locale from "translations";
import CustomModal from './components/Modal';
import { visaPassengerFactory } from './helpers/visaPassengerFactory';
import { ReactComponent as UploadIcon } from "assets/images/visa/upload.svg";
import { uploadFile } from 'services/auth';
import { store } from 'react-notifications-component';
import validate, { isFormValid } from 'helpers/validate';
import AddNewPassengerModal from "./components/AddNewPassenger";
import { ReactComponent as MRZIcon } from "assets/images/visa/mrz.svg";

export default function EditPassangerProfile() {
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const { visa, reservation, productsBuilder,inventory,payment,onlineVisa,teamManagement } = Locale;
  const dispatch = useMarketplaceDispatch();
  const { onlineVisaEditPassenger,  onlineVisaSearch, redirectURL } = useMarketplaceState();
  const [changingImage, setchangingImage] = useState(false)
  const [passportChangingImage, setPassportChangingImage] = useState(false)
	const [errors, setErrors] = useState({});
  const passengerIndex = onlineVisaEditPassenger?.index

  const [ModalNewMember, setModalNewMember] = useState(false);

  // useEffect(() => {
    
  //   if (isFormValid(errors)) {
  //     savePassanger()
  //   }
  // }, [isErrorLoaded]);
  
  let history = useHistory()
  const {state} = useLocation();
  const reapply = state?.invalidData
  const [relationsLookups, setRelationsLookups] = useState([])

  const [residenceyImage, setResidenceyImage] = useState(onlineVisaEditPassenger?.residence_data?.residence_photo);
	const handleUpload = (e) => {
		const files = e.target.files;
		setResidenceyImage(files[0]);
	};

  const residenceRequired = onlineVisaSearch?.residence_data;

  const { locale, allCountries,serverErrors } = useSBSState();
	const dispatchSBS = useSBSDispatch();


  const countries = allCountries;
  // const [countries] = useFetchCountries();
  const [modalOpen, setModalOpen] = useState(false);
  function toggleModal() {
    setModalOpen(!modalOpen)
  }

  	//////// handle Errors ////////
	function checkFormErrors() {
		let errorsFields = [
			"first_name_en",
			"last_name_en",
			"nationality_id",
			"passport_number",
			"passport_photo",
			"gender",
			"title",
			"country",
			"birth_country",
			"issue_country",
			"issue_place",
			"birth_place",
			"date_of_birth",
			"issue_date",
			"expire_date",
			"photo",
			"passport_type",
			"marital_status",
		];
		if (residenceRequired) {
			errorsFields = [
				...errorsFields,
				"residencey_number",
				"residencey_issue_date",
				"residencey_expire_date",
				"residence_photo",
			];
		}
		let submitError = {};

		errorsFields.forEach((key) => {
			let value =
				key === "nationality_id" ? member["nationality"]?.value : member[key];
				if(key === "residence_photo") value = member["residence_photo"] || residenceyImage;
				if(key === "passport_photo") value = member?.["passport_photo"] || member?.["passportPhotoFile"];
				if(key === "photo") value = member?.["photo"] || member?.["imgFile"];
        console.log(key,value,"valuew",member[key]);
			submitError = {
				...submitError,
				...validate(
					{ name: key, value },
					{
						required: true,
            serverError:false

					}
				),
			};
		});
		setErrors(() => submitError);
	}
  console.log("errorserrors",errors);

	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}


  const genderLookups = [
		{
			id: 1,
			name: `${locale === "en" ? "Male" : "ذكر"}`,
			code: "male"
		},
		{ id: 2, name: `${locale === "en" ? "Female" : "أنثي"}`,code: "female" },
		
	];


  // useEffect(() => {
  //   const fetchMehremRelations = async () => {
  //     const respones = await listMehrem()
  //     if (respones.status === 200 || respones.status === 201) {
  //       setRelationsLookups(respones.data.data)
  //     }
  //   }
  //   fetchMehremRelations()
  // }, [])

  const makeLookups = (arr) => {
    return arr.map((item, index) => {
      return {
        id: item.id,
        name: locale === "ar" ? item.Name_En : item.Name_En,
      };
    });
  };
  const PassType = makeLookups([
    {
      "id": 1,
      "Name_En": "Normal",
      "Name_Ar": "عادي"
    },
    {
      "id": 2,
      "Name_En": "Diplomatic",
      "Name_Ar": "دبلوماسي"
    },
    {
      "id": 3,
      "Name_En": "Travel Document",
      "Name_Ar": "وثيقة سفر"
    },
    {
      "id": 4,
      "Name_En": "UN Passport",
      "Name_Ar": "جواز الأمم المتحدة"
    },
    {
      "id": 99,
      "Name_En": "Other",
      "Name_Ar": "اخرى"
    }
  ]);
  // const RelationsList = makeLookups([
  //   {
  //     "id": -1,
  //     "Name_En": "No Mahram",
  //     "Name_Ar": "بدون محرم",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 0,
  //     "Name_En": "",
  //     "Name_Ar": "",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 1,
  //     "Name_En": "Son",
  //     "Name_Ar": "ابن",
  //     "Gender_id": 1,
  //     "RR_TYPE": 1,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 2,
  //     "Name_En": "Daughter",
  //     "Name_Ar": "ابنه",
  //     "Gender_id": 2,
  //     "RR_TYPE": 1,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 3,
  //     "Name_En": "Father",
  //     "Name_Ar": "اب",
  //     "Gender_id": 1,
  //     "RR_TYPE": 1,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 4,
  //     "Name_En": "Mother",
  //     "Name_Ar": "ام",
  //     "Gender_id": 2,
  //     "RR_TYPE": 1,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 5,
  //     "Name_En": "Brother",
  //     "Name_Ar": "اخ",
  //     "Gender_id": 1,
  //     "RR_TYPE": 1,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 6,
  //     "Name_En": "Sister",
  //     "Name_Ar": "اخت",
  //     "Gender_id": 2,
  //     "RR_TYPE": 1,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 7,
  //     "Name_En": "Husband",
  //     "Name_Ar": "زوج",
  //     "Gender_id": 1,
  //     "RR_TYPE": 1,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 8,
  //     "Name_En": "Wife",
  //     "Name_Ar": "زوجة",
  //     "Gender_id": 2,
  //     "RR_TYPE": 1,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 9,
  //     "Name_En": "Grandfather",
  //     "Name_Ar": "الجد",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 10,
  //     "Name_En": "Grandmother",
  //     "Name_Ar": "الجدة",
  //     "Gender_id": 2,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 11,
  //     "Name_En": "Nephew-Brother Son",
  //     "Name_Ar": "ابن الاخ",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 12,
  //     "Name_En": "Nephew-Sister Son",
  //     "Name_Ar": "ابن الاخت",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 13,
  //     "Name_En": "Wife`s Son-in-law",
  //     "Name_Ar": "صهر الزوجة",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 14,
  //     "Name_En": "Husband`s Son-in-law",
  //     "Name_Ar": "صهر الزوج",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 15,
  //     "Name_En": "Women Group",
  //     "Name_Ar": "عصبة النساء",
  //     "Gender_id": 1,
  //     "RR_TYPE": 2,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 16,
  //     "Name_En": "Grandson",
  //     "Name_Ar": "حفيد",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 17,
  //     "Name_En": "Mother-in-law",
  //     "Name_Ar": "الحماة",
  //     "Gender_id": 2,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 18,
  //     "Name_En": "Uncle[Mother brother]",
  //     "Name_Ar": "الخال",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 19,
  //     "Name_En": "Aunt[Mother sister]",
  //     "Name_Ar": "الخالة",
  //     "Gender_id": 2,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 20,
  //     "Name_En": "Uncle[Father brother]",
  //     "Name_Ar": "العم",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 21,
  //     "Name_En": "Aunt[Father Sister]",
  //     "Name_Ar": "العمة",
  //     "Gender_id": 2,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 22,
  //     "Name_En": "Daughter-in-law",
  //     "Name_Ar": "زوجة الابن",
  //     "Gender_id": 2,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 23,
  //     "Name_En": "Son-in-law",
  //     "Name_Ar": "زوج البنت",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 24,
  //     "Name_En": "Step Mother",
  //     "Name_Ar": "زوجة الاب",
  //     "Gender_id": 2,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 0
  //   },
  //   {
  //     "id": 25,
  //     "Name_En": "Step Father",
  //     "Name_Ar": "زوج الام",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 26,
  //     "Name_En": "Granddaughter",
  //     "Name_Ar": "حفيدة",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 27,
  //     "Name_En": "Father-in-law",
  //     "Name_Ar": "الحمو",
  //     "Gender_id": 1,
  //     "RR_TYPE": 0,
  //     "RR_Custom": 1
  //   },
  //   {
  //     "id": 99,
  //     "Name_En": "Others",
  //     "Name_Ar": "اخرى",
  //     "Gender_id": 1,
  //     "RR_TYPE": 1,
  //     "RR_Custom": 1
  //   }
  // ]);
  const TitleList = makeLookups([
    {
      "id": 1,
      "Name_En": "Mr",
      "Name_Ar": "السيد"
    },
    {
      "id": 2,
      "Name_En": "Miss",
      "Name_Ar": "الأنسة"
    },
    {
      "id": 3,
      "Name_En": "Mrs",
      "Name_Ar": "السيدة"
    },
    {
      "id": 4,
      "Name_En": "Dr",
      "Name_Ar": "دكتور"
    },
    {
      "id": 5,
      "Name_En": "His Excellency",
      "Name_Ar": "سعادة"
    },
    {
      "id": 6,
      "Name_En": "His Royal Highness",
      "Name_Ar": "سمو"
    },
    {
      "id": 99,
      "Name_En": "Other",
      "Name_Ar": "اخرى"
    }
  ])
  const MaritalStatus = makeLookups([
    {
      "id": 1,
      "Name_En": "Single",
      "Name_Ar": "أعزب"
    },
    {
      "id": 2,
      "Name_En": "Married",
      "Name_Ar": "متزوج"
    },
    {
      "id": 3,
      "Name_En": "Divorced",
      "Name_Ar": "مطلق"
    },
    {
      "id": 4,
      "Name_En": "Widowed",
      "Name_Ar": "أرمل"
    },
    {
      "id": 99,
      "Name_En": "Other",
      "Name_Ar": "اخرى"
    }

  ]);
  const EduLevel = makeLookups([
    {
      "id": 1,
      "Name_Ar": "بدون تعليم",
      "Name_En": "No Education"
    },
    {
      "id": 2,
      "Name_Ar": "تعليم ابتدائي",
      "Name_En": "Primary School"
    },
    {
      "id": 3,
      "Name_Ar": "تعليم ثانوي",
      "Name_En": "High School"
    },
    {
      "id": 4,
      "Name_Ar": "تعليم جامعي",
      "Name_En": "Higher Education"
    },
    {
      "id": 99,
      "Name_Ar": "اخرى",
      "Name_En": "Other"
    }
  ]);



  const [member, setMember] = useState({
    first_name_ar: "",
		first_name_en: "",
		last_name_en: "",
		last_name_ar: "",
    father_name: { ar: "", en: "" },
    grandfather_name: { ar: "", en: "" },
    national_id: "",
    gender: null,
    title: "",
    passport_type: { value: "1", label: locale === "en" ? "normal" : "normal" },
    passport_number: "",
    marital_status: { value: "99", label: locale === "en" ? "other" : "other" },
    educational_level: { value: "99", label: locale === "en" ? "other" : "other" },
    country: onlineVisaSearch?.residence,
    nationality: null,
    birth_country: null,
    birth_place: "",
    issue_country: null,
    issue_place: "",
    date_of_birth: "",
    issue_date: "",
    expire_date: "",
    job: "",
    address: "",
    photo: "",
    passport_photo: "",
    // relationship: { value: null, label: { en: "", ar: "" } },
    // mehrem_name: { value: null, label: "" },
    mrz: "",
  });
  useEffect(() => {
    const formatedMember = visaPassengerFactory('', onlineVisaEditPassenger, countries);
    setMember(formatedMember);
  }, [onlineVisaEditPassenger, countries]);

  // const mehermList = onlineVisaPassenger.filter((passenger) => passenger?.id != onlineVisaEditPassenger?.id || passenger?.uniqueIdFrontend != onlineVisaEditPassenger?.uniqueIdFrontend).map((item) => {
  //   return {
  //     id: item.passport_number,
  //     name: locale === "ar" ? item.full_name_ar : item.full_name_en
  //   }
  // })
  console.log("member",member);

  const uploadResidenceyImage = async () => {
    if(typeof residenceyImage === "string" ){
      return residenceyImage
    }
		const formData = new FormData();
		formData.append("file", residenceyImage);
		formData.append("name", residenceyImage?.name);
		formData.append("bucket", "visaResidencey");
		const response = await uploadFile(formData);
		if (response.data.data.uuid) {
			return response.data.data.uuid;
		}
	};
  const savePassanger = async () => {
		let photo = {};
		if (passportChangingImage) {
			const res = await editPassportPhoto();
			if (!res) {
				return;
			}

			photo.passport = res;
		}

		if (changingImage) {
			const res = await editImage();
			if (!res) {
				return;
			}
			photo.face = res;
		}
		let residenceyImageUUID;
		if (residenceyImage) {
			residenceyImageUUID = await uploadResidenceyImage();
		}
		const data = {
			id: member.id ?? "",
			uniqueIdFrontend: member.uniqueIdFrontend ?? "",
			full_name_ar:
      member?.full_name_ar&&member?.full_name_ar?.trim() !== ""
					? member?.full_name_ar 
					: `${member?.first_name_ar} ${member?.father_name?.ar} ${member?.grandfather_name?.ar} ${member?.last_name_ar}`,
			full_name_en: member?.first_name_en !=="" &&member?.last_name_en !==""?`${member?.first_name_en} ${member?.father_name?.en} ${member?.grandfather_name?.en} ${member?.last_name_en}`:member?.full_name_en ,
			first_name_ar: member?.first_name_ar,
			first_name_en: member?.first_name_en,
			father_name_ar: member?.father_name?.ar,
			father_name_en: member?.father_name?.en,
			grandfather_name_ar: member?.grandfather_name?.ar,
			grandfather_name_en: member?.grandfather_name?.en,
			last_name_ar: member?.last_name_ar,
			last_name_en: member?.last_name_en,
			birth_date: member.date_of_birth,
			age: member?.age,
			national_id: member?.national_id,
			gender: member?.gender?.code,
			title: member?.title?.label,
			passport_type: member?.passport_type?.label,
			passport_number: member?.passport_number,
			marital_status: member?.marital_status?.label,
			educational_level: member?.educational_level?.label,
			country: member?.country?.label,
			nationality: member?.nationality?.label,
			birth_country: member?.birth_country?.label,
			birth_place: member?.birth_place,
			issue_country_label: member?.issue_country?.label,
			issue_country: member?.issue_country?.value,
			issue_place: member?.issue_place,
			date_of_birth: member?.date_of_birth
				? moment(member?.date_of_birth).format("YYYY-MM-DD")
				: "",
			issue_date: member?.issue_date
				? moment(member?.issue_date).format("YYYY-MM-DD")
				: "",
			expire_date: member?.expire_date
				? moment(member?.expire_date).format("YYYY-MM-DD")
				: "",
			job: member?.job,
			address: member?.address,
			// relationship: member?.relationship?.label,
			// mehrem_relation_code: member?.relationship?.value?.toString(),
			// mehrem_passport_number: member?.mehrem_name?.value,
			// mehrem_name: member?.mehrem_name?.label,
			photo: changingImage ? photo?.face : member?.photo,
			passport_photo: passportChangingImage
				? photo?.passport
				: member?.passport_photo,
			mrz: member?.mrz,
			nationality_id: member?.nationality?.value,
			offline_company_id: member.offline_company_id
				? member.offline_company_id
				: "",
			...(residenceRequired && {
				residence_data: {
					residence_photo: residenceyImageUUID,
					residence_number: member?.residencey_number,
					issue_date: member?.residencey_issue_date,
					expiry_date: member?.residencey_expire_date,
				},
			}),
      
		};

    
		if (reapply) {
			const residence_data = data.residence_data;
			const passengerData = {
				request_id: +onlineVisaSearch?.id,
				passengers: [
					{
						...data,
            passenger_id:data.id,
						national_id: data?.national_id,
						nationality_id: data?.nationality_id,

						country:
							data?.country?.[locale] ||
							data?.country ||
							data?.issue_country_label,
						issue_country: data?.PPIssueCountry || data?.issue_country,
           
						

						grandfather_name_en: data?.grandfather_name_en,
						last_name_en: data?.last_name_en,

            first_name_ar: data?.first_name_ar,
            father_name_ar: data?.father_name_ar,
					  grandfather_name_ar: data?.grandfather_name_ar,
					  last_name_ar: data?.last_name_ar,

					  
            first_name_en: data?.first_name_en,
					  father_name_en: data?.father_name_en || "",
						// last_name_en: data?.last_name_en && data?.last_name_en?.trim() !== "" ? data?.last_name_en : data?.full_name_en?.split(" ")[3],

						passport_photo: data?.passport_photo,
						gender: data?.gender?.en || data?.gender,
						title: data?.title?.en || data?.title,
						birth_country:
							data?.birth_country_name?.name?.[locale] ||
							data?.birth_country ||
							data?.issue_country_label,
						passport_type: data?.passport_type?.name || data?.passport_type,
						issue_place: data?.issue_place?.toString() || "",
						nationality:
							data?.nationality_name?.name?.en ||
							data?.nationality ||
							data?.issue_country_label,
						passport_number: data?.passport_number?.toString(),
						...(residence_data && {
							residence_data: {
								residence_number: residence_data?.residence_number,
								residence_photo: residence_data?.residence_photo,
								issue_date: moment(residence_data?.issue_date).format("YYYY-MM-DD") ,
								expiry_date: moment(residence_data?.expiry_date).format("YYYY-MM-DD"),
							},
						}),
					},
				],
			};
			const response = await editinvalidPassengers(passengerData);
			if (response.status === 200) {
				store.addNotification({
					title: "",
					message:"success",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 5000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
        dispatch({
          type: "onlineVisaRepalceEditPassenger",
          payload: {...data,status:"waiting"},
        });
			} else {
				store.addNotification({
					title: "",
					message: response?.message,
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 5000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
				return;
			}
		}else{
      dispatch({
        type: "onlineVisaRepalceEditPassenger",
        payload: data,
      });
    }
		history.push({ pathname: redirectURL, state: "editPassenger" });
	};
  const passangerErrors =serverErrors?.[passengerIndex]
  const formatServerError =(errors)=>{
    const newError = {}
    
    Object.keys(errors).forEach((item)=>{
      switch (item) {
        case "residencey.residence_number":
          newError["residencey_number"] = errors[item]
          break;
        case "residencey.issue_date":
          newError["residencey_issue_date"] = errors[item]
          break;
        case "residencey.expiry_date":
          newError["residencey_expire_date"] = errors[item]
          break;
        case "residencey.residence_photo":
          newError["residence_photo"] = errors[item]
          break;
        case "residence_data":
          break;
      
        default:
          newError[item] = errors[item]
          break;
      }
    })
    return newError

  }

  
  useEffect(() => {
		if (serverErrors && passangerErrors) {

      const formatedErrors = formatServerError(passangerErrors)
			setErrors(formatedErrors);
			
			// dispatchSBS({
			// 	type: "serverErrors",
			// 	payload: null,
			// });
		}

		return () => {};
	}, [serverErrors]);

	useEffect(() => {
		if (isFormValid(errors)) {
      
			savePassanger();
      if(serverErrors&&passangerErrors){
        const cloneErrors = [...serverErrors] 
        cloneErrors[passengerIndex] = undefined;
        dispatchSBS({
          type: "serverErrors",
          payload: cloneErrors,
        });
      }
		}
		return () => {};
	}, [isErrorLoaded]);

  const editImage = async () => {
    let formData = new FormData()
    formData.append("photo", member.imgFile)
    formData.append("photo_type", "face_image")
    formData.append("nationality_id", member?.nationality?.value ?? member.issue_country)
    formData.append("passport_number", member.passport_number)
    formData.append("offline_company_id", member.offline_company_id ? member.offline_company_id : "")
    const res = await uploadImageOnlineVisa(formData)
    if (res?.data?.data?.faceImageUrl) {
      setMember({
        ...member,
        photo: res.data.data.faceImageUrl,
      })
      setchangingImage(false)
    }
    return res?.data?.data?.faceImageUrl
  }

  const editPassportPhoto = async () => {
    let formData = new FormData();
    formData.append("photo", member.passportPhotoFile)
    formData.append("photo_type", "full_image")
    formData.append("nationality_id", member?.nationality?.value ?? member.issue_country)
    formData.append("passport_number", member.passport_number)
    formData.append("offline_company_id", member.offline_company_id ? member.offline_company_id : "")
    const res = await uploadImageOnlineVisa(formData)
    if (res?.data?.data?.faceImageUrl) {

      setMember({
        ...member,
        passport_photo: res?.data?.data?.faceImageUrl,
      })
     
      setPassportChangingImage(false)
    }
    return res?.data?.data?.faceImageUrl
  }
	const toggleModelNewMember = () => {
		setModalNewMember(!ModalNewMember);
	};


    const formatDataEdit = (data) => {
  
      let passdata = {
        first_name_en: data?.first_name,
        first_name_ar: "",
        father_name: { ar: "", en: data?.second_name },
        grandfather_name: { ar: "", en: data?.third_name },
        last_name_en: data?.last_name,
        last_name_ar: "",
        date_of_birth: data?.birth_date || null,
        expire_date: data?.ex_date || null,
        issue_date: data?.issue_date || null,
        issue_place: data?.issuplace,
        issue_country: data?.issue_country,
        country: data?.country ? data?.country : data?.issue_country ,
        nationality: data?.nat ? data?.nat : data?.issue_country ,
        birth_country: data?.nat ? data?.nat : data?.issue_country ,
        passport_number: data?.ppno,
        gender: {
          value: data?.gender,
          code: +data?.gender === 1 ? "male" : "female",
          label:
            +data?.gender === 1
              ? `${locale === "en" ? "Male" : "Male"}`
              : +data?.gender === 2
              ? `${locale === "en" ? "Female" : "Female"}`
              : "",
        },
        title: {
          value: +data?.gender === 1 ? "Mr" : +data?.gender === 2 ? "Mrs" : "",
          label:
            +data?.gender === 1
              ? `${locale === "en" ? "Mr" : "Mr"}`
              : +data?.gender === 2
              ? `${locale === "en" ? "Mrs" : "Mrs"}`
              : "",
        },
        photo: data?.face_image_url,
        passport_photo: data?.full_image_url,
        mrz: data?.mrz,
      };
      let issue_country = countries.find(
        (country) => country.id === passdata.issue_country
      );
      let nationality = countries.find(
        (country) => country.id === passdata.nationality
      );
      let country = countries.find((country) => country.id === passdata.country);
   
      console.log(member);
      setMember((prev) => ({
        national_id:"",
        job:"",
        status:"invalid",
        educational_level:{label:""},
        address:"",
        ...passdata,
        passenger_id:member.id,
        birth_place:passdata?.birth_place? passdata?.birth_place:"",
        id:member.id,
        issue_country: issue_country?.id? { value: issue_country?.id, label: issue_country?.name }:null,
        nationality: nationality?.id? { value: nationality?.id, label: nationality?.name }:null,

        birth_country: nationality?.id? { value: nationality?.id, label: nationality?.name }:null,
        country: country?.id? { value: country?.id, label: country?.name }:null,
      }));
      
      setModalNewMember(false)
    };


  return (
    <div className="list-container">

      <div className="row">
        <div className={`
            member-photo m-0 col-md-2 col-12 d-flex 
            flex-column align-items-center
            text-center online-vise-img mt-4
            `}
        >
          <div className={` ${
          (errors?.photo?.required ||
             errors?.photo?.serverError )
              && !member.photo && !member.imgPrev
            ? `border-danger`
            : ""
        }`}>

          <img
            className="img-fluid rounded w-75"
            
            src={member.imgPrev ? member.imgPrev :
              member.photo
              ? `${member.photo + "?" + new Date().getTime()}`
              : ImagePlaceholder
            }
            alt=""
            />
            </div>
          {/* {changingImage ?
            <button className="btn btn-link text-center w-75 file-btn mt-2" onClick={editImage}>
              {productsBuilder.save}
            </button>
            :
            null
          } */}
          <button className="btn btn-link text-center w-75 file-btn mt-2">
            <i class="fas fa-edit mx-2"></i>
            {visa.ChangePhoto}
            <input
              type="file"
              className="custom-file"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => {
                setMember({
                  ...member,
                  imgPrev: URL.createObjectURL(e.target.files[0]),
                  imgFile: e.target.files[0],
                });
                setErrors({
                  ...errors,
                  ...validate(
                    {
                      name: "photo",
                      value: e.target.files[0].name
                    },
                    { required: true }
                  ),
                });
                setchangingImage(true)
              }}
            />
          </button>


          <p className='h6'>{visa.profilePictureHint}</p>

          {/* passport photo */}

          <div onClick={toggleModal} className={`mt-4 pointer ${
																(errors?.passport_photo?.required ||
                                   errors?.passport_photo?.serverError )
                                    && !member?.passprotPhotoPrev && !member.passport_photo
																	? `border-danger`
																	: ""
															}`}>
            <img className="img-fluid rounded w-75" 
              src={member.passprotPhotoPrev ?
                member?.passprotPhotoPrev :
                member.passport_photo ?
                  member.passport_photo :
                  ImagePlaceholder} alt="passport" />
          </div>

          {/* change passport photo in case of group passengers */}
          {/* {passportChangingImage ?
            <button className="btn btn-link text-center w-75 file-btn mt-2" onClick={editPassportPhoto}>
              {productsBuilder.save}
            </button>
            :
            null
          } */}
          {/* {!member.passport_photo || member.passport_photo === "" ? */}
            <button className="btn btn-link text-center w-75 file-btn mt-2">
              <i class="fas fa-edit mx-2"></i>
              {visa.ChangePassportPhoto}
              <input
                type="file"
                className="custom-file"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => {
                  setMember({
                    ...member,
                    passprotPhotoPrev: URL.createObjectURL(e.target.files[0]),
                    passportPhotoFile: e.target.files[0],
                  });
                  setErrors({
                    ...errors,
                    ...validate(
                      {
                        name: "passport_photo",
                        value: e.target.files[0].name
                      },
                      { required: true }
                    ),
                  });
                  setPassportChangingImage(true);
                }}
              />
            </button>


            {member.status=="invalid" ?      <button
									className="btn bg-nxt m-1 text-white mt-3"
								
									onClick={toggleModelNewMember}
								>
								<MRZIcon className="me-1" style={{ flex: "1 0 auto" }} />
							<span>{onlineVisa.insertDateUploadPassport}</span>
								</button> : null}
            {/* :

            null} */}
        </div>


        <div className=" col-md-10 col-12 p-0 ">
          <div className=" d-flex align-items-start justify-content-between">
            <div className=" w-100">
              <div className="box1">
                <div className="row align-items-center w-100 no-gutter  m-0 ">
                  <div className="col-3">
                    <TextField
                      hasLabel={true}
                      type="text"
                      // disabled={true}
                      label={reservation.messages.passportNo}
                      placeholder={reservation.messages.passportNoPlaceholder}
                      value={member.passport_number}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          passport_number: e.target.value
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "passport_number", value: e.target.value },
														{ required: true }
													),
												});
											}}
											color={errors?.passport_number?.required || errors?.passport_number?.serverError   ? "danger" : ""}
											errors={errors?.passport_number}
                    />
                  </div>
                  <div className="col-md-3 ">
                    <TextField
                      hasLabel={true}
                      type="text"
                      label={visa.NATIONALID}
                      placeholder={visa.NATIONALIDPlaceholder}
                      value={member.national_id}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          national_id: e.target.value
                        })
                      }}

                    />
                  </div>
                  <div className="col-md-3 ">
                    <SelectField
                      name="contract"
                      placeholder={visa.SelectGender}
                      // disabled={true}
                      options={genderLookups}
                      label={visa.Gender}
                      value={member?.gender?.label?.[locale] ? member?.gender?.label?.[locale] : member.gender?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          gender: e,
                          title: e.code?.toLowerCase() === "male" 
													? { id: 1, value: 1, label: "Mr", name: "Mr"} 
													: e.code?.toLowerCase() === "female" ? { id: 2, value: 2, label: "Miss", name: "Miss"}
													: ""
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "gender", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.gender?.required || errors?.gender?.serverError  ? "danger" : ""}
											errors={errors?.gender}
                    />
                  </div>

                  <div className="col-md-3 ">
                    <SelectField
                      name="group-status"
                      placeholder={visa.SelectTitle}
                      label={visa.title}
                      options={TitleList}
                      value={member?.title?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          title: e
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "title", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.title?.required || errors?.title?.serverError  ? "danger" : ""}
											errors={errors?.title}
                    />
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>

              <div className="box2 bg-gray p-1 mx-1 px-2 rounded">
                <h3 className="p-1 font-dark-blue"> {visa.ArabicName}</h3>
                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-3 ">
                    <TextField
                      hasLabel={false}
                      type="text"
											label={visa.firstName}
                      placeholder={visa.firstNamePlaceholder}
                      value={member?.first_name_ar}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          first_name_ar:  e.target.value
                        })
                      }}

                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      placeholder={visa.fatherNamePlaceholder}
                      value={member?.father_name?.ar}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          father_name: { en: member?.father_name?.en, ar: e.target.value }
                        })
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      placeholder={visa.grandFatherNamePlaceholder}
                      value={member?.grandfather_name?.ar}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          grandfather_name: { en: member?.grandfather_name?.en, ar: e.target.value }
                        })
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      placeholder={visa.lastNamePlaceholder}
                      value={member?.last_name_ar}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          last_name_ar: e.target.value 
                        })
                      }}
                    />
                  </div>
                  <div className="col-md-3"></div>
                </div>
                <h3 className="p-1 font-dark-blue"> English Name</h3>
                <div className="row align-items-center w-100 no-gutter m-0  ">
                  <div className="col-md-3 ">
                    <TextField
                      hasLabel={false}
                      type="text"
                      label={visa.firstName}
                      placeholder={visa.firstNamePlaceholder}
                      value={member?.first_name_en}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          first_name_en: e.target.value, 
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "first_name_en", value: e.target.value },
														{ required: true }
													),
												});
											}}
											color={errors?.first_name_en?.required ? "danger" : ""}
											errors={errors?.first_name_en}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      //label={visa.fatherName}
                      placeholder={visa.fatherNamePlaceholder}
                      value={member?.father_name?.en}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          father_name: { en: e.target.value, ar: member?.father_name?.ar }
                        })
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      //label={visa.grandFatherName}
                      placeholder={visa.grandFatherNamePlaceholder}
                      value={member?.grandfather_name?.en}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          grandfather_name: { en: e.target.value, ar: member?.grandfather_name?.ar }
                        })
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <TextField
                      hasLabel={false}
                      type="text"
                      label={visa.lastName}

                      placeholder={visa.lastNamePlaceholder}
                      value={member?.last_name_en}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          last_name_en: e.target.value,
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "last_name_en", value: e.target.value },
														{ required: true }
													),
												});
											}}
											color={errors?.last_name_en?.required || errors?.last_name_en?.serverError ? "danger" : ""}
											errors={errors?.last_name_en }
                    />
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>

              <div className=" my-2  ">
                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-3">
                    <SelectField
                      name="contract"
                      label={visa.passportType}
                      placeholder={visa.passportTypePlaceholder}
                      options={PassType}
                      value={member?.passport_type?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          passport_type: e
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "passport_type", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.passport_type?.required || errors?.passport_type?.serverError  ? "danger" : ""}
											errors={errors?.passport_type}
                    />
                  </div>
                  {/* marital Status */}
                  <div className="col-md-3">
                    <SelectField
                      name="contract"
                      label={visa.maritalStatus}
                      placeholder={visa.maritalStatusPlaceholder}
                      options={MaritalStatus}
                      value={member?.marital_status?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          marital_status: e
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "marital_status", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.marital_status?.required || errors?.marital_status?.serverError  ? "danger" : ""}
											errors={errors?.marital_status}
                    />
                  </div>

                  {/* education level */}
                  <div className="col-md-3">
                    <SelectField
                      name="group-status"
                      label={visa.educationLevel}
                      placeholder={visa.educationLevelPlaceholder}
                      options={EduLevel}
                      value={member.educational_level?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          educational_level: e
                        })
                      }}
                    />
                  </div>
                  {/* country */}
                  <div className="col-md-3">
                    <SelectField
                      name="contract"
                      label={visa.country}
                      placeholder={visa.countryPlaceholder}
                      options={countries}
                      // disabled={member.country}
                      value={member?.country?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          country: e
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "country", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.country?.required || errors?.country?.serverError  ? "danger" : ""}
											errors={errors?.country}
                    />
                  </div>
                </div>

                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-3">
                    <SelectField
                      name="contract"
                      label={visa.nationality}
                      // disabled={true}
                      placeholder={visa.nationalityPlaceholder}
                      options={countries}
                      value={member?.nationality?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          nationality: e
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "nationality_id", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.nationality_id?.required || errors?.nationality_id?.serverError  ? "danger" : ""}
											errors={errors?.nationality_id}
                    />
                  </div>
                  <div className="col-md-3">
                    <SelectField
                      name="contract"
                      label={visa.birthCountry}
                      placeholder={visa.birthCountryPlaceholder}
                      options={countries}
                      value={member?.birth_country?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          birth_country: e
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "birth_country", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.birth_country?.required || errors?.birth_country?.serverError  ? "danger" : ""}
											errors={errors?.birth_country}
                    />
                  </div>

                  <div className="col-md-3">
                    <SelectField
                      name="group-status"
                      label={visa.issueCountry}
                      placeholder={visa.issueCountryPlaceholder}
                      options={countries}

                      value={member.issue_country?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          issue_country: e
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "issue_country", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.issue_country?.required || errors?.issue_country?.serverError  ? "danger" : ""}
											errors={errors?.issue_country}

                    />
                  </div>

                  <div className="col-md-3">
                    <TextField
                      hasLabel={true}
                      type="text"
                      label={visa.issuePlace}
                      placeholder={visa.issuePlacePlaceholder}
                      value={member.issue_place}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          issue_place: e.target.value
                        })
                        setErrors({
                          ...errors,
                          ...validate(
                            {
                              name: "issue_place",
                              value: e.target.value,
                            },
                            { required: true }
                          ),
                        });
                      }}
                      color={
                        errors?.issue_place?.required || errors?.issue_place?.serverError  ? "danger" : ""
                      }
                      errors={errors?.issue_place}
                    />
                  </div>
                </div>

                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-3">
                    <TextField
                      hasLabel={true}
                      type="text"
                      label={visa.birthPlace}
                      placeholder={visa.birthPlacePLaceholder}
                      value={member.birth_place}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          birth_place: e.target.value
                        })
                        setErrors({
                          ...errors,
                          ...validate(
                            {
                              name: "birth_place",
                              value: e.target.value,
                            },
                            { required: true }
                          ),
                        });
                      }}
                      color={
                        errors?.birth_place?.required || errors?.birth_place?.serverError  ? "danger" : ""
                      }
                      errors={errors?.birth_place}


                    />
                  </div>

                  <div className="col-md-3 ">
                    <DatePickerField
                      label={visa.Birthday}
                      placeholder={"DD/MM/YYYY"}
                      // disabled={true}
                      date={moment(member?.date_of_birth)}
                      value={moment(member?.date_of_birth)}
                      onChangeDate={(e) => {
                        setMember({
                          ...member,
                          date_of_birth:  moment(e).format("YYYY-MM-DD")
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "date_of_birth", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.date_of_birth?.required || errors?.date_of_birth?.serverError  ? "danger" : ""}
											errors={errors?.date_of_birth}
                      isOutsideRange={(day) => {
												return (
													!day.isAfter(moment().subtract(80, "years"), "day") ||
													!day.isBefore(moment().subtract(18, "years"), "day")
												);
											}}
                      hasYears={true}
											startYear={moment().subtract(80, "years")}
											endYear={moment().subtract(18, "years")}
                    />
                  </div>

                  <div className="col-md-3 ">
                    <DatePickerField
                      label={visa.issueDate}
                      placeholder={"DD/MM/YYYY"}
                      // isOutsideRange={() => false}
                      date={member?.issue_date ? moment(member?.issue_date) : null}
                      onChangeDate={(e) => {
                        setMember({
                          ...member,
                          issue_date:  moment(e).format("YYYY-MM-DD")
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "issue_date", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.issue_date?.required || errors?.issue_date?.serverError  ? "danger" : ""}
											errors={errors?.issue_date}
                      isOutsideRange={(day) => {
												return !day.isBefore(moment(), "day");
											}}
                    />
                  </div>

                  <div className="col-md-3 ">
                    <DatePickerField
                      label={visa.expiryDate}
                      // disabled={true}
                      placeholder={"DD/MM/YYYY"}
                      date={moment(member?.expire_date)}
                      value={moment(member?.expire_date)}
                      onChangeDate={(e) => {
                        setMember({
                          ...member,
                          expire_date:  moment(e).format("YYYY-MM-DD")
                        })
                        setErrors({
													...errors,
													...validate(
														{ name: "expire_date", value: e },
														{ required: true }
													),
												});
											}}
											color={errors?.expire_date?.required || errors?.expire_date?.serverError  ? "danger" : ""}
											errors={errors?.expire_date}
                      isOutsideRange={(day) => {
												return (
													day.isBefore(moment().add("6", "month")) ||
													day.isBefore(moment().subtract(10, "years"))
												);
											}}
											hasYears={true}
											startYear={moment().add(6, "month")}
											endYear={moment().add(10, "years")}
											initialVisibleMonth={() =>
												moment().add("6", "month") || null
											}
                    />
                  </div>

                </div>


                <div className="row align-items-center w-100 no-gutter m-0 ">
                  <div className="col-md-3">
                    <TextField
                      hasLabel={true}
                      type="text"
                      label={visa.JOB}
                      placeholder={visa.jobPlaceholdre}
                      value={member?.job}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          job: e.target.value
                        })
                      }}

                    />
                  </div>

                </div>

                <div className="row align-items-center w-100 no-gutter m-0 ">
                  {/* <div className="col-md-6">
										<TextField
											hasLabel={true}
											type="text"
											label={visa.ADDRESS}
											//6placeholder={visa.ADDRESSPlaceholder}
											value={member?.CAddaCity}
									
										
										/>
									</div> */}

                  {/* <div className="col-md-3">
                    <SelectField
                      name="group-status"
                      label={visa.MAHRAM}
                      placeholder={visa.MAHRAMPlaceholder}
                      options={mehermList}
                      value={member?.mehrem_name?.label}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          mehrem_name: e
                        })
                      }}

                    />
                  </div> */}

                  {/* <div className="col-md-3">
                    <SelectField
                      name="group-status"
                      label={visa.RELATION}
                      placeholder={visa.relationPlaceholder}
                      options={relationsLookups}
                      value={member?.relationship?.label?.[locale]}
                      onChange={(e) => {
                        setMember({
                          ...member,
                          relationship: { value: e.value, label: { ar: e.label, en: e.label } }
                        })
                      }}



                    />
                  </div> */}
                </div>

                {/* Residence Data */}
								{residenceRequired ? (
									<div className="my-2">
										<h4 className="mb-2">{inventory.messages.residenceData}</h4>
										<div className="row residence-section">
											<div className="col-3">
                      <div className={`p-2 upload-residence w-100 ${
																(errors?.residence_photo?.required || errors?.residence_photo?.serverError ) && !residenceyImage
																	? `border-danger`
																	: ""
															}`}>
													<p className="text-caption mb-2">
														{inventory.messages.uploadResidencyphoto}
													</p>
													{residenceyImage ? (
														<p className="text-gray d-flex align-items-center">
															<span className="flex-fill overflow-hidden">
																{residenceyImage?.name ?? residenceyImage}
															</span>
															<i
																className="fas fa-times-circle pointer text-danger flex-fill"
																onClick={() => setResidenceyImage(null)}
															></i>
														</p>
													) : (
														<div>
															<label
																htmlFor="upload-residence"
																className="upload-residence-button bg-nxt"
															>
																{payment.messages.upload}
																<UploadIcon className="ms-1" />
															</label>
															<input
																onChange={handleUpload}
																style={{ display: "none" }}
																type="file"
																id="upload-residence"
																accept="image/*"
															/>
														</div>
													)}
												</div>
											</div>
											<div className="col-3">
												<TextField
													hasLabel={true}
													type="text"
													// disabled={data?.ppno}
													label={inventory.messages.residencyNumber}
													placeholder={inventory.messages.residencyNumber}
													value={member.residencey_number}
													onChange={(e) => {
														setMember({
															...member,
															residencey_number: e.target.value,
														});
                            setErrors({
															...errors,
															...validate(
																{
																	name: "residencey_number",
																	value: e.target.value,
																},
																{ required: true }
															),
														});
													}}
													color={
														errors?.residencey_number?.required || errors?.residencey_number?.serverError  ? "danger" : ""
													}
													errors={errors?.residencey_number}
												/>
											</div>
											<div className="col-md-3 ">
												<DatePickerField
													label={visa.issueDate}
													minDate={false}
													placeholder={"DD/MM/YYYY"}
													date={
														member?.residencey_issue_date
															? moment(member?.residencey_issue_date)
															: ""
													}
													onDateChange={(e) => {
														setMember({
															...member,
															residencey_issue_date:  moment(e).format("YYYY-MM-DD"),
														});
                            setErrors({
															...errors,
															...validate(
																{ name: "residencey_issue_date", value: e },
																{ required: true }
															),
														});
													}}
													hasYears={true}
													isOutsideRange={(day) => {
														return !day.isBefore(moment(), "day");
													}}
                          color={
														errors?.residencey_issue_date?.required || errors?.residencey_issue_date?.serverError  
															? "danger"
															: ""
													}
													errors={errors?.residencey_issue_date}
												/>
											</div>
                      <div className="col-md-3 datepicker-centerd">
												<DatePickerField
													label={visa.expiryDate}
													// disabled={data?.ex_date}
													placeholder={"DD/MM/YYYY"}
													date={
														member?.residencey_expire_date
															? moment(member?.residencey_expire_date)
															: ""
													}
													value={
														member?.residencey_expire_date
															? moment(member?.residencey_expire_date)
															: ""
													}
													onDateChange={(e) => {
														setMember({
															...member,
															residencey_expire_date:  moment(e).format("YYYY-MM-DD"),
														});
                            setErrors({
															...errors,
															...validate(
																{ name: "residencey_expire_date", value: e },
																{ required: true }
															),
														});
													}}
													isOutsideRange={(day) => {
														return day.isBefore(moment().add(1, "day"), "day");
													}}
													hasYears={true}
													// startYear={moment().add(6, "month")}
													// endYear={moment().add(10, "years")}
													// initialVisibleMonth={() =>
													// 	moment().add("6", "month") || null
													// }
                          color={
														errors?.residencey_expire_date?.required || errors?.residencey_expire_date?.serverError  
															? "danger"
															: ""
													}
													errors={errors?.residencey_expire_date}
												/>
											</div>
										</div>
									</div>
								) : null}

                <div className="row align-items-center w-100 no-gutter m-0  round mrz   px-2">
                  <div className="mrz-label col-md-2 bg-grey py-2 text-light text-center rounded ">
                    <p>MRZ</p>
                  </div>
                  <div className="col-md-10  text-16 bg-gray">
                    <input className='py-2 w-100 bg-transparent border-0 px-2' onChange={(e)=>{
                      	setMember({
                          ...member,
                          mrz:  e.target.value,
                        });
                    }} style={{ wordBreak: 'break-word' }} value={member?.mrz} />
                  </div>
                </div>


                <div className="d-flex justify-content-end my-4 px-2">
                  <button className='btn bg-gray mx-2' onClick={() => {
                    history.push({ pathname: redirectURL, state: 'editPassenger' });
                  }}>
                    {productsBuilder.cancel}
                  </button>
                  <button className='btn bg-nxt' onClick={submit}>
                    {productsBuilder.save}
                  </button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>


      {/* {member.passport_photo && member.passport_photo !== "" && modalOpen && */}
        <CustomModal
          modalIsOpen={modalOpen}
          header={<span className='h5 font-weight-bold text-capitalize'>{visa.passportPhotoPreview}</span>}
          toggle={toggleModal}
          size={"md"}
          centered={true}
        >
          <div className='mt-3'>
            <img className="img-fluid rounded w-100" src={member.passport_photo ? member.passport_photo : ImagePlaceholder} alt="full size passport" />
          </div>
        </CustomModal>



        <CustomModal
				modalIsOpen={ModalNewMember}
				header={onlineVisa.AddNew}
				toggle={toggleModelNewMember}
				size={"md"}
				centered={true}
			>
				<AddNewPassengerModal manaul={true} setPassengerData={(e)=>formatDataEdit(e)}  />
				{/* <MRZ checkedIds={checkedIds} /> */}
			</CustomModal>
      {/* } */}


    </div>
  );
}
