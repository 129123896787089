import TextField from "components/Form/TextField/TextField";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SearchIcon from "assets/images/crm/search-normal.png";
import AddIcon from "assets/images/crm/add.png";
import { accountingPaymentsList, deletePayment } from "services/CRM";
import moment from "moment";
import NoDataIcon from "assets/images/crm/no-data.png";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import Locale from "translations";
import validate, { isFormValid } from "helpers/validate";
import { store } from "react-notifications-component";
import { addPayments } from "services/productbuilder";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CRM from '..';

export default function AccountingPaymentModel(props) {
	const { inventory, payment , CRM} = Locale;
	const [paymentData, setpaymentData] = useState([]);
	const [paymentsState, setPaymentsState] = useState({
		amount: "",
		pay_date: undefined,
	});
	const [filter, setFilter] = useState({
		search:"",
		page:1
	})
	const [Reload, setReload] = useState(false);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const toggle=()=>setIsOpen(prev=>!prev)
	function checkFormErrors() {
		let SubmitError = {};
		Object.keys(paymentsState).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: paymentsState[key] },
					{ required: true }
				),
			};
		});
		setErrors(() => SubmitError);
	}
	useEffect(() => {
		async function fetchPayments() {
			const res = await accountingPaymentsList(props.id, {...filter});
			setpaymentData(res?.data?.data);
		}
		fetchPayments();
	}, [props.id, filter,Reload]);
	useEffect(() => {
		if (isFormValid(errors)) {
			const addPaymentsFn = async () => {
				let data = {
					package_reservation_id: +props.id,
					amount: +paymentsState?.amount,
					pay_date: moment(paymentsState?.pay_date).format("YYYY-MM-DD"),
				};
				const response = await addPayments(data);
				if (response.status === 200 || response.status === 201) {
					store.addNotification({
						title: "info!",
						message: response.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 1500,
							onScreen: true,
							pauseOnHover: true,
						},
						onRemoval: () => {
							setReload((prev) => !prev);
							 toggle();
							setPaymentsState({
								amount: "",
								pay_date: undefined,
							});
						},
					});
				}
			};
			addPaymentsFn();
		}
	}, [isErrorLoaded]);

	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}
	const deletePay= async(id)=>{
		
		const response = await deletePayment(id);
		if (response.status === 200 || response.status === 201) {
			store.addNotification({
				title: "info!",
				message: response.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setReload((prev) => !prev);
					//  toggle();
					// setPaymentsState({
					// 	amount: "",
					// 	pay_date: undefined,
					// });
				},
			});
		}
				
	}
	return (
		<Modal isOpen={props.isOpen} className="accounting-payment-model" size="lg">
			<ModalHeader className="font-weight-bold" toggle={props.toggle}>
				{CRM.Payment}
			</ModalHeader>
			<ModalBody className="p-0">
				<Filter filter={filter} setFilter={setFilter} toggle={toggle}/>
				<AddAmount
					paymentData={paymentData}
					paymentsState={paymentsState}
					setPaymentsState={setPaymentsState}
					errors={errors}
					setErrors={setErrors}
					submit={submit}
					selectedItem={props.selectedItem}
					isOpen={isOpen} 
					toggle={toggle}
				/>
				<PaymentList paymentData={paymentData}  deletePay={deletePay}/>
			</ModalBody>
		</Modal>
	);
}
export function Filter({toggle,filter, setFilter }) {
	const { 	CRM } = Locale;

	return (
		<div className="filter">
			<div className="row w-100">
				<div className="col-md-6">
					<TextField
						type="text"
						hasLabel={false}
						placeholder={CRM.Search}
						isImage={true}
						image={SearchIcon}
						prependImage={true}
						value={filter.search}
						onChange={(e)=>{
							setFilter({
								...filter,
								search: e.target.value
							})
						}}
					/>
				</div>
			</div>
			<button className="btn d-flex" onClick={()=>toggle()}>
				{" "}
				<img src={AddIcon} alt=" add payment" /> {CRM.Add}
			</button>
		</div>
	);
}
export function PaymentList({ paymentData,deletePay }) {
	const { 	CRM } = Locale;

	return (
		<div className="list">
			<table className="p-2 bg-white table ">
				<thead>
					<tr>
						<th>{CRM.InvoiceId}</th>
						<th>{CRM.Amount}</th>
						<th>{CRM.PayDate}</th>
						<th>{CRM.online}</th>
						<th width="8%">{CRM.Action}</th>
					</tr>
				</thead>
				<tbody>
					{paymentData && paymentData?.length > 0 ? (
						paymentData.map((item) => (
							<tr key={item?.id}>
								<td>{item?.id}</td>
								<td>{item?.amount}</td>
								<td>{moment(item?.pay_date).format("DD.MMM.YYYY")}</td>
								<td>{item?.is_online ? "Yes" : "No"}</td>
								<td>
									<span className="bg-light-danger px-2" role="button" onClick={()=>deletePay(item?.id)}>
										<i class="far fa-times-circle text-danger"></i>
									</span>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="5">
								<div className="product-build__product-no-data fullHeight no-result">
									<img src={NoDataIcon} alt=" No Results" />
									<h4>{CRM.noResults}</h4>
									<p>{CRM.fillSearch}</p>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}
export function AddAmount({
	selectedItem,
	paymentsState,
	setPaymentsState,
	errors,
	setErrors,
	submit,
	isOpen,
	toggle
}) {
	const { inventory, payment } = Locale;

	return (
		<Modal centered isOpen={isOpen} toggle={toggle} hasFooter={false} className='cs-addPayment'>
		<ModalHeader className="align-items-center" toggle={toggle}>
			{inventory.messages.addpayment}
		</ModalHeader>
		<ModalBody>
			<div className="container">
				<div className="row payment-mode1l mb-2">
					<div className="col-12 d-flex justify-content-evenly mt-2 mb-4">
						<div className="col-4 d-flex Total">
							<p className="model-lebal">
								{inventory.messages.totalPrice}
							</p>
							<p className="model-amount ">
								{selectedItem?.total_price}{" "}
								{selectedItem?.currency}{" "}
							</p>
						</div>
						<div className="col-4 d-flex Paid">
							<p className="model-lebal ">{payment.messages.paid}</p>
							<p className="model-amount">
								{selectedItem?.payments_total_paid}{" "}
								{selectedItem?.currency}{" "}
							</p>
						</div>
						<div className="col-4 d-flex Outstanding">
							<p className="model-lebal ">{payment.messages.Outstanding}</p>
							<p className="model-amount">
							{selectedItem?.payments_outstanding}{" "}
								{selectedItem?.currency}
							</p>
						</div>
					</div>
					<div className="col-md-4">
						<TextField
							label={payment.messages.amount}
							type="text"
							placeholder={inventory.messages.enteramount}
							name="amount"
							value={paymentsState.amount}
							onChange={(e) => {
								setPaymentsState({
									...paymentsState,
									amount: e.target.value,
								});
								setErrors({
									...errors,
									...validate(
										{ name: "amount", value: e.target.value },
										{ required: true }
									),
								});
							}}
							color={errors?.amount?.required ? "danger" : ""}
							errors={errors?.amount}
						/>
					</div>
					<div className="col-md-4 ">
						<DatePickerField
							label={inventory.messages.ondate}
							placeholder={"DD/MM/YYYY"}
							name="pay_date"
							date={paymentsState.pay_date?moment(paymentsState.pay_date):""}
							onChangeDate={(e) => {
								setPaymentsState({ ...paymentsState, pay_date: e });
								setErrors({
									...errors,
									...validate(
										{ name: "pay_date", value: e },
										{ required: true }
									),
								});
							}}
							isOutsideRange={(day) => {
								return false;
							}}
							color={errors?.pay_date?.required ? "danger" : ""}
							errors={errors?.pay_date}
						/>
					</div>
					<div className="col-12 d-flex justify-content-end align-items-center ">
						<button className="btn w-100  py-2 btn-model mt-3" onClick={submit}>
							{inventory.messages.addpayment}
						</button>
					</div>
				</div>
			</div>
		</ModalBody>
	</Modal>
		// <div className="cs-addPayment">
		// 	<div className="row payment-model mb-2 mx-0">
		// 		<div className="col-12 d-flex justify-content-evenly my-4">
		// 			<div className="col-4 d-flex Total">
		// 				<p className="model-lebal">{inventory.messages.totalPrice}</p>
		// 				<p className="model-amount ">
		// 					{selectedItem?.total_price} {selectedItem?.currency}{" "}
		// 				</p>
		// 			</div>
		// 			<div className="col-4 d-flex Paid">
		// 				<p className="model-lebal ">{payment.messages.paid}</p>
		// 				<p className="model-amount">
		// 					{selectedItem?.payments_total_paid} {selectedItem?.currency}{" "}
		// 				</p>
		// 			</div>
		// 			<div className="col-4 d-flex Outstanding">
		// 				<p className="model-lebal ">{payment.messages.Outstanding}</p>
		// 				<p className="model-amount">
		// 					{selectedItem?.payments_outstanding} {selectedItem?.currency}
		// 				</p>
		// 			</div>
		// 		</div>
		// 		<div className="col-6">
		// 			<TextField
		// 				label={payment.messages.amount}
		// 				type="text"
		// 				placeholder={inventory.messages.enteramount}
		// 				name="amount"
		// 				value={paymentsState.amount}
		// 				onChange={(e) => {
		// 					setPaymentsState({
		// 						...paymentsState,
		// 						amount: e.target.value,
		// 					});
		// 					setErrors({
		// 						...errors,
		// 						...validate(
		// 							{ name: "amount", value: e.target.value },
		// 							{ required: true }
		// 						),
		// 					});
		// 				}}
		// 				color={errors?.amount?.required ? "danger" : ""}
		// 				errors={errors?.amount}
		// 			/>
		// 		</div>
		// 		<div className="col-6 ">
		// 			<DatePickerField
		// 				label={inventory.messages.ondate}
		// 				placeholder={"DD/MM/YYYY"}
		// 				name="pay_date"
		// 				date={paymentsState.pay_date ? moment(paymentsState.pay_date) : ""}
		// 				onChangeDate={(e) => {
		// 					setPaymentsState({ ...paymentsState, pay_date: e });
		// 					setErrors({
		// 						...errors,
		// 						...validate({ name: "pay_date", value: e }, { required: true }),
		// 					});
		// 				}}
		// 				isOutsideRange={(day) => {
		// 					return false;
		// 				}}
		// 				color={errors?.pay_date?.required ? "danger" : ""}
		// 				errors={errors?.pay_date}
		// 			/>
		// 		</div>
		// 		<div className="col-12 d-flex justify-content-end align-items-center ">
		// 			<button className="btn w-50  py-2 btn-model" onClick={submit}>
		// 				{inventory.messages.addpayment}
		// 			</button>
		// 		</div>
		// 	</div>
		// </div>
	);
}
