import TransportUmrahSearchForm from "../NewMarketplace/ViewServices/ServicesSearch/Transfer/TransportUmrahForm"
import bannerUmrah from "assets/images/market-place/marketplace-umrah-bg.jpg";
import { useLocation, useHistory } from "react-router-dom";
import LocationIcon from "../NewMarketplace/icons/location";
import { Fragment, useState } from "react";
import { ReactComponent as CheckMarkIcon } from "assets/images/market-place/checkmark-circle-icon.svg";
import { ReactComponent as IconDueAmount } from "assets/images/duaIcon.svg";
import { formatPrice } from 'helpers/utils';
import { useSBSState } from 'context/global';
import CheckBox from 'components/chekbox';
import NewPaymentAction from 'components/NewPaymentAction';
import { paymentTransportation } from 'services/transportation';
import Locale from 'translations';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const fetchURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function TransferUmrahList() {
  //*** hooks
  const { marketPlace, operationStatement, productElements } = Locale;
  const { currency, locale } = useSBSState();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  // url params
  const params = {
    cycle_type: searchParams.get("cycle_type"),
    start_date: searchParams.get("start_date"),
    vehicle_type: searchParams.get("vehicle_type"),
    vehicle_model: searchParams.get("vehicle_model"),
    vehicle_seats: searchParams.get("vehicle_seats"),
  }

  // *** states
  const [transferData, setTransferData] = useState({
    supplier: {
      name: "",
      cycleType: "",
      image: null,
    },
    list: [],
    acceptedTerms: false
  });
  const [termsModalOpen, seTermsModalOpen] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});

  // *** variables
  const totalPrice = transferData.list.reduce((acc, curr) => acc + (curr.quantity * curr.price), 0);
  // *** functions
  function handleSearchData(data) {
    setTransferData({
      supplier: {
        name: data?.residences?.[0]?.supplier?.name,
        cycleType: data?.name,
      },
      list: data?.residences?.map(item => {
        delete item.residences;
        delete item.cycle_type;
        item.quantity = 0;
        return item;
      }),
    })
  }



  function handleVehicleQuantity({ index, value }) {
    let clonedTransfer = { ...transferData }
    clonedTransfer.list[index].quantity = value;
    setTransferData(clonedTransfer)
  }

  function toggleModelPayment() {
    setActionType('')
    setFormData({})
    setModalPayment(!modalPayment);
  };

  function toggleTermsModal() {
    seTermsModalOpen(!termsModalOpen)
  }

  async function bookTransportation(paymentType, currency, otp) {

    const data = {
      vehicles: transferData.list.filter(item=> item?.quantity > 0).map(item => ({
        id: item?.id,
        count: item?.quantity
      })),
      currency: currency,
      payment_method: paymentType,
    }

    const response = await paymentTransportation(data);

    if (response.status === 200) {
      history.push({ pathname: "/inventory/transfer" });
      toggleModelPayment();
    } else if (response?.status === 202) {
      setActionType(response?.data?.actionType)
      setFormData(response?.data?.data?.formData)

    } else if (response?.status === 202) {
      setActionType(response?.data?.actionType)
      setFormData(response?.data?.data?.formData)
    }
  }

  return (
    <div className="mp-transfer-response">
      <div className="transfer-response-header" style={{ backgroundImage: `url(${bannerUmrah})` }}>
        <div className="col-12 col-md-8 mx-auto">
          <TransportUmrahSearchForm
            searchParams={params}
            isModify={true}
            handleSearchData={handleSearchData}
          />
        </div>
      </div>
      {transferData.supplier.name &&
        <div className="details-wrapper">
          <div className="row mx-0 my-5">

            <div className="col-md-8 col-12">
              <div className="transfer-response-details">
                <img src={bannerUmrah} alt="" />
                <div className="d-flex flex-column gap-10">
                  <h1>{transferData.supplier.name}</h1>
                  <p>
                    <LocationIcon />
                    <span className="mx-1">{transferData.supplier.cycleType}</span>
                  </p>
                </div>
              </div>

              <ul className="transfers-list mt-4">
                {transferData.list.map((item, index) => {
                  return (
                    <li className="list-item" key={item?.id}>
                      <div className="d-flex gap-10">
                        <img src={`${fetchURL}/${item?.photo}`} alt="" />
                        <h2>{item?.vehicle_type?.name}</h2>
                      </div>
                      <div className="item-spec">

                        {/* item  Features*/}
                        <div className="d-flex flex-column gap-10">
                          <span>{marketPlace.messages.features}</span>
                          <span className="d-flex align-items-center">
                            <CheckMarkIcon className="tick-circle" />
                            <span className="text-light mx-1">{operationStatement.Model} {item?.vehicle_model?.name}</span>
                          </span>
                        </div>

                        {/* item  Capacity*/}
                        <div className="d-flex flex-column gap-10">
                          <span>{marketPlace.messages.capacity}</span>
                          <span className="text-light">{item?.seats_count}</span>
                        </div>

                        {/* item Price */}
                        <div className="d-flex flex-column gap-10">
                          <span>{marketPlace.Price}</span>
                          <span className="price-text">{item?.price} {item?.currency}</span>
                          <span className="text-light">{marketPlace.forOneVehicle}</span>
                        </div>
                      </div>
                      {/* item  quantity */}
                      <div className="item-quantity">
                        <QuantityInput
                          decrease={(currentValue) => handleVehicleQuantity({ index, value: currentValue })}
                          increase={(currentValue) => handleVehicleQuantity({ index, value: currentValue })}
                          quantity={item.quantity}
                        />
                      </div>
                    </li>
                  )
                })}
              </ul>

            </div>

            {/* summary */}
            <div className="col-md-4 col-12">
              <div className="summary-wrapper">
                <h3 className="font-weight-bold">{marketPlace.messages.bookingSummary}</h3>
                <hr />
                {/* summary items */}
                <ul className="summary-list">
                  {transferData.list.filter(item => item?.quantity > 0).map((item) => {
                    return (
                      <Fragment key={`summary-${item?.id}`}>
                        <li className="summary-item my-3">
                          <img src={`${fetchURL}/${item?.photo}`} alt="" />
                          <div className="w-100">
                            <h4 className="font-weight-bold">{item?.vehicle_type?.name}</h4>
                            <p className="d-flex justify-content-between mt-1">
                              <span className="text-light">{marketPlace.transportationVehicleCount}</span>
                              <span>{item.quantity}</span>
                            </p>
                            <p className="d-flex justify-content-between mt-1">
                              <span className="text-light">{marketPlace.Price} </span>
                              <span className="price-text">
                                {formatPrice(+item?.price * +item.quantity)} {item?.currency}
                              </span>
                            </p>
                          </div>
                        </li>
                        <hr />
                      </Fragment>
                    )
                  })}
                </ul>
                <div className="summary-final-price">
                  <p className="d-flex align-items-center justify-content-between py-2">
                    <span className="text-light">{operationStatement.Total}</span>
                    <span className="h5">
                      {formatPrice(totalPrice)} {currency}
                    </span>
                  </p>
                </div>
              </div>
              <div className="checkout-wrapper mt-3">
                <div className="d-flex align-items-center gap-10">
                  <IconDueAmount width="42px" height="42px" />
                  <div className="d-flex flex-column">
                    <span className="total-title">{marketPlace.ReservationTotalCost}</span>
                    <p className="price">
                      <span className="amount">{formatPrice(totalPrice)}</span>
                      {" "}{currency}
                    </p>
                  </div>
                </div>

                <button className="btn book-btn"
                  disabled={+totalPrice === 0 || !transferData.acceptedTerms}
                  onClick={() => setModalPayment(true)}
                >
                  {marketPlace.bookNow}
                </button>
                {/* terms and condition */}
                <div className="d-flex terms-condition">
                  <CheckBox
                    name={"terms-condition"}
                    checked={transferData.acceptedTerms}
                    onChange={() => setTransferData({ ...transferData, acceptedTerms: !transferData.acceptedTerms })}
                    text={
                      <p className="d-flex mt-1">
                        <span> {marketPlace.iHaveReadAndAccepted}</span>
                        <button className="btn p-0 shadow-none h6 mx-1" onClick={toggleTermsModal}>
                          {marketPlace.termsAndCondition}
                        </button>
                      </p>
                    }
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      }

      {modalPayment && (
        <NewPaymentAction
          isOpen={modalPayment}
          toggleModal={toggleModelPayment}
          canPayLater={false}
          formData={formData}
          actionType={actionType}
          Price={totalPrice}
          currency={currency}
          secondCurrency={transferData.list[0]?.provider_currency}
          onPayment={bookTransportation}
          setActionType={setActionType}
          setFormData={setFormData}
        />
      )}

      {/* terms and condition modal */}
      <Modal isOpen={termsModalOpen} centered size="lg" className="mp-transportation-terms-modal">
        <ModalHeader toggle={toggleTermsModal}>
          {productElements.cancelationPolicy}
        </ModalHeader>
        <ModalBody>
          <ul className="ps-4">
            {termsAndConditionList[locale || "en"].map((term, index) => {
              return (
                <li key={`term-${locale}-${index}`} className="my-3">
                  {term}
                </li>
              )
            })}
          </ul>
        </ModalBody>
      </Modal>
    </div>
  )
}



function QuantityInput({ increase, decrease, quantity }) {
  const [current, setCurrent] = useState(quantity);
  function handleIncrease() {
    let newCurrent = current + 1;
    setCurrent(newCurrent)
    return increase(newCurrent)
  }
  function handleDecrease() {
    let newCurrent = current - 1;
    setCurrent(newCurrent);
    return decrease(newCurrent)
  }
  return (
    <div className="quantity-input">

      {/* decrease btn */}
      <button className="btn p-0"
        disabled={quantity === 0}
        onClick={handleDecrease}
      >
        <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.0001 1H1" stroke="#D4A655" stroke-width="2" stroke-linecap="round" />
        </svg>
      </button>

      <span>{quantity}</span>

      {/* increase btn */}
      <button className="btn increase-btn p-0" onClick={handleIncrease}>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.00007 13.0001L7.00007 0.99993M13.0001 7L1 7" stroke="#D4A655" stroke-width="2" stroke-linecap="round" />
        </svg>
      </button>
    </div>

  )
}


let termsAndConditionList = {
  en: [
    "The above prices are with Saudi Riyal. All Rates are subject to change without prior notice at any time.",
    "Individual trip rate will be used for any non-complete round trip, or if changes were made after the allowed change time.",
    "In case of No-Show'Cancelation'Amendments: - within 24 hours prior to the trip time, penalty of full sector cost will be applied. - within 48 hours prior to the trip time, penalty of 50% of sector charge will be applied.",
    "Maximum waiting time is 20 minutes after the scheduled time, and 90 minutes at Airports as per scheduled fight arrival time, after that the clients will be considered as No-Show, full charge is applied, and trip will be cancelled.",
    "In case the requested vehicle is not available, an equivalent vehicle will be arranged with no change in price, there is no guarantee that the same vehicle will be provided for all trips",
    "If Qasswa couldn’t deliver the service for a trip for any reason, the affected sector only will be refunded. Qasswa will Not be held responsible for any other circumstances or delays.",
    "Mazarath trip is performed daily from 7:00 am to 10:00 am only. There is no tour guide along with Mazarat while we can’t assure completing all sites in the Mazarat as it depends on the availability of locations",
    "Qasswa shall not be hold any liability for any baggage or items left in the vehicle after drop-off.",
    "Any damage caused by passengers to vehicles’ interior or exterior, he will be asked for immediate charge of his trip’s cost or more based on the damage done. If passenger refuse to pay such charges, the agent who made the reservation is obliged to pay such charges to Qasswa",
    "Smoking is strictly prohibited inside vehicles. Otherwise, a charge of SAR 500 must be paid by passenger. If passenger refuse to pay such charges, the agent who made the reservation is obliged to pay such charges to Qasswa.",
    "Vehicle are restricted to its capacity of passengers and luggage. No extras will be allowed.",
    "Approval and payment of reservation will confirm reading and accepting the terms and conditions above",
    "The above prices are valid to 14 Feb 2025 / 15 Shaaban 1446",
    "For inquiries and assistance, contact us on +966 500 644 755."
  ],
  ar: [
    "الأسعار أعلاه خاصة بالعمرة وهي بالريال السعودي، ويجوز تغييرها من شركة القصواء دون إشعار مسبق.",
    "في حال تغيير البرامج المتفق عليها، يحق للقصواء تعديل الأسعار لأسعار أجزاء الرحلة منفصلة.",
    "في حال عدم حضور العميل أو في حال إلغاء الرحلة أو تعديلها قبل أقل من 24 ساعة من موعد الرحلة، يتم احتساب كامل قيمة الرحلة.",
    "في حال عدم حضور العميل أو في حال إلغاء الرحلة أو تعديلها قبل أقل من 48 ساعة من موعد الرحلة يخصم %50 من قيمة الرحلة",
    "وقت انتظار السيـارة للعميل هو 20 دقيقـة من بداية رحلته، وفي حـال الوصـول من المطار يكون الانتظار 90 دقيقة من موعد الوصول المجدول بعدها سيعتبر الضيف غير حاضر وتخصم كامل قيمة الرحلة، وتلغى الرحلة.",
    "في حالة عدم تقديم الخدمة من القصواء لأي ظرف فيتم ارجاع مبلغ الرحلة المتأثرة فقط للعميل، دون أي مسؤولية عن أي أضرار أو تأخيرات أخرى لاحقة.",
    "في حالة عدم توفر المركبة المطلوبة، سيتم التعويض بمركبة أخرى من نفس النوع أو نوع آخر بدون تغيير القيمة. لا توجد أي ضمانة أن كل الرحلات ستكون بنفس المركبة.",
    "يتم عمل جولة المزارات كل يوم من الساعة السابعة إلى العاشرة صباحا فقط, لا يوجد مرشد مرافق للرحلة كما لا يمكن ضمان زيارة جميع المزارات في كل رحلة، و ذلك حسب الإمكانية.",

    "لا تتحمل القصواء أي مسؤولية عن أي أمتعة أو متعلقات يتم تركها داخل المركبات بعد إنتهاء الرحلة.",
    "أي ضرر يحصل للمركبة من الركاب سواء داخلي أو خارجي سيؤدي إلى تغريم الركاب مبلغا لا يقل عن قيمة الرحلة أو يزيد حسب حجم الضرر, وفي حالة رفض الركاب تحمل القيمة فتتعهد الجهة المستأجرة بدفع تلك المبالغ.",
    "التدخين ممنوع منعا باتا داخل مركبات القصواء, ويتحمل الراكب غرامة 500 ريال عن كل حالة في حال التدخين، وفي حالة رفض الركاب تحمل القيمة فتتعهد الجهة المستأجرة بدفع تلك المبالغ.",
    "لا يمكن تحميل المركبات بأقصى من قدرتها الإستيعابية للركاب والأمتعة.",
    "الموافقة على أي تسعيرة أو مشوار من القصواء يعني بالضرورة الموافقة على الشروط والأحكام عاليه وعدم الإطلاع عليها لا يعفي من الإلتزام بها.",
    "الأسعار أعلاه سارية يوم 14 فبراير 2025 م / 15 شعبان 1446 هـ .",
    "للاستفسار والمساعدة يرجى التواصل مع قسم خدمة العملاء 755 644 500 +",
  ],
}