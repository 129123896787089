const { roomTypesLookup } = require('modules/products-builder/Requestes/RequestView/helpers/localLookups');

export const initalRoomObj = {
  count: 0,
  meal_type: null,
  category: null,
  price_adult: "",
  price_child: 0,
  max_child: "",
}

export function formatPricingData(pricingData, allCurrencies, selected_trip, pricing_for, selected_pricing_for, currentCurrency) {
  return {
    ...pricingData,
    selected_trip,
    currency: pricingData?.basic_currency_id ? (allCurrencies?.find(currency => currency?.id === pricingData?.basic_currency_id)) : currentCurrency ? currentCurrency : { id: 95, currency_code: "SAR", name: "Saudi Riyal" },
    pricing_for: pricing_for || getPricingFor(pricingData),
    selected_pricing_for: selected_pricing_for || pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2b") ? "b2b" : pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2c") ? "b2c" : null,
    pricing: formatPricingCombinations(pricingData),
    room_types: pricingData?.room_types?.map(room => ({
      ...room,
      label: roomTypesLookup[room?.type],
      name: roomTypesLookup[room?.type],
      value: room?.type,
      id: room?.type,
    }))
  }
}


function getPricingFor(pricingData) {
  let pricingFor;
  const pricingContainB2B = pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2b");
  const pricingContainB2C = pricingData?.product_price_combinations?.find(c => c?.pricing_for === "b2c");

  if (pricingData?.product_price_combinations?.filter(c => c?.pricing_for)?.length === 0) return null;

  if (pricingContainB2B && !pricingContainB2C) {
    pricingFor = { id: 'b2b', name: 'B2B' }
  } else if (!pricingContainB2B && pricingContainB2C) {
    pricingFor = { id: 'b2c', name: 'B2C' }
  } else if (pricingContainB2B && pricingContainB2C) {
    pricingFor = { id: 'B2B_B2C', name: 'B2B & B2C' }
  }
  return pricingFor
}

function formatPricingCombinations(pricingData) {
  const productItems = pricingData?.product_items || [];
  const hotels = productItems.filter(item => item?.item?.id === 6);

  const pricing = [
    {
      for: "b2b",
      hasVat: +pricingData?.product_price_combinations[0]?.vat > 0 ? true : false,
      vat: pricingData?.product_price_combinations?.find(discount => discount?.pricing_for === "b2b")?.vat,
      hasDiscount: pricingData?.product_price?.discounts?.find(discount => discount?.pricing_for === "b2b"),
      discount: pricingData?.product_price?.discounts?.find(discount => discount?.pricing_for === "b2b"),
      product_items: productItems.map(formatProductItem("b2b")),
      hotels: hotels.map(formatProductHotels("b2b", pricingData)),
      product_price_combinations: [pricingData?.product_price_combinations?.find(discount => discount?.pricing_for === "b2b") || { child_original_price: 0, full_original_price: 0 }]
    },
    {
      for: "b2c",
      hasVat: +pricingData?.product_price_combinations[0]?.vat > 0 ? true : false,
      vat: pricingData?.product_price_combinations?.find(discount => discount?.pricing_for === "b2c")?.vat,
      hasDiscount: pricingData?.product_price?.discounts?.find(discount => discount?.pricing_for === "b2c"),
      discount: pricingData?.product_price?.discounts?.find(discount => discount?.pricing_for === "b2c"),
      product_items: productItems.map(formatProductItem("b2c")),
      hotels: hotels.map(formatProductHotels("b2c", pricingData)),
      product_price_combinations: [pricingData?.product_price_combinations?.find(discount => discount?.pricing_for === "b2c") || { child_original_price: 0, full_original_price: 0 }]
    },
  ];

  return pricing;
}



function formatProductItem(pricingFor) {
  return productItem => ({
    ...productItem,
    itemable: {
      ...productItem?.itemable,
      price_adult: productItem?.prices?.find(price => price?.pricing_for === pricingFor)?.price_adult,
      price_child: productItem?.prices?.find(price => price?.pricing_for === pricingFor)?.price_child,
      price_infant: productItem?.prices?.find(price => price?.pricing_for === pricingFor)?.price_infant,
    },
  });
}

function formatProductHotels(pricingFor, pricingData) {
  let isFullpackagePricing = pricingData?.product_price?.product_price_type?.id === 1;
  return hotel => {
    let pricesArray = isFullpackagePricing
      ? pricingData?.product_price_combinations?.filter(price => price?.pricing_for === pricingFor)
      : hotel?.prices?.find(price => price?.pricing_for === pricingFor)?.rooms_prices;

    const hotelName = hotel?.itemable?.hotel_name;
    const roomTypes = pricingData?.room_types?.map(roomType => {
      const roomId = hotel?.itemable?.rooms?.find(room => +room?.room_type === roomType?.type)?.id;
      const productHotelId = hotel?.itemable?.rooms?.find(room => +room?.room_type === roomType?.type)?.product_hotel_id || null;
      const rooms = pricingData?.product_price_combinations?.length > 0 || hotel?.prices?.length > 0
        ? getRoomPrice(pricesArray, roomType?.type, isFullpackagePricing)
        : [{ ...initalRoomObj, room_type: roomType?.room_type }];

      return {
        count: roomType?.count,
        type: roomType?.type,
        id: roomId,
        product_hotel_id: productHotelId, rooms
      };
    });

    return {
      hotel_name: hotelName,
      id: hotel?.id,
      hotelId: hotel?.itemable?.id,
      rooms_types: roomTypes
    };
  };
}

function getRoomPrice(roomsPricesArray, roomType, isFullpackagePricing) {
  let roomsWithPrices = [];
  if (isFullpackagePricing) {
    roomsWithPrices = roomsPricesArray?.filter(room => +room?.combinations[0]?.room_type === +roomType).map(roomData => {
      return {
        count: roomData?.combinations[0]?.count,
        price_adult: roomData?.full_original_price,
        price_child: roomData?.child_original_price,
        max_child: roomData?.combinations[0]?.max_child,
        category: { id: roomData?.combinations[0]?.room_category, name: roomData?.combinations[0]?.room_category, label: roomData?.combinations[0]?.room_category },
        meal_type: { id: roomData?.combinations[0]?.meal_type, name: roomData?.combinations[0]?.meal_type, label: roomData?.combinations[0]?.meal_type }
      }
    })
  } else {
    roomsWithPrices = roomsPricesArray?.filter(room => +room?.room_type === roomType).map(roomData => {
      return {
        ...roomData,
        category: { id: roomData?.room_category, name: roomData?.room_category, label: roomData?.room_category },
        meal_type: { id: roomData?.meal_type, name: roomData?.meal_type, label: roomData?.meal_type }
      }
    })
  }


  return roomsWithPrices
}