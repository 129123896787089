import { roomTypesLookup } from 'modules/products-builder/Requestes/RequestView/helpers/localLookups';

export function formatPaxForSharedReservation(reservationDetails, adultsPaxCount, childsPaxCount) {
  debugger
  if (!reservationDetails) return { adults: [], children: [] };

  const product = reservationDetails.product || reservationDetails;
  const productPriceBreakDown = product?.product_price?.product_price_type?.id === 0;
  const combinations = product?.product_price_combinations || [];
  const travelers = reservationDetails.travelers || [];

  // Formatting individual travelers
  const formattedTravelers = travelers.map(traveler => formatTraveler(traveler, roomTypesLookup, productPriceBreakDown, combinations));

  const productAdultsTravelers = formattedTravelers.filter(traveler => traveler.is_adult);
  const productChildsTravelers = formattedTravelers.filter(traveler => !traveler.is_adult);

  // Adding missing travelers
  const newAdults = adultsPaxCount > productAdultsTravelers.length ? addMissingTravelers(adultsPaxCount, productAdultsTravelers, true) : [];
  const newChilds = childsPaxCount > productChildsTravelers.length ? addMissingTravelers(childsPaxCount, productChildsTravelers, false) : [];
  return {
    adults: [...productAdultsTravelers, ...newAdults],
    children: [...productChildsTravelers, ...newChilds],
  };
}


// Function to calculate price for a traveler based on combination
function calculatePrice(traveler, combination, productPriceBreakDown) {
  if (!combination) return 0;
  if (!productPriceBreakDown) {
    return traveler.is_adult ? combination.full_price_after_discount : combination.child_price_after_discount || 0;
  } else {
    return combination.combinations.reduce((total, combo) => total + (traveler.is_adult ? combo.price_adult : combo.price_child || 0), 0);
  }
}

// Function to format an individual traveler
function formatTraveler(traveler, roomTypesLookup, productPriceBreakDown, combinations) {
  const combination = traveler.combination_id ? combinations.find(comb => comb.id === traveler.combination_id) : null;
  const price = calculatePrice(traveler, combination, productPriceBreakDown);

  return {
    id: traveler.id,
    roomType: {
      value: traveler.roomAvailabilities[0]?.room_type,
      id: traveler.roomAvailabilities[0]?.room_type,
      label: roomTypesLookup[traveler.roomAvailabilities[0]?.room_type],
      name: roomTypesLookup[traveler.roomAvailabilities[0]?.room_type],
      room_type: traveler.roomAvailabilities[0]?.room_type,
    },
    price: traveler.roomAvailabilities?.length > 0 ? price : 0,
    combination_id: traveler.combination_id,
    is_adult: traveler.is_adult
  };
}
// Function to add missing travelers
function addMissingTravelers(paxCount, travelersList, isAdult) {
  let random = Math.floor(Math.random() * 50)
  const newTravelers = [];
  const missingCount = paxCount - travelersList.length;
  for (let i = 0; i < missingCount; i++) {
    newTravelers.push({ id: `new_${isAdult ? 'adult' : 'child'}_${i}-${random}`, price: 0, is_adult: isAdult });
  }
  return newTravelers;
}

export function generatePrivateRoomsType(roomsAvailabilities, privateRooms) {
  let privateRoomsTypes = [];
  if (roomsAvailabilities) {
    privateRoomsTypes = roomsAvailabilities?.filter(room => room?.room_remaining_availability > 0)?.map((room) => {
      const roomCount = privateRooms?.find(r => r?.roomType?.room_type === room?.room_type)?.roomCount || 0;
      const roomRemainingCount = room?.room_remaining_availability > 0 ? Math.floor((room?.room_remaining_availability / +room?.room_type)) : roomCount;
      return {
        id: room?.id,
        room_type: room?.room_type,
        name: roomTypesLookup?.[room?.room_type],
        value: room?.room_type,
        label: roomTypesLookup?.[room?.room_type],

        roomRemainingAvailability:
          [...Array(roomRemainingCount || 0).keys()].map(i => (
            { id: i + 1, value: i + 1, label: `${i + 1}`, name: `${i + 1}` }
          )),
        disabled: privateRooms?.find(r => r?.roomType?.room_type === room?.room_type) ? true : false,
      };
    });
  }

  return privateRoomsTypes
}

export function formatPrivateRooms(reservation) {
  let childCount = 0;
  let adultsCount = 0;
  let servicesList = reservation?.product?.product_items;
  let hotelsCount = servicesList?.filter(service => service?.item?.id === 6).length;
  const combinations = reservation?.product?.product_price_combinations;
  const roomAvailabilities = reservation?.roomAvailabilities;

  let roomsList = roomAvailabilities?.map((room, index) => {
    const prevRoom = index > 0 ? roomAvailabilities[index - 1] : null;
    const nextRoom = roomAvailabilities[index + 1];
    const combination = combinations?.find(comb => comb?.combinations[0]?.room_type === room?.room_type?.toString());
    // get last index of room type to get full count of room type
    let indexOfLastType = nextRoom?.room_type !== room?.room_type ? index + 1 : null;
    // reset if room type changed
    if (prevRoom && prevRoom?.room_type !== room?.room_type) {
      adultsCount = 0;
      childCount = 0;
    }
    adultsCount = adultsCount + room?.adults_count;
    childCount = childCount + room?.children_count;
    const roomCount = indexOfLastType ? adultsCount / room?.room_type / hotelsCount : 0;

    return {
      indexOfLastType,
      roomType: {
        id: room?.room_type,
        value: room?.room_type,
        name: roomTypesLookup[room?.room_type],
        label: roomTypesLookup[room?.room_type],
        room_type: room?.room_type
      },
      roomCount: roomCount,
      childCount: childCount / hotelsCount,
      combination_id: combination?.id,
      childrenAvailability: indexOfLastType && [...Array((roomCount * 2) + 1).keys()].map(count => ({ name: `${count}`, id: `${count}`, value: `${count}` })),
    }
  });


  roomsList = roomsList.filter(room => room?.indexOfLastType);

  return roomsList;
}

