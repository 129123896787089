import TextField from 'components/Form/TextField/TextField'
import { SettingIcon } from 'modules/UmrahOperations/shared/Icons'
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Locale from 'translations'
import validate, { isFormValid } from 'helpers/validate';
import { setSerialCode } from 'services/operationStatement';
import { useHistory } from 'react-router-dom';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';

export default function OperatingNewSerialModal({ isOpen, toggle }) {
  const { operationStatement, marketPlace } = Locale;
  const history = useHistory();
  const [serialState, setSerialState] = useState({
    type: "create",
    operating_serial_code: "",
    operating_serial_start: "",
  });
  const [errors, setErrors] = useState({});

  function checkFormErrors() {
    let submitErrors = {}
    Object.keys(serialState).forEach(key => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: serialState?.[key] },
          { required: key !== "phone_country", }
        ),
      }
    });
    setErrors({ ...submitErrors });
    return submitErrors;
  }

  async function submit() {
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) return;
    // call api 
    const { operating_serial_code, operating_serial_start } = serialState;
    const res = await setSerialCode({ operating_serial_code, operating_serial_start });
    if (res?.status === 200 || res?.status === 201) {
      handleResponesNotification({ alertType: "success", message: res?.data?.message });
      history.push("/operations/operation-view");
    }
  }



  return (
    <Modal isOpen={isOpen} centered className="operation-modal">
      <ModalHeader toggle={toggle}>
        <SettingIcon color="#292D32" />
        <span className="mx-2 font-weight-bold" style={{ fontSize: "18px" }}>{operationStatement.operatingSerial}</span>
      </ModalHeader>
      <ModalBody>
        {/* radio groups */}
        <div className="d-flex align-items-center gap-24">

          <div className="d-flex align-items-center">
            <input
              id="create-serial"
              name="create-serial"
              type="radio"
              class="radio-input"
              checked={serialState.type === "create"}
              value={"create"}
              onChange={(e) => {
                setSerialState({ ...serialState, type: e.target.value });
                setErrors({})
              }}
            />
            <label htmlFor="create-serial" className="mb-0 mx-2 pointer user-select-none">
              {operationStatement.createSerialManual}
            </label>
          </div>

          <div className="d-flex align-items-center">
            <input
              id="auto-serial"
              name="auto-serial"
              type="radio"
              class="radio-input"
              checked={serialState.type === "auto"}
              value={"auto"}
              onChange={(e) => {
                setSerialState({ ...serialState, type: e.target.value });
                setErrors({})
              }}
            />
            <label htmlFor="auto-serial" className="mb-0 mx-2  pointer user-select-none">
              {operationStatement.autoSerial}
            </label>
          </div>
        </div>
        {/* inputs */}
        <div className="mt-3">
          <label htmlFor="" className="control-field__label">
            <span>{operationStatement.operatingNumberSerial}</span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 100, hide: 100 }}
              overlay={<Tooltip id={"operating-Serial"}>
                <p>{operationStatement.enterCountAlphabetical}</p>
                <p>{operationStatement.countNumbers}</p>
              </Tooltip>
              }
              trigger={["hover", "hover"]}
              onEnter={(e) => {
                e.querySelector('.tooltip-inner').style.maxWidth = '600px'
              }}
            >
              <i className="fas fa-exclamation-circle mx-1"></i>
            </OverlayTrigger>

          </label>

          <div className='row mx-0 align-items-center'>
            <div className="col-md-4 col-12 px-0">
              <TextField
                hasLabel={false}
                label={operationStatement.code}
                id="serial-text"
                name="serial-text"
                placeholder="SA"
                value={serialState.operating_serial_code}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    setSerialState({ ...serialState, operating_serial_code: e.target.value });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "operating_serial_code", value: e.target.value },
                        { required: true }
                      ),
                    });
                  }
                }}
                color={errors?.operating_serial_code?.required ? "danger" : ""}
                errors={errors?.operating_serial_code}
                disabled={serialState.type === "auto"}
              />
            </div>
            <span className='mx-1'>-</span>

            <div className='col-md-7 col-12 px-0'>
              <TextField
                type="number"
                hasLabel={false}
                label={operationStatement.number}
                id="serial-number"
                name="serial-number"
                placeholder="0100"
                value={serialState.operating_serial_start}
                onChange={(e) => {
                  setSerialState({ ...serialState, operating_serial_start: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "operating_serial_start", value: e.target.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.operating_serial_start?.required ? "danger" : ""}
                errors={errors?.operating_serial_start}
                disabled={serialState.type === "auto"}
              />
            </div>
          </div>

          <button className="btn save-btn"
            onClick={() =>
              serialState.type === "create"
                ? submit()
                : history.push("/operations/operation-view")
            }
          >
            {marketPlace.submit}
          </button>
        </div>


      </ModalBody>
    </Modal>
  )
}
