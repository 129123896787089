import React, { memo, useEffect, useState } from "react";
import CustomModal from "./CustomModal";
import SelectField from "components/Form/SelectField/SelectField";
import { getProductsList, getServiceBuilderList } from "services/webBuilder";
import { ReactComponent as UploadIcon } from "assets/images/webBuilder/upload-image-icon.svg";
import UploadFileComponent from "./UploadFile";
import Locale from 'translations';


let mapItemIdToServiceBuilder = {
	1: "builder_flight",
	2: "builder_transfer",
	6: "builder_hotel",
	7: "builder_tour",
	8: "builder_other",
}

const formatPackageList = (data) => {
	return data.map((i) => {
		let selectedItem = i.product_items.hotel
			? i.items.find((current) => current.item.id === 6) : "";
		const hotelName = selectedItem?.itemable?.hotel?.name
		const locationName = i?.country || selectedItem?.itemable?.country?.name;
		const country_id = i?.country_id || selectedItem?.itemable?.country?.id;
		return {
			product_uuid: i.product_uuid,
			id: i.id,
			name: i.name,
			departure_date_at: i.departure_date_at,
			return_date_at: i.return_date_at,
			stars: i.product_classification?.id ?? 0,
			hotelName,
			price: i?.price,
			currency: i?.currency,
			locationName,
			country: i?.country,
			country_id,
			product_type: i?.product_type,
		};
	});
};

const formatServiceList = (data, pickName) => {
	return data.map((packages) => {
		const country = packages?.country || packages?.items[0]?.itemable?.country?.name;
		const country_id = packages?.country_id || packages?.items[0]?.itemable?.country?.id;
		const city = packages?.items[0]?.itemable?.city?.name || packages?.items[0]?.itemable?.city_name || null;
		return {
			product_uuid: packages.product_uuid,
			id: packages.id,
			name: packages.name ?? pickName(packages?.items?.[0]?.itemable),
			price: packages?.price,
			currency: packages?.currency,
			stars: packages?.items?.[0]?.itemable?.hotel?.rating || 0,
			locationName: country,
			city,
			country_id
		};
	});
};

const formatFlightServiceList = (data) => {
	return data.map((flight) => {
		const flightItems = flight?.items?.map(item => {
			return {
				fromCountry: item?.itemable?.from_country,
				fromPort: item?.itemable?.from_port,
				toCountry: item?.itemable?.to_country,
				toPort: item?.itemable?.to_port,
			}
		})
		return {
			product_uuid: flight.product_uuid,
			id: flight.id,
			name: flight.name,
			price: flight?.price,
			currency: flight?.currency,
			flightItems,
		};
	});
}

const formatServiceBuilderList = (items, sourceModule) => {
	return items.map(item => {
		return {
			product_uuid: item.id,
			id: item.id,
			name: item?.name,
			nameLocale: item?.service?.name,
			nights_count: item?.service?.nights_count,
			stars: item?.service?.hotel_stars || item?.service?.stars,
			hotelName: item?.service?.hotel_name || item?.service?.items?.[0]?.item?.hotel_name,
			price: item?.price || 0,
			currency: item?.currency,
			country: item?.service?.country?.names || item.service.items?.[0].item?.country?.names,
			locationName: item?.service?.country?.names || item.service.items?.[0].item?.country?.names,
			country_id: item?.service?.country?.country_id || item.service.items?.[0].item?.country?.country_id,
			city: item?.service?.city?.name || item.service.items?.[0].item?.city?.name,
			images: item?.service?.images,
			vehicleTypeName: item?.service?.vehicle_type_name,
			vehicleModel: item?.service?.vehicle_type_name,
			withDriver: item?.service?.with_driver,
			vehicleCapacity: item?.service?.car_capacity,
			description: item?.service?.description,
			destination: item?.service?.destination?.names,
			sourceModule
		}
	})
}

const getServiceName = {
	1: () => null,
	2: () => null,
	6: (itemable) => itemable.hotel.name,
	7: (itemable) => itemable.name.en,
};

function AddService({
	open,
	handleModalState,
	onAdd,
	label,
	item_id,
	details,
	header,
	selectedIds = [],
	showPackageBuilderOption
}) {
	const { commons, webBuilder, Service_Builder } = Locale;
	const [image, setImage] = useState(null);
	const [value, setValue] = useState(null);
	// const [options, setOptions] = useState([]);
	const [sourceModule, setSourceModule] = useState(null) // package-builder / service-builder
	const [optionsList, setOptionList] = useState({
		"package-builder": null,
		"service-builder": null,
	})

	const isItemFlight = item_id === 1;

	const handleUpload = (value) => {
		setImage(value);
	};



	const fetchProductsList = async () => {
		const payload = {
			product_type_id: item_id ? 6 : undefined,
			...(!item_id && { no_service_type: 1 }),
			item_id,
		};
		let res;
		if (sourceModule === "service-builder") {
			res = await getServiceBuilderList(
				{ type: payload.no_service_type ? "builder_package" : mapItemIdToServiceBuilder[item_id] }
			)
		} else if (sourceModule === "package-builder") {
			res = await getProductsList(payload)
		}

		if (res?.status === 200) {
			const data = res.data.data?.filter(product => product?.is_has_price);
			let formatedOptions;

			if (isItemFlight && sourceModule !== "service-builder") {
				formatedOptions = formatFlightServiceList(data);
			} else if (sourceModule === "service-builder") {
				const serviceBuilderData = res?.data?.data?.filter(product => product?.price && +product?.price > 0 && !product?.in_website);
				formatedOptions = formatServiceBuilderList(serviceBuilderData, sourceModule)
			} else if (item_id) {
				formatedOptions = formatServiceList(data, getServiceName[item_id])
			} else {
				formatedOptions = formatPackageList(data);
			}
			setOptionList({ ...optionsList, [sourceModule]: formatedOptions });
		}
	};

	useEffect(() => {
		// for other service section
		if (item_id === 8 || (showPackageBuilderOption !== undefined && !showPackageBuilderOption)) {
			setSourceModule("service-builder");
		} else if (details) {
			setValue(details);
			setImage(details?.image);
			setSourceModule(details?.sourceModule || "package-builder");
		} else {
			setSourceModule("package-builder");
		}
		return () => { };
	}, [details]);

	useEffect(() => {
		if (!optionsList[sourceModule]) {
			fetchProductsList();
		}
		return () => { };
	}, [sourceModule]);

	const addService = () => {
		const data = {
			...value,
			sourceModule,
			image,
		};

		onAdd(data);
		handleModalState();
	};

	const onClosedModal = () => {
		setValue(null);
		setImage(null);
	};

	return (
		<CustomModal
			isOpen={open}
			header={`${webBuilder.adding} ${header}`}
			toggle={handleModalState}
			onClosed={onClosedModal}
		>
			<div className="adding-service-modal">
				<div className="d-flex gap-24 service-builder-holder px-0 pt-0">
					{/* package builder radio */}
					{(item_id !== 8) && 
						<div className="d-flex gap-10 align-items-center">
							<input
								type="radio"
								id="package-builder"
								name="package-builder"
								value="package-builder"
								checked={sourceModule === "package-builder"}
								onChange={(e) => {
									setSourceModule(e.target.value);
									if (details && details.sourceModule === e.target.value) {
										setValue(details);
										setImage(details?.image);
									} else {
										setValue(null);
										setImage(null);
									}
								}}
								disabled={showPackageBuilderOption !== undefined && !showPackageBuilderOption}
							/>
							<label htmlFor="package-builder" className="mb-0 pointer">Package Builder</label>
						</div>
					}

					{/* service builder radio */}
					<div className="d-flex gap-10 align-items-center">
						<input
							type="radio"
							id="service-builder"
							name="service-builder"
							value="service-builder"
							checked={sourceModule === "service-builder"}
							onChange={(e) => {
								setSourceModule(e.target.value);
								if (details && details.sourceModule === e.target.value) {
									setValue(details);
									setImage(details?.image);
								} else {
									setValue(null);
									setImage(null);
								}
							}}
						/>
						<label htmlFor="service-builder" className="mb-0 pointer">{Service_Builder.title}</label>
					</div>
				</div>
				<SelectField
					label={label}
					placeholder={`${commons.selectPlaceholder} ${header}`}
					value={value?.name}
					options={optionsList[sourceModule]?.filter((ele) => !selectedIds.includes(ele.id)) || []}
					onChange={(e) => {
						setImage(e?.images?.[0])
						setValue(e);
					}}
				/>
				{(!isItemFlight || sourceModule === "service-builder") &&
					<>
						<UploadFileComponent
							icon={UploadIcon}
							// you must change this Id every time you use this component to avoid any conflict
							id={`${header}-${item_id || 0}-image`}
							label={webBuilder.uploadImage}
							onUpload={handleUpload}
						/>


						{image && <img alt={label} src={image} height={133} style={{ width: "100%" }} />}

						<hr />

						{(!image || !value) &&
							<p className="text-danger my-1" color="error">
								{webBuilder.youShouldSelect} {header} {webBuilder.andImage}
							</p>
						}
					</>
				}


				<button
					className="primary-button"
					onClick={addService}
					disabled={(isItemFlight && !value) || (!isItemFlight && (!image || !value))}
				>
					Add
				</button>
			</div>
		</CustomModal>
	);
}

export default memo(AddService);
