import DateTimePickerField from 'components/Form/DateTimePickerField/DateTimePickerField';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import { TransportationIcon } from 'modules/UmrahOperations/shared/Icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import WathcIconsSrc from "assets/images/umrah-operations/watch-icon.svg";
import ItineraryTransportationView from './TransportationView';
import validate, { isFormValid } from 'helpers/validate';
import useItinerary from '../useItinerary';
import Locale from 'translations';
import UploadFile from 'components/UploadFile';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import { fetchAreas, fetchCitiesByName } from 'services/lookups';
import { useSBSState } from 'context/global';
import moment from 'moment';
import NumberField from 'components/Form/NumberField/NumberField';

function getItemName(item) {
  if (item?.item_type_id === 1) {
    return item?.airport?.name;
  }
  if (item?.item_type_id === 6) {
    return item.hotel_name?.name;
  }
  if (item?.item_type_id === 7) {
    return item?.name;
  }
};

const keysNotRequired = [
  "description",
  "isNew",
  "currentView",
  "notes",
  "terms",
  "from_area",
  "to_area",
  "vehicle_type",
  "vehicle_model",
  "vehicle_count",
  "vehicle_photo",
];



export default function TripPlannerItineraryTransportation({
  transportation,
  itemIndex,
  selectedDay,
  tripPlannerData,
  setTripPlannerData,
  currentSelectedDayItems,
  vehiclesList
}) {

  // hooks
  const { locale } = useSBSState()
  const { saveTransportationItem, removeSegment, canEdit } = useItinerary({ setTripPlannerData, selectedDay, tripPlannerData });
  const { packagePlanner, operationStatement, companyDetails, productsBuilder, productElements, inventory, commons } = Locale;

  // state
  const [currentView, setCurrentView] = useState(transportation?.currentView);
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [cititesList, setCititesList] = useState({
    from: [],
    to: []
  })
  const [areasList, setAreasList] = useState({
    from: [],
    to: []
  })

  // varibles
  const isUpdatingItem = transportation?.id ? true : false;

  const segmentsOptions = useMemo(() => {
    let options = [];
    currentSelectedDayItems?.forEach(item => {
      if (item.item_type_id === 1) {
        options.push({ ...item, airport: item?.from_airport });
        options.push({ ...item, airport: item?.to_airport });
      } else {
        options.push(item);
      }
    });
    return options;
  }, [currentSelectedDayItems])

  // get segments options
  const elementOptions = segmentsOptions?.map((item) => {
    let data = null;
    if ([1, 6, 7].includes(item.item_type_id)) {
      data = {
        id: item.id,
        name: getItemName(item),
      };
    }
    return data;
  }).filter((i) => i);


  // ** functions
  function handleTransportationInputs({ key, value }) {
    let tripPlannerClone = { ...tripPlannerData }
    let daysClone = tripPlannerClone.itinerary_days;
    let selectedDayIndex = selectedDay?.day - 1;
    let updatedDay = daysClone[selectedDayIndex];
    updatedDay.items[itemIndex] = { ...updatedDay.items[itemIndex], [key]: value };

    setTripPlannerData(tripPlannerClone);

    setErrors({
      ...errors,
      ...validate(
        { name: key, value },
        { required: true }
      ),
    });
  }

  const generateYearsList = useCallback((startYear, endYear) => {
    startYear = startYear ? moment(startYear) : moment();
    endYear = endYear ? moment(endYear) : moment();
    const diffOfDates = endYear.year() - startYear.year() + 1
    const yearsArr = [...Array(diffOfDates).keys()].map(i => {
      return {
        id: i + startYear.year(),
        value: i + startYear.year(),
        name: i + startYear.year(),
        label: i + startYear.year(),
      }
    });
    return yearsArr;
  }, [])

  const modelsList = generateYearsList("2000");

  async function getCititesList(inputValue, cityType) {
    if (inputValue.length > 2) {
      const citiesRes = await fetchCitiesByName(inputValue);
      let result = [];
      citiesRes?.forEach((item) => {
        result.push({
          ...item,
          name: item.names?.[locale] || item.name,
          value: item.id,
          label: item.names?.[locale] || item.name,
        });
      });
      setCititesList({ ...cititesList, [cityType]: result });
    }
  };


  async function getAreasList(city_id, areaType) {
    const areaRes = await fetchAreas(city_id);
    setAreasList({ ...areaRes, [areaType]: areaRes });
  }
  // remove transportation
  async function removeTransportation() {
    removeSegment(tripPlannerData, itemIndex, transportation?.id);
  }

  // check form errors
  function checkFormErrors() {
    let submitError = {}

    Object.keys(transportation).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: transportation[key] },
          { required: keysNotRequired.includes(key) || (transportation.option === "manual" && (key === "to" || key === "from")) ? false : true }
        ),
      }
    });
    setErrors(() => submitError)
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      saveTransportationItem(transportation);
    }
  }, [isErrorLoaded]);

  // reset view when selected day changes
  useEffect(() => {
    if (!transportation?.isNew) {
      setCurrentView("view");
    }
  }, [transportation?.isNew, selectedDay]);



  return (
    <div className="itinerary-item">
      {currentView === "view" &&
        <ItineraryTransportationView
          item={transportation}
          setCurrentView={setCurrentView}
          removeItem={removeTransportation}
          canEdit={canEdit}
        />
      }

      {currentView === "form" &&
        <>
          {/* form header */}
          <div className="form-header">
            <span className="d-flex align-items-center gap-10">
              <TransportationIcon color="#050001" />
              {productsBuilder.internalTransportations}
            </span>
            
            {canEdit &&
              <button className="btn p-0" onClick={() => removeTransportation()}>
                <svg width="11px" height="11px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 9L9 1M9 9L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </button>
            }
          </div>
          {/* form inputs */}
          <div className="itinerary-item-form p-4">
            {/* from to row */}
            <div className="row m-0 g " style={{ rowGap: "5px" }}>
              <div className="col-md-12" >
                {/* tabs manual or auto */}
                <div className="tab-items d-flex align-items-center">
                  <h2 className='title' > {packagePlanner.GenerateTransportation} : </h2>
                  <div className='tabs'>
                    <ul>
                      <li>
                        <button
                          className={transportation.option === "auto" ? "tabs btn active-tabs" : "tabs-btn"}
                          onClick={() => handleTransportationInputs({ key: "option", value: "auto" })}
                        >
                          {packagePlanner.auto}
                        </button>
                      </li>
                      <li>
                        <button
                          className={transportation.option === "manual" ? "tabs btn active-tabs " : "tabs-btn"}
                          onClick={() => handleTransportationInputs({ key: "option", value: "manual" })}
                        >
                          {packagePlanner.manual}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* UploadFile Photo */}
              <div className="col-12 mb-3">
                <UploadFile
                  id={`upload-package-main-image-${itemIndex}`}
                  label={packagePlanner.attractionImageGallery}
                  onChange={(value) => {
                    handleTransportationInputs({ key: "vehicle_photo", value });
                    setErrors({
                      ...errors,
                      ...validate(
                        {
                          name: "vehicle_photo",
                          value: value.length,
                        },
                        { required: true }
                      ),
                    });
                  }}
                  value={transportation?.vehicle_photo}
                  errorMsg={
                    errors?.vehicle_photo?.required
                      ? inventory.viewReservation.Photos + " " + commons.isRequired
                      : false
                  }
                />
              </div>
            </div>
            {/*  */}
            <div className="content-tabs">
              <div className={transportation.option === "auto" ? 'content  active-content' : 'content'} >
                <div className="row m-0">

                  <div className="col-md-4 col-12">
                    <SelectField
                      label={productsBuilder.From}
                      id={`from_${itemIndex}`}
                      name={`from_${itemIndex}`}
                      options={elementOptions.filter((option) => option.id !== transportation?.to?.id)}
                      value={transportation?.from?.name}
                      onChange={(e) => {
                        handleTransportationInputs({ key: "from", value: e });
                      }}
                      color={errors?.from?.required ? "danger" : ""}
                      errors={errors?.from}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <SelectField
                      label={productsBuilder.To}
                      id={`to_${itemIndex}`}
                      name={`to_${itemIndex}`}
                      options={elementOptions.filter((option) => option.id !== transportation?.from?.id)}
                      value={transportation?.to?.name}
                      onChange={(e) => {
                        handleTransportationInputs({ key: "to", value: e });
                      }}
                      color={errors?.to?.required ? "danger" : ""}
                      errors={errors?.to}
                    />
                  </div>
                </div>
              </div>

              {/* <div className={transportation.option === "manual" ? 'content  active-content' : 'content'}  >
                <div className="row m-0">

                  <div className="col-md-4 col-12">
                    <TextField
                      label={productsBuilder.From}
                      id={`from_${itemIndex}`}
                      name={`from_${itemIndex}`}
                      value={transportation?.from?.name}
                      onChange={(e) => {
                        handleTransportationInputs({ key: "from", value: e.target.value });
                      }}
                      color={errors?.from?.required ? "danger" : ""}
                      errors={errors?.from}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <TextField
                      label={productsBuilder.To}
                      id={`to_${itemIndex}`}
                      name={`to_${itemIndex}`}
                      value={transportation?.to?.name}
                      onChange={(e) => {
                        handleTransportationInputs({ key: "to", value: e.target.value });
                      }}
                      color={errors?.to?.required ? "danger" : ""}
                      errors={errors?.to}
                    />
                  </div>
                </div>
              </div> */}
            </div>


            <div className="row m-0 mt-2" style={{ rowGap: "5px" }}>
              {/* from City */}
              <div className="col-md-3 col-12">

                <AutoCompleteField
                  label={productElements.fromCity}
                  id={`from_city_${itemIndex}`}
                  name={`from_city_${itemIndex}`}
                  isSearchable={true}
                  listAuto={cititesList.from}
                  setListAuto={setCititesList}
                  getListAuto={(e) => getCititesList(e, "from")}
                  value={transportation?.from_city?.name || ""}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "from_city", value: { name: e } })
                  }}
                  onSelectValue={(e) => {
                    handleTransportationInputs({ key: "from_city", value: { id: e?.value, name: e?.label } });
                    getAreasList(e.value, "from")
                  }}
                  color={errors?.from_city?.required ? "danger" : ""}
                  errors={errors?.from_city}
                />
              </div>
              {/* area from */}
              <div className="col-md-3 col-12">
                <SelectField
                  label={productElements.fromArea}
                  id={`from_area_${itemIndex}`}
                  name={`from_area_${itemIndex}`}
                  options={areasList.from}
                  value={transportation?.from_area?.name}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "from_area", value: e });
                  }}
                  color={errors?.from_area?.required ? "danger" : ""}
                  errors={errors?.from_area}
                  disabled={!transportation.from_city?.id}
                />
              </div>
              {/* pickup point */}
              <div className="col-md-3 col-12">
                <TextField
                  label={operationStatement.Pickuppoint}
                  id={`pickup_point_${itemIndex}`}
                  name={`pickup_point_${itemIndex}`}
                  value={transportation?.pickup_point}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "pickup_point", value: e.target.value });
                  }}
                  color={errors?.pickup_point?.required ? "danger" : ""}
                  errors={errors?.pickup_point}
                />
              </div>
              {/* pickup time */}
              <div className="col-md-3 col-12 time-picker">
                <DateTimePickerField
                  label={operationStatement.PickupTime}
                  showTimeSelectOnly={true}
                  icon={WathcIconsSrc}
                  dateFormat="HH:mm a"
                  id={`pickup_time_${itemIndex}`}
                  name={`pickup_time_${itemIndex}`}
                  date={isUpdatingItem ? new Date(transportation?.pickup_time) : transportation?.pickup_time}

                  onChangeDate={(e) => {
                    handleTransportationInputs({ key: "pickup_time", value: e });
                  }}
                  color={errors?.pickup_time?.required ? "danger" : ""}
                  errors={errors?.pickup_time}
                  disabled={true}
                />
              </div>
              {/* city to */}
              <div className="col-md-3 col-12">
                <AutoCompleteField
                  label={productElements.toCity}
                  id={`to_city_${itemIndex}`}
                  name={`to_city_${itemIndex}`}
                  isSearchable={true}
                  listAuto={cititesList.to}
                  setListAuto={setCititesList}
                  getListAuto={(e) => getCititesList(e, "to")}
                  value={transportation?.to_city?.name || ""}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "to_city", value: { name: e } })
                  }}
                  onSelectValue={(e) => {
                    handleTransportationInputs({ key: "to_city", value: { id: e?.value, name: e?.label } });
                    getAreasList(e.value, "to")
                  }}
                  color={errors?.to_city?.required ? "danger" : ""}
                  errors={errors?.to_city}
                />

              </div>
              {/* area to */}
              <div className="col-md-3 col-12">
                <SelectField
                  label={productElements.toArea}
                  id={`to_area_${itemIndex}`}
                  name={`to_area_${itemIndex}`}
                  options={areasList.to}
                  value={transportation?.to_area?.name}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "to_area", value: e });
                  }}
                  color={errors?.to_area?.required ? "danger" : ""}
                  errors={errors?.to_area}
                  disabled={!transportation.to_city?.id}
                />

              </div>
              {/* drop off point */}
              <div className="col-md-3 col-12">
                <TextField
                  label={operationStatement.DropoffPoint}
                  id={`drop_off_point_${itemIndex}`}
                  name={`drop_off_point_${itemIndex}`}
                  value={transportation?.drop_off_point}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "drop_off_point", value: e.target.value });
                  }}
                  color={errors?.drop_off_point?.required ? "danger" : ""}
                  errors={errors?.drop_off_point}
                />
              </div>
              {/* drop off time */}
              <div className="col-md-3 col-12 time-picker">
                <DateTimePickerField
                  label={packagePlanner.dropOffTime}
                  showTimeSelectOnly={true}
                  icon={WathcIconsSrc}
                  dateFormat="HH:mm a"
                  id={`drop_off_time_${itemIndex}`}
                  name={`drop_off_time_${itemIndex}`}
                  date={isUpdatingItem ? new Date(transportation?.drop_off_time) : transportation?.drop_off_time}
                  onChangeDate={(e) => {
                    handleTransportationInputs({ key: "drop_off_time", value: e });
                  }}
                  color={errors?.drop_off_time?.required ? "danger" : ""}
                  errors={errors?.drop_off_time}
                  disabled={true}
                />
              </div>
              {/* car */}
              <div className="col-md-3 col-12">
                <SelectField
                  label={productsBuilder.CarType}
                  id={`from_${itemIndex}`}
                  name={`from_${itemIndex}`}
                  options={vehiclesList}
                  value={transportation?.vehicle_type?.name}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "vehicle_type", value: e });
                  }}
                  color={errors?.vehicle_type?.required ? "danger" : ""}
                  errors={errors?.vehicle_type}
                />
              </div>
              <div className="col-md-3 col-12">
                <SelectField
                  label={productsBuilder.CarModel}
                  id={`from_${itemIndex}`}
                  name={`from_${itemIndex}`}
                  options={modelsList}
                  value={transportation?.vehicle_model?.name}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "vehicle_model", value: e });
                  }}
                  color={errors?.vehicle_model?.required ? "danger" : ""}
                  errors={errors?.vehicle_model}
                  disabled={!transportation?.vehicle_type}
                />
              </div>
              <div className="col-md-3 col-12">
                <TextField
                  label={productsBuilder.CarCapacity}
                  id={`capacity_${itemIndex}`}
                  name={`capacity_${itemIndex}`}
                  value={transportation?.vehicle_type?.capacity}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 col-12">
                <NumberField
                  label={productsBuilder.BookedCount}
                  removeArrow={true}
                  id={`vehicle_count_${itemIndex}`}
                  name={`vehicle_count_${itemIndex}`}
                  value={transportation?.vehicle_count}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "vehicle_count", value: e.target.value });
                  }}
                  onWheel={(e) => e.target.blur()}
                  color={errors?.vehicle_count?.required ? "danger" : ""}
                  errors={errors?.vehicle_count}
                />
              </div>
            </div>

            {/* transportation  description row */}
            <div className="row m-0 mt-2">
              <div className="col-12">
                <label htmlFor="item_description" className="control-field__label">{companyDetails.description}</label>
                <textarea
                  placeholder={packagePlanner.enterDescription}
                  id={`description_${itemIndex}`}
                  name={`description_${itemIndex}`}
                  cols="30"
                  rows="3"
                  value={transportation?.description}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "description", value: e.target.value })
                  }}
                  className="textarea-input"
                />
              </div>
            </div>
            {/*  */}
            {/*transportation  Notes row  */}
            <div className="row m-0 mt-2">
              <div className="col-12">
                <label htmlFor={`notes_${itemIndex}`} className="control-field__label">{productElements.notes}</label>
                <textarea
                  placeholder={productsBuilder.enterNotes}
                  id={`notes_${itemIndex}`}
                  name={`notes_${itemIndex}`}
                  cols="30"
                  rows="3"
                  value={transportation?.notes}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "notes", value: e.target.value })
                  }}
                  className="textarea-input"
                />
              </div>
            </div>
            {/*transportation  termsAndConditions row  */}
            <div className="row m-0 mt-2">
              <div className="col-12">
                <label htmlFor={`terms_${itemIndex}`} className="control-field__label">{productsBuilder.termsAndConditions}</label>
                <textarea
                  placeholder={productsBuilder.termsAndConditions}
                  id={`terms_${itemIndex}`}
                  name={`terms_${itemIndex}`}
                  cols="30"
                  rows="3"
                  value={transportation?.terms}
                  onChange={(e) => {
                    handleTransportationInputs({ key: "terms", value: e.target.value })
                  }}
                  className="textarea-input"
                />
              </div>
            </div>
            {/*  */}
            <div className="d-flex justify-content-end">
              <button className="btn px-5 py-2 mt-2 mx-2 border"
                onClick={() => isUpdatingItem ? setCurrentView("view") : removeTransportation()}
              >
                {companyDetails.cancel}
              </button>
              <button className="btn px-5 py-2 mt-2 bg-nxt"
                onClick={submit}
              >
                {isUpdatingItem ? operationStatement.Update : productsBuilder.Save}
              </button>
            </div>
          </div>
        </>
      }


    </div>
  )
}
