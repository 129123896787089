import CategoryCard from 'modules/UmrahOperations/shared/CategoryCard'
import React from 'react'
import { ReactComponent as OperationNoIcon } from "assets/images/umrah-operations/operationNo-icon.svg";
import { ReactComponent as AgentIcon } from "assets/images/umrah-operations/shield-tick.svg";
import { ReactComponent as GlobalIcon } from "assets/images/umrah-operations/global-icon.svg";
import { ReactComponent as UsersIcon } from "assets/images/umrah-operations/users-icon.svg";
import { ReactComponent as OperatingAttractionIcon } from "assets/images/umrah-operations/attraction-icon.svg";
import { ReactComponent as OperatingArrivalDepartureIcon } from "assets/images/umrah-operations/arrival-departure.svg";
import { ReactComponent as OperatingOtherIcon } from "assets/images/umrah-operations/other-services-icon.svg";

import { ReactComponent as DepartureIcon } from "assets/images/umrah-operations/departure-icon.svg";
import { ReactComponent as ArrivalIcon } from "assets/images/umrah-operations/arrival-icon.svg";
import { CircleArrowLeft, CircleArrowRight, FlightIcon, HotelIcon, TransportationIcon } from 'modules/UmrahOperations/shared/Icons';
import moment from 'moment';
import Locale from 'translations';






export default function OperationStatementHeader({ OperationStatement }) {
  const { operationStatement } = Locale;
  const makkahHotel = OperationStatement?.hotels?.find(hotel => hotel?.city_id === 3);
  const madinahHotel = OperationStatement?.hotels?.find(hotel => hotel?.city_id === 4);
  const totalVehichelsCount = OperationStatement?.transportation?.vehicles?.reduce((acc, curr) => acc + (+curr?.count), 0);
  const paxCount = (+OperationStatement?.adults || 0) + (+OperationStatement?.children || 0) + (+OperationStatement?.infants || 0);
  let tripDepartures = OperationStatement?.trip?.departures;
  let tripDeparturesDates = tripDepartures?.filter(departure => departure?.departure_date && moment(departure?.departure_date).isValid()).map(departure => moment(departure?.departure_date));
  let closestDepartureDate = tripDepartures?.length > 0 ? moment.min(tripDeparturesDates) : null;



  return (
    <div className="operation-statement-header">
      <div className="category-item">
        <CategoryCard
          categroyTitle={operationStatement.OperationNo}
          categroyInfo={{ name: OperationStatement?.company_reference_number, icon: <OperationNoIcon /> }}
        />
      </div>
      <div className="category-item">
        <CategoryCard
          categroyTitle={operationStatement.AgentName}
          categroyInfo={{ name: OperationStatement?.agent?.name, icon: <AgentIcon /> }}
          categorySub={[
            { name: OperationStatement?.agent?.country?.name, icon: <GlobalIcon /> },
            { name: paxCount, icon: <UsersIcon /> },
          ]}
        />
      </div>
      <div className="category-item">
        <CategoryCard
          categroyTitle={operationStatement.Arrival}
          categroyInfo={{
            name: ` ${OperationStatement?.trip?.arrival_port?.code ? OperationStatement?.trip?.arrival_port?.code : "-"}  
					${OperationStatement?.trip?.arrival_trip_number ? ` - ${OperationStatement?.trip?.arrival_trip_number}` : ""}`,
            icon: <FlightIcon  />,
          }}
          categorySub={[
            {
              name: OperationStatement?.trip?.arrival_date ? moment(OperationStatement?.trip?.arrival_date).format("DD-MM-YYYY HH:mm") : null,
              icon: <DepartureIcon color='#219653' />
            },
            {
              name: closestDepartureDate ? moment(closestDepartureDate).format("DD-MM-YYYY HH:mm") : null,
              // name: null,
              icon: <ArrivalIcon />
            },
          ]}
        />
      </div>

      <div className="category-item">
        <CategoryCard
          categroyTitle={operationStatement.MakkahHotel}
          categroyInfo={{
            name: makkahHotel?.hotels?.[0]?.hotel?.name || makkahHotel?.hotel?.name,
            icon: <HotelIcon />,
          }}
          categorySub={[
            {
              name: makkahHotel?.hotels || makkahHotel?.hotel ? moment((makkahHotel?.hotels?.[0]?.check_in_date || makkahHotel?.check_in_date)).format("DD-MM-YYYY HH:mm") : null,
              icon: <CircleArrowRight color='#219653' />
            },
            {
              name: makkahHotel?.hotels || makkahHotel?.hotel ? moment(makkahHotel?.hotels?.[0]?.check_out_date || makkahHotel?.check_out_date).format("DD-MM-YYYY HH:mm") : null,
              icon: <CircleArrowLeft color='#EA5455' />
            },
          ]}
        />
      </div>

      <div className="category-item">
        <CategoryCard
          categroyTitle={operationStatement.MadinahHotel}
          categroyInfo={{
            name: madinahHotel?.hotels?.[0]?.hotel?.name || madinahHotel?.hotel?.name,
            icon: <HotelIcon />,
          }}
          categorySub={[
            {
              name: madinahHotel?.hotels || madinahHotel?.hotel ?
                moment(madinahHotel?.hotels?.[0]?.check_in_date || madinahHotel?.check_in_date).format("DD-MM-YYYY HH:mm") : null,
              icon: <CircleArrowRight color='#219653' />
            },
            {
              name: madinahHotel?.hotels || madinahHotel?.hotel ?
                moment(madinahHotel?.hotels?.[0]?.check_out_date || madinahHotel?.check_out_date).format("DD-MM-YYYY HH:mm") : null,
              icon: <CircleArrowLeft color='#EA5455' />
            },
          ]}
        />
      </div>

      <div className="category-item">
        <CategoryCard
          categroyTitle={operationStatement.Catering}
          categroyInfo={{
            name: OperationStatement?.catering_type === 0 ? "Include accommodation" :
              OperationStatement?.catering_type === 1 ? "Catering Provider" : "",
            icon: <HotelIcon />,
          }}
        />
      </div>
      <div className="category-item">
        <CategoryCard
          categroyTitle={operationStatement.Attractions}
          categroyInfo={{
            name: `(${OperationStatement?.attractions?.length}) Attraction`,
            icon: <OperatingAttractionIcon width="16px" height="16px" stroke='#98A2B3' />,
          }}
        />
      </div>
      <div className="category-item">
        <CategoryCard
          categroyTitle={operationStatement.Transportation}
          categroyInfo={{
            name: OperationStatement?.transportation?.transporter_id?.name || OperationStatement?.transportation?.transporter?.name,
            icon: <TransportationIcon />,
          }}
          categorySub={[
            {
              name: `${OperationStatement?.transportation?.cycle_type?.name ?? "-"}`,
              icon: <OperatingArrivalDepartureIcon width="16px" height="16px" stroke='#98A2B3' />
            },
            {
              name: `${totalVehichelsCount ?? "-"}`,
              icon: <TransportationIcon />,
            },
          ]}
        />
      </div>
      <div className="category-item">
        <CategoryCard
          categroyTitle={operationStatement.OtherServices}
          categroyInfo={{
            name: `(${OperationStatement?.other_services?.length}) Service`,
            icon: <OperatingOtherIcon width="16px" height="16px" stroke='#98A2B3' />,
          }}
        />
      </div>

    </div>
  )
}
