import { HotelIcon } from 'modules/UmrahOperations/shared/Icons'
import React, { useEffect, useState } from 'react'
import ItineraryHotelView from './HotelView'
import { useSBSState } from 'context/global'
import SelectField from 'components/Form/SelectField/SelectField';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import { getHotelMapToolHotels } from 'services/productbuilder';
import NumberField from 'components/Form/NumberField/NumberField';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
import validate, { isFormValid } from 'helpers/validate';
import { getCities } from 'services/marketplace';
import useItinerary from '../useItinerary';
import Locale from 'translations';



const keysNotRequired = ["notes", "description", "isNew", "currentView"];

export default function TripPlannerItineraryHotel({
  tripPlannerData,
  selectedDay,
  setTripPlannerData,
  hotel,
  itemIndex,
  packageIsUmrahOrHajj,
  availableCountries
}) {
  // ** hooks
  const { locale } = useSBSState();
  const { packagePlanner, operationStatement, companyDetails, productsBuilder, inventory, productElements } = Locale;
  const { saveHotelItem, removeSegment, canEdit } = useItinerary({ setTripPlannerData, selectedDay, tripPlannerData });
  // states
  const [hotelsList, setHotelsList] = useState([]);
  const [citiesList, setCitiesList] = useState([])
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [currentView, setCurrentView] = useState(hotel?.currentView);
  const isUpdatingHotel = hotel?.id ? true : false;

  const { mealsTypesLookup } = getOperationsLookup(Locale);

  // itinerary hotel inputs changes
  function handleHotelInputs(keyName, value, checkError) {
    let tripPlannerClone = { ...tripPlannerData }
    let daysClone = tripPlannerClone.itinerary_days;
    let selectedDayIndex = selectedDay?.day - 1;
    let updatedDay = daysClone[selectedDayIndex];
    if (keyName === "country") {
      updatedDay.items[itemIndex] = { ...updatedDay.items[itemIndex], [keyName]: value, city: null, hotel_name: { name: "" } };
    } else if (keyName === "city" || keyName === "country") {
      updatedDay.items[itemIndex] = { ...updatedDay.items[itemIndex], [keyName]: value, hotel_name: { name: "" } };
    } else {
      updatedDay.items[itemIndex] = { ...updatedDay.items[itemIndex], [keyName]: value };
    }
    setTripPlannerData(tripPlannerClone);
    if (checkError) {
      setErrors({
        ...errors,
        ...validate(
          { name: keyName, value },
          { required: keysNotRequired.includes(keyName) ? false : true }
        ),
      });
    }
  }

  // get hotels list based on user input
  async function getHotelsList(inputValue) {
    if (inputValue.length > 2) {
      let params = {
        name: inputValue,
        country_id: hotel?.country?.id,
        city_id: hotel?.city?.id,
      }
      const hotelsRes = await getHotelMapToolHotels(params);
      let result = [];
      hotelsRes?.data?.forEach((item) => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        });
      });
      setHotelsList(result);
    }
  };

  // get list cities based on selected country
  async function getCitiesList(inputValue) {
    if (inputValue.length > 2) {
      const IdCountry = hotel?.country?.value;
      const citiesRes = await getCities(inputValue, IdCountry);
      if (citiesRes?.status >= 200 && citiesRes?.status < 300) {
        const formatCities = citiesRes?.data?.data?.map((city) => {
          return {
            id: city?.id,
            label: city?.name,
            value: city?.id,
            name: city?.name,
            country_id: city?.country_id,
          };
        });
        setCitiesList(formatCities);
      }
    }
  };


  // delete hotel
  async function removeHotel() {
    removeSegment(tripPlannerData, itemIndex, hotel?.id);
  }

  // check form errors
  function checkFormErrors() {
    let submitError = {}
    Object.keys(hotel).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: hotel[key] },
          { required: keysNotRequired.includes(key) ? false : true }
        ),
      }
    });
    setErrors(() => submitError)
  }

  function submitHotels() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      saveHotelItem(hotel);
    }
  }, [isErrorLoaded]);

  // reset view when selected day changes
  useEffect(() => {
    if (!hotel?.isNew) {
      setCurrentView("view");
    }
  }, [hotel?.isNew, selectedDay]);


  return (
    <div className="itinerary-item">
      {currentView === "view" &&
        <ItineraryHotelView
          item={hotel}
          setCurrentView={setCurrentView}
          removeHotel={removeHotel}
          selectedDay={selectedDay?.day}
          canEdit={canEdit}
        />
      }


      {/* add edit hotels form */}
      {currentView === "form" &&
        <>
          <div className="form-header">
            <span className="d-flex align-items-center gap-10">
              <HotelIcon color="#050001" />
              {packagePlanner.hotel}
            </span>
            {canEdit &&
              <button className="btn p-0" onClick={() => removeHotel()}>
                <svg width="11px" height="11px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 9L9 1M9 9L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </button>
            }
          </div>

          {/* hotel form */}
          <div className="itinerary-item-form p-4">
            <div className="row m-0">
              {/* hotel country */}
              <div className="col-md-4 col-12">
                <SelectField
                  label={operationStatement.Country}
                  id={`hotel_country_${itemIndex}`}
                  name={`hotel_country_${itemIndex}`}
                  options={packageIsUmrahOrHajj ? availableCountries?.filter(country => country?.id === 966) : availableCountries}
                  value={hotel?.country?.name}
                  onChange={(e) => {
                    handleHotelInputs("country", e, true);
                  }}
                  color={errors?.country?.required ? "danger" : ""}
                  errors={errors?.country}
                  disabled={isUpdatingHotel && packageIsUmrahOrHajj}
                />
              </div>

              {/* hotel city */}
              <div className="col-md-4 col-12">
                <AutoCompleteField
                  label={operationStatement.City}
                  id={"hotel_city"}
                  name={"hotel_city"}
                  isSearchable={true}
                  listAuto={citiesList}
                  setListAuto={setCitiesList}
                  getListAuto={getCitiesList}
                  value={hotel?.city?.name || ""}
                  onChange={(e) => {
                    handleHotelInputs("city", { name: e }, false)
                  }}
                  onSelectValue={(e) => {
                    handleHotelInputs("city", { id: e?.value, name: e?.label }, true)
                  }}
                  disabled={!hotel.country}
                  color={errors?.city?.required ? "danger" : ""}
                  errors={errors?.city}
                />
              </div>

            </div>

            <div className="row m-0 mt-2">
              {/* hotel name */}
              <div className="col-md-4 col-12">
                <AutoCompleteField
                  label={operationStatement.Hotelname}
                  id={`hotel_name_${itemIndex}`}
                  name={`hotel_name_${itemIndex}`}
                  isSearchable={true}
                  listAuto={hotelsList}
                  setListAuto={setHotelsList}
                  getListAuto={getHotelsList}
                  value={hotel?.hotel_name?.name || ""}
                  onChange={(e) => {
                    handleHotelInputs("hotel_name", { name: e }, false)
                  }}
                  onSelectValue={(e) => {
                    handleHotelInputs("hotel_name", { id: e?.value, name: e?.label }, true)
                  }}
                  disabled={!hotel.country || !hotel?.city?.id}
                  color={errors?.hotel_name?.required ? "danger" : ""}
                  errors={errors?.hotel_name}
                />
              </div>
              {/* nights count */}
              <div className="col-md-4 col-12">
                <NumberField
                  label={operationStatement.Nights}
                  removeArrow={true}
                  id={`hotel_nights_count_${itemIndex}`}
                  name={`hotel_nights_count_${itemIndex}`}
                  value={hotel?.nights || ""}
                  onChange={(e) => {
                    handleHotelInputs("nights", e.target.value, true)
                  }}
                  onWheel={(e) => e.target.blur()}
                  color={errors?.nights?.required ? "danger" : ""}
                  errors={errors?.nights}
                />
              </div>
              {/* meal plan */}
              <div className="col-md-4 col-12">
                <SelectField
                  label={inventory.messages.MealPlan}
                  id={`hotel_meal_plan_${itemIndex}`}
                  name={`hotel_meal_plan_${itemIndex}`}
                  options={mealsTypesLookup}
                  value={hotel?.meal_plan?.name}
                  onChange={(e) => {
                    handleHotelInputs("meal_plan", e, true)
                  }}
                  color={errors?.meal_plan?.required ? "danger" : ""}
                  errors={errors?.meal_plan}
                />
              </div>
            </div>

            <div className="row m-0 mt-2">
              {/* hotel description */}
              <div className="col-12">
                <label htmlFor="hotel_description" className="control-field__label">{companyDetails.description}</label>
                <textarea
                  placeholder={packagePlanner.enterDescription}
                  id={`hotel_description_${itemIndex}`}
                  name={`hotel_description_${itemIndex}`}
                  cols="30"
                  rows="3"
                  value={hotel?.description}
                  onChange={(e) => {
                    handleHotelInputs("description", e.target.value, false)
                  }}
                  className="textarea-input"
                />
              </div>
              {/* hotel notes */}
              <div className="col-12">
                <label htmlFor="hotel_notes" className="control-field__label">{productElements.notes}</label>
                <textarea
                  placeholder={productsBuilder.enterNotes}
                  id={`hotel_notes_${itemIndex}`}
                  name={`hotel_notes_${itemIndex}`}
                  cols="30"
                  rows="3"
                  value={hotel?.notes}
                  onChange={(e) => {
                    handleHotelInputs("notes", e.target.value, false)
                  }}
                  className="textarea-input"
                />
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <button className="btn px-5 py-2 mt-2 mx-2 border"
                onClick={() => isUpdatingHotel ? setCurrentView("view") : removeHotel()}
              >
                {companyDetails.cancel}
              </button>
              <button className="btn px-5 py-2 mt-2 bg-nxt"
                onClick={submitHotels}
              >
                {isUpdatingHotel ? operationStatement.Update : productsBuilder.Save}
              </button>
            </div>
          </div>

        </>
      }
    </div>
  )
}
