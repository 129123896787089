import SelectField from 'components/Form/SelectField/SelectField'
import NumberField from 'components/Form/NumberField/NumberField';
import validate from 'helpers/validate';
import { ReactComponent as PaxIcon } from 'assets/images/market-place/people-pax-icon.svg'
import Locale from 'translations';



export default function PackagePricing({
  reservationDetails,
  paxCount,
  setPaxCount,
  errors,
  setErrors,
  categoryType,
  setCategoryType,
  children
}) {

  const { marketPlace } = Locale;
  const totalAdultPax = reservationDetails?.remaining_pax;
  const totalChildrenPax = reservationDetails?.remaining_pax;

  return (
    <div className='package-pricing package-category-pricing mt-3'>
      {/* pax  */}
      <div className="d-flex flex-wrap align-items-center  gap-10">
        <div className="d-flex align-items-center me-5">
          <PaxIcon />
          <span className="mx-2">{marketPlace.Pax}: </span>
        </div>
        <div className="d-flex align-items-center gap-10">
          <span>{marketPlace.Adults}</span>
          <div className="col-md-5 col-6">
            <NumberField
              hasLabel={false}
              label={marketPlace.Adults}
              id="adults_count"
              name="adults_count"
              removeArrow={true}
              value={paxCount?.adultPax}
              max={totalAdultPax || "0"}
              onChange={(e) => {
                if (e.target.value <= totalAdultPax) {
                  setPaxCount({ ...paxCount, adultPax: Number(e.target.value)?.toString() });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "adultPax", value: +e.target.value },
                      { required: true, maxNumber: +totalAdultPax }),
                  })
                }
              }}
              onWheel={(e) => e.target.blur()}
              errors={errors?.adultPax}
              color={errors?.adultPax?.required ? "danger" : ""}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-10">
          <span>{marketPlace.Childs}</span>
          <div className="col-md-5 col-6">
            <NumberField
              hasLabel={false}
              label={marketPlace.Childs}
              id="childs_count"
              name="childs_count"
              removeArrow={true}
              max={totalChildrenPax || "0"}
              value={paxCount?.childsPax}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= totalChildrenPax) {
                  setPaxCount({ ...paxCount, childsPax: Number(e.target.value)?.toString() });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "childsPax", value: +e.target.value },
                      { required: false, maxNumber: +totalChildrenPax }),
                  })
                }
              }}
              onWheel={(e) => e.target.blur()}
              errors={errors?.childsPax}
              color={errors?.childsPax?.required ? "danger" : ""}
            />
          </div>
        </div>
      </div>

      <div>
        <SelectField
          label={marketPlace.Reservationtype}
          id="reservation_type"
          name="reservation_type"
          options={[{ id: "private", name: marketPlace.Private }, { id: "shared", name: marketPlace.Shared }]}
          value={categoryType?.name}
          onChange={(e) => {
            setCategoryType(e);
          }}
          disabled={!paxCount?.adultPax}
        />
      </div>

          {children}
    </div>
  )
}
