import React from 'react'

// ** components
import { ReactComponent as FilterIcon } from 'assets/images/crm/filter.svg'
import SearchIcon from 'assets/images/crm/search.svg'
import { ReactComponent as DownArrowIcon } from 'assets/images/crm/down.svg'
import { ReactComponent as UpArrowIcon } from 'assets/images/crm/up.svg'
import TextField from 'components/Form/TextField/TextField'
import SelectField from 'components/Form/SelectField/SelectField'
import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import Locale from 'translations'
import moment from 'moment'
import { isInclusivelyAfterDay } from 'react-dates'

const CRMFilters = ({
	toggleFilter,
	setToggleFilter,
	filters,
	setFilters,
	getAllReservationPackage,
	resetFilters
}) => {
	const { inventory,CRM } = Locale;

	const sources = [
		// { id: 'online', value: 'online', name: 'Online' },
		{ id: 'offline', value: 'offline', name: 'Offline' },
		{ id: 'b2b', value: 'b2b', name: 'B2b' },
		{ id: 'website', value: 'website', name: 'Website' }
	]

	const Priorities = [
		{ id: 'high', value: 'high', name: 'High' },
		{ id: 'medium', value: 'medium', name: 'Medium' },
		{ id: 'low', value: 'low', name: 'Low' }
	]

	const filterStatus = [
		{ id: 'pending', value: 'pending', name: inventory.messages.pending },
		{ id: 'approved', value: 'approved', name: inventory.messages.approved },
		{ id: 'paid', value: 'paid', name: inventory.messages.paid },
		{ id: 'confirmed', value: 'confirmed', name: inventory.messages.confirmed },
		{ id: 'rejected', value: 'rejected', name: inventory.messages.rejected },
		{ id: 'expired', value: 'expired', name: inventory.messages.expired },
		{ id: 'canceled', value: 'canceled', name: inventory.messages.canceled },
	]

	function filterReservationFrom(source) {
		let reservationFrom = [];
		if (filters?.reservation_from?.includes(source)) {
			reservationFrom = filters?.reservation_from?.filter(from => from !== source);
		} else {
			reservationFrom = [...filters?.reservation_from, source];
		}

		setFilters({ ...filters, reservation_from: reservationFrom })
		getAllReservationPackage(null, { ...filters, reservation_from: reservationFrom })
	}

	const filterReservationStatus = (status) => {
		if (status && filters?.reservation_status?.value !== status?.value) {
			setFilters({ ...filters, reservation_status: status })
			getAllReservationPackage(null, { ...filters, reservation_status: status })
		} else {
			setFilters({ ...filters, reservation_status: null })
			getAllReservationPackage(null, { ...filters, reservation_status: null })
		}
	}

	const getAllHighPrioritiesReservations = (priority) => {
		debugger
		if (priority && filters?.priority?.value !== priority?.value) {
			setFilters({ ...filters, priority })
			getAllReservationPackage(null, { ...filters, priority })
		} else {
			setFilters({ ...filters, priority: null })
			getAllReservationPackage(null, { ...filters, priority: null })
		}
	}

	return (
		<div className='filter-wrapper'>
			<div className='filter-row'>
				<div className='filter-items'>
					<TextField
						type='text'
						id='statement-operation-no'
						name='statement-operation-no'
						hasLabel={false}
						placeholder={CRM.searchOrFilter}
						isImage={true}
						image={SearchIcon}
						prependImage={true}
						value={filters?.search}
						onChange={(e) => {
							setFilters({ ...filters, search: e.target.value });
							getAllReservationPackage(null, { ...filters, search: e.target.value });
						}}
					/>
					<button className={`btn item btn-filter-outline shadow-none ${filters?.priority?.value === "high" ? "active" : ""}`}
						onClick={() =>
							getAllHighPrioritiesReservations({ id: 'high', value: 'high', name: 'High' })
						}
					>
						{CRM.HighPriority}
						
					</button>
					<button className={`btn item btn-filter-outline shadow-none ${filters?.reservation_status?.value === "pending" ? "active" : ""}`}
						onClick={() =>
							filterReservationStatus({ id: 'pending', value: 'pending', name: 'Pending' })
						}
					>
						{CRM.New}
					</button>
				</div>

				<div className='filter-items'>
					<button
						className='btn item btn-filter'
						onClick={() => setToggleFilter(!toggleFilter)}
					>
						<FilterIcon color='#FFF' />
						<span className='btn-txt'>{CRM.Filter}</span>
						{toggleFilter ? (
							<UpArrowIcon color='#FFF' />
						) : (
							<DownArrowIcon color='#FFF' />
						)}
					</button>

					<button className={`btn item btn-filter-outline ${filters?.reservation_from?.includes("offline") ? "active" : ""}`}
						onClick={() =>
							filterReservationFrom('offline')
						}
					>
						Offline
					</button>
					<button className={`btn item btn-filter-outline ${filters?.reservation_from?.includes("b2b") ? "active" : ""}`}
						onClick={() =>
							filterReservationFrom('b2b')
						}
					>
						B2B
					</button>
					<button className={`btn item btn-filter-outline ${filters?.reservation_from?.includes("website") ? "active" : ""}`}
						onClick={() =>
							filterReservationFrom('website')
						}
					>
						Website
					</button>
				</div>
			</div>

			{toggleFilter ? (
				<>
					<h3 className='advancedFilter-title'>{CRM.AdvancedFilter}</h3>
					<div className='row' style={{ marginLeft: '-6px' }}>
						<div className='col-md-2 col-sm-3-col-4'>
							<SelectField
								label={CRM.Source}
								placeholder={CRM.SelectOption}
								id='reservation_from'
								name='reservation_from'
								options={sources}
								value={filters.reservation_from?.length > 1 ? null : filters.reservation_from}
								onChange={(e) => {
									setFilters({
										...filters,
										reservation_from: [e?.value]
									})
								}}
							/>
						</div>

						<div className='col-md-2 col-sm-3-col-4'>
							<SelectField
								label={CRM.priority}
								placeholder={CRM.SelectOption}
								id='priority'
								name='priority'
								options={Priorities}
								value={filters.priority?.name}
								onChange={(e) => {
									setFilters({
										...filters,
										priority: e
									})
								}}
							/>
						</div>

						<div className='col-md-2 col-sm-3-col-4'>
							<SelectField
								label={CRM.Status}
								placeholder={CRM.SelectOption}
								id='reservation_status'
								name='reservation_status'
								options={filterStatus}
								value={filters.reservation_status?.name}
								onChange={(e) => {
									setFilters({
										...filters,
										reservation_status: e
									})
								}}
							/>
						</div>

						<div className='col-md-2 col-sm-3-col-4'>
							<DatePickerField
								label={CRM.RequestDate}
								date={filters.request_date}
								customIcon={true}
								onChangeDate={(filterDate) => {
									setFilters({
										...filters,
										request_date: filterDate
									})
								}}
								isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, "day"))}
							/>
						</div>

						<div className='col-md-2 col-sm-3-col-4'>
							<DatePickerField
								label={CRM.DepartureDate}
								date={filters.departure_date}
								customIcon={true}
								onChangeDate={(filterDate) => {
									setFilters({
										...filters,
										departure_date: filterDate
									})
								}}
								isOutsideRange={(day) => {
									return false
								}}
							/>
						</div>

						<div className='col-md-2 col-sm-3-col-4'>
							<label htmlFor='crm_total_from' className="mb-1">{CRM.TotalPriceRange}</label>
							<div
								className='d-flex align-items-center'
								style={{ gap: '5px' }}
							>
								<input
									type='number'
									id="crm_total_from"
									name="crm_total_from"
									style={{ width: '75px', height: '41px' }}
									value={filters?.total_from}
									onChange={(e) =>
										setFilters({ ...filters, total_from: e?.target?.value })
									}
								/>
								<span>{CRM.To}</span>
								<input
									type='number'
									id="crm_total_to"
									name="crm_total_to"
									style={{ width: '75px', height: '41px' }}
									value={filters?.total_to}
									onChange={(e) =>
										setFilters({ ...filters, total_to: e?.target?.value })
									}
								/>
							</div>
						</div>
					</div>

					<div className='filter-buttons'>
						<button
							className='btn btn-shared btn-clear'
							onClick={() => resetFilters()}
						>
							{
								CRM.ClearFilter
							}
							
						</button>
						<button
							className='btn btn-shared btn-apply'
							onClick={() => getAllReservationPackage('', filters)}
						>
							{CRM.ApplyFilter}
						</button>
					</div>
				</>
			) : null}
		</div>
	)
}

export default CRMFilters
