import Locale from 'translations'

const { packagePlanner, productsBuilder } = Locale;

export const flightTypes = [
  { label: packagePlanner.directFlight , name: packagePlanner.directFlight , id: "direct" },
  { label: productsBuilder.roundtrip , name: productsBuilder.roundtrip , id: "round" },
]

export const categroyTypes = [
  { id: 'Active', name: 'Active' },
  { id: 'Beach & Relaxation', name: 'Beach & Relaxation' },
  { id: 'City Breaks', name: 'City Breaks' },
  { id: 'Culture & History', name: 'Culture & History' },
  { id: 'Cycling', name: 'Cycling' },
  { id: 'Honeymoons', name: 'Honeymoons' },
  { id: 'Luxury', name: 'Luxury' },
  { id: 'Skiing', name: 'Skiing' },
  { id: 'Solo Traveler', name: 'Solo Traveler' },
  { id: 'Spa Holidays', name: 'Spa Holidays' },
  { id: 'Train & River Journeys', name: 'Train & River Journeys' },
  { id: 'Walking & Trekking', name: 'Walking & Trekking' },
  { id: 'Business trip', name: 'Business trip' },
  { id: 'Medical tourism', name: 'Medical tourism' },
  { id: 'Wildlife & Safaris', name: 'Wildlife & Safaris' }
];

export const pricingForLookup = [
  { id: 'B2B_B2C', name: 'B2B & B2C' },
  { id: 'b2b', name: 'B2B' },
  { id: 'b2c', name: 'B2C' },
]

export const initialFlightItemObj = {
  flight_type: null,
  carrier_name: "",
  return_day: null,
  from_country: "",
  from_airport: "",
  to_country: "",
  to_airport: ""
}
export const initialHotelItemObj = {
  country: null,
  city: "",
  hotel_name: null,
  nights: "",
  meal_plan: "",
  description: "",
  notes: ""
}
export const initialAttractionItemObj = {
  name: "",
  country: null,
  city: null,
  photos: [],
  services: [""],
  description: "",
  duration: "",
  terms: "",
  policy: "",
  notes: "",
  pickup: "",
  drop_off: "",
}
export const initialTransportationItemObj = {
  option: "auto",
  from: null,
  to: null,
  from_city:  null,
  to_city:  null,
  from_area:  null,
  to_area:  null,
  vehicle_type: null,
  vehicle_model: null,
  vehicle_count: null,
  vehicle_photo: [],
  pickup_point: null,
  pickup_time: null,
  drop_off_point: null,
  drop_off_time: null,
  description: "",
  notes: "",
  terms: "",
}