import Filter from "./filter";
import Locale from 'translations';
import React, { useEffect, useState } from "react";
import { listallTravelers } from 'services/CRM';
import Pagination from 'components/Pagination';



export default function Travelers() {
  // ** hooks
  const { CRM } = Locale;


  // ** states
  const [travelersList, setTravelersList] = useState([]);
  const [filter, setFilter] = useState({
    search_key: "",
    nationality_id: "",
    source: "",
    page: 1,
  });

  const [meta, setMeta] = useState();


  // ** functions
  async function fetchTravelers(filters) {
    const res = await listallTravelers({
      search_key: filters?.search_key,
      source: filters?.source?.id,
      nationality_id: filters?.nationality_id?.id,
      page: filters?.page
    });
    setTravelersList(res?.data?.data);
    setMeta(res?.data?.meta);
  }

  const resetFilters = () => {
    fetchTravelers({}).then(() => {
      setFilter({
        search_key: "",
        nationality_id: "",
        source: "",
        page: 1,
      })
    });
  };

  const goTo = (page) => {
    fetchTravelers({ page })
  };

  useEffect(() => {
    fetchTravelers({});
  }, []);



  return (
    <div className='cs-requests customers'>
      <Filter
        filter={filter}
        setFilter={setFilter}
        resetFilters={resetFilters}
        fetchTravelersWithFilters={fetchTravelers}
      />
      <div className="table-container">
        <table className="bg-white table">
          <thead>
            <tr>
              <th>{CRM.Name}</th>
              <th>{CRM.passportNo}</th>
              <th>{CRM.nationalID}</th>
              <th>{CRM.gender}</th>
              <th>{CRM.birthdate}</th>
              <th >{CRM.nationality}</th>
              <th >{CRM.tripName}</th>
              <th >{CRM.tripDate}</th>
              <th >{CRM.source}</th>
              <th >{CRM.APPNO}</th>
            </tr>
          </thead>
          <tbody>
            {travelersList.length > 0 ? (
              travelersList.map(traveler => {
                return (
                  <tr key={traveler?.id}>
                    <td>{traveler.first_name || "-"} {traveler.last_name || "-"}</td>
                    <td>{traveler.passport_number || "-"}</td>
                    <td>-</td>
                    <td>{traveler.gender === "M" ? CRM.male : CRM.female}</td>
                    <td>{traveler.birth_date}</td>
                    <td>{traveler.nationality?.name}</td>
                    <td>{traveler.trip_name || "-"}</td>
                    <td>{traveler.trip_departure_date || "-"}</td>
                    <td>
                      <span className={`${traveler.reservation_from ? "unexcuted-btn" : ""} text-uppercase py-1 px-3`}>
                      { CRM[traveler.reservation_from] || traveler.reservation_from || "-" }
                      </span>
                    </td>
                    <td>{traveler.reservation_num || "-"}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10">
                  <div className="product-build__product-no-data fullHeight no-result">
                    <h4>{CRM.noResults}</h4>
                    <p>You need to fill the search fields first to get results</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </div>


      <div className="mt-3">
        <Pagination info={meta} goTo={goTo} />
      </div>

    </div>

  )
}