import moment from "moment";
import { fetchPorts } from "services/lookups";
import { getOperationsLookup, } from './operationsLookup';
import { convertLocalDateToUTCDate } from 'helpers/convertDateToUTCDate';



export async function formatViewOperationStatement(data, Locale) {
	let Ports = await fetchPorts(
		966,
		data?.trip?.transport_type === "Air" ? 2 : 1
	);
	let arrival_port_id = Ports.filter(
		(res) => res.id === data?.trip?.arrival_port_id
	)[0];
	const generate_hotel = data?.generate_hotel ?? false;
	const {
		cycleTypesLookup,
		requestTypeLookup,
		statementTypeList,
		citiesLookup,
		mealsTypesLookup,
		movmentTypesLookup,
		visaTypesLookup,
		transportTypesLookup
	} = getOperationsLookup(Locale);
	// let departure_port_id = Ports.filter(
	// 	(res) => res.id === data?.trip?.departure_port_id
	// )[0];

	return {
		...data,
		operation_details: {
			...data,
			adults: data?.adults ? data.adults : 1,
			agent_id: data?.agent?.id,
			phone: data?.agent?.phone,
			email: data?.agent?.email,
			country: {
				name: data?.agent?.country?.name,
				label: data?.agent?.country?.name,
				value: data?.agent?.country?.id,
				id: data?.agent?.country?.id,
			},
			statement_type: statementTypeList.find(type => type?.id === data.statement_type) ||
			{
				label: data.statement_type,
				id: data.statement_type,
				value: data.statement_type,
				name: data.statement_type,
			},
			// responsible_phone_code: {
			// 	name: data.agent.country.name,
			// 	label: data.agent.country.name,
			// 	value: data.agent.country.id,
			// 	id: data.agent.country.id,
			// 	phone_code:data.agent.country.id,
			// },
			phoneCode: data?.agent ? {
				name: data?.agent?.country?.name,
				label: data?.agent?.country?.name,
				value: data?.agent?.country?.id,
				id: data?.agent?.country?.id,
				phone_code: data?.agent?.country?.id,
				flag: data?.agent?.country?.flag
			} : null
		},
		trip: {
			...data.trip,
			arrival_date: convertLocalDateToUTCDate(data.trip?.arrival_date),
			transport_type: transportTypesLookup.find(type => type?.id === data?.trip?.transport_type),
			arrival_port_id: arrival_port_id,
			arrival_hall_id:
				arrival_port_id?.halls && arrival_port_id?.halls?.length > 0
					? arrival_port_id.halls.filter(
						(res) => res.id === data?.trip.arrival_hall_id
					)[0]
					: null,
			departures: data?.trip?.departures?.map(departure => {
				return {
					id: departure?.id,
					departure_carrier_name: departure?.carrier,
					departure_trip_number: departure?.trip_number,
					departure_date: convertLocalDateToUTCDate(departure?.date),
					departure_port_id: departure?.port,
					departure_hall_id: departure?.hall,
					departure_halls: departure?.port?.halls,
					bus_arrival_date: departure?.bus_arrival_date,
				}
			}),
			arrival_carrier_name: data?.trip?.arrival_carrier,

		},
		hotels:
			data.hotels && data.hotels.length > 0
				? data.hotels.map((hotel) => {
					return {
						...hotel,
						city: citiesLookup.find(city => city.id === hotel.city_id),
						hotel: hotel.hotels.map((Hotel) => {
							return {
								...Hotel,
								no_nights: !generate_hotel ? moment(Hotel.check_out_date).diff(moment(Hotel.check_in_date), 'days') : Hotel?.nights,
								hotel: { ...Hotel.hotel, value: Hotel?.hotel?.id, label: Hotel?.hotel?.name },
								meal_type: mealsTypesLookup.find(type => type?.id === Hotel.meal_type) ||
								{
									label: Hotel.meal_type?.replace("_", " "),
									value: Hotel.meal_type,
									name: Hotel.meal_type?.replace("_", " "),
									id: Hotel.meal_type,
								},
								check_in_date: moment(Hotel.check_in_date),
								check_out_date: !generate_hotel ? moment(Hotel.check_out_date) : moment(Hotel.check_in_date).add(Hotel?.nights, 'days'),
								...Hotel.rooms_count,
							};
						}),
					};
				})
				: [],
		catering:
			data.catering && data.catering.length > 0
				? data.catering.map((cater) => {
					return {
						...cater,
						catering:
							cater.catering && cater.catering.length > 0
								? cater.catering.map((res) => {
									return {
										...res,
										meal_type: mealsTypesLookup.find(type => type?.id === res.meal_type) ||
										{
											label: res.meal_type?.replace("_", " "),
											value: res.meal_type,
											name: res.meal_type?.replace("_", " "),
											id: res.meal_type,
										},
									};
								})
								: [{}],
					};
				})
				: [],

		transportation: {
			id: data?.transportation?.id,
			cycle_type: cycleTypesLookup?.find(type => type?.id === data?.transportation?.cycle_type),
			request_type: requestTypeLookup.find(type => type?.id === data?.transportation?.request_type),
			transporter_id: data?.transportation?.transporter || null,
			credential_number: data?.transportation?.credential_number || "",
			operation_id: data?.transportation?.operation_id || "",
			vehicles: data?.transportation?.vehicles?.map(vehicle => {
				return {
					id: vehicle?.id,
					vehicle_type_id: vehicle?.vehicle_type,
					vehicle_model: { id: vehicle?.vehicle_model, name: vehicle?.vehicle_model, label: vehicle?.vehicle_model },
					count: vehicle?.count,
				}
			}) || [],
			movements: data?.transportation?.movements?.map(movement => {
				return {
					id: movement?.id,
					movement_date: moment(movement?.movement_date).toDate(),
					pax: movement?.pax,
					movement_type: movmentTypesLookup?.find(type => type?.id === movement?.movement_type),
					pickup_points: movement?.pickup_points ? movement?.pickup_points.map(res => {
						return {
							...res,
							label: res.title,
							id: res.item_id,
							value: res.item_id,
							name: res.title,
						}
					}) : [],
					pickup_city_id: { ...movement?.pickup_city, label: movement?.pickup_city?.name, value: movement?.pickup_city?.id },
					destination_city_id: { ...movement?.drop_off_city, label: movement?.drop_off_city?.name, value: movement?.drop_off_city?.id },
					destinations: movement?.destinations ? movement?.destinations.map(res => {
						return {
							...res,
							label: res.title,
							id: res.item_id,
							value: res.item_id,
							name: res.title,
						}
					}) : [],
					pickup_time: moment(movement?.movement_date),
				}
			}) || []
		},
		attractions: data?.attractions?.map(attraction => {
			return {
				...attraction,
				city_id: citiesLookup.find(city => city?.id === attraction?.city_id),
				date_time: convertLocalDateToUTCDate(attraction?.date_time),
				attraction_id: attraction?.attraction,
				cityAttractionsLookup: attraction?.city_attraction?.map(cityAttraction => {
					return {
						...cityAttraction,
						label: cityAttraction?.name
					}
				})
			}
		}) || [],
		visas: data?.visas?.map(visa => {
			return {
				...visa,
				visa_type: visaTypesLookup.find(type => type?.id === visa?.visa_type)
			}
		}) || [],
		other_services: data?.other_services?.map(service => {
			return {
				...service,

			}
		}),
		travellers: data?.travellers
	};
}
