import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import React, { useState } from "react";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
// import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";

import { SORT_ITEMS_IN_CONTAINER_ACTION } from "../../../interfaces/blockTypes";
import { EditBox } from "modules/WebBuilder/shared/EditBox";
import DragAndDropContainer from "modules/WebBuilder/shared/DragAndDropContainer";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import FlightServiceCard from "./FlightCard";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import Locale from "translations";
import FlightServiceCardTheme4 from "./Theme4Card";
import ThemeSevenFlights from './theme7';
import FlightServiceCardTheme5 from './Theme5Card';
import FlightServiceBuilderCard from './ServiceBuilderCard';
import CustomCarousel from 'modules/WebBuilder-V2/shared/CustomCarousel';
import Carousel from 'react-multi-carousel';
import CustomRightArrow from 'modules/WebBuilder-V2/shared/CustomRightArrow';
import CustomLeftArrow from 'modules/WebBuilder-V2/shared/CustomLeftArrow';

const flightBlocksType = "flightBlocksType";


function FlightsService({ details, onFocus, focusContainer }) {
	const { id, type, body, title, items, items_per_row, image } = details;
	const { selectedLanguage, style } = useWebBuilderState();
	const dispatch = useWebBuilderDispatch();
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const { webBuilder } = Locale;

	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	};
	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToFlight = (item) => {
		updateItem
			? dispatch({
				type: "editItemInContainer",
				payload: {
					id,
					item,
					itemIndex: updateItem.index,
				},
			})
			: dispatch({
				type: "addItemToContainer",
				payload: {
					id,
					item,
				},
			});
		setUpdateItem(null);
	};

	const updateFlights = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language
			},
		});
	};

	const sortFlightsItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};
	const selectedIds = items ? items.map((i) => i.id) : [];


	function renderFlightView(item) {
		switch (style.theme) {
			case "theme-4":
				return <FlightServiceCardTheme4 {...item} itemType="flight" />
			case "theme-5":
				return <FlightServiceCardTheme5  {...item} itemType="flight" />
			case "theme-10":
				return <FlightServiceCardTheme4  {...item} itemType="flight" />
			default:
				return <FlightServiceCard {...item} itemType="flight" />
		}
	}

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 3
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3
		},
		tablet: {
			breakpoint: { max: 1024, min: 567 },
			items: 2
		},
		mobile: {
			breakpoint: { max: 567, min: 0 },
			items: 1
		}
	};


	return (
		<EditBox editMode={focusContainer === type}>
			<div
				onClick={() => onFocus(type)}
				className={`web-builder-content-flights wb-flights 
					${style.theme === "theme-7" ||
						style.theme === "theme-5" ||
						style.theme === "theme-10" ? '' : 'container'}`
				}
				id={id}
			>
				{style.theme === "theme-7" ?
					<ThemeSevenFlights
						items={items}
						sortFlightsItems={sortFlightsItems}
						focusContainer={focusContainer}
						type={type}
						editItem={editItem}
						itemsBoxActions={itemsBoxActions}
						updateFlights={updateFlights}
						title={title}
						body={body}
						handleOpenAddModal={handleOpenAddModal}
						image={image}
					/>
					:
					<div className={`${style.theme === "theme-5" || style.theme === "theme-10" ? "container" : ""}`}>
						<div className="service-top-section">
							<ServicesContent
								header={title}
								body={body}
								changeHeader={(value) =>
									updateFlights({
										name: "title",
										value,
										language: selectedLanguage,
									})
								}
								changebody={(value) =>
									updateFlights({ name: "body", value, language: selectedLanguage })
								}
							/>
							<AddElement
								onClick={handleOpenAddModal}
								title={webBuilder.addMoreFlights}
							/>
						</div>


						{items?.[0]?.sourceModule === "service-builder"
							?
							// service builder cards 
							<div className="services-carousel-container">
								<Carousel
									responsive={responsive}
									itemClass={"hotel-slide"}
									className=""
									slidesToSlide={1}
									keyBoardControl={true}
									customRightArrow={<CustomRightArrow />}
									customLeftArrow={<CustomLeftArrow />}
									renderButtonGroupOutside={true}
									removeArrowOnDeviceType={["tablet", "mobile"]}
									renderArrowsWhenDisabled={true}
									infinite={true}
								>
									{items.map((item, index) => (
										<DragAndDropContainer
											accept={flightBlocksType}
											type={flightBlocksType}
											id={item.id}
											index={index}
											action={SORT_ITEMS_IN_CONTAINER_ACTION}
											moveItemInContainer={(dragIndex, hoverIndex) =>
												sortFlightsItems({ dragIndex, hoverIndex })
											}
											key={item.id}
										>
											<EditBox
												editMode={focusContainer === type}
												actions={[
													...editItem(item, index),
													...itemsBoxActions(item.id),
												]}
											>
												<div className="px-2" key={item.id || index}>
													<FlightServiceBuilderCard {...item} openEditModal={openEditModal} />
												</div>
											</EditBox>
										</DragAndDropContainer>
									))}
								</Carousel>
							</div>
							:
							// package builder cards 
							<div className={"flights-cards-container"} style={{ gridTemplateColumns: `repeat(${+items_per_row} , 1fr)` }}>
								{items.map((item, index) => (
									<DragAndDropContainer
										accept={flightBlocksType}
										type={flightBlocksType}
										id={item.id}
										index={index}
										action={SORT_ITEMS_IN_CONTAINER_ACTION}
										moveItemInContainer={(dragIndex, hoverIndex) =>
											sortFlightsItems({ dragIndex, hoverIndex })
										}
										key={item.id}
									>
										<EditBox
											editMode={focusContainer === type}
											actions={[
												...editItem(item, index),
												...itemsBoxActions(item.id),
											]}
										>
											{renderFlightView(item)}
										</EditBox>
									</DragAndDropContainer>
								))}
							</div>
						}
					</div>
				}

				{openAdd && (
					<AddService
						handleModalState={handleOpenAddModal}
						open={openAdd}
						item_id={1}
						label={webBuilder.flight}
						header={webBuilder.flights}
						onAdd={addItemToFlight}
						details={updateItem}
						selectedIds={selectedIds}
						showPackageBuilderOption={items.length === 0 ? true : items?.[0].sourceModule === "package-builder"}
					/>
				)}
			</div>
		</EditBox>
	);
}

export default FlightsService;
