import SelectField from 'components/Form/SelectField/SelectField';
import React from 'react'
import Locale from 'translations';

export default function PassportsHeader({ passportsGroups, passportsData, handleHeaderInputsChanges, getPassportsListByGroupOrTag }) {
  const { onlineVisa, operationStatement } = Locale;
  
  return (
    <>
      <div className='radio-group-container'>
        <div className='d-flex flex-wrap align-items-center'>
          <div className='item'>
            <input
              type="radio"
              id={"safavisa-groups"}
              name={"safavisa-groups"}
              checked={passportsData.source === "safavisa-groups"}
              onChange={()=> {}}
            />
            <span className='checkmark'></span>
            <label htmlFor="safavisa-groups" className="user-select-none">{operationStatement.getFromSafaVisa}</label>
          </div>

          <div className='item disabled'>
            <input
              type="radio"
              id={"nusuk"}
              name={"nusuk"}
              disabled={true}
            />
            <span className='checkmark'></span>
            <label htmlFor="nusuk" className="user-select-none">{operationStatement.getFromNusuk}</label>
          </div>
        </div>
      </div>

      <div className="row mx-0 mt-3 align-items-center">
        <div className="col-md-4 col-12">
          <SelectField
            label={`${onlineVisa.GroupName}*`}
            placeholder={onlineVisa.GroupName}
            name="group-name"
            id="group-name"
            options={passportsGroups?.list || []}
            value={passportsData.selectedGroup?.name}
            onChange={(e) => handleHeaderInputsChanges({ key: "selectedGroup", value: e})}
            disabled={passportsData.selectedTag}
          />
        </div>
        <div className="col-md-4 col-12">
          <SelectField
            label={`${onlineVisa.tag}*`}
            placeholder={onlineVisa.tag}
            name="tag-name"
            id="tag-name"
            options={passportsGroups?.tags || []}
            value={passportsData.selectedTag?.name}
            onChange={(e) => handleHeaderInputsChanges({ key: "selectedTag", value: e })}
            disabled={passportsData.selectedGroup}
          />
        </div>

        <button type="button" className="btn bg-nxt mt-4 px-5 py-2 rounded-lg"
          disabled={!passportsData.selectedGroup && !passportsData.selectedTag}
          onClick={getPassportsListByGroupOrTag}
        >
          {onlineVisa.search}
        </button>

      </div>
    </>
  )
}
