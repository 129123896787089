import Carousel from "react-multi-carousel";
import { getActivityLogs } from "services/activity";
import { useEffect, useState } from "react";
import moment from "moment";
import { ReactComponent as DotsIcon } from "assets/images/dashboard/dots.svg";
import { ReactComponent as RefreshIcon } from "assets/images/dashboard/refresh-2.svg";
import { ReactComponent as TeamMembersIcon } from "assets/images/dashboard/team-members-icon.svg";
import { ReactComponent as BalanceIcon } from "assets/images/dashboard/total-balance-icon.svg";
import { getDashboardStats } from 'services/profile';
import { fetchActiveSubscriptionPlans } from 'services/subscription';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useSBSState } from 'context/global';
import { formatPrice } from 'helpers/utils';
import Locale from 'translations';
import CarouselPlan from './CarouselPlan';

export default function DashboardV2() {
  const { dashboard } = Locale;
  const { locale, dynamicNav } = useSBSState();

  const [dashboardState, setDashboardState] = useState({
    activitylogs: [],
    subscriptionPackages: [],
    stats: null
  });


  console.log(dynamicNav);


  useEffect(() => {
    async function fetchDashboard() {
      let [statisticsRes, activitylogsRes, subscriptionPackagesRes] = await Promise.all(
        [getDashboardStats(), getActivityLogs(), fetchActiveSubscriptionPlans()]
      );
      setDashboardState({
        stats: statisticsRes?.data,
        activitylogs: activitylogsRes?.data?.data,
        subscriptionPackages: subscriptionPackagesRes?.data?.data
      })
    }
    fetchDashboard();
  }, []);

  // const totalBalanceIncreased = dashboardState.stats?.balance?.last_month_percentage > 0;
  // const totalBalanceDecreased = dashboardState.stats?.balance?.last_month_percentage < 0;


  // statistics
  return (
    <div className="dashboard-v2">
      <div className="dashboard-cards">
        {/* team members card */}
        <article className="card-item">
          <div className="card-item-header">
            <h2 className="text-muted">
              <TeamMembersIcon />
              <span className="mx-2">{dashboard.messages.teamMembers}</span>
            </h2>
            <DotsIcon />
          </div>

          <p className="unit-count my-2">{dashboardState.stats?.team_members?.total}</p>

          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column ">
              <div className="d-flex gap-10 align-items-center">
                <span className="circle"></span>
                <p className="text-muted">{dashboard.messages.activeUsers}</p>
              </div>
              <div>{dashboardState.stats?.team_members?.active}</div>
            </div>
            <div className="d-flex flex-column ">
              <div className="d-flex gap-10 align-items-center">
                <span className="circle" style={{ backgroundColor: "#D63E3E" }}></span>
                <p className="text-muted">{dashboard.messages.inactiveUsers}</p>
              </div>
              <div>{dashboardState.stats?.team_members?.inactive}</div>
            </div>
          </div>
        </article>

        {/* total balance card */}
        <article className="total-balance card-item">
          <div className="card-item-header">
            <h2 className="text-muted">
              <BalanceIcon />
              <span className="mx-2">{dashboard.messages.totalBalance}</span>
            </h2>
            <DotsIcon />
          </div>

          <p className="unit-count my-3">
            {formatPrice(dashboardState.stats?.balance?.total)} {dashboardState.stats?.balance?.currency}
          </p>

          {/* <div className="d-flex justify-content-between align-items-center">
            <span className="text-muted">From last month</span>
            <span className={`balance-percentage  ${totalBalanceIncreased ? "increased" : totalBalanceDecreased ? "decreased" : ""}`}>

              {totalBalanceIncreased ? "+" : totalBalanceDecreased ? "-" : ""}

              {`${dashboardState.stats?.balance?.last_month_percentage?.toString() || ""} %`}
            </span>
          </div> */}
        </article>

        {/* Current bundle card */}

        <article className="current-bundle card-item">
          {dashboardState.subscriptionPackages.length > 0 ?
            <Carousel
              responsive={responsive}
              customRightArrow={<CustomRight />}
              customLeftArrow={<CustomLeft />}
              slidesToSlide={1}
              keyBoardControl={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              showDots={false}
              infinite={true}
              renderButtonGroupOutside={true}
              rtl={locale === "ar"}
            >
              {dashboardState?.subscriptionPackages?.map(subscribedPackage => {
                const consumedDays = subscribedPackage?.start_date ? moment().diff(moment(subscribedPackage?.start_date), "days") : "0";
                const remainingDays = subscribedPackage?.end_date ? moment(subscribedPackage?.end_date).add(1,"day").diff(moment(), "days") : "0";;
                const packageExpireOn = subscribedPackage?.end_date ? moment(subscribedPackage?.end_date).format("DD MMM YYYY") : ""
                const totalPackageDuration = moment(subscribedPackage?.end_date).diff(moment(subscribedPackage?.start_date), "days")
                const consumedPercentage = Math.ceil((+consumedDays / +totalPackageDuration) * 100);

                return (
                  <CurrentBundleCard
                    key={subscribedPackage?.category}
                    consumedDays={consumedDays}
                    consumedPercentage={consumedPercentage}
                    remainingDays={remainingDays}
                    packageExpireOn={packageExpireOn}
                    title={subscribedPackage?.name }
                  />
                )
              })}
            </Carousel>
            :
            <CurrentBundleCard
              consumedDays={null}
              consumedPercentage={null}
              remainingDays={null}
              packageExpireOn={null}
              title={"Basic Bundle"}
            />
          }

        </article>
        {/* profile card */}
        {/* <article className="complete-profile card-item">
          <div className="card-item-header">
            <h2 className="complete-profile-title">
              Complete your profile
            </h2>
            <DotsIcon />
          </div>
          <div className="consumption mt-4">
            <div className="consumpton-remained"></div>
          </div>
          <p className="completion-message mt-3">You have completed 80% of your profile</p>
          <div className="d-flex justify-content-end mt-3">
            <button className="btn filled-btn">
              Complete Profile
            </button>
          </div>
        </article> */}
      </div>


      <div className="dashboard-main-section">

        <div className="solution-plans">
          <p className="title">{dashboard.messages.discoverOurSolutions}</p>
          {dynamicNav?.length > 0 &&
            <Carousel
              responsive={responsive}
              slidesToSlide={1}
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={10000}
              customTransition="transform 300ms ease-in-out"
              customDot={<CustomDot />}
              keyBoardControl={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              renderButtonGroupOutside={false}
              showDots={true}
              infinite={true}
              rtl={locale === "ar"}
            >
              {dynamicNav?.filter(navItem => navItem?.category !== "marketplace").map(navItem => {
                return <CarouselPlan key={navItem?.id} bundleData={navItem} />
              })}
            </Carousel>
          }
        </div>


        <div className="daily-activities">
          <h2 className="title">{dashboard.messages.dailyActivities}</h2>
          <div className="logs-list">
            {dashboardState.activitylogs.length > 0 ?
              dashboardState.activitylogs.map(log => {
                return (
                  <div className="activitiy-log-item" key={log?.id}>
                    <span className="text-muted">{moment(log.updated_at).format("hh:mm")}</span>
                    <span className="circle"></span>
                    <p className="text-muted">{log.full_description}</p>
                  </div>
                )
              })
              :
              <div>
                <p className="text-muted">No logs existing</p>
              </div>
            }
          </div>
        </div>

      </div>
    </div>
  )
}



const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


const CustomDot = ({ onClick, ...rest }) => {
  const { active } = rest;
  return (
    <li className={`custom-dot ${active ? "custom-dot-active" : ""}`} onClick={onClick}>
    </li>
  );
};

const CustomRight = ({ onClick }) => (
  <button className="btn p-0 arrow right shadow-none" onClick={onClick}>
    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 0.5L0.725 5.25L5 10" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </button>
);
const CustomLeft = ({ onClick }) => (
  <button className="btn p-0 arrow left shadow-none" onClick={onClick}>
    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 0.5L0.725 5.25L5 10" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </button>
);

function CurrentBundleCard({ title, consumedDays, consumedPercentage, remainingDays, packageExpireOn }) {
  const { dashboard } = Locale;

  return (
    <>
      <div className="card-item-header">
        <h2 className="font-weight-bold">
          {dashboard.messages.currentBundle}
        </h2>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <p className="bundle-name">{title}</p>
        <Link to="/subscription/packages-plans" className="btn filled-btn">
          <RefreshIcon />
          <span className="btn-title"> {dashboard.messages.renewBundle}</span>
        </Link>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <p className="bundle-remaining-time">
          {dashboard.messages.consumed} {+consumedDays > 0 ? consumedDays : "0"} {dashboard.messages.days}
        </p>
        <p className="bundle-remaining-percent">{consumedPercentage}%</p>
      </div>
      <div className="consumption mt-1">
        <div className="consumpton-remained" style={{ width: `${consumedPercentage}%` }}></div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <p className="bundle-expiry-date">{+remainingDays > 0 ? remainingDays : "0"} {dashboard.messages.daysLeft}</p>
        <p className="bundle-expiry-date">{dashboard.messages.expiresOn} {packageExpireOn}</p>
      </div>
    </>
  )
}