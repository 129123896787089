import { ReactComponent as MasterCardIcon } from "assets/images/subscription-plan/master-card.svg"
import { ReactComponent as VisaIcon } from "assets/images/subscription-plan/visa.svg"
import { ReactComponent as WalletIcon } from "assets/images/subscription-plan/wallet.svg"
import { ReactComponent as DueAmountIcon } from "assets/images/subscription-plan/due-amount.svg"

import CheckBox from 'components/chekbox'
import { useLocation, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSBSDispatch, useSBSState } from 'context/global'
import { getPrebookListWallets } from 'services/wallet'
import { formatPrice } from 'helpers/utils'
import useNavigationBar from 'layouts/useNavigationBar'
import { HexagonIconWrapper } from './SubscriptionPlanDetails'
import Locale from 'translations'
// import moment from 'moment'
import { payPackagePlan } from 'services/subscriptionPlan'
import { store } from 'react-notifications-component'
import { getSubscripedModules } from 'services/auth'
import { handlePaymentCurrency } from 'components/NewPaymentAction'



export default function SubscriptionCheckout() {
  // ** hooks
  const { inventory } = Locale;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useSBSDispatch();
  const { companyInfo } = useSBSState();
  const { dynamicModulesRoutes, tabsIcons, fetchDynamicNav } = useNavigationBar();
  const selectedBundle = location.selectedBundle;

  // ** states
  const [paymentMethod, setPaymentMethod] = useState("");
  const [payment, setPayment] = useState({});
  const [listWallets, setListWallets] = useState([]);
  const [canPayOnline, setCanPayOnline] = useState(false);
  const [paymentOnline, setPaymentOnline] = useState({});
  const [isDrop, setIsDrop] = useState(false);
  

  const totalPrice = paymentMethod === "online"
    ? payment.newDue ? formatPrice(payment.newDue)
      : formatPrice(payment?.exchange_rate_price + payment?.exchange_rate_price * 0.025)
    : formatPrice(payment?.exchange_rate_price || "");


  const bundleDurationInDays = selectedBundle?.duration;

  // ** logic functions
  async function fetchSafaWallet() {
    setListWallets([]);
    // let onlinePay = companyInfo?.country?.nat_code === "IDN" && selectedCurrency === "IDR" ? "IDR" : ONLINE_CURRENCY;
    let onlinePay = handlePaymentCurrency(companyInfo?.country?.id || companyInfo?.country?.nationality_id);

    const walletRes = await getPrebookListWallets({
      currency: selectedBundle?.currency + "," + onlinePay,
      price: selectedBundle.total_price,
    });
    if (walletRes.status >= 200 && walletRes?.status < 300) {
      const walletsData = walletRes?.data?.data;
      const data = walletsData?.filter((i) => i.amount > 0);
      const dataOnline = walletsData?.find((i) => i.currency === onlinePay);
      const dataPro = walletsData?.find((i) => i.currency === selectedBundle.currency);

      let Online = dataOnline ? dataOnline : dataPro;
      setCanPayOnline(dataOnline ? dataOnline : false);
      setPaymentOnline(Online);
      setPaymentMethod(dataOnline ? "online" : "debit");
      setPayment(Online);
      setListWallets(data);
    }
  }

  function handleNewDeduct(e) {
    let paymentClone = { ...payment };
    if (e.target.checked) {
      paymentClone.newDue =
        paymentClone?.exchange_rate_price + (paymentClone?.exchange_rate_price - paymentClone.amount) * 0.025 - paymentClone.amount;
    } else {
      paymentClone.newDue = null;
    }
    setPayment(paymentClone);
  };


  // get active modules after succesful payment
  async function fetchSubsciptionPlan() {
    const res = await getSubscripedModules();
    if (res?.status === 200) {
      dispatch({ type: "subscriptionPlan", payload: res?.data?.data });
    }
  }

  async function afterSuccessfulSubscription() {
    return await Promise.all([fetchDynamicNav(), fetchSubsciptionPlan()])
  }

  // redirect to plans list 
  useEffect(() => {
    if (!selectedBundle) {
      history.push("/subscription/packages-plans")
    }
    if (selectedBundle) {
      fetchSafaWallet()
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function payToSubscribe() {
    const data = {
      payment_method: paymentMethod,
      currency: payment?.currency,
    };
    const payRes = await payPackagePlan(selectedBundle?.id, data);
    if (payRes.status === 200 || payRes.status === 201) {
      store.addNotification({
        title: "info!",
        message: payRes?.data?.message ?? "Payment Successful",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
        onRemoval: () => { }
      });
      afterSuccessfulSubscription();
      history.push("/dashboard")
      
    } else if (payRes?.status === 202) {
      // setActionType(payRes?.data?.actionType);
      // setFormData(payRes?.data?.data?.formData);
    }
  }


  return (
    <section className="checkout">

      <div className="payment-container boxs-payment">
        <h1 className="title">Subscribe Your Plan</h1>

        <div className="payments-methods">
          {/* pay from credit card */}
          {canPayOnline &&
            <div className={`online-method ${paymentMethod === "online" ? "active-method" : ""}`}>
              <div className="method-item">
                <div className="method-input">
                  <input
                    type="radio"
                    name="pay-online"
                    id="pay-online"
                    checked={paymentMethod === "online"}
                    value={"online"}
                    onChange={() => {
                      setPaymentMethod("online");
                      setPayment(paymentOnline);
                    }}
                  />
                  <label for="pay-online" className="m-0 pointer user-select-none">
                    {inventory.messages.PayFromCreditCard}

                  </label>
                </div>

                <div>
                  <MasterCardIcon />
                  <VisaIcon />
                </div>
              </div>

              {/* deduct from wallet checkbox */}
              {paymentMethod === "online" &&
                payment.amount > 0 &&
                payment.amount <
                payment?.exchange_rate_price +
                payment?.exchange_rate_price * 0.025 &&
                <div className="method-item mt-4">
                  <div className="checkBox-input method-input">
                    <CheckBox
                      id="deduct-from-wallet"
                      name="deduct-from-wallet"
                      type="checkbox"
                      text="Deduct from wallet?"
                      onChange={(e) => handleNewDeduct(e)}
                    />
                  </div>

                  <p className="wallet-balance">
                    <span>Your Balance: </span>
                    <span className="font-weight-bold">{payment.amount} {payment.currency}</span>
                  </p>
                </div>
              }
            </div>
          }

          {/* pay from my wallet */}
          <div className={`wallet-method mt-3 ${paymentMethod === "debit" ? "active-method" : ""}`}>
            <div className="method-item">
              <div className="method-input">
                <input
                  type="radio"
                  name="pay-wallet"
                  id="pay-wallet"
                  checked={paymentMethod === "debit"}
                  value={"debit"}
                  onChange={() => {
                    setPaymentMethod("debit");
                    setPayment(paymentOnline);
                  }}
                />
                <label for="pay-wallet" className="m-0 pointer user-select-none">
                  {inventory.messages.PayFromMyWallet}
                </label>
              </div>

              <WalletIcon />
            </div>
            {paymentMethod === "debit" &&
              <ListMyWallets
                payment={payment}
                setIsDrop={setIsDrop}
                isDrop={isDrop}
                listWallets={listWallets}
                setPayment={setPayment}
              />
              // <SelectField
              //   options={listWallets}
              // />
            }
          </div>
        </div>

        {/* Due Amount */}
        <div className="due-amount-container">
          <DueAmountIcon />
          <div className="">
            <h3 className="title">{inventory.messages.dueAmount}</h3>
            {/* Due  amount */}
            <p className="amount">
              {totalPrice} {payment?.currency}
            </p>
            <p className="caution">{inventory.messages.AllPaymentsMadeByCredit}</p>
          </div>
        </div>

        <button className="btn pay-btn" onClick={payToSubscribe}>
          Pay & Subscribe
        </button>
      </div>

      {/****************************  checkout summary ****************************/}
      <div className="checkout-summary">
        <div className="bundle-info">

          <div className="d-flex align-items-center justify-content-between mb-3">
            <h2 className="title">{selectedBundle?.title}</h2>
            <span className="font-weight-bold h5 mb-0 text-primary">
              {totalPrice} {payment?.currency} / {" "}
              {bundleDurationInDays + " Days"}
            </span>
          </div>

          <ul className="package-module-list">
            {selectedBundle?.modules?.filter(bundleModule => bundleModule?.status === "active").map(bundleModule => {
              return (
                <li key={bundleModule?.key}>
                  {
                    dynamicModulesRoutes?.[bundleModule?.key]?.icon
                    ||
                    <HexagonIconWrapper icon={tabsIcons?.[selectedBundle.category]} />
                  }
                  <span>{bundleModule?.name}</span>
                </li>
              )
            })}
          </ul>
        </div>

        <div className="total-amount mt-1">
          <h4 className="font-weight-bold">TOTAL</h4>
          <p className="total mt-3">
            {totalPrice} {payment?.currency} / {" "}
            {bundleDurationInDays + " Days"}
          </p>
        </div>
      </div>

    </section>
  )
}


function ListMyWallets({ payment, setIsDrop, isDrop, listWallets, setPayment }) {
  const { marketPlace } = Locale;
  return (
    <div className="fake-select mx-auto" style={{maxWidth: "240px"}}>
      {payment?.currency ?
        <button className="btn shadow-none d-flex align-items-center justify-content-between w-100"
          onClick={() => setIsDrop(!isDrop)}
        >
          <p>{payment.currency}</p>
          <p className="amount">
            {payment?.amount && payment?.amount > 0 ? formatPrice(payment?.amount) : null}
            {payment.currency}
          </p>
          <i class="fas fa-angle-down"></i>
        </button>
        :
        <>
          <p className="placholder"> {marketPlace.selectWallet}</p>
          <i class="fas fa-angle-down"></i>
        </>
      }
      {isDrop && (
        <div className="dropdown-list">
          {isDrop && listWallets?.length > 0 && listWallets.map((wallet) => {
            console.log(payment.currency , wallet.currency);
            
            return (
              <button className={`btn shadow-none mx-md-5 drop-select gap-10 w-100 pay-${payment.currency === wallet.currency ? "active" : ""}`}
                onClick={() => {
                  setPayment(wallet);
                  setIsDrop(!isDrop);
                }}
                style={{ maxWidth: "240px" }}
              >
                <p className="placholder d-flex">
                  {wallet.currency}
                </p>
                <p className="amount">
                  {wallet?.amount > 0 ? formatPrice(wallet?.amount) : null} {wallet.currency}
                </p>
              </button>
            );
          })}
        </div>
      )}
    </div>
  )
}