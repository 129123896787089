import moment from 'moment';
import { cityInitialData, initalArrivalDepartureData } from './multiStepFormConfig';
import { TRANSPORTATION_TYPES_LOOKUP, TRANSPORTATIONS_REQUEST_LOOKUP } from './lookups';

export const mapDetailsData = (detailsData, allCountries) => {
  return {
    data: {
      tripName: detailsData?.trip_name,
      tripLevel: detailsData?.trip_level,
      supervisor: detailsData?.supervisor_name,
      supervisorPhoneNumber: detailsData?.supervisor_number,
      supervisorPhone: allCountries.find(country => country.id === detailsData?.supervisor_phone_country_id),
    },
    errors: {},
  }
};

export const mapArrivalDepartureData = (arrivalDepartureData) => {
  if (!arrivalDepartureData) return initalArrivalDepartureData;

  if (arrivalDepartureData?.transport_type === "Air") { // you can find the types of transport in lookups.js 
    return {
      ...initalArrivalDepartureData,
      data: {
        ...initalArrivalDepartureData.data,
        transportType: TRANSPORTATION_TYPES_LOOKUP[0],
        arrivalCarrierName: arrivalDepartureData.arrival_carrier,
        arrivalCarrierNumber: arrivalDepartureData.arrival_trip_number,
        arrivalDate: moment(arrivalDepartureData.arrival_date),
        arrivalTime: moment(arrivalDepartureData.arrival_time).toDate(),
        arrivalAirport: arrivalDepartureData.arrival_port_id,
        arrivalAirportHall: arrivalDepartureData.arrival_hall_id,
        deprature: arrivalDepartureData.departures.map(dep => ({
          data: {
            departureCarrierName: dep.carrier,
            departureCarrierNumber: dep.trip_number,
            departureDate: moment(dep.date),
            departureTime: moment(dep.time).toDate(),
            departureAirport: dep.port_id,
            departureAirportHall: dep.hall_id,
          },
          errors: {},
        }),
        ),
      },
      errors: {},
    }
  }

  if (arrivalDepartureData?.transport_type === "Land") {
    return {
      ...initalArrivalDepartureData,
      data: {
        ...initalArrivalDepartureData.data,
        transportType: { name: "Land", id: "2", label: "Land" },
      },
      land: {
        data: {
          arrivalDate: moment(arrivalDepartureData?.arrival_date),
          arrivalTime: moment(arrivalDepartureData?.arrival_date).toDate(),
          arrivalAirport: arrivalDepartureData?.arrival_port,
          departures: arrivalDepartureData?.departures.map(dep => ({
            data: {
              departureDate: moment(dep.date),
              departureTime: moment(dep.date).toDate(),
              departureAirport: dep.port,
            },
            errors: {},
          })),
        },
        errors: {},
      },
    }
  }
};

export const mapHotelData = (hotelsData, citiesLookup, mealsTypesLookup) => {
  if (!hotelsData || hotelsData?.length === 0) return { cities: [cityInitialData] };

  return {
    cities: hotelsData.map(hotel => ({
      data: {
        city: citiesLookup.find(city => city.id === hotel.city_id),
        checkIn: moment(hotel.hotels[0].check_in_date),
        nightsNumber: moment(hotel.hotels[0].check_out_date).diff(moment(hotel.hotels[0].check_in_date), 'days'),
        checkOut: moment(hotel.hotels[0].check_out_date),
        hotels: hotel.hotels.map(h => ({
          data: {
            hotelName: h.hotel,
            mealsType: mealsTypesLookup.find(meal => meal.id === h.meal_type),
            singleCount: h.rooms_count.single,
            doubleCount: h.rooms_count.double,
            tripleCount: h.rooms_count.triple,
            quadCount: h.rooms_count.quadruple,
            quintCount: h.rooms_count.quintuple,
            sextCount: h.rooms_count.sixtuple,
          },
          errors: {},
        })),
      },
      errors: {},
    })),
  };
};

export const mapAttractionsData = (attractionsData, citiesLookup) => {
  return attractionsData.attractions.map(attraction => ({
    data: {
      city: citiesLookup.find(city => city.id === attraction.city_id),
      attractionsName: attraction.attraction,
      attractionsDate: moment(attraction.date_time),
      attractionsTime: moment(attraction.date_time).toDate(),
    },
    errors: {},
  }));
}

export const mapTransportationData = (transportationData) => {
  const transportType = TRANSPORTATIONS_REQUEST_LOOKUP.find(t => transportationData?.request_type === t.id)
  return {
    data: {
      requestType: transportType || null,
      transporterName: transportType?.id === "through-umrah-operator" ? null : transportationData?.transporter,
      approvalNumber: transportationData?.credential_number || null,
      operationId: transportationData?.operation_id || null,
      vehicles: (transportationData?.vehicles || []).map(vehicle => ({
        data: {
          vehicleType: { ...vehicle.vehicle_type, name: vehicle.vehicle_type?.name?.en },
          vehicleModel: { id: vehicle.vehicle_model, name: vehicle.vehicle_model },
          vehicleCount: vehicle.count,
        },
        errors: {},
      })),
    },
    errors: {},
  };
}