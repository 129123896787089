import SelectField from 'components/Form/SelectField/SelectField'

export function GenerateLinkOrChangeOperatorForm({ data, setData, setIsCompanySelectView, companiesList, handleGenerateLink }) {

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsCompanySelectView(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <SelectField
          label="Select Umrah Company"
          placeholder="Select Company"
          options={companiesList}
          value={data?.company?.name || ''}
          onChange={(e) => setData({ ...data, company: e })}
        />
      </div>

      <button className='primary-button mt-4' disabled={!data?.company} onClick={handleGenerateLink}>
        {data?.operation_link ? 'Change Operator' : 'Generate Link'}
      </button>
    </form>
  )
}