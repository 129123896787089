import axios from "axios";
const companyManagementURL = process.env.REACT_APP_API_URL + "/v1/company-management";

export const fetchUserProfile = async () => {
	return await axios
		.get(`${companyManagementURL}/view_user_profile`)
		.then((res) => res.data.data);
};

export const updateUserProfile = async (data) => {
	return await axios
		.post(`${companyManagementURL}/edit_user_profile`, data)
		.then((res) => res);
};

export const fetchCompanyProfile = async () => {
	return await axios
		.get(`${companyManagementURL}/company_info`)
		.then((res) => res.data.data);
};

export const updateCompanyProfile = async (data) => {
	return await axios
		.post(`${companyManagementURL}/edit_company_profile`, data)
		.then((res) => res);
};
export const deleteCompanyAccount = async () => {
	return await axios
		.post(`${companyManagementURL}/delete-my-account`)
		.then((res) => res);
};
export const getDynamicNav = async () => {
	return await axios
		.get(`${companyManagementURL}/dynamic-nav`)
		.then((res) => res);
};
export const getDashboardStats = async () => {
	return await axios
		.get(`${companyManagementURL}/home-statistics`)
		.then((res) => res);
};

export const updateCompanyLogo = async (data) => {
	return await axios
		.post(`${companyManagementURL}/edit-company-logo`, data)
		.then((res) => res);
};
