import {
  AttractionIcon,
  BuildingIcon,
  CarIcon,
  NoteIcon,
  PassportTravelIcon,
  RoutingIcon,
} from "../icons";

import { ArivalDepraureForm } from './ArrivalDeparture';
import HotelForm from './Hotel';
import AttractionsForm from './Attractions';
import { TransportationForm } from './Transportation';
import PassportForm from './Passport';
import DetailsForm from './Details';
import { TRANSPORTATION_TYPES_LOOKUP } from './lookups';


export const initialDetailsData = {
  data: {
    tripName: "",
    tripLevel: "",
    supervisor: "",
    supervisorPhone: "",
    supervisorPhoneNumber: "",
  },
  errors: {},
}

export const initialdepartureData = {
  data: {
    departureCarrierName: "",
    departureCarrierNumber: "",
    departureDate: null,
    departureTime: null,
    departureAirport: "",
    departureAirportHall: "",
  },
  errors: {},
};

export const initalArrivalDepartureData = {
  data: {
    transportType: TRANSPORTATION_TYPES_LOOKUP[0],
    arrivalCarrierName: "",
    arrivalCarrierNumber: "",
    arrivalDate: null,
    arrivalTime: null,
    arrivalAirport: "",
    arrivalAirportHall: "",
    deprature: [initialdepartureData],
  },
  land: {
    data: {
      arrivalDate: "",
      arrivalTime: "",
      arrivalAirport: null,
      departures: [{
        data: {
          departureDate: "",
          departureTime: "",
          departureAirport: null,
        },
        errors: {},
      }]
    },
    errors: {},
  },
  errors: {},
}

export const initialAttractionData = {
  data: {
    city: null,
    attractionsName: null,
    attractionsDate: null,
    attractionsTime: null,
  },
  errors: {}
}

export const cityInitialData = {
  data: {
    city: null,
    checkIn: null,
    nightsNumber: null,
    checkOut: null,
    hotels: [
      {
        data: {
          hotelName: null,
          mealsType: null,
          singleCount: 0,
          doubleCount: 0,
          tripleCount: 0,
          quadCount: 0,
          quintCount: 0,
          sextCount: 0,
        },
        errors: {},
      }
    ],
  },
  errors: {}
}

export const hotelInitialData = {
  cities: [cityInitialData],
}

export const vehicleInitialData = {
  data: {
    vehicleType: null,
    vehicleModel: null,
    vehicleCount: null,
  },
  errors: {},
}

export const transportationInitialData = {
  data: {
    requestType: null,
    transporterName: null,
    approvalNumber: null,
    operationId: null,
    vehicles: [vehicleInitialData]
  },
  errors: {}
}

export const tabs = [
  {
    tab: {
      title: "Details",
      icon: <NoteIcon />,
    },
    form: DetailsForm,
    formId: "details",
  },
  {
    tab: {
      title: "Arrival & Departure",
      icon: <RoutingIcon />,
    },
    form: ArivalDepraureForm,
    formId: "arrival_departure",
  },
  {
    tab: {
      title: "Hotels",
      icon: <BuildingIcon />,
    },
    form: HotelForm,
    formId: "hotels_form",
  },
  {
    tab: {
      title: "Attractions",
      icon: <AttractionIcon />,
    },
    form: AttractionsForm,
    formId: "attractions_form",
  },
  {
    tab: {
      title: "Passport",
      icon: <PassportTravelIcon />,
    },
    form: PassportForm,
    formId: "passport_form",
  },
  {
    tab: {
      title: "Transportation",
      icon: <CarIcon />,
    },
    form: TransportationForm,
    formId: "transportation_form",
  },
];