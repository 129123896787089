import React, { useState } from 'react'
import Locale from 'translations'
import { ReactComponent as TripDateIcon } from 'assets/images/trip-planner/routing-2.svg'
import { ReactComponent as TrickCircleIcon } from 'assets/images/trip-planner/tick-circle.svg'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { AlertCircleIcon } from 'modules/UmrahOperations/shared/Icons'
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import moment from 'moment'

export default function TripDates({ tripsDatesList, selectedTripDate, handleSelectTripDate, deleteNonPublishTrip, handleDeleteAllTrips }) {
	const { packagePlanner, operationStatement } = Locale;
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	function toggleWarningModal() {
		setWarningModalOpen(!warningModalOpen);
	}

	return (
		<>
			<div className='trip-dates'>

				<div className='tripDates-header justify-content-between'>
					<div className="d-flex gap-10 w-100 justify-content-center">
						<TripDateIcon />
						<h6>{packagePlanner.allTripsDates}</h6>
					</div>
					{tripsDatesList?.some(trip => !trip?.is_published) &&
						<button className="btn shadow-none mx-3 px-0" onClick={toggleWarningModal}>
							{/* <svg width="11px" height="11px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 9L9 1M9 9L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" />
							</svg> */}
							<DeleteIcon />
						</button>
					}
				</div>
				{tripsDatesList?.map(trip => {
					const isActive = trip?.product_uuid === selectedTripDate?.product_uuid;
					return (
						<div className={`tripDates-main  ${isActive ? "active" : "pointer"}`}
							onClick={() => !isActive && handleSelectTripDate(trip)}
							key={trip?.product_uuid}
						>

							<div className='tripDates-items'>
								{trip?.is_published ? <TrickCircleIcon /> : null}
								<p className='dates'>{moment(trip?.departure_date_at).format("DD-MMM-YYYY")}</p>
							</div>
							<div className='tripDates-items justify-content-center'>
								<p className='dates'>{moment(trip?.return_date_at).format("DD-MMM-YYYY")}</p>
							</div>
							{/* hide actions if trip has reservation */}
							<div className='dates-actions mx-3'>
								{!trip?.is_published ?
									<span className={`${isActive ? "hidden" : ""}`} role='button'
										onClick={(e) => !isActive && deleteNonPublishTrip(e, trip?.product_uuid)}
									>
										<svg width="11px" height="11px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1 9L9 1M9 9L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" />
										</svg>
									</span>
									:
									null
								}
							</div>

						</div>
					)
				})}
			</div>
			<Modal isOpen={warningModalOpen} centered className="change-pricing-Modal">
				<ModalHeader toggle={toggleWarningModal}>
					<span className="font-weight-bold">{packagePlanner.deleteAllTrips}</span>
				</ModalHeader>
				<ModalBody>
					<div className="d-flex gap-10 align-items-center">
						<AlertCircleIcon color="#D92D20" />
						<p className="">{packagePlanner.deleteAllTripsMessage} </p>
					</div>
					<div className="d-flex gap-10 mt-5">
						<button className="btn reject-btn" onClick={() => toggleWarningModal()}>
							{operationStatement.No}
						</button>
						<button className="btn confirm-btn" onClick={(e) => handleDeleteAllTrips(e, toggleWarningModal)}>
							{operationStatement.Yes}
						</button>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}
