import React from 'react'
import RoomsPricing from './shared/RoomsPricing'
import PricingDiscountVAT from './shared/PricingDiscountVAT'
import Locale from 'translations';
import { ReactComponent as HotelIcon } from "assets/images/market-place/services-hotel-icon.svg";
import { ReactComponent as VisaIcon } from "assets/images/market-place/services-visaonline-icon.svg";
import { ReactComponent as DepartureIcon } from "assets/images/market-place/departur-transport-icon.svg";
import { ReactComponent as InternalIcon } from "assets/images/market-place/services-transport-icon.svg";
import { ReactComponent as SightSeenIcon } from "assets/images/market-place/sightseen-icon.svg";
import { ReactComponent as InsuranceIcon } from "assets/images/market-place/insurance-icon.svg";
import { ReactComponent as OtherServicesIcon } from "assets/images/market-place/otherservices-icon.svg";
import validate from 'helpers/validate';
import _ from "lodash";
import { initalRoomObj } from 'modules/TripPlanner/helpers/formatPricing'
import NumberField from 'components/Form/NumberField/NumberField';

const packageIcons = {
  1: <DepartureIcon className="departure-icon" />,
  2: <InternalIcon />,
  4: <VisaIcon />,
  5: <InsuranceIcon />, //insurance
  6: <HotelIcon />,
  7: <SightSeenIcon />, // Sightseen 
  8: <OtherServicesIcon />, // other services 
}

export default function TripPlannerFullPackagePricing({
  pricingForText,
  setPricingData,
  pricingData,
  toggleVatDiscount,
  selectedPricingType,
  errors,
  setErrors,
  roomsViewCategory
}) {
  const { productsBuilder, packagePlanner, marketPlace } = Locale;
  const fullPricing = pricingData?.pricing?.find(price => price?.for === pricingData?.selected_pricing_for);
  const productItems = pricingData?.product_items?.filter(item => item?.item?.id !== 6);
  const hotelsList = fullPricing?.hotels;

  function handleServicesInputsChanges({ key, value }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for);
    let combination = { ...pricingClone[selectedPricingIndex]?.product_price_combinations[0] }
    combination = { ...combination, [key]: value }
    pricingClone[selectedPricingIndex].product_price_combinations[0] = combination
    if (key === "full_original_price") {
      setErrors({
        ...errors,
        ...validate(
          { name: [`${key}-${pricingData?.selected_pricing_for}`], value },
          { required: true },
        ),
      });
    }

    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  function handleVatDiscountInputs({ key, value }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for)
    pricingClone[selectedPricingIndex][key] = value;
    setPricingData({ ...pricingData, pricing: pricingClone })
  }


  // handle adult, child price and max children per room
  function handleRoomsPricesChanges({ key, value, roomType, roomIndex, hotelId }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for)
    let hotelIndex = pricingClone[selectedPricingIndex].hotels.findIndex(item => item?.id === hotelId);
    let hotel = { ...pricingClone[selectedPricingIndex].hotels[hotelIndex] }
    let selectedRoomType = hotel?.rooms_types?.find(room => room?.type === roomType);
    selectedRoomType.rooms[roomIndex] = {
      ...selectedRoomType.rooms[roomIndex],
      [key]: value,
    }
    if (key === "price_adult") {
      setErrors({
        ...errors,
        ...validate(
          { name: [`${key}-${pricingData?.selected_pricing_for}-${hotelId}-${roomType}-${roomIndex}`], value },
          { required: true },
        ),
      });
    }

    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  // handle category, meal type and room count for b2b and b2c pricing
  function handleRoomsForB2BAndB2C({ keyName, value, roomType, hotelId, roomIndex }) {
    let pricingClone = _.cloneDeep(pricingData?.pricing);
    pricingClone = pricingClone?.map(pricingItem => {
      return {
        ...pricingItem,
        hotels: pricingItem?.hotels?.map(hotel => {
          let selectedRoomType = hotel?.rooms_types?.find(room => room?.type === roomType && hotelId === hotel.id);
          if (selectedRoomType) {
            selectedRoomType.rooms[roomIndex] = {
              ...selectedRoomType.rooms[roomIndex],
              [keyName]: value,
            }
          }
          return { ...hotel }
        })
      }
    });
    setErrors({
      ...errors,
      ...validate(
        { name: [`${keyName}-${hotelId}-${roomType}-${roomIndex}`], value },
        { required: true },
      ),
    });
    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  function addNewRoomBasedOnType({ hotelId, roomType }) {
    let pricingClone = _.cloneDeep(pricingData?.pricing);
    pricingClone = pricingClone?.map(pricing => {
      return {
        ...pricing,
        hotels: pricing?.hotels?.map(hotel => {
          let selectedRoomType = hotel?.rooms_types?.find(room => room?.type === roomType && hotelId === hotel.id);
          if (selectedRoomType) {
            selectedRoomType.rooms = [...selectedRoomType.rooms, { ...initalRoomObj, room_type: roomType }];
          }
          return { ...hotel }
        })
      }
    })

    setPricingData({ ...pricingData, pricing: pricingClone })
  }


  function deleteRoom({ hotelId, roomType, roomIndex }) {
    let pricingClone = _.cloneDeep(pricingData?.pricing);
    pricingClone = pricingClone?.map(pricing => {
      return {
        ...pricing,
        hotels: pricing?.hotels?.map(hotel => {
          let selectedRoomType = hotel?.rooms_types?.find(room => room?.type === roomType && hotelId === hotel.id);
          if (selectedRoomType) {
            selectedRoomType.rooms.splice(roomIndex, 1)
          }
          return { ...hotel }
        })
      }
    })
    setPricingData({ ...pricingData, pricing: pricingClone })
  }


  return (
    <div className="pricing-type-wrapper">
      <div className="full-package-service-head">
        {marketPlace.ServiceType}
      </div>
      {productItems?.map(service => {
        return (
          <div key={service?.id} className="full-package-service">
            {packageIcons[service?.item?.id]}
            <span className="mx-2">
              {service.item.id === 1
                ? productsBuilder.externalTransportations
                : service.item.id === 2
                  ? productsBuilder.internalTransportations
                  : service?.item?.id === 7
                    ? service?.itemable?.name
                    : service.item.name
              }
            </span>
          </div>
        )
      })}
      {hotelsList.length === 0 ?
        <div className="table-container row px-1">
          <table className="table services-pricing-table">
            <thead>
              <tr>
                <th style={{ verticalAlign: "middle" }}>
                  <div className="t-head"> Services</div>
                </th>
                <th>
                  <div className="t-head">{packagePlanner.pricePerAdult}</div>
                </th>
                <th>
                  <div className="t-head">{packagePlanner.pricePerChild}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="40%" className="pe-0">
                  <div className="t-sub text-center">Package Price</div>
                </td>
                <td>
                  <NumberField
                    label={packagePlanner.pricePerAdult}
                    hasLabel={false}
                    removeArrow={true}
                    value={fullPricing?.product_price_combinations[0]?.full_original_price || ""}
                    onChange={(e) => handleServicesInputsChanges({
                      key: "full_original_price",
                      value: e.target.value,
                    })}
                    errors={errors[`full_original_price-${pricingData?.selected_pricing_for}`]}
                    color={errors[`full_original_price-${pricingData?.selected_pricing_for}`]?.required ? "danger" : ""}
                  />
                </td>
                <td>
                  <NumberField
                    label={packagePlanner.pricePerChild}
                    hasLabel={false}
                    removeArrow={true}
                    value={fullPricing?.product_price_combinations[0]?.child_original_price || ""}
                    onChange={(e) => handleServicesInputsChanges({
                      key: "child_original_price",
                      value: e.target.value,
                    })}
                    errors={errors[`child_original_price-${pricingData?.selected_pricing_for}`]}
                    color={errors[`child_original_price-${pricingData?.selected_pricing_for}`]?.required ? "danger" : ""}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        :
        hotelsList?.map((hotel, index) => {
          return (
            <RoomsPricing
              key={hotel?.id}
              service={hotel}
              handleRoomsForB2BAndB2C={handleRoomsForB2BAndB2C}
              handleRoomsPricesChanges={handleRoomsPricesChanges}
              selectedPricingType={selectedPricingType}
              productHasHotel={hotelsList.length > 0}
              roomsTypes={hotel?.rooms_types}
              addNewRoomBasedOnType={addNewRoomBasedOnType}
              errors={errors}
              selectedFor={pricingData?.selected_pricing_for}
              roomsViewCategory={roomsViewCategory}
              deleteRoom={deleteRoom}
            >
              <div className="header text-start d-flex align-items-center">
                <HotelIcon />
                <span className="mx-1">{hotel?.hotel_name} <b> (Hotel {index + 1})</b></span>
              </div>
            </RoomsPricing>
          )
        })
      }


      <PricingDiscountVAT
        pricing={fullPricing}
        pricingForText={pricingForText}
        toggleVatDiscount={toggleVatDiscount}
        handleVatDiscountInputs={handleVatDiscountInputs}
      />

    </div>
  )
}
