import NumberField from 'components/Form/NumberField/NumberField'
import SelectField from 'components/Form/SelectField/SelectField';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
import { roomTypesLookup } from 'modules/products-builder/Requestes/RequestView/helpers/localLookups';
import React, { Fragment } from 'react'
import Locale from 'translations';




export default function RoomsPricing({
  children,
  service,
  handleRoomsPricesChanges,
  handleRoomsForB2BAndB2C,
  roomsTypes,
  productHasHotel,
  addNewRoomBasedOnType,
  errors,
  selectedFor,
  roomsViewCategory,
  deleteRoom
}) {

  const { packagePlanner, marketPlace } = Locale;
  const { mealsTypesLookup } = getOperationsLookup(Locale);

  return (
    <>
      {children}

      <div className="table-container mb-3">
        <table className="table room-pricing-table">
          <thead>
            <tr>
              <th width="40%">
                <span className="room-th font-weight-bold">{productHasHotel ? packagePlanner.roomTypeCount : marketPlace.Services}</span>
              </th>
              <th>
                <span className="room-th">Count</span>
              </th>
              <th>
                <span className="room-th">{packagePlanner.pricePerAdult}</span>
              </th>
              <th>
                <span className="room-th">{packagePlanner.pricePerChild}</span>
              </th>
              {productHasHotel &&
                <th>
                  <span className="room-th">{packagePlanner.maxChildPerRoom}</span>
                </th>
              }
              <th></th>
            </tr>
          </thead>

          <tbody>
            {roomsTypes?.map((roomType, index) => {
              let currentRoomsCount = roomType?.rooms?.length > 0 ? roomType?.rooms?.reduce((totalRoomCount, room) => totalRoomCount + +room?.count, 0) : 0;
              let isRoomsFull = currentRoomsCount >= roomType?.count;

              return (
                <Fragment key={`room-${index}`}>
                  <tr className="bg-gray-100" style={{ borderTop: "14px solid #fff" }}>
                    <td width="40%">
                      <div className="d-flex justify-content-between align-items-center px-1">
                        <span>
                          {productHasHotel
                            ? `${roomTypesLookup[roomType?.type] || ""}  ${marketPlace.Room}`
                            : ""
                          }
                        </span>
                        <button className="btn add-new-room-btn p-0"
                          onClick={() => addNewRoomBasedOnType({ hotelId: service?.id, roomType: roomType?.type })}
                          disabled={isRoomsFull}
                        >
                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.667 16H21.3337" stroke="#6A4D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16 21.3332V10.6665" stroke="#6A4D1B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </button>
                      </div>
                    </td>
                    <td><div className="total-room-count">{roomType?.count}</div></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {/* rooms */}
                  {roomType?.rooms.map((room, roomCategoryIndex) => {
                    return (
                      <tr className="bg-gray-100" key={`${roomType?.type}-${roomType?.id}-${index}-${roomCategoryIndex}`}>
                        <td width="40%">
                          <div className="row mx-0">
                            <div className="col-6">
                              <SelectField
                                label={"Category"}
                                hasLabel={false}
                                placeholder="Category"
                                options={roomsViewCategory}
                                value={room?.category?.name}
                                onChange={(e) => handleRoomsForB2BAndB2C({
                                  keyName: "category",
                                  value: e,
                                  roomType: roomType?.type,
                                  roomIndex: roomCategoryIndex,
                                  hotelId: service?.id
                                })}
                                errors={errors[`category-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]}
                                color={errors[`category-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]?.required ? "danger" : ""}
                              />
                            </div>
                            <div className="col-6">
                              <SelectField
                                label={"Meal Type"}
                                hasLabel={false}
                                placeholder="Meal Type"
                                options={mealsTypesLookup}
                                value={room?.meal_type?.name}
                                onChange={(e) => handleRoomsForB2BAndB2C({
                                  keyName: "meal_type",
                                  value: e,
                                  roomType: roomType?.type,
                                  roomIndex: roomCategoryIndex,
                                  hotelId: service?.id
                                })}
                                errors={errors[`meal_type-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]}
                                color={errors[`meal_type-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]?.required ? "danger" : ""}
                              />
                            </div>
                          </div>
                        </td>

                        <td width="5%">
                          <NumberField
                            label="Count"
                            hasLabel={false}
                            removeArrow={true}
                            id={`room_count_${roomCategoryIndex}`}
                            name={`room_count_${roomCategoryIndex}`}
                            max={+roomType?.count}
                            value={room?.count}
                            onChange={(e) => {
                              let value = +e.target.value;
                              const allRoomsCount = roomType?.rooms.reduce((totalRoomsCount, room, i) => (i === roomCategoryIndex ? totalRoomsCount : totalRoomsCount + +room?.count), 0) + value;
                              let remainingCount = +roomType?.count - allRoomsCount;

                              if (remainingCount < 0) {
                                value = +roomType?.count - Math.abs(allRoomsCount - value);
                              } else {
                                value = +e.target.value
                              }
                              handleRoomsForB2BAndB2C({
                                keyName: "count",
                                value: Number(value).toString(),
                                roomType: roomType?.type,
                                roomIndex: roomCategoryIndex,
                                hotelId: service?.id
                              })
                            }}
                            onWheel={(e) => e.target.blur()}
                            errors={errors[`count-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]}
                            color={errors[`count-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]?.required ? "danger" : ""}
                          />
                        </td>
                        {/* adult price */}
                        <td>
                          <NumberField
                            label={packagePlanner.pricePerAdult}
                            hasLabel={false}
                            removeArrow={true}
                            id={`adult_price_${roomCategoryIndex}`}
                            name={`adult_price_${roomCategoryIndex}`}
                            value={room?.price_adult}
                            onChange={(e) => handleRoomsPricesChanges({
                              key: "price_adult",
                              value: Number(e.target.value).toString(),
                              roomType: roomType?.type,
                              roomIndex: roomCategoryIndex,
                              hotelId: service?.id
                            })}
                            onWheel={(e) => e.target.blur()}
                            errors={errors[`price_adult-${selectedFor}-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]}
                            color={errors[`price_adult-${selectedFor}-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]?.required ? "danger" : ""}
                          />
                        </td>
                        {/* child price */}
                        <td>
                          <NumberField
                            label={packagePlanner.pricePerChild}
                            hasLabel={false}
                            removeArrow={true}
                            id={`child_price_${roomCategoryIndex}`}
                            name={`child_price_${roomCategoryIndex}`}
                            value={room?.price_child}
                            onChange={(e) => handleRoomsPricesChanges({
                              key: "price_child",
                              value: Number(e.target.value).toString(),
                              roomType: roomType?.type,
                              roomIndex: roomCategoryIndex,
                              hotelId: service?.id
                            })}
                            onWheel={(e) => e.target.blur()}
                          // errors={errors[`price_child-${selectedFor}-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]}
                          // color={errors[`price_child-${selectedFor}-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]?.required ? "danger" : ""}
                          />
                        </td>
                        {/* max child */}
                        <td>
                          <NumberField
                            label={packagePlanner.maxChildPerRoom}
                            hasLabel={false}
                            removeArrow={true}
                            id={`max_child_${roomCategoryIndex}`}
                            name={`max_child_${roomCategoryIndex}`}
                            value={room?.max_child || ""}
                            onChange={(e) => handleRoomsPricesChanges({
                              key: "max_child",
                              value: Number(e.target.value).toString(),
                              roomType: roomType?.type,
                              roomIndex: roomCategoryIndex,
                              hotelId: service?.id
                            })}
                            onWheel={(e) => e.target.blur()}
                          // errors={errors[`max_child-${selectedFor}-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]}
                          // color={errors[`max_child-${selectedFor}-${service?.id}-${roomType?.type}-${roomCategoryIndex}`]?.required ? "danger" : ""}
                          />
                        </td>

                        <td>
                          {roomCategoryIndex > 0 ?
                            <button className="btn p-0 mt-3"
                              onClick={() => deleteRoom({
                                hotelId: service.id,
                                roomType: roomType?.type,
                                roomIndex: roomCategoryIndex,
                              })}
                            >
                              <svg width="11px" height="11px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 9L9 1M9 9L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                              </svg>
                            </button>
                            :
                            null
                          }
                        </td>
                      </tr>
                    )
                  })}
                </Fragment>
              )
            })}



          </tbody>
        </table>

      </div>
    </>
  )
}
