import React, { useEffect, useState } from 'react'
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from 'helpers/validate';
import { fetchAreas, fetchCities } from "services/lookups";
import UploadFile from 'components/UploadFile';
import Locale from 'translations';
import { AddIcon, CameraFlashIcon } from '../../Icons';
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import ItineraryAttractionView from './AttractionView';
import useItinerary from '../useItinerary';


const keysNotRequired = ["services", "terms", "description", "notes", "other", "policy", "isNew", "currentView", "area"];

export default function TripPlannerItineraryAttraction({
  tripPlannerData,
  selectedDay,
  setTripPlannerData,
  attractionData,
  itemIndex,
  availableCountries
}) {
  // ** hooks
  const { inventory, commons, packagePlanner, operationStatement, companyDetails, productElements, productsBuilder } = Locale;
  const { saveAttractionItem, removeSegment, canEdit } = useItinerary({ setTripPlannerData, selectedDay, tripPlannerData });

  // states
  const [citiesList, setCitiesList] = useState([])
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [currentView, setCurrentView] = useState(attractionData?.currentView);
  const [areasList, setAreasList] = useState([])


  // variables 
  const isUpdatingAttraction = attractionData?.id ? true : false;

  // itinerary attraction inputs changes
  function handleAttractionInputs({ key, value }) {
    let tripPlannerClone = { ...tripPlannerData }
    let daysClone = tripPlannerClone.itinerary_days;
    let selectedDayIndex = selectedDay?.day - 1;
    let updatedDay = daysClone[selectedDayIndex];
    // reset city if country changed
    if (key === "country" && value?.id !== attractionData?.country?.id) {
      updatedDay.items[itemIndex] = { ...updatedDay.items[itemIndex], [key]: value, city: null };
    } else {
      updatedDay.items[itemIndex] = { ...updatedDay.items[itemIndex], [key]: value };
    }
    setTripPlannerData(tripPlannerClone);
    if (key !== "photos") {
      setErrors({
        ...errors,
        ...validate(
          { name: key, value },
          { required: keysNotRequired.includes(key) ? false : true }
        ),
      });
    }
  }

  // get list cities based on selected country
  const fetchCity = async (countryId) => {
    const citiesRes = await fetchCities(countryId);
    setCitiesList(citiesRes);
  };
  // get list cities based on selected city
  async function getAreasList(city_id) {
    const areasRes = await fetchAreas(city_id);
    setAreasList(areasRes);
  }

  function handleAttractionServiceInputs(value, serviceIndex) {
    let tripPlannerClone = { ...tripPlannerData }
    let daysClone = tripPlannerClone.itinerary_days;
    let selectedDayIndex = selectedDay?.day - 1;
    let updatedDay = daysClone[selectedDayIndex];
    let attractionServices = updatedDay.items[itemIndex].services;
    attractionServices[serviceIndex] = value;
    setTripPlannerData(tripPlannerClone);
  }

  function addNewExtraService() {
    const services = [...attractionData.services].concat("");
    handleAttractionInputs({ key: "services", value: services });
  }


  function deleteExtraService(index) {
    const services = [...attractionData.services].filter((i, ind) => index !== ind);
    handleAttractionInputs({ key: "services", value: services });
  };

  // check form errors
  function checkFormErrors() {
    let submitError = {}
    Object.keys(attractionData).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: attractionData[key] },
          { required: keysNotRequired.includes(key) ? false : true }
        ),
      }
    });
    setErrors(() => submitError)
  }

  function submitAttraction() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }


  // delete attraction
  function removeAttraction() {
    removeSegment(tripPlannerData, itemIndex, attractionData?.id)
  }



  useEffect(() => {
    if (isFormValid(errors)) {
      saveAttractionItem(attractionData);
    }
  }, [isErrorLoaded]);

  useEffect(() => {
    if (!attractionData?.isNew) {
      setCurrentView("view");
    }
  }, [attractionData?.isNew, selectedDay]);



  return (
    <div className="itinerary-item">


      {currentView === "view" &&
        <ItineraryAttractionView
          item={attractionData}
          setCurrentView={setCurrentView}
          removeItem={removeAttraction}
          canEdit={canEdit}
        />
      }


      {/* add edit hotels form */}
      {currentView === "form" &&
        <>
          <div className="form-header">
            <span className="d-flex align-items-center gap-10">
              <CameraFlashIcon color="#050001" />
              {packagePlanner.attraction}
            </span>
            {canEdit &&
              <button className="btn p-0" onClick={removeAttraction}>
                <svg width="11px" height="11px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 9L9 1M9 9L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </button>
            }
          </div>

          {/* attraction form */}
          <div className="itinerary-item-form p-4">
            <div className="row m-0">
              {/* attraction photos */}
              <div className="col-12">
                <UploadFile
                  id={`upload-package-main-image-${itemIndex}`}
                  label={packagePlanner.attractionImageGallery}
                  onChange={(value) => {
                    handleAttractionInputs({ key: "photos", value });
                    setErrors({
                      ...errors,
                      ...validate(
                        {
                          name: "photos",
                          value: value.length,
                        },
                        { required: true }
                      ),
                    });
                  }}
                  value={attractionData.photos}
                  errorMsg={
                    errors?.photos?.required
                      ? inventory.viewReservation.Photos + " " + commons.isRequired
                      : false
                  }
                />
              </div>
              {/* attraction name */}
              <div className="col-md-4 col-12 mt-2">
                <TextField
                  label={packagePlanner.attractionName}
                  placeholder={productsBuilder.enterPlace}
                  id={`attractin_name_${itemIndex}`}
                  name={`attractin_name_${itemIndex}`}
                  value={attractionData?.name}
                  onChange={(e) => {
                    handleAttractionInputs({ key: "name", value: e.target.value });
                  }}
                  color={errors?.name?.required ? "danger" : ""}
                  errors={errors?.name}
                />
              </div>

              {/* country & city & area */}
              <div className="row mx-0 w-100">
                {/* attraction country */}
                <div className="col-md-4 col-12">
                  <SelectField
                    label={operationStatement.Country}
                    id={`attraction_country_${itemIndex}`}
                    name={`attraction_country_${itemIndex}`}
                    options={availableCountries}
                    value={attractionData?.country?.name}
                    onChange={(e) => {
                      handleAttractionInputs({ key: "country", value: e });
                      if (e?.id !== attractionData?.country?.id) {

                      }
                      fetchCity(e.id);
                    }}
                    color={errors?.country?.required ? "danger" : ""}
                    errors={errors?.country}
                  />
                </div>
                {/* attraction city */}
                <div className="col-md-4 col-12">
                  <SelectField
                    label={operationStatement.City}
                    id={`attraction_city_${itemIndex}`}
                    name={`attraction_city_${itemIndex}`}
                    value={attractionData?.city?.name || ""}
                    options={citiesList}
                    onChange={(e) => {
                      handleAttractionInputs({ key: "city", value: e });
                      getAreasList(e?.id);
                    }}
                    disabled={!attractionData.country?.id}
                    color={errors?.city?.required ? "danger" : ""}
                    errors={errors?.city}
                  />
                </div>
                {/* attraction area */}
                <div className="col-md-4 col-12">
                  <SelectField
                    label={productElements.area}
                    id={`attraction_city_${itemIndex}`}
                    name={`attraction_city_${itemIndex}`}
                    value={attractionData?.area?.name || ""}
                    options={areasList}
                    onChange={(e) => {
                      handleAttractionInputs({ key: "area", value: e });
                    }}
                    disabled={!attractionData.city?.id}
                    color={errors?.area?.required ? "danger" : ""}
                    errors={errors?.area}
                  />
                </div>

              </div>

            </div>
            <div className="row mx-0 mt-2">
              {/* attraction Duration */}
              <div className="col-md-4 col-12">
                <TextField
                  label={productsBuilder.duration}
                  placeholder={"2h 30m"}
                  id={`attraction_duration_${itemIndex}`}
                  name={`attraction_duration_${itemIndex}`}
                  value={attractionData?.duration || ""}
                  onChange={(e) => {
                    handleAttractionInputs({ key: "duration", value: e.target.value });
                  }}
                  color={errors?.duration?.required ? "danger" : ""}
                  errors={errors?.duration}
                />
              </div>
              {/* attraction pickup point */}
              <div className="col-md-4 col-12">
                <TextField
                  label={operationStatement.Pickuppoint}
                  placeholder={productsBuilder.enterPlace}
                  id={`attraction_pickup_${itemIndex}`}
                  name={`attraction_pickup_${itemIndex}`}
                  value={attractionData?.pickup || ""}
                  onChange={(e) => {
                    handleAttractionInputs({ key: "pickup", value: e.target.value });
                  }}
                  color={errors?.pickup?.required ? "danger" : ""}
                  errors={errors?.pickup}
                />
              </div>
              {/* attraction drop_off point */}
              <div className="col-md-4 col-12">
                <TextField
                  label={operationStatement.DropoffPoint}
                  placeholder={productsBuilder.enterPlace}
                  id={`attraction_drop_off_${itemIndex}`}
                  name={`attraction_drop_off_${itemIndex}`}
                  value={attractionData?.drop_off || ""}
                  onChange={(e) => {
                    handleAttractionInputs({ key: "drop_off", value: e.target.value });
                  }}
                  color={errors?.drop_off?.required ? "danger" : ""}
                  errors={errors?.drop_off}
                />
              </div>
            </div>
            {/* attraction extra services */}
            <div className="d-flex flex-column mt-2">
              <span className="control-field__label">{productElements.includedServices}</span>
              {attractionData?.services?.map((extraService, serviceIndex) => {
                return (
                  <div className="col-md-4 col-12 d-flex gap-10" key={`attraction_services_${serviceIndex}`}>
                    <TextField
                      hasLabel={false}
                      placeholder={"Enter Service"}
                      id={`attraction_service_${serviceIndex}`}
                      name={`attraction_service_${serviceIndex}`}
                      value={extraService || ""}
                      onChange={(e) => {
                        handleAttractionServiceInputs(e.target.value, serviceIndex)
                      }}
                    />
                    <button className="btn p-0 shadow-none delete-row-btn" onClick={() => deleteExtraService(serviceIndex)}>
                      <DeleteIcon />
                    </button>
                  </div>
                )
              })}
              <button className="btn add-row-btn p-0 shadow-none mt-2 align-self-baseline" onClick={() => addNewExtraService()}>
                <AddIcon color="#6A4D1B" />
                {productsBuilder.addNew}
              </button>
            </div>

            <div className="row mx-0">
              {/* Description */}
              <div className="col-12 mt-2">
                <label htmlFor={`attration_description_${itemIndex}`} className="control-field__label">{companyDetails.description}</label>
                <textarea
                  placeholder={packagePlanner.enterDescription}
                  id={`attration_description_${itemIndex}`}
                  name={`attration_description_${itemIndex}`}
                  cols="30"
                  rows="3"
                  value={attractionData?.description}
                  onChange={(e) => {
                    handleAttractionInputs({ key: "description", value: e.target.value })
                  }}
                  className="textarea-input"
                />
              </div>

              {/* Terms and conditions */}
              <div className="col-12 mt-2">
                <label htmlFor={`attration_terms_${itemIndex}`} className="control-field__label">{productsBuilder.termsAndConditions}</label>
                <textarea
                  placeholder={productsBuilder.termsAndConditions}
                  id={`attration_terms_${itemIndex}`}
                  name={`attration_terms_${itemIndex}`}
                  cols="30"
                  rows="3"
                  value={attractionData?.terms}
                  onChange={(e) => {
                    handleAttractionInputs({ key: "terms", value: e.target.value })
                  }}
                  className="textarea-input"
                />
              </div>
              {/* Cancelation Policy */}
              {/* <div className="col-12 mt-2">
                <label htmlFor={`attration_policy_${itemIndex}`} className="control-field__label">Cancelation Policy</label>
                <textarea
                  placeholder="Enter cancelation policy"
                  id={`attration_policy_${itemIndex}`}
                  name={`attration_policy_${itemIndex}`}
                  cols="30"
                  rows="3"
                  value={attractionData?.policy}
                  onChange={(e) => {
                    handleAttractionInputs({ key: "policy", value: e.target.value })
                  }}
                  className="textarea-input"
                />
              </div> */}
              {/* Other */}
              <div className="col-12 mt-2">
                <label htmlFor={`attration_notes_${itemIndex}`} className="control-field__label">{productElements.notes}</label>
                <textarea
                  placeholder={productElements.notesPlaceholder}
                  id={`attration_notes_${itemIndex}`}
                  name={`attration_notes_${itemIndex}`}
                  cols="30"
                  rows="3"
                  value={attractionData?.notes}
                  onChange={(e) => {
                    handleAttractionInputs({ key: "notes", value: e.target.value })
                  }}
                  className="textarea-input"
                />
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <button className="btn px-5 py-2 mt-2 mx-2 border"
                onClick={() => isUpdatingAttraction ? setCurrentView("view") : removeAttraction()}
              >
                {companyDetails.cancel}
              </button>
              <button className="btn px-5 py-2 mt-2 bg-nxt"
                onClick={submitAttraction}
              >
                {isUpdatingAttraction ? operationStatement.Update : productsBuilder.Save}
              </button>
            </div>
          </div>

        </>
      }
    </div >
  )
}
