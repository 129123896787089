import NumberField from 'components/Form/NumberField/NumberField';
import Locale from 'translations';
import { ReactComponent as HotelIcon } from "assets/images/market-place/services-hotel-icon.svg";
import { ReactComponent as VisaIcon } from "assets/images/market-place/services-visaonline-icon.svg";
import { ReactComponent as DepartureIcon } from "assets/images/market-place/departur-transport-icon.svg";
import { ReactComponent as InternalIcon } from "assets/images/market-place/services-transport-icon.svg";
import { ReactComponent as SightSeenIcon } from "assets/images/market-place/sightseen-icon.svg";
import { ReactComponent as InsuranceIcon } from "assets/images/market-place/insurance-icon.svg";
import { ReactComponent as OtherServicesIcon } from "assets/images/market-place/otherservices-icon.svg";

const packageIcons = {
  1: <DepartureIcon />,
  2: <InternalIcon />,
  4: <VisaIcon />,
  5: <InsuranceIcon />, //insurance
  6: <HotelIcon />,
  7: <SightSeenIcon />, // Sightseen 
  8: <OtherServicesIcon />, // other services 
}

export default function ServicesPricing({ pricingForText, servicesList, handleServicesInputsChanges, errors, selectedFor }) {

  const { productsBuilder, packagePlanner, marketPlace } = Locale;

  return (
    <div className="mb-3">
      <table className="table services-pricing-table">
        <thead>
          <tr>
            <td rowSpan="2" colSpan="2" width="250px" className="t-head" style={{verticalAlign: "middle"}}>
              <div className="t-head"> {marketPlace.ServiceType}</div>
            </td>
            <th colSpan="3" scope="colgroup">
              <div className="t-head">
                {packagePlanner.totalPackagePriceFor} <span className="text-uppercase">{pricingForText}</span>
              </div>
            </th>
          </tr>
          <tr>
            <th scope="col">
              <div className="t-sub">{packagePlanner.pricePerAdult}</div>
            </th>
            <th scope="col">
              <div className="t-sub">{packagePlanner.pricePerChild}</div>
            </th>
            <th scope="col">
              <div className="t-sub">{packagePlanner.pricePerInfant}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {servicesList?.map((service, index) => {
            return (
              <tr key={service?.id}>
                <td colSpan="2">
                  <div className="t-sub text-start text-capitalize">
                    {packageIcons[service?.item?.id]}
                    <span className="mx-2">
                      {service.item.id === 1
                        ? productsBuilder.externalTransportations
                        : service.item.id === 2
                          ? productsBuilder.internalTransportations
                          : service?.item?.id === 7
                            ? service?.itemable?.name
                            : service.item.name
                      }
                    </span>
                  </div>
                </td>
                <td>
                  <NumberField
                    label={packagePlanner.pricePerAdult}
                    hasLabel={false}
                    removeArrow={true}
                    value={service?.itemable?.price_adult || ""}
                    onChange={(e) => handleServicesInputsChanges({
                      key: "price_adult",
                      value: e.target.value,
                      serviceId: service?.id
                    })}
                    errors={errors[`price_adult-${selectedFor}-${service?.id}`]}
                    color={errors[`price_adult-${selectedFor}-${service?.id}`]?.required ? "danger" : ""}
                  />
                </td>
                <td>
                  <NumberField
                    label={packagePlanner.pricePerChild}
                    hasLabel={false}
                    removeArrow={true}
                    value={service?.itemable?.price_child || ""}
                    onChange={(e) => handleServicesInputsChanges({
                      key: "price_child",
                      value: e.target.value,

                      serviceId: service?.id
                    })}
                    errors={errors[`price_child-${selectedFor}-${service?.id}`]}
                    color={errors[`price_child-${selectedFor}-${service?.id}`]?.required ? "danger" : ""}
                  />
                </td>
                <td>
                  <NumberField
                    label={packagePlanner.pricePerInfant}
                    hasLabel={false}
                    removeArrow={true}
                    value={service?.itemable?.price_infant || ""}
                    onChange={(e) => handleServicesInputsChanges({
                      key: "price_infant",
                      value: e.target.value,
                      serviceId: service?.id
                    })}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
