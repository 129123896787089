export function DuplicatedIcon({color="#fff"}){
  return <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.9697 12.25V16.75C16.9697 20.5 15.4697 22 11.7197 22H7.21973C3.46973 22 1.96973 20.5 1.96973 16.75V12.25C1.96973 8.5 3.46973 7 7.21973 7H11.7197C15.4697 7 16.9697 8.5 16.9697 12.25Z"
    stroke={color}
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M21.9697 5.85V9.15C21.9697 11.9 20.8697 13 18.1197 13H16.9697V12.25C16.9697 8.5 15.4697 7 11.7197 7H10.9697V5.85C10.9697 3.1 12.0697 2 14.8197 2H18.1197C20.8697 2 21.9697 3.1 21.9697 5.85Z"
    stroke={color}
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
}