import generateUniqueID from "helpers/generateUniqueID";
import { useState } from "react";
import LinkGenerationModal from './LinkGenerationModal';

export default function B2C() {
	const [selections, setSelections] = useState({
		standard: false,
		premium: false,
		luxury: false,
	});

	return (
		<div className="b2c_hajj setup">
			<div className="card setup">
				<h2 className="title">Setup</h2>
				<div className="divider" />

				<div className="content">
					<h3 className="title">Select Hajj Rate</h3>
					<div className="checkboxes">
						<div className="checkbox">
							<Checkbox
								label="Standard"
								checked={selections.standard}
								onChange={() =>
									setSelections({
										...selections,
										standard: !selections.standard,
									})
								}
							/>
						</div>

						<div className="checkbox">
							<Checkbox
								label="Premium"
								checked={selections.premium}
								onChange={() =>
									setSelections({ ...selections, premium: !selections.premium })
								}
							/>
						</div>

						<div className="checkbox">
							<Checkbox
								label="Luxury"
								checked={selections.luxury}
								onChange={() =>
									setSelections({ ...selections, luxury: !selections.luxury })
								}
							/>
						</div>
					</div>

					<button className="generate-btn">Generate Link</button>
				</div>
			</div>

			{/* <LinkGenerationModal isOpen={true} link="http://www.safalive.com/123456/companylink/generateopertaion" /> */}
		</div>
	);
}

const Checkbox = ({
	label = "Standard",
	checked = false,
	onChange = () => {},
	className = "",
}) => {
	const id = generateUniqueID();

	return (
		<label htmlFor={id} className={`checkbox-container ${className}`}>
			<input
				id={id}
				type="checkbox"
				className="checkbox-input"
				checked={checked}
				onChange={onChange}
			/>
			<div className="checkbox">
				<svg
					className="checkmark"
					viewBox="0 0 12 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1.5 5L4.5 8L10.5 2"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
			<span className="checkbox-label">{label}</span>
		</label>
	);
};
