import React from 'react'
import { HotelIcon } from 'modules/UmrahOperations/shared/Icons'
import RoomsPricing from './shared/RoomsPricing'
import PricingDiscountVAT from './shared/PricingDiscountVAT'
import ServicesPricing from './shared/ServicesPricing'
import _ from "lodash";
import validate from 'helpers/validate';
import { initalRoomObj } from 'modules/TripPlanner/helpers/formatPricing'


export default function TripPlannerBreakdownPricing({
  pricingForText,
  setPricingData,
  pricingData,
  toggleVatDiscount,
  selectedPricingType,
  errors,
  setErrors,
  roomsViewCategory
}) {

  const breakdownPricing = pricingData?.pricing?.find(price => price?.for === pricingData?.selected_pricing_for);
  const servicesList = breakdownPricing?.product_items?.filter(service => service?.item?.id !== 6);
  const hotelsList = breakdownPricing?.hotels;

  function handleServicesInputsChanges({ key, value, serviceId }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for)
    let serviceIndex = pricingClone[selectedPricingIndex].product_items.findIndex(item => item?.id === serviceId);
    let service = { ...pricingClone[selectedPricingIndex].product_items[serviceIndex] }

    pricingClone[selectedPricingIndex].product_items[serviceIndex] = {
      ...service,
      itemable: {
        ...service?.itemable,
        [key]: Number(value).toString()
      }
    };

    if (key === "price_adult") {
      setErrors({
        ...errors,
        ...validate(
          { name: [`${key}-${pricingData?.selected_pricing_for}-${serviceId}`], value },
          { required: true },
        ),
      });
    }

    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  // handle adult, child price and max children per room
  function handleRoomsPricesChanges({ key, value, roomType, roomIndex, hotelId }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for)
    let hotelIndex = pricingClone[selectedPricingIndex].hotels.findIndex(item => item?.id === hotelId);
    let hotel = { ...pricingClone[selectedPricingIndex].hotels[hotelIndex] }
    let selectedRoomType = hotel?.rooms_types?.find(room => room?.type === roomType);
    selectedRoomType.rooms[roomIndex] = {
      ...selectedRoomType.rooms[roomIndex],
      [key]: value,
    }
    if (key === "price_adult") {
      setErrors({
        ...errors,
        ...validate(
          { name: [`${key}-${pricingData?.selected_pricing_for}-${hotelId}-${roomType}-${roomIndex}`], value },
          { required: true },
        ),
      });
    }
    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  // handle category, meal type and room count for b2b and b2c pricing
  function handleRoomsForB2BAndB2C({ keyName, value, roomType, hotelId, roomIndex }) {
    let pricingClone = _.cloneDeep(pricingData?.pricing);
    pricingClone = pricingClone?.map(pricingItem => {
      return {
        ...pricingItem,
        hotels: pricingItem?.hotels?.map(hotel => {
          let selectedRoomType = hotel?.rooms_types?.find(room => room?.type === roomType && hotelId === hotel.id);
          if (selectedRoomType) {
            selectedRoomType.rooms[roomIndex] = {
              ...selectedRoomType.rooms[roomIndex],
              [keyName]: value,
            }
          }
          return { ...hotel }
        })
      }
    });
    setErrors({
      ...errors,
      ...validate(
        { name: [`${keyName}-${hotelId}-${roomType}-${roomIndex}`], value },
        { required: true },
      ),
    });
    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  function addNewRoomBasedOnType({ hotelId, roomType }) {
    let pricingClone = _.cloneDeep(pricingData?.pricing);
    pricingClone = pricingClone?.map(pricing => {
      return {
        ...pricing,
        hotels: pricing?.hotels?.map(hotel => {
          let selectedRoomType = hotel?.rooms_types?.find(room => room?.type === roomType && hotelId === hotel.id);
          if (selectedRoomType) {
            selectedRoomType.rooms = [...selectedRoomType.rooms, { ...initalRoomObj, room_type: roomType }];
          }
          return { ...hotel }
        })
      }
    })

    setPricingData({ ...pricingData, pricing: pricingClone })
  }


  function deleteRoom({ hotelId, roomType, roomIndex }) {
    let pricingClone = _.cloneDeep(pricingData?.pricing);
    pricingClone = pricingClone?.map(pricing => {
      return {
        ...pricing,
        hotels: pricing?.hotels?.map(hotel => {
          let selectedRoomType = hotel?.rooms_types?.find(room => room?.type === roomType && hotelId === hotel.id);
          if (selectedRoomType) {
            selectedRoomType.rooms.splice(roomIndex, 1)
          }
          return { ...hotel }
        })
      }
    })
    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  function handleVatDiscountInputs({ key, value }) {
    let pricingClone = [...pricingData?.pricing];
    let selectedPricingIndex = pricingClone?.findIndex(price => price?.for === pricingData?.selected_pricing_for)
    pricingClone[selectedPricingIndex][key] = value;
    setPricingData({ ...pricingData, pricing: pricingClone })
  }

  return (
    <div className="pricing-type-wrapper">

      <div className="service-pricing">
        <ServicesPricing
          pricingForText={pricingForText}
          servicesList={servicesList}
          handleServicesInputsChanges={handleServicesInputsChanges}
          errors={errors}
          selectedFor={pricingData?.selected_pricing_for}
        />
      </div>

      {hotelsList?.map((hotel, index) => {
        return (
          <RoomsPricing
            key={hotel?.id}
            service={hotel}
            handleRoomsForB2BAndB2C={handleRoomsForB2BAndB2C}
            handleRoomsPricesChanges={handleRoomsPricesChanges}
            selectedPricingType={selectedPricingType}
            productHasHotel={hotelsList.length > 0}
            roomsTypes={hotel?.rooms_types}
            addNewRoomBasedOnType={addNewRoomBasedOnType}
            errors={errors}
            selectedFor={pricingData?.selected_pricing_for}
            roomsViewCategory={roomsViewCategory}
            deleteRoom={deleteRoom}
          >
            <div className="header text-start d-flex align-items-center">
              <HotelIcon />
              <span className="mx-1">{hotel?.hotel_name} <b> (Hotel {index + 1})</b></span>
            </div>
          </RoomsPricing>
        )
      })}


      <PricingDiscountVAT
        pricing={breakdownPricing}
        pricingForText={pricingForText}
        toggleVatDiscount={toggleVatDiscount}
        handleVatDiscountInputs={handleVatDiscountInputs}
      />

    </div>
  )
}
