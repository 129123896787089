import { memo } from 'react'
//-------------------------------------------------------------

const ServiceBuilderHeader = ({ title, children}) => {

  // ---------- JSX Code ----------
  return (
      <div className='header-holder'>
        <h3 className='header-title'> {title}</h3>
        {children}
      </div>
  )
}

export default memo(ServiceBuilderHeader)