import React from "react";
import Locale from "translations";
import { useSBSState } from "context/global";
import { useHistory, useParams } from "react-router-dom";
import { formatPrice } from 'helpers/utils';

export default function CardPackage({ packageDetails }) {
	const { marketPlace } = Locale;
	const { locale } = useSBSState();
	let history = useHistory();
	const { status } = useParams()

	const FILEUPLOAD_URL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
	return (
		<div
			className="package-card col-md-3 col-12"
			style={{
				backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${FILEUPLOAD_URL}/${packageDetails.photo})`, cursor: "pointer"
			}}
			onClick={() =>
				history.push(
					`/${status}/package-details/${packageDetails.product_uuid}`
				)
			}
		>
			<div className="layer-top"></div>
			<div className="package-name">
				<h1>{packageDetails.name}</h1>
				<div className="hotel-star-rate d-inline px-2 ">
					{packageDetails.stars?[...Array(packageDetails.stars)].map((i) => (
						<i
							key={i}
							className="fas fa-star image-shadow"
							aria-hidden="true"
						></i>
					)):null}
				</div>
				<div className="d-flex flex-row cols-p">
					{packageDetails?.city?.name?.[locale] ? <p>
						<i class="fas fa-map-marker-alt"></i>{" "}
						{packageDetails?.city?.name?.[locale]}
					</p> : null}
					{packageDetails.nights > 0 ? <p>
						<i class="fas fa-moon"></i>{" "}
						{`${packageDetails.nights} ${marketPlace.Nights}`}
					</p> : null}
				</div>
			</div>
			<div className="package-price d-flex">

				<div className="d-flex flex-column  align-items-start">
					<p>{marketPlace.startingFrom}</p>
					<h1>{`${formatPrice(packageDetails.price)} `}  <span style={{ fontSize: "14px" }}>{packageDetails.currency}</span> </h1>
				</div>
			</div>
		</div>
	);
}
