import CheckBox from 'components/chekbox';
import { useSBSState } from 'context/global';
import moment from 'moment'
import { ReactComponent as TrashIcon } from "assets/images/umrah-operations/trash.svg";
import { CheckMarkIcon } from 'modules/UmrahOperations/shared/Icons';
import Locale from 'translations';

export default function TravelersTable({
  travelersList,
  insertedTravelers,
  handleSelectAllPassport,
  handleSelectPassport,
  handleDeletePassport,
  isPassportInserted,
}) {
  const { locale } = useSBSState();
  const { operationStatement } = Locale;


  return (
    <div className="table-container">
      <table className="table table-update w-100 mb-0">
        <thead>
          <tr>
            {!isPassportInserted &&
              <th>
                <div className="d-flex">
                  <div className="checkbox-container">
                    <CheckBox
                      checked={travelersList.filter(traveler => traveler.selected).length > 0}
                      id={`select-all-passport`}
                      name={`select-all-passport`}
                      onChange={(e) => handleSelectAllPassport(e.target.checked)}
                    // disabled={}
                    />
                  </div>
                  <span>{operationStatement.select}</span>
                </div>

              </th>
            }
            <th>{operationStatement.passportNumber}</th>
            <th>{operationStatement.agentName}</th>
            <th>{operationStatement.gender}</th>
            <th>{operationStatement.birthday}</th>
            <th>{operationStatement.age}</th>
            <th>{isPassportInserted ? operationStatement.nationalId : operationStatement.relationship}</th>
            <th>{operationStatement.nationality}</th>
            {isPassportInserted ?
              <th>{operationStatement.passportPhoto}</th>
              :
              null
            }
            {isPassportInserted ?
              <th>{operationStatement.actions}</th>
              :
              null
            }
          </tr>
        </thead>


        <tbody>
          {travelersList.length > 0 ?
            travelersList.map((passport, index) => {
              return (
                <tr key={passport?.id}>
                  {!isPassportInserted &&
                    <td>
                      <div className="checkbox-container">
                        <CheckBox
                          checked={passport?.selected}
                          id={`passport-${index}`}
                          name={`passport-${index}`}
                          value={passport?.id}
                          onChange={() => handleSelectPassport({ isPassportSelected: passport?.selected, passportNumber: passport?.passport_number })}
                          disabled={insertedTravelers?.find(traveler => traveler.passport_number === passport.passport_number) || false}
                        />
                      </div>
                    </td>
                  }

                  <td className="font-weight-bold text-black-900">
                    {passport?.passport_number}
                  </td>

                  <td>{passport?.first_name ? `${passport?.first_name} ${passport?.last_name}` : "-"}</td>

                  <td>{passport?.gender || "-"}</td>

                  <td>{passport?.birth_date ? moment(passport?.birth_date).format("DD-MM-YYYY") : "-"}</td>

                  <td>
                    {passport?.age || "-"}
                  </td>

                  {isPassportInserted ?
                    <td className="text-black-900">{passport?.national_id || "-"}</td>
                    :
                    <td>{passport?.relationship?.[locale] || passport?.relationship || "-"}</td>
                  }

                  <td className="font-weight-bold">{passport?.nationality?.[locale] || passport?.nationality?.name || "-"}</td>

                  {isPassportInserted ?
                    <td>
                      {passport.passport_photo && passport.passport_photo !== ""

                        ? <CheckMarkIcon width={"16px"} height={"16px"} />
                        : <span className="text-danger font-weight-bold">-</span>
                      }
                    </td>
                    :
                    null
                  }

                  {isPassportInserted ?
                    <td>
                      <button type="button" className="btn" onClick={() => handleDeletePassport({ passportNumber: passport?.passport_number })}>
                        <TrashIcon className="pointer" />
                      </button>
                    </td>
                    :
                    null
                  }
                </tr>
              )
            })
            :
            <tr>
              <td colSpan={9} className="text-center">{operationStatement.noTravelers}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}
