import { ReactComponent as ExportExcelIcon } from "assets/images/umrah-operations/export-excel-icon.svg";
import TextField from "components/Form/TextField/TextField";
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import Locale from "translations";
import SelectField from "components/Form/SelectField/SelectField";
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";
import { ReactComponent as EyelIcon } from "assets/images/Tds/eye.svg";
import Pagination from 'components/Pagination';
import { Link } from 'react-router-dom';


export default function Requests() {
	const { operationStatement, marketPlace } = Locale;

	return (
		<div className="b2c_hajj requests">
			<div className="card requests">
				<div className="d-flex justify-content-between align-items-center flex-wrap gap-1">
					<h2 className="title">B2C Hajj Requests</h2>
					<button className="export-execl-btn">
						<ExportExcelIcon />
						<span>Export Excel</span>
					</button>
				</div>
				<div className="divider" />

				<div className="content">
					<div className="filters">
						<div>
							<TextField
								type="text"
								id="statement-operation-no"
								name="statement-operation-no"
								label={marketPlace.messages.search}
								placeholder={operationStatement.searchEX}
								isImage={true}
								image={SeachIcon}
								prependImage={true}
								// value={}
								// onChange={}
							/>
						</div>

						<div>
							<SelectField
								label={"Nationality"}
								placeholder={"Select"}
								name="nationality"
								id="nationality"
								options={[]}
								// value={}
								// onChange={}
							/>
						</div>

						<div>
							<SelectField
								label={"Rate"}
								placeholder={"Select"}
								name="rate"
								id="rate"
								options={[]}
								// value={}
								// onChange={}
							/>
						</div>

						<div>
							<SelectField
								label={"City"}
								placeholder={"Select"}
								name="city"
								id="city"
								options={[]}
								// value={}
								// onChange={}
							/>
						</div>

						<div>
							<button className="primary-button reset-btn">
								<ResetFilterIcon />
								<span>Clear</span>
							</button>
						</div>
					</div>

					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th>Passport No.</th>
									<th>Name</th>
									<th>nationality</th>
									<th>Rate</th>
									<th>City</th>
									<th>Phone number</th>
									<th>Actions</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td>123456</td>
									<td>John Doe</td>
									<td>USA</td>
									<td>Standard</td>
									<td>New York</td>
									<td>123456789</td>
								  <td>
									<Link to="/b2c_hajj/requests/view/:id">
                  	<EyelIcon/>
                  </Link>
                  </td>
								</tr>

                <tr>
									<td>123456</td>
									<td>John Doe</td>
									<td>USA</td>
									<td>Standard</td>
									<td>New York</td>
									<td>123456789</td>
								  <td>
                    <Link to="/b2c_hajj/requests/view/:id">
                      <EyelIcon/>
                    </Link>
                  </td>
								</tr>

                <tr>
									<td>123456</td>
									<td>John Doe</td>
									<td>USA</td>
									<td>Standard</td>
									<td>New York</td>
									<td>123456789</td>
								  <td>
									<Link to="/b2c_hajj/requests/view/:id">
                      <EyelIcon/>
                    </Link>
                  </td>
								</tr>

                <tr>
									<td>123456</td>
									<td>John Doe</td>
									<td>USA</td>
									<td>Standard</td>
									<td>New York</td>
									<td>123456789</td>
									<td>
									<Link to="/b2c_hajj/requests/view/:id">
                      <EyelIcon/>
                    </Link>
                  </td>
								</tr>
							</tbody>
						</table>
					</div>
          
          <Pagination />
				</div>
			</div>
		</div>
	);
}
