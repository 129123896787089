import React, { useEffect, useState } from "react";
import checkIcon from "assets/images/crm/tick-circle.png";
import { ReactComponent as EyeIcon } from "assets/images/crm/eye.svg";
import { ReactComponent as RightICon } from "assets/images/crm/right.svg";
import CRMTabs from "modules/CRM/shared/CRMTabs";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as CRMMainIcon } from "assets/images/crm/main.svg";
import { ReactComponent as CSRequestsIcon } from "assets/images/crm/cs-requests.svg";
import { ReactComponent as UserIcon } from "assets/images/crm/user.svg";
import { ReactComponent as AccountingIcon } from "assets/images/crm/accounting.svg";
import Locale from "translations";
import { reservationPackagesList } from "services/CRM";
import moment from "moment";
import { Tooltip } from 'reactstrap';
import { ReactComponent as ProfileIcon } from 'assets/images/crm/profile.svg'
import NoDataIcon from "assets/images/crm/no-data.png";
import { ViewIcon } from 'modules/CRM/Icons';

export default function ViewCrmCustomer() {
	const { tab, id } = useParams();
	const history = useHistory();
	const email = history.location.state?.data;
	const email_localstorage = localStorage.getItem("custumers_email");
	const { CRM } = Locale;
	const CRM_TABS = [
		{ title: CRM.cRM, icon: <CRMMainIcon />, url: "main" },
		{
			title: CRM.accounting,
			icon: <AccountingIcon />,
			url: "accounting",
		},
		{
			title: CRM.csRequests,
			icon: <CSRequestsIcon />,
			url: "cs-requests",
		},
		{ title: CRM.customers, icon: <UserIcon />, url: "customers" },
	];
	const [activeTab, setActiveTab] = useState("customers");
	const [data, setData] = useState();

	// ** useEffect to set tabs
	useEffect(() => {
		if (id === undefined) {
			setActiveTab("customers");
		} else {
			setActiveTab("customers");
		}
	}, [tab]);
	useEffect(() => {
		const getAllReservationPackage = async (type, searchFilters, page) => {
			const res = await reservationPackagesList({
				owner_email: email_localstorage,
			});
			if (res?.status === 200) {
				setData(res?.data?.data);
			}
		};
		getAllReservationPackage();
	}, [email_localstorage]);

	return (
		<div className="" style={{ marginTop: "3rem" }}>
			<div className="bg-gray-50">
				<CRMTabs
					tabs={CRM_TABS}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					classNames="px-4 col-md-12 col-12 mx-auto"
				/>
			</div>
			<div className="view-customers-crm cs-requests crm-wrapper ">
				<div className="title">
					<h4>{CRM.Customers}</h4>
					<hr />
				</div>
				<Info data={data}/>
				<List data={data} />
			</div>
		</div>
	);
}

export function Info({ data }) {
	const {CRM}=Locale
	const info=data&& data.length > 0&&data[0]?.owner;
	return (
		<div className="customer-info">
			<div className="row mx-0">
				<div className="col-md-3">
					<div className="d-flex flex-column info-item">
						<p>{CRM.Name}</p>
						<span>{info?.full_name}</span>
					</div>
				</div>
				<div className="col-md-3">
					<div className="d-flex flex-column info-item">
						<p>{CRM.Email}</p>
						<span>{info?.email}</span>
					</div>
				</div>
				<div className="col-md-3">
					<div className="d-flex flex-column info-item">
						<p>{CRM.Phone}</p>
						<span>{info?.phone_code} {info?.phone}</span>
					</div>
				</div>
				<div className="col-md-3">
					<div className="d-flex flex-column info-item border-none">
						<p>{CRM.WhatsAppNumber}</p>
						<span>-</span>
					</div>
				</div>
				<div className="col-md-3 mt-3">
					<div className="d-flex flex-column info-item border-none">
						<p>{CRM.historyPackage}</p>
						<img src={checkIcon} alt="" width={25} height={25} />
					</div>
				</div>
			</div>
		</div>
	);
}
export function List({ data }) {
	const history = useHistory();
	const { inventory ,CRM } = Locale;
	const [usersTooltip, setUsersTooltip] = useState(false);
	const toggleUsers = (id) => {
		setUsersTooltip((prevState) => ({
			...prevState,
			[id]: !prevState[id]
		}))
	}

	const statusLocaleAndClasses = (status) => {
		switch (status) {
			case "pending":
				return {
					locale: inventory.messages.pending,
					className: "crm-status-bg-pending",
				};
			case "approved":
				return {
					locale: inventory.messages.approved,
					className: "crm-status-bg-accepted",
				};
			case "paid":
				return {
					locale: inventory.messages.paid,
					className: "crm-status-bg-accepted",
				};
			case "confirmed":
				return {
					locale: inventory.messages.confirmed,
					className: "crm-status-bg-accepted",
				};
			case "rejected":
				return {
					locale: inventory.messages.rejected,
					className: "crm-status-bg-rejected",
				};
			case "expired":
				return {
					locale: inventory.messages.expired,
					className: "crm-status-bg-rejected",
				};
			case "canceled":
				return {
					locale: inventory.messages.canceled,
					className: "crm-status-bg-rejected",
				};
			default:
				return { locale: "", className: "" };
		}
	};
	const customersData =
		data && data.length > 0
			? data.map((customer) => (
					<tr className="table-row">
						<td className="table-column">{customer?.reference_num}</td>

						<td className="table-column">
							<div className="d-flex flex-column">
								<span className="table-text">
									{customer?.departure_date
										? moment(customer?.departure_date).format("YYYY-MM-DD")
										: "_"}
								</span>
								<span className="table-text">{`${
									customer?.nights ? `${customer?.nights} ${CRM.nights}` : "_"
								}`}</span>
							</div>
						</td>

						<td className="table-column">
							<div className="d-flex flex-column gap-2">
								<span className="table-text">
									{customer?.package_name ? customer?.package_name : "_"}
								</span>
								{customer?.travelers?.filter(traveler => traveler?.first_name && traveler?.last_name).length > 0 ? (
											<div className='d-flex align-items-center'>
												<span className='table-text'>
													{customer?.pax} Pax
													</span>

												<span>
													<div
														id={`Tooltip-users-${customer?.id}`}
														className='d-flex gap-1 align-items-center mx-3'
													>
														<EyeIcon />
														<span className='table-text text-orange'>
															{CRM.View}
														</span>
													</div>

													<Tooltip
														placement='bottom'
														isOpen={usersTooltip && usersTooltip[customer?.id]}
														target={`Tooltip-users-${customer?.id}`}
														toggle={() => toggleUsers(customer?.id)}
													>
														{customer?.travelers?.filter(traveler => traveler?.first_name && traveler?.last_name)?.map((person) => (
															<div
																className='d-flex justify-content-start align-items-center'
																key={person?.id}
																style={{ gap: '8px', margin: '8px 0' }}
															>
																<ProfileIcon />
																<span>
																	{person?.first_name} {` `}
																	{person?.last_name}
																</span>
															</div>
														))}
													</Tooltip>
												</span>
											</div>
										) : (
											<div className='d-flex align-items-center'>
												<span className='table-text'>
													{customer?.pax} {CRM.Pax}
													</span>

												<span>
													<div
														id={`Tooltip-users-${customer?.id}`}
														className='d-flex gap-1 align-items-center mx-3'
													>
														<ViewIcon color="#707170"/>
														<span className='table-text' style={{color:"#707170"}}>
															{CRM.View}
														</span>
													</div>
												</span>
											</div>
										)}
							</div>
						</td>

						<td className="table-column">
							<span className="table-text text-green">{`${
								customer?.total_price
									? `${customer?.total_price} ${customer?.currency}`
									: "_"
							}`}</span>
						</td>

						<td className="table-column">
							<div className="d-flex flex-column gap-2">
								<span className="table-text text-success">{`${
									customer?.payments_total_paid
										? `${customer?.payments_total_paid} ${customer?.currency}`
										: "0"
								}`}</span>
								<span className="table-text text-orange">{`${
									customer?.payments_outstanding
										? `${customer?.payments_outstanding} ${customer?.currency}`
										: "0"
								}`}</span>
							</div>
						</td>

						<td className="table-column">
							<div className="d-flex flex-column gap-2">
								<span className="table-text">
									{customer?.created_at
										? moment(customer?.created_at).format("YYYY-MM-DD")
										: "_"}
								</span>
								<span className="table-text">
									{customer?.created_at
										? moment(customer?.created_at).format("hh:mm")
										: "_"}
								</span>
							</div>
						</td>

						<td className="table-column">
							<p className="text offline text-capitalize">
								{customer?.reservation_from === "offline"
								? CRM.offline
								: customer?.reservation_from === "online"
								? CRM.online
								: customer?.reservation_from
								? customer?.reservation_from
								: "-"
								}
							</p>
						</td>

						<td className="table-column">
							<p
								className={`table-text new text-capitalize  ${
									statusLocaleAndClasses(customer?.reservation_status).className
								}`}
							>
								{customer?.reservation_status
									? statusLocaleAndClasses(customer?.reservation_status).locale
									: "-"}
							</p>
						</td>

						<td className="table-column">
							<div className="d-flex flex-column gap-2">
								<span className="table-text text-orange">{CRM.called}</span>
								<span className="table-text">
									{CRM.by}{" "}
									{customer?.call_log
										? customer?.call_log?.by_user
										: "_"}
								</span>
							</div>
						</td>

						<td className="table-column">
							<button
								className="btn"
								onClick={() => history.push(`/crm/main/${customer?.id}`)}
							>
								<RightICon />
							</button>
						</td>
					</tr>
			  ))
			: <tr>
			<td colSpan="10">
				<div className="product-build__product-no-data fullHeight no-result">
					<img src={NoDataIcon} alt=" No Results" />
					<h4>{CRM.noResults}</h4>
					<p>{CRM.fillSearch}</p>
				</div>
			</td>
		</tr>;
	return (
		<div className="crm-table-wrapper">
			<table className="table table-responsive1 crm-table">
				<thead className="crm-table-head">
					<tr>
						<th>{CRM.ReferenceNo}</th>
						<th>
							<div className="d-flex flex-column">
								<span className="text"> {CRM.DepartureNight}</span>
							</div>
						</th>
						<th>
							<div className="d-flex flex-column">
								<span className="text">{CRM.PackagePax}</span>
							</div>
						</th>
						<th>{CRM.Total}</th>
						<th>
							<div className="d-flex flex-column">
								<span className="text">{CRM.Paid} /</span>
								<span className="text">{CRM.OutStanding}</span>
							</div>
						</th>
						<th>{CRM.RequestDate}</th>
						<th>{CRM.Source}</th>
						<th>{CRM.DealStatus}</th>
						<th>{CRM.StatusBy}</th>
						<th width="2%"></th>
					</tr>
				</thead>

				<tbody className="crm-table-body">{customersData}</tbody>
			</table>
		</div>
	);
}
