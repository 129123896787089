import NumberField from 'components/Form/NumberField/NumberField';
import SelectField from 'components/Form/SelectField/SelectField'
import { useSBSState } from 'context/global';
import { pricingForLookup } from 'modules/TripPlanner/helpers/tripPlannerLookups';
import React from 'react'
import { useLocation } from 'react-router-dom';
import Locale from 'translations';

export default function PricingHeader({ pricingData, setPricingData, selectedPricingType, setSelectedPricingType, allCurrencies, saveRoomsAvailability, productType }) {
	// hooks

	const { allLookupsBuilder } = useSBSState();
	const location = useLocation();
	const isProductBuilder = location.pathname.includes("products-builder");
	const { productElements, productsBuilder } = Locale;
	const productHasHotel = pricingData?.product_items?.find(item => item?.item?.id === 6) ? true : false;
	// const isProductServiceOnly = productType?.id === 6;
	// const isProductCustomPackage = productType?.id === 5
	// const pricingHasCombination = pricingData?.product_price_combinations?.find(c => c?.combinations?.length > 0);
	const adultPax = pricingData?.pax;

	function handleChangeInputs({ key, value }) {
		let pricing = [...pricingData?.pricing];
		setPricingData({
			...pricingData,
			[key]: value,
			pricing
		})
	}


	function handlePricingFor(value, prevValue, valueObj) {
		let pricingClone = [...pricingData?.pricing];
		if (prevValue === "b2b" && (value === "B2B_B2C" || value === "b2c")) {
			let targetHotels = pricingClone?.find(price => price?.for === "b2b")?.hotels;
			targetHotels = targetHotels.map(hotel => {
				return {
					...hotel,
					rooms_types: hotel?.rooms_types?.map(roomType => {
						return {
							...roomType,
							rooms: roomType?.rooms?.map(room => {
								return {
									...room,
									price_adult: 0,
									price_child: 0,
									max_child: ""
								}
							})
						}
					})
				}
			})
			pricingClone[1].hotels = targetHotels
		}
		else if (prevValue === "b2c" && (value === "B2B_B2C" || value === "b2b")) {
			let targetHotels = pricingClone?.find(price => price?.for === "b2c")?.hotels;
			targetHotels = targetHotels.map(hotel => {
				return {
					...hotel,
					rooms_types: hotel?.rooms_types?.map(roomType => {
						return {
							...roomType,
							rooms: roomType?.rooms?.map(room => {
								return {
									...room,
									price_adult: 0,
									price_child: 0,
									max_child: ""
								}
							})
						}
					})
				}
			})
			pricingClone[0].hotels = targetHotels
		} else {
			pricingClone = [...pricingData?.pricing];
		}
		setPricingData({
			...pricingData,
			pricing: pricingClone,
			pricing_for: valueObj,
			selected_pricing_for:
				(!pricingData?.selected_pricing_for || valueObj?.id?.toLowerCase() !== pricingData?.selected_pricing_for)
					? (valueObj?.id?.toLowerCase() === "b2b" || valueObj?.id === "B2B_B2C") ? "b2b" : "b2c"
					: pricingData?.selected_pricing_for,
		})
	}


	const updateRoomsCount = (index, value) => {
		let arr = pricingData.room_types;
		arr[index]["count"] = Number(value).toString();
		setPricingData({
			...pricingData,
			room_types: arr,
		});
	};

	// const showFullPackagePrice = (isProductCustomPackage && productHasHotel) || (!isProductCustomPackage && !isProductServiceOnly);

	return (
		<div className="pricing-header-container">
			{adultPax > 0 &&
				<div className="select-type">
					{pricingData?.product_items?.filter(item => item?.item?.id === 6)?.length <= 1?
						<button className={`btn shadow-none p-0 ${selectedPricingType === "fullpackage" ? "active" : ""}`}
							onClick={() => {
								setSelectedPricingType("fullpackage")
							}}
						>
							{productsBuilder.fullPakcagePerPax}
						</button>
						:
						null
					}

					<button className={`btn shadow-none p-0 ${selectedPricingType === "breakdown" ? "active" : ""}`}
						onClick={() => {
							setSelectedPricingType("breakdown")
						}}
					>
						{productsBuilder.packageBreakdown}
					</button>

				</div>

			}
			<div className="pricing-header row mx-0">
				{/* currency */}
				<div className="col-md-4 col-12">
					<SelectField
						label={`${productsBuilder.currency}: `}
						id="select_currency"
						name="select_currency"
						options={allCurrencies}
						value={pricingData?.currency?.name}
						onChange={(e) => handleChangeInputs({ key: "currency", value: e })}
					/>
				</div>
				{adultPax > 0 &&
					<div className="col-md-4 col-12">
						<SelectField
							label={`${productsBuilder.pricingFor}: `}
							id="pricing_for"
							name="pricing_for"
							options={pricingForLookup}
							value={pricingData?.pricing_for?.name}
							onChange={(e) => {
								// handleChangeInputs({ key: "pricing_for", value: e });
								handlePricingFor(e.id, pricingData?.pricing_for?.id, e)
							}}
						/>
					</div>
				}
				{/* adults count */}
				<div className="col-md-4 col-12">
					<NumberField
						label={`Max PAX: `}
						removeArrow={true}
						id="adult_count"
						name="adult_count"
						value={pricingData?.adults_count || pricingData?.pax}
						onChange={(e) => handleChangeInputs({ key: "adults_count", value: Number(e.target.value).toString() })}
						disabled={isProductBuilder}
					/>
				</div>
				{/* children count */}
				{/* <div className="d-flex align-items-center gap-10">
						<label htmlFor="children_count">{packagePlanner.childPax}:</label>
						<div>
							<NumberField
								hasLabel={false}
								removeArrow={true}
								id="children_count"
								name="children_count"
								value={pricingData?.children_count}
								onChange={(e) => handleChangeInputs({ key: "children_count", value: e.target.value })}
								disabled={isProductBuilder}
							/>
						</div>
					</div> */}

				{!isProductBuilder ?
					<>
						{productHasHotel &&
							<div className="row mx-0 w-100">
								<div className="col-md-4">
									<SelectField
										multi
										class="fixH"
										label={productElements.roomsTypes}
										options={allLookupsBuilder?.room_types}
										placeholder={
											pricingData.room_types?.length > 0
												? pricingData.room_types
												: productElements.roomsTypes
										}
										defaultValue={() => {
											return pricingData.room_types?.map(
												(room) =>
												(room = {
													value: room.id || room.value,
													label: room.name || room.label,
												})
											);
										}}
										getOptionLabel={(x) => x.name}
										getOptionValue={(x) => x.id}
										onChange={(values) => {
											if (values && values.length > 0) {
												let x = values.map(res => { return { ...res, count: res.count ? res.count : 1 } })
												setPricingData({ ...pricingData, room_types: x });
											}

										}}
									/>
								</div>
								<div className="col-md-8">
									<div className="room-count mt-4">
										<div className="room-box mt-2">
											{pricingData?.room_types?.map((room, index) => {
												return (
													<div className="d-inline-flex mt-1 mr-2">
														<div className="room-item b-radius-left">
															{room?.name}
														</div>
														<div className="room-count b-radius-right">
															<input
																type="number"
																min="1"
																max="999"
																className="text-input"
																value={room?.count || 1}
																placeholder={productElements.roomCountPlaceholder}
																onChange={(e) => {
																	updateRoomsCount(index, e.target.value);
																}}
															// onInput={maxLengthCheck}
															// onBlur={validateRoomsType}
															/>
														</div>
													</div>

												)
											})}
										</div>
									</div>
								</div>
							</div>
						}

						<div className="col-12">
							<button className="btn btn bg-nxt py-2"
								onClick={() => saveRoomsAvailability()}
							>
								{productsBuilder.Save}
							</button>

						</div>
					</>
					:
					null
				}
			</div>
		</div>
	)
}
