import TextField from "components/Form/TextField/TextField";
import React from "react";
import SearchIcon from "assets/images/crm/search-normal.png";
import Locale from "translations";
import SelectField from 'components/Form/SelectField/SelectField';
import { useSBSState } from 'context/global';
import clearIcon from "assets/images/wallet/clearIcon.svg";


export default function Filter({ filter, setFilter, resetFilters, fetchTravelersWithFilters }) {

  const { CRM } = Locale;
  const { allCountries } = useSBSState()

  const SOURCE_LOOKUP = [
    // { id: 'online', value: 'online', name: 'Online' },
    { id: 'offline', value: 'offline', name: 'Offline' },
    { id: 'b2b', value: 'b2b', name: 'B2b' },
    { id: 'website', value: 'website', name: 'Website' }
  ]




  return (
    <div className="filter mx-0 my-3">
      <div className="row w-100">
        <div className='col-12 d-flex align-items-center justify-between'>

          {/* filter by search text */}
          <div className='col-2'>
            <TextField
              type="text"
              hasLabel={false}
              placeholder={CRM.Search}
              isImage={true}
              image={SearchIcon}
              prependImage={true}
              value={filter.search_key}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  search_key: e.target.value,
                });
              }}
            />
          </div>

          {/* filter by nationality */}
          <div className="col-md-2">
            <SelectField
              placeholder={CRM.nationality}
              hasLabel={false}
              options={allCountries}
              value={filter.nationality_id?.name || ""}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  nationality_id: e,
                });
              }}
            />

          </div>

          {/* filter by source */}
          <div className="col-md-2">
            <SelectField
              placeholder={CRM.source}
              hasLabel={false}
              options={SOURCE_LOOKUP}
              value={filter?.source?.name || ""}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  source: e
                });
              }}
            />

          </div>

          {/* action buttons */}
          <button className="btn applay col-2"
            onClick={() => fetchTravelersWithFilters(filter)}
          >
            {CRM.applyFilter}
          </button>
          <button
            className="btn-reset-filter px-3 mx-2 col-1"
            onClick={() => resetFilters()}
          >
            <img src={clearIcon} alt=" " />
            clear
          </button>

        </div>


      </div>

    </div>
  );
}
