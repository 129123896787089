import React, { useEffect, useState } from "react";
import Filter from "./filter";
import { ReactComponent as ViewIcon } from "assets/images/crm/eye.svg";
import NoDataIcon from "assets/images/crm/no-data.png";
import AccountingPaymentModel from "../Models/accountingPayment";
import { accountingList } from "services/CRM";
import { Tooltip } from "reactstrap";
import { ReactComponent as CallIcon } from "assets/images/crm/call.svg";
import { ReactComponent as SMSIcon } from "assets/images/crm/sms.svg";
import { ReactComponent as ProfileIcon } from "assets/images/crm/profile.svg";
import Pagination from 'components/Pagination';
import Locale from 'translations';

export default function Accounting() {
	const {CRM } = Locale;

	const [data, setData] = useState([]);
	const [filter, setFilter] = useState({
		search: "",
		owner_name: "",
		package_name: "",
		page: 1,
	});
	const [meta, setMeta] = useState();
	const [reload, setReload] = useState(false);
	useEffect(() => {
		async function fetchTransaction() {
			const res = await accountingList({
				...filter,
				// owner_name:filter.owner_name.name,
				// package_name:filter.package_name.name,

			});
			setData(res?.data?.data);
			setMeta(res?.data?.meta);
		}
		fetchTransaction();
	}, [ reload]);

	const search=()=>{
setReload(prev=>!prev)
	}
	const [isOpen, setIsOpen] = useState(false);
	const [IsId, setIsId] = useState(null);
	const [selectedItem, setSelectedItem] = useState({});

	const toggle = (id = null,item={}) => {
		setIsOpen((prev) => !prev);
		setIsId(id);
		setSelectedItem(item)
	};
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [tooltipPax, setTooltipPax] = useState(false);

	const toggleTooltip = (id) => {
		setTooltipOpen((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};
	const toggleTooltipPax = (id) => {
		setTooltipPax((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};
	const AllAccounting =
		data?.length > 0 ? (
			data?.map((item, index) => {
				return (
					<tr key={index}>
						<td>
							<div className="d-flex flex-column ownerName-column">
								<p>{item.owner?.full_name}</p>
								<span
									id={`Tooltip-user-info-${item?.reference_num}-${item?.id}`}
								>
									<ViewIcon /> {CRM.ViewDetails}
								</span>
								<Tooltip
									placement="bottom"
									isOpen={tooltipOpen && tooltipOpen[item?.id]}
									target={`Tooltip-user-info-${item?.reference_num}-${item?.id}`}
									toggle={() => toggleTooltip(item?.id)}
									autohide={true}
									style={{ background: "rgba(0, 0, 0, 0.6)" }}
								>
									<div
										className="d-flex flex-column justify-content-start"
										style={{
											gridGap: "3px",
											gap: "16px",
											padding: "10px 16px 10px 16px",
										}}
									>
										<p
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "start",
												gap: "8px",
											}}
										>
											<CallIcon />
											<span>{item?.owner?.phone}</span>
										</p>
										<p
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "start",
												gap: "3px",
												fontSize: "16px",
												fontWeight: 500,
											}}
										>
											<SMSIcon />
											<span>{item?.owner?.email}</span>
										</p>
									</div>
								</Tooltip>
							</div>
						</td>
						<td>
							<div className="d-flex flex-column ownerName-package">
								<p>{item?.trip_type}</p>
								<span className="d-flex" style={{ gap: "16px" }}>
									<span>{item?.pax} {CRM.pax}</span>
									<span
										className="view"
										id={`Tooltip-pax-info-${item?.reference_num}`}
									>
										<ViewIcon />
										{CRM.View}
									</span>
									<Tooltip
										placement="bottom"
										isOpen={tooltipPax && tooltipPax[item?.id]}
										target={`Tooltip-pax-info-${item?.reference_num}`}
										toggle={() => toggleTooltipPax(item?.id)}
										autohide={true}
										style={{ background: "rgba(0, 0, 0, 0.6)" }}
									>
										<div
											className="d-flex flex-column justify-content-start"
											style={{
												gridGap: "3px",
												gap: "16px",
												padding: "10px 16px 10px 16px",
											}}
										>
											{item?.travelers &&
												item?.travelers?.length > 0 &&
												item?.travelers.map((traveler) => (
													<p
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "start",
															gap: "8px",
															fontSize: "16px",
															fontWeight: 500,
														}}
													>
														<ProfileIcon />
														<span>
															{traveler?.first_name} {traveler?.last_name}
														</span>
													</p>
												))}
										</div>
									</Tooltip>
								</span>
							</div>
						</td>
						<td>
							{item?.total_price} {item?.currency}
						</td>
						<td className="text-green">
							{item?.payments_total_paid} {item?.currency}
						</td>
						<td className="text-yellow">
							{item?.payments_outstanding} {item?.currency}
						</td>
						<td>
							<button className="btn-manage" onClick={() => toggle(item?.id,item)}>
								{CRM.manage}
							</button>
						</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="6">
					<div className="product-build__product-no-data fullHeight no-result">
						<img src={NoDataIcon} alt=" No Results" />
						<h4>{CRM.noResults}</h4>
						<p>{CRM.fillSearch}You need to fill the search fields first to get results</p>
					</div>
				</td>
			</tr>
		);
		const goTo = (page) => {
			setFilter({ ...filter, page: page });
			setReload(prev=>!prev)
		};
	return (
		<div className="accounting">
			<Filter filter={filter} setFilter={setFilter} search={search}/>
			<div className="table-accounting">
				<table className="p-2 bg-white table ">
					<thead>
						<tr>
							<th>{CRM.OwnerName}</th>
							<th>{CRM.PackagePax}</th>
							<th>{CRM.Total}</th>
							<th>{CRM.Paid}</th>
							<th>{CRM.OutStanding}</th>
							<th width="8%">{CRM.Action}</th>
						</tr>
					</thead>
					<tbody> {AllAccounting}</tbody>
				</table>

			</div>
			{data?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}

			{isOpen && (
				<AccountingPaymentModel isOpen={isOpen} toggle={toggle} id={IsId} selectedItem={selectedItem} />
			)}
		</div>
	);
}
