import SelectField from '../SelectField/SelectField';
import NumberField from '../NumberField/NumberField';
import Locale from 'translations';


const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function PhoneField(props) {

	const { userDetails, operationStatement } = Locale;

	return (
		<div className="row mx-0 w-100">
			<label class="control-field__label col-12">{props.label}</label>
			{/* phone code */}
			<div className="col-4 company_phone_code">
				<SelectField
					label={operationStatement.code}
					hasLabel={false}
					id="phone_code"
					name="phone_code"
					value={
						props?.phoneCode?.flag ?
							<img
								src={`${fetchFlag}/${props?.phoneCode?.flag}`}
								width="35px"
								alt={props?.phoneCode?.label}
							/>
							:
							props?.phoneCode?.label || props?.phoneCode
					}
					onChange={props?.onSelectPhoneCode}
					options={props.countriesLookup}
					color={props.phoneCodeColor}
					errors={props.phoneCodeError}
				/>
			</div>
			{/* phone */}
			<div className="col-8 px-0">
				<NumberField
					type={"phone"}
					label={userDetails.phoneNumber}
					hasLabel={false}
					id="phone"
					name="phone"
					value={props?.phone}
					removeArrow={true}
					placeholder={"000 0000 000"}
					min={3}
					onChange={props.onChangePhone}
					extraTextPosition="prepend"
					extraText={
						props?.phoneCode?.phone_code ||
						props?.phoneCode?.label ||
						props.phoneCode?.value
					}
					color={props.phoneColor}
					errors={props.phoneError}
				/>
			</div>
		</div>

	);
}