import Pagination from 'components/Pagination';
import Filter from './Filter';
import Header from './Header';
import Table from './Table';
import { useEffect, useState } from 'react';
import { fetchTripOperations } from 'services/tripOperations';
import moment from 'moment';

const initialFilters = {
  search: '',
  status: '',
  arrival_dates: [null, null],
  page: 1
}

export default function TripOperations() {
  const [meta, setMeta] = useState({})
  const [tripOperations, setTripOperations] = useState([]);
  const [filters, setFilters] = useState(() => initialFilters)

  const listTripOperations = async (filters) => {
    const formattedFilters = () => {
      if (filters.arrival_dates) {
        return {
          ...filters,
          arrival_dates: filters.arrival_dates.map(date => date ? moment(date).format('YYYY-MM-DD') : date)
        };
      }
      return filters;
    };

    const res = await fetchTripOperations(formattedFilters())
    setTripOperations(res.data)
    setMeta(res.meta)
  }

  const goTo = (page) => {
    listTripOperations({ ...filters, page })
  }

  const handleSearch = () => {
    listTripOperations({ ...filters, page: 1 })
  }

  const handleReset = () => {
    setFilters(initialFilters)
    listTripOperations(initialFilters)
  }

  useEffect(() => {
    listTripOperations({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className='trip_operations'>
      <Header />
      <Filter filters={filters} setFilters={setFilters} handleSearch={handleSearch} handleReset={handleReset} />
      <div>
        <Table tripOperations={tripOperations} listTripOperations={listTripOperations} />
        <div className='mt-3'>
          <Pagination info={meta} goTo={goTo} />
        </div>
      </div>
    </div>
  );
}


