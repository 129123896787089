import { useEffect, useState } from 'react'
// React-Router-Dom
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Shared Components
import DefualtPricesTaxes from '../shared/DefualtPricesTaxes';
// Servicies
import { fetchCities } from 'services/lookups';
import { addTransferService, editTransferService, getServicesById } from 'services/serviveBuilder';
// Helper
import validate, { isFormValid } from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
// Custom Hook
import useSharedData from '../constant/useSharedData';
// --------------------------------------------------------------------------------------


const ServiceBuilderTransferForm = () => {

  // *** Hooks 
  const { Service_Builder, inventory, commons } = Locale
  const { status, id } = useParams()
  const history = useHistory()
  const { allCountries } = useSBSState()
  const {
    cyclesOptions,
    driverOptions,
    refundOptions,
    requiredTourismInputs,
    requiredUmrahInputs,
    vehiclesModel,
    vehicleTypesOptions,
    isPayloadValid
  } = useSharedData()

  // *** State 
  const [showPricing, setShowPricing] = useState(false)
  const [citiesList, setCitiesList] = useState([])
  const [errors, setErrors] = useState({});
  const [transferData, setTransferData] = useState({
    serviceId: '',
    trip_type: 'umrah',
    name_en: '',
    name_ar: '',
    // country_id: '',
    // city_id: '',
    country: '',
    city: '',
    // cycle_type: "",
    cycle: "",
    // vehicle_type: "",
    vehicleType: "",
    // vehicle_model: "",
    model: "",
    // is_refundable: 1,
    refundable: '',
    car_capacity: '',
    policy_en: '',
    policy_ar: ' ',
    cancellation_en: '',
    cancellation_ar: '',
    description_en: '',
    description_ar: ' ',
    withDriver: '',
    images: [],
    price: '',
    currency: "SAR",
    taxes: [{
      name: '',
      type: '',
      value: '',
    }]
  });
  const [taxesIsSelected, setTaxesIsSelected] = useState("");

  const requiredInputs = transferData?.trip_type === 'umrah' ? requiredUmrahInputs : requiredTourismInputs;

  // *** Fuction Logic 
  function handleTransferInputsChanges({ key, value }) {
    let transferDataClone = { ...transferData }
    if (key === 'country') {
      fetchCity(value?.id)
    }
    transferDataClone[`${key}`] = value;
    setTransferData({ ...transferDataClone })
  }

  function checkFormErrors() {
    let submitError = {};
    requiredInputs?.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: transferData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  const fetchCity = async (countryId) => {
    const citiesRes = await fetchCities(countryId);
    setCitiesList(citiesRes);
  };

  const fetchTransferData = async () => {
    const response = await getServicesById(id)
    if (response?.status === 200) {
      setTransferData({
        serviceId: response?.data?.data?.service.id,
        name_en: response?.data?.data?.service?.name?.en,
        name_ar: response?.data?.data?.service?.name?.ar,
        country: response?.data?.data?.service?.country,
        city: response?.data?.data?.service?.city,
        trip_type: response?.data?.data?.service?.trip_type,
        cycle: cyclesOptions?.find(item => item?.id === response?.data?.data?.service?.cycle_type),
        vehicleType: vehicleTypesOptions?.find(item => Number(item?.id) === response?.data?.data?.service?.vehicle_type),
        model: {
          name: response?.data?.data?.service?.vehicle_model,
          id: response?.data?.data?.service?.vehicle_model
        },
        car_capacity: response?.data?.data?.service?.car_capacity,
        withDriver: driverOptions?.find(item => item.id === response?.data?.data?.service?.with_driver),
        refundable: refundOptions?.find(e => e.id === response?.data?.data?.service?.refundable),
        policy_en: response?.data?.data?.service?.policy,
        policy_ar: '',
        description_en: response?.data?.data?.service?.description,
        description_ar: '',
        images: response?.data?.data?.service?.images,
        price: response?.data?.data?.service?.price,
        currency: response?.data?.data?.service?.currency,
        taxes: response?.data?.data?.service?.taxes
      })
    }
    setShowPricing(response?.data?.data?.service?.price ? true : false)
    return response
  }

  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) {
      return
    }
    let requestBody = {
      name: {
        en: transferData?.name_en,
        ar: transferData?.name_ar,
      },
      trip_type: transferData?.trip_type,
      // for trip type umrah send saudi arabia id (966)
      country_id: transferData?.trip_type === "umrah" ? 966 : (transferData?.country?.id || transferData?.country?.country_id),
      city_id: transferData?.city?.id,
      cycle_type: transferData?.cycle?.id,
      vehicle_type: `${(transferData?.vehicleType?.id)}`,
      vehicle_model: transferData?.model?.id,
      refundable: transferData?.refundable?.id,
      car_capacity: transferData?.car_capacity,
      with_driver: transferData?.withDriver?.id,
      description: {
        en: transferData?.description_en,
        ar: transferData?.description_ar,
      },
      policy: {
        en: transferData?.policy_en,
        ar: transferData?.policy_ar,
      },
      images: transferData?.images,
      price: transferData?.price || null,
      currency: transferData?.currency,
      // taxes: isPayloadValid(transferData?.taxes?.[0]) ? transferData?.taxes : []
      taxes: taxesIsSelected ? transferData?.taxes : []
    }
    if (status === 'add') {
      const response = await addTransferService(requestBody);
      if (response?.status === 200 || response?.status === 201) {
        console.log(requestBody)
        history.push('/website/service-builder')
      }
    } else if (status === 'edit') {
      const response = await editTransferService(transferData?.serviceId, requestBody);
      if (response?.status === 200 || response?.status === 201) {
        history.push('/website/service-builder')
      }
    }
  }

  useEffect(() => {
    if (status === 'edit' && id) {
      fetchTransferData()
    }
  }, [])

  // console.log(currentYear)

  // -------- JSX Code ---------
  return (
    <div >
      <h1 className='form-title'>
        {Service_Builder?.select_transfer_details}
      </h1>

      <form onSubmit={submit} className="mt-4 ">

        {/* Transfer Service Form  */}
        <div className='row form-holder mx-2 mb-5'>

          {/* Trip Type Umrah */}
          <div className="col-2 mt-2">
            <label
              htmlFor={`umrah`}
              className={`${transferData.trip_type === 'umrah' ? 'active-tour-tab' : 'tour-tab'} pointer-event`}
            >
              <input
                type="radio"
                id={`umrah`}
                name={`trip_type`}
                value="umrah"
                checked={transferData?.trip_type === 'umrah'}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'trip_type', value: e?.target?.value })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "trip_type", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.trip_type?.required ? "danger" : ""}
                errors={errors?.trip_type}
              />
              <p className='taxes-type-value'> {Service_Builder?.umrah}</p>
            </label>
          </div>

          {/* Trip Type Tour */}
          <div className="col-2 custom-field mt-2">
            <label
              htmlFor={`tourism`}
              className={`${transferData.trip_type === 'tourism' ? 'active-tour-tab' : 'tour-tab'}`}
            >
              <input
                type="radio"
                id={`tourism`}
                name={`trip_type`}
                value="tourism"
                checked={transferData?.trip_type === 'tourism'}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'trip_type', value: e?.target?.value })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "trip_type", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.trip_type?.required ? "danger" : ""}
                errors={errors?.trip_type}
              />
              <p className='taxes-type-value'>  {Service_Builder?.tourism}</p>
            </label>
          </div>

          <div className="col-8 mt-2" />

          {/* Service Name en */}
          <div className="col-4 custom-field mt-2">
            <TextField
              type="text"
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.service_name_en}
              placeholder={Service_Builder?.service_name_en}
              name="name_en"
              id="name_en"
              value={transferData?.name_en}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'name_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_en", value: e.target.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_en?.required ? "danger" : ""}
              errors={errors?.name_en}
            />
          </div>

          {/* Service Name ar */}
          <div className="col-4 custom-field mt-2">
            <TextField
              type="text"
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.service_name_ar}
              placeholder={Service_Builder?.service_name_ar}
              name="name_ar"
              id="name_ar"
              value={transferData?.name_ar}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'name_ar', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_ar", value: e.target.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_ar?.required ? "danger" : ""}
              errors={errors?.name_ar}
            />
          </div>

          <div className="col-4 custom-field mt-3" />

          {/* Cycle */}
          {transferData?.trip_type === 'umrah' &&
            <div className="col-4 mt-3">
              <SelectField
                hasLabel={status === 'edit' ? true : false}
                label={Service_Builder?.cycle}
                placeholder={Service_Builder?.cycle}
                id="cycle"
                name="cycle"
                value={transferData?.cycle?.name}
                options={cyclesOptions}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'cycle', value: e })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "cycle", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.cycle?.required ? "danger" : ""}
                errors={errors?.cycle}
              />
            </div>
          }

          {/* Country */}
          {transferData?.trip_type === 'tourism' &&
            <div className="col-2 mt-3">
              <SelectField
                hasLabel={status === 'edit' ? true : false}
                label={Service_Builder?.country}
                placeholder={Service_Builder?.country}
                id="country"
                name="country"
                value={transferData?.country?.name}
                options={allCountries}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'country', value: e })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "country", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.country?.required ? "danger" : ""}
                errors={errors?.country}
              />
            </div>
          }

          {/* City */}
          {transferData?.trip_type === 'tourism' &&
            <div className="col-2 mt-3">
              <SelectField
                disabled={!transferData?.country?.name}
                hasLabel={status === 'edit' ? true : false}
                label={Service_Builder?.city}
                placeholder={Service_Builder?.city}
                id="city"
                name="city"
                value={transferData?.city?.name}
                options={citiesList}
                onChange={(e) => {
                  handleTransferInputsChanges({ key: 'city', value: e })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "city", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.city?.required ? "danger" : ""}
                errors={errors?.city}
              />
            </div>
          }

          {/* Type */}
          <div className="col-4 mt-3">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.type}
              placeholder={Service_Builder?.type}
              id="vehicleType"
              name="vehicleType"
              value={transferData?.vehicleType?.name}
              options={vehicleTypesOptions}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'vehicleType', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "vehicleType", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.vehicleType?.required ? "danger" : ""}
              errors={errors?.vehicleType}
            />
          </div>

          {/* Model */}
          <div className="col-4 mt-3">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.model}
              placeholder={Service_Builder?.model}
              id="model"
              name="model"
              value={transferData?.model?.name}
              options={vehiclesModel}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'model', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "model", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.model?.required ? "danger" : ""}
              errors={errors?.model}
            />
          </div>

          {/*Transfer Car Capacity  */}
          <div className="col-4 custom-field mt-3">
            <TextField
              type="number"
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.car_capacity}
              placeholder={Service_Builder?.car_capacity}
              name="car_capacity"
              id="car_capacity"
              value={transferData?.car_capacity}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'car_capacity', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "car_capacity", value: e.target.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.car_capacity?.required ? "danger" : ""}
              errors={errors?.car_capacity}
            />
          </div>

          {/* Transfer With Driver  */}
          <div className="col-4 mt-3">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.with_driver}
              placeholder={Service_Builder?.with_driver}
              id="withDriver"
              name="withDriver"
              value={transferData?.withDriver?.name}
              options={driverOptions}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'withDriver', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "withDriver", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.withDriver?.required ? "danger" : ""}
              errors={errors?.withDriver}
            />
          </div>

          {/* Transfer Refund*/}
          <div className="col-4 mt-3 align-self-baseline">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.refundable}
              placeholder={Service_Builder?.refundable}
              id="country"
              name="country"
              value={transferData?.refundable?.name}
              options={refundOptions}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'refundable', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "refundable", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.refundable?.required ? "danger" : ""}
              errors={errors?.refundable}
            />
          </div>

          {/* Transfer Description*/}
          <div className="col-6 custom-field mt-3">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              height={'126px'}
              label={Service_Builder?.description}
              placeholder={Service_Builder?.description}
              value={transferData?.description_en}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'description_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.description_en?.required ? "danger" : ""}
              errors={errors?.description_en}
            />
          </div>

          {/* Transfer Policy*/}
          <div className="col-6 custom-field mt-3">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              height={'126px'}
              label={Service_Builder?.policy}
              placeholder={Service_Builder?.policy}
              value={transferData?.policy_en}
              onChange={(e) => {
                handleTransferInputsChanges({ key: 'policy_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "policy_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.policy_en?.required ? "danger" : ""}
              errors={errors?.policy_en}
            />
          </div>

          {/* Transfer Iamges*/}
          <div className="col-12 custom-field mt-3">
            <div className='images-holder d-flex  align-items-center '>
              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                label={Service_Builder?.add_images}
                value={transferData.images}
                onChange={(images) => {
                  handleTransferInputsChanges({ key: "images", value: images })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "images", value: images.length },
                      { required: true }
                    ),
                  });
                }}
                errorMsg={
                  errors?.images?.required
                    ? inventory?.viewReservation?.Photos + " " + commons?.isRequired
                    : false
                }
              />
            </div>
          </div>

        </div>

        {/* Show Pricing Checkbox*/}
        <div className="d-flex align-items-center mb-3">
          <input
            type="Checkbox"
            name={"eheck1"}
            id={"exampleCheck1"}
            checked={showPricing}
            onChange={() => setShowPricing(!showPricing)}
          />

          <label
            className="form-check-label pointer font-bold text-caption mx-2 rememberme-label"
            htmlFor={"exampleCheck1"}
          >
            {Service_Builder?.select_pricing}
          </label>
        </div>

        {/* Hotel Pricing and Taxes*/}
        {showPricing &&
          <DefualtPricesTaxes
            pricingState={{
              price: transferData?.price,
              currency: transferData?.currency,
              taxes: transferData?.taxes,
            }}
            setPricingState={(data) => setTransferData((prevData) => ({ ...prevData, ...data }))}
          onSelectTaxes={(value) => setTaxesIsSelected(value)}
          />}

        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-3">

          <button type="submit" className="submit-btn ">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {status === 'edit' ? Service_Builder?.save_changes : Service_Builder?.save}
            </span>
          </button>

        </div>

      </form>
    </div>
  )
}

export default ServiceBuilderTransferForm