

export function calculateBreakdownPackage({ reservationDetails, reservationCagetory, hotelRooms, paxCount }) {
  let totalPrice = 0;
  // let vatPercentage = +reservationDetails?.product_price_combinations?.find(p => p?.pricing_for === "b2b")?.vat / 100 ;
  // let discountPercentage = +reservationDetails?.product_price?.discounts?.find(p => p?.pricing_for === "b2b")?.discount / 100;
  
  if (reservationCagetory === "shared") {
    // calc hotels rooms with discount and vat
    hotelRooms.forEach(hotel => {
      hotel?.pax?.forEach(paxItem => totalPrice += paxItem?.price)
    });

    // product non hotel items prices discount and vat (discount and vat calcuated in the backend)
    reservationDetails?.product_items.forEach(item => {
      let b2bPrices = item?.prices?.find(price => price?.pricing_for === "b2b");
      let adultsPrices = +b2bPrices?.price_adult * (+paxCount?.adultPax) || 0;
      let childrenPrices = +b2bPrices?.price_child * (+paxCount?.childsPax) || 0;

      totalPrice += (adultsPrices + childrenPrices)
    });
  }

  if (reservationCagetory === "private") {

  }

  // // Calculate the discount amount
  // const discountedTotalPrice = totalPrice - (totalPrice * discountPercentage ) ;

  // // Calculate the VAT amount
  // const vatAmount = (discountedTotalPrice * vatPercentage);

  // // Calculate the final price
  // totalPrice = discountedTotalPrice + vatAmount;

  return totalPrice;
}

export function calculateFullPackage({ reservationDetails, reservationCagetory, hotelRooms }) {
  let totalPrice = 0;

  if (!reservationDetails || !reservationCagetory || !hotelRooms || hotelRooms?.length === 0) return;
  if (reservationCagetory === "shared") {
    const requiredFields = ['roomType', 'roomCategory', 'roomMeal'];
    let matchedCombination;
    let totalAdultsPrice = 0;
    let totalChildrenPrice = 0;
    let totalAdultsCount = 0;
    let totalChildrenCount = 0;

    for (let i = 0; i < hotelRooms.length; i++) {
      const hotel = hotelRooms[i];

      const completePaxItems = hotel.pax.filter(paxItem => requiredFields.every(field => paxItem[field]));

      if (!completePaxItems.length) continue;
      // get matched combination for room style
      for (let j = 0; j < hotel.pax.length; j++) {
        totalAdultsCount = 0;
        totalChildrenCount = 0;
        const paxItem = hotel.pax[j];
        matchedCombination = reservationDetails?.product_price_combinations?.find(allCombinations => {
          return allCombinations?.combinations?.find(currentComb => {
            return (
              hotel?.hotelId === currentComb?.product_hotel_id &&
              currentComb?.room_type === paxItem?.roomType?.id &&
              currentComb?.room_category === paxItem?.roomCategory?.label &&
              currentComb?.meal_type === paxItem?.roomMeal?.label
            )
          })
        });
        totalAdultsCount += (+paxItem?.adultsCount || 0);
        totalChildrenCount += (+paxItem?.childrenCount || 0);
        if (matchedCombination) {
          totalAdultsPrice += matchedCombination?.full_price_after_discount * (totalAdultsCount);
          totalChildrenPrice += matchedCombination?.child_price_after_discount * (totalChildrenCount);
        }
      }
    }


    totalPrice = totalAdultsPrice + totalChildrenPrice;
  }

  if(reservationCagetory === "private") {

  }

  return totalPrice

}