import React, { useEffect, useState } from "react";
import OperationStatementHeader from "./StatementHeader";
import Hotels from "./OperationElements/Hotels";
import OperationDetails from "./OperationElements/OperationDetails";
import OperationTabs from "modules/UmrahOperations/shared/Tabs";
import { ReactComponent as OperatingDetailsIcon } from "assets/images/umrah-operations/details.svg";
import { ReactComponent as OperatingHotelsIcon } from "assets/images/umrah-operations/hotels.svg";
import { ReactComponent as OperatingArrivalDepartureIcon } from "assets/images/umrah-operations/arrival-departure.svg";
import { ReactComponent as OperatingAttractionIcon } from "assets/images/umrah-operations/attraction-icon.svg";
import { ReactComponent as OperatingTransportationIcon } from "assets/images/umrah-operations/car-icon.svg";
import { ReactComponent as OperatingVisaIcon } from "assets/images/umrah-operations/visa-icon.svg";
import { ReactComponent as OperatingOtherIcon } from "assets/images/umrah-operations/other-services-icon.svg";
import { ReactComponent as OperatingCateringIcon } from "assets/images/umrah-operations/catering.svg";
import { ReactComponent as RejectIcon } from "assets/images/umrah-operations/stop-slash-icon.svg";
import { ReactComponent as AcceptIcon } from "assets/images/umrah-operations/check.svg";
import Locale from "translations";
import BreadCrump from "modules/UmrahOperations/shared/BreadCrump";
import ArrivalDeparture from "./OperationElements/ArrivalDeparture";
import {

	changeStatementStatus,
	getOperationStatement,
	getSentOperationStatement,
} from "services/operationStatement";
import { useParams } from "react-router-dom";
import { formatViewOperationStatement } from "modules/UmrahOperations/helper/formatUmrahOperations";
import OperationStatementVisa from "./OperationElements/Visa";
import OperationStatementAttractions from "./OperationElements/Attractions";
import OperationStatementOtherServices from "./OperationElements/OtherServices";
import OperationStatementTransportation from "./OperationElements/Transportation";
import OperationStatementCatering from "./OperationElements/Catering";
import moment from "moment";
import { now } from "moment/moment";
import { store } from "react-notifications-component";
import { useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { FetchProductLookupd } from 'services/productbuilder';
import { PassportIcon } from 'modules/UmrahOperations/shared/Icons';
import OperationStatementPassports from './OperationElements/Passports';
import ShowSubscription from 'helpers/ShowSubscription';

export default function OperationStatementView() {
	const { operationStatement, companyDetails, inventory, interests } = Locale;
	let { id, reference } = useParams();
	// TODO replace id with reference in routes
	let statementId = reference;
	let statementReference = id;
	const { locale } = useSBSState();
	const [productsOption, setProductsOption] = useState([]);

	const [OperationStatement, setOperationStatement] = useState({
		operation_details: {
			adults: 1,
			children: 0,
			infants: 0,
			agent_id: null,
		},
		arrival_departure: {},
		hotels: [],

		visas: [],
		trip: {
			departures: [
				{
					departure_carrier_name: { name: "" },
					departure_trip_number: null,
					departure_date: null,
					departure_port_id: null,
					departure_hall_id: null,
					bus_arrival_date: null,
				},
			],
		},
		attractions: [],
		other_services: [],
		transportation: {
			cycle_type: null,
			request_type: null,
			transporter_id: null,
			credential_number: "",
			operation_id: "",
			vehicles: [],
			movements: [],
		},
		catering: [],
		travelers: []
	});

	const [activeTab, setActiveTab] = useState("operation-details");
	const [prevActiveTab, setPrevActiveTab] = useState("operation-details");

	// sentStatementMain
	async function getStatementMain() {
		let statementResponse;
		if (statementReference === "sent") {
			let data = {
				"is_sent_to_company": false,
			}
			statementResponse = await getSentOperationStatement(statementId, data);
		} else {
			statementResponse = await getOperationStatement(statementId);
		}
		if (statementResponse.status === 200 || statementResponse.status === 201) {
			let data = await formatViewOperationStatement(statementResponse.data.data, Locale);
			setOperationStatement(data);
		}
	}
	useEffect(() => {
		if (statementId) {
			getStatementMain();
		}
	}, []);

	const operationTabsItems = [
		{
			title: operationStatement.details,
			icon: <OperatingDetailsIcon />,
			url: "operation-details",
		},

		{
			title: operationStatement.ArrivalDeparture,
			icon: <OperatingArrivalDepartureIcon />,
			url: "operation-ArrivalDeparture",
			disabled: statementId ? false : true,
		},
		{
			title: operationStatement.hotel,
			icon: <OperatingHotelsIcon />,
			url: "operation-hotels",
			disabled: statementId ? false : true,
		},
		{
			title: operationStatement.catering,
			icon: <OperatingCateringIcon />,
			url: "operation-catering",
			disabled: statementId && OperationStatement.hotels.length > 0 ? false : true,
		},
		{
			title: operationStatement.attractions,
			icon: <OperatingAttractionIcon />,
			url: "operation-attraction",
			disabled: statementId ? false : true,
		},
		{
			title: operationStatement.transportation,
			icon: <OperatingTransportationIcon />,
			url: "operation-transportation",
			disabled: statementId ? false : true,
		},
		{
			title: operationStatement.visa,
			icon: <OperatingVisaIcon />,
			url: "operation-visa",
			disabled: statementId ? false : true,
		},
		{
			title: operationStatement.otherServices,
			icon: <OperatingOtherIcon />,
			url: "operation-other",
			disabled: statementId ? false : true,
		},
		{
			title: operationStatement.passport,
			icon: <PassportIcon />,
			url: "operation-passports",
			disabled: statementId ? false : true,
		},
	];



	async function changeStatus(status) {
		const res = await changeStatementStatus(statementId, { status });
		if (res?.status === 200) {
			store.addNotification({
				title: `${interests.messages.updateSuccessfully}`,
				message: `${interests.messages.EntriesUpdateSuccess}`,
				insert: "top",
				type: "success",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			getStatementMain();
		}
	}


	let currentTabIndex = operationTabsItems.findIndex(
		(tab) => tab?.url === activeTab
	);


	useEffect(() => {
		const fetchLookups = async () => {
			const res = await FetchProductLookupd();
			const formatLookup = res?.data?.data?.map((lookup) => {
				return { ...lookup, label: lookup?.name, value: lookup?.id };
			});
			setProductsOption([...formatLookup]);
		};
		fetchLookups();
	}, []);

	const operationHasSent = statementReference ? true : false;

	return (
		<ShowSubscription module="operation_statement">
			<div className="operation-statement-view px-new-26">
				<BreadCrump title={operationStatement.operatingStatement}>
					{/* <div className="">
					<TextField
						type="text"
						id="statement-operation-no"
						name="statement-operation-no"
						hasLabel={false}
						placeholder={operationStatement.quickOperationNo}
						isImage={true}
						image={SeachIcon}
						prependImage={true}
					// onChange={(e) => {
					//   setFilters({
					//     ...filters,
					//     search: e.target.value,
					//   });
					// }}
					// value={filters?.search}
					/>
				</div> */}
					{/* <ShowForPermission permission="Manage-operation-statement">
					<button className="btn export-excel-btn mx-2">
						<ExportExcelIcon />
						<span className="mx-1">{operationStatement.exportExcel}</span>
					</button>
				</ShowForPermission> */}
				</BreadCrump>
				<div className="container-fluid ">
					<OperationStatementHeader OperationStatement={OperationStatement} />

					<div className="operating-view-container my-4 px-new-26">
						<div className="w-100">
							<div className="w-100 d-flex justify-content-between align-items-center flex-wrap my-3">
								<p className="operating-title-view">
									{!statementId
										? operationStatement.AddNewOperatingStatement
										: operationStatement.updateNewOperatingStatement}
								</p>
								<div className="d-flex align-content-center">
									<button
										disabled={currentTabIndex === 0 || !statementId}
										onClick={() => {
											let tap =
												currentTabIndex > 0
													? operationTabsItems[currentTabIndex - 1].url
													: operationTabsItems[0].url;
											setActiveTab(tap);
											setPrevActiveTab(operationTabsItems[currentTabIndex].url);
										}}
										className={`operating-btn-back mx-1
								${currentTabIndex === 0 || !statementId ? "disabled" : ""}
								`}
									>
										{locale === "en" ? (
											<i class="fas fa-arrow-left mx-2"></i>
										) : (
											<i class="fas fa-arrow-right mx-2"></i>
										)}

										{companyDetails.back}
									</button>

									<button
										disabled={currentTabIndex === operationTabsItems.length - 1 || !statementId}
										onClick={() => {
											let tap =
												currentTabIndex < operationTabsItems.length - 1
													? operationTabsItems[currentTabIndex + 1].url
													: operationTabsItems[0].url;
											setActiveTab(tap);
											setPrevActiveTab(operationTabsItems[currentTabIndex].url);
										}}
										className={`operating-btn-next mx-1
								${currentTabIndex === operationTabsItems.length - 1 || !statementId ? "disabled" : ""}
								`}
									>
										{companyDetails.next}
										{locale === "en" ? (
											<i class="fas fa-arrow-right mx-2"></i>
										) : (
											<i class="fas fa-arrow-left mx-2"></i>
										)}
									</button>
								</div>
							</div>
							<OperationTabs
								tabs={operationTabsItems}
								activeTab={activeTab}
								IsNotPushUrl={true}
								setActiveTab={setActiveTab}
								setPrevActiveTab={setPrevActiveTab}
								classNames=" col-md-12 col-12  mx-auto"
							/>
						</div>

						<div className="col-md-12 mt-4">
							{/* operation details */}
							{activeTab === "operation-details" ? (
								<OperationDetails
									operationHasSent={operationHasSent}
									OperationStatement={OperationStatement}
									setOperationStatement={setOperationStatement}
								/>
							) : null}

							{/* Arrival Departure */}
							{activeTab === "operation-ArrivalDeparture" ? (
								<ArrivalDeparture
									operationHasSent={operationHasSent}
									OperationStatement={OperationStatement}
									setOperationStatement={setOperationStatement}
									productsOption={productsOption}

								/>
							) : null}

							{activeTab === "operation-hotels" ? (
								<Hotels
									getStatementMain={getStatementMain}
									OperationStatement={OperationStatement}
									setOperationStatement={setOperationStatement}
									productsOption={productsOption}
									operationHasSent={operationHasSent}

								/>
							) : null}

							{/* Catering */}
							{activeTab === "operation-catering" ? (
								<OperationStatementCatering
									OperationStatement={OperationStatement}
									setOperationStatement={setOperationStatement}
									operationHasSent={operationHasSent}
								/>
							) : null}

							{/* attractions */}
							{activeTab === "operation-attraction" ? (
								<OperationStatementAttractions
									operationHasSent={operationHasSent}
									OperationStatement={OperationStatement}
									setOperationStatement={setOperationStatement}
								/>
							) : null}
							{/* Transportation */}
							{activeTab === "operation-transportation" ? (
								<OperationStatementTransportation
									OperationStatement={OperationStatement}
									setOperationStatement={setOperationStatement}
									operationHasSent={operationHasSent}
								/>
							) : null}
							{/* visa */}
							{activeTab === "operation-visa" ? (
								<OperationStatementVisa
									OperationStatement={OperationStatement}
									setOperationStatement={setOperationStatement}
									operationHasSent={operationHasSent}
								/>
							) : null}
							{/* other services */}
							{activeTab === "operation-other" ? (
								<OperationStatementOtherServices
									operationHasSent={operationHasSent}
									OperationStatement={OperationStatement}
									setOperationStatement={setOperationStatement}
								/>
							) : null}
							{/* passport */}
							{activeTab === "operation-passports" ? (
								<OperationStatementPassports
									operationHasSent={operationHasSent}
									OperationStatement={OperationStatement}
									setOperationStatement={setOperationStatement}
								/>
							) : null}

						</div>
					</div>
					<div className="w-100 p-3"></div>

					{(OperationStatement?.status === "new" &&
						moment(now()).isBefore(
							moment(OperationStatement?.trip?.arrival_date)
						)) ||
						(OperationStatement?.status === "new" &&
							!OperationStatement?.trip?.arrival_date) ||
						(OperationStatement?.status === "draft" &&
							moment(now()).isBefore(
								moment(OperationStatement?.trip?.arrival_date)
							)) ||
						(OperationStatement?.status === "draft" &&
							!OperationStatement?.trip?.arrival_date) ? (
						<ShowForPermission permission="Manage-operation-statement">
							<div className="d-flex justify-content-end py-3">
								{!operationHasSent ?
									<>
										<button
											className=" mx-2 reject-btn"
											onClick={() => changeStatus("rejected")}
										>
											<RejectIcon />
											<span className="mx-2">{inventory.messages.reject}</span>
										</button>

										<button
											className=" accept-btn"
											onClick={() => changeStatus("approved")}
										>
											<AcceptIcon />
											<span className="mx-2">{inventory.messages.accept}</span>
										</button>
									</>
									: null}
							</div>
						</ShowForPermission>
					) : null}
				</div>
			</div>
		</ShowSubscription>
	);
}
