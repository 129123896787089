import React, { useCallback, useEffect, useState, useRef } from "react";
import Locale from "translations";
import TripDates from "./TripDates";
import BreakdownPricing from "./BreakdownPricing";
import FullPackagePricing from "./FullPackagePricing";
import PricingHeader from "./PricingHeader";
import { useSBSState } from "context/global";
import { getSingleProductPricing } from "services/productbuilder";
import { formatPricingData } from "modules/TripPlanner/helpers/formatPricing";
import { successfulResponse } from "modules/TripPlanner/helpers/formatTripPlannerData";
import { saveTripAvailability, saveTripPricing } from "services/tripPlanner";
import { SaveIcon } from "../Icons";
import { isFormValid } from 'helpers/validate';
import { fetchRoomViewLookups } from 'services/marketplace';
import useTripPricing from './useTripPricing';

export default function TripPlannerPricing({ trips, productType, deleteNonPublishTrip, handleDeleteAllTrips }) {
	// hooks
	const { allCurrencies } = useSBSState();
	const { productsBuilder, packagePlanner } = Locale;
	const pricingSelectedForRef = useRef(null);

	// states
	const [selectedPricingType, setSelectedPricingType] = useState("breakdown");
	const [pricingData, setPricingData] = useState({
		selected_trip: null,
		currency: null,
		adults_count: "",
		children_count: "",
		pricing_for: null,
		selected_pricing_for: null,
	});
	const [errors, setErrors] = useState({});
	const [roomsViewCategory, setRoomsViewCategory] = useState([])


	// custom hooks 
	const { formatFullpackagePayload, formatBreakdownPayload, checkFormErrors } = useTripPricing(pricingData, selectedPricingType, setErrors)


	// get pricing info based on selected trip date
	async function fetchPricingInfo(productUUID, tripDate, pricing_for, selected_pricing_for) {
		const res = await getSingleProductPricing(productUUID);
		if (res?.status === 200) {
			const data = res?.data?.data;
			const formatedPricing = formatPricingData(data, allCurrencies, tripDate, pricing_for, selected_pricing_for, pricingData?.currency);
			setPricingData({
				...formatedPricing,
				adults_count: data?.pax,
			});
			setSelectedPricingType(
				data?.product_price?.product_price_type?.id === 0 ||
					data?.product_items?.filter(item => item?.item?.id === 6)?.length > 1 ||
					productType?.id === 6
					? "breakdown"
					: "fullpackage"
			);
		}
	}

	// select trip by date
	function handleSelectTripDate(tripDate) {
		setPricingData({ ...pricingData });
		fetchPricingInfo(tripDate?.product_uuid, tripDate);
	}

	// save package Availability
	async function saveRoomsAvailability() {
		let data = {
			pax: pricingData?.adults_count,
			children_pax: pricingData?.children_count,
			room_types: pricingData?.room_types?.map((room) => ({
				type: room?.value,
				count: room?.count,
			})),
		};
		const res = await saveTripAvailability(
			pricingData?.selected_trip?.product_uuid,
			data
		);
		if (res?.status === 200) {
			successfulResponse("trip Availability updated");
			let current_selected_pricing_for = (pricingData?.pricing_for?.id === "B2B_B2C" ? "b2b" : pricingData?.pricing_for?.id) || null;
			let pricingFor = pricingData?.pricing_for;
			fetchPricingInfo(
				pricingData?.selected_trip?.product_uuid,
				pricingData?.selected_trip,
				pricingFor,
				current_selected_pricing_for,
			);
		}
	}


	function toggleVatDiscount({ key }) {
		let pricingClone = [...pricingData?.pricing];
		let selectedPricingIndex = pricingClone?.findIndex(
			(price) => price?.for === pricingData?.selected_pricing_for
		);
		pricingClone[selectedPricingIndex][key] =
			!pricingClone[selectedPricingIndex][key];
		setPricingData({ ...pricingData, pricing: pricingClone });
	}

	async function savePricing(applyToAll) {
		if (isFormValid(checkFormErrors())) {
			let mutualData = {
				apply_to_all: applyToAll ? 1 : 0,
				product_price_type_id: selectedPricingType === "fullpackage" ? 1 : 0,
				basic_currency_id: pricingData?.currency?.id,
			}
			let fullpackageData = {
				...formatFullpackagePayload(),
				...mutualData
			}

			let breakdownData = {
				...formatBreakdownPayload(),
				...mutualData
			}

			let data = selectedPricingType === "fullpackage" ? fullpackageData : breakdownData;
			const res = await saveTripPricing(pricingData?.selected_trip?.product_uuid, data);
			if (res?.status === 200) {
				successfulResponse(productsBuilder.PriceAddedSuccessfully);
			}
		}
	}



	const hasKeyContainingString = useCallback((targetString) => {
		return Object.keys(errors).some(key => key.includes(targetString) && errors[key].required);
	}, [errors]);

	useEffect(() => {
		async function getRoomViewCategory() {
			const roomViews = await fetchRoomViewLookups()
			const formatRoomViewLookup = roomViews?.data?.data?.map((roomView) => {
				return { ...roomView, label: roomView?.name, value: roomView?.id };
			});
			setRoomsViewCategory(formatRoomViewLookup)
		}
		getRoomViewCategory()
	}, []);

	// get first trip if no selected trip
	useEffect(() => {
		if (!pricingData.selected_trip) {
			handleSelectTripDate(trips[0])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="trip-planner-pricing">
			<div className="trip-planner-main">
				<TripDates
					tripsDatesList={trips}
					selectedTripDate={pricingData.selected_trip}
					handleSelectTripDate={handleSelectTripDate}
					deleteNonPublishTrip={deleteNonPublishTrip}
					handleDeleteAllTrips={handleDeleteAllTrips}
				/>
				{pricingData.selected_trip ? (
					<div style={{ width: "calc(100% - 320px)" }}>
						<PricingHeader
							pricingData={pricingData}
							setPricingData={setPricingData}
							selectedPricingType={selectedPricingType}
							setSelectedPricingType={setSelectedPricingType}
							allCurrencies={allCurrencies}
							saveRoomsAvailability={saveRoomsAvailability}
							productType={productType}
						/>


						{(pricingData?.pricing_for &&
							pricingData?.currency &&
							selectedPricingType &&
							pricingData?.pax > 0) ? (
							<>
								<ul className="pricing-for-list mb-4" ref={pricingSelectedForRef}>
									{pricingData?.pricing_for?.id === "B2B_B2C" || pricingData?.pricing_for?.id === "b2b" ? (
										<li className={`item 
												${pricingData?.selected_pricing_for === "b2b" ? "active" : ""}
												${hasKeyContainingString("b2b") && pricingData?.selected_pricing_for !== "b2b" ? "hasError" : ""}
											`}
											onClick={() => {
												setPricingData({
													...pricingData,
													selected_pricing_for: "b2b",
													pricing: [...pricingData?.pricing,]
												})

											}}
										>
											{packagePlanner.pricingForB2b}
										</li>
									) : null}
									{pricingData?.pricing_for?.id === "B2B_B2C" || pricingData?.pricing_for?.id === "b2c" ? (
										<li className={`item 
											${pricingData?.selected_pricing_for === "b2c" ? "active" : ""} 
											${hasKeyContainingString("b2c") && pricingData?.selected_pricing_for !== "b2c" ? "hasError" : ""}
										`}
											onClick={() => setPricingData({ ...pricingData, selected_pricing_for: "b2c" })}
										>
											{packagePlanner.pricingForB2c}
										</li>
									) : null}
								</ul>

								{selectedPricingType === "fullpackage" ? (
									<FullPackagePricing
										pricingData={pricingData}
										setPricingData={setPricingData}
										toggleVatDiscount={toggleVatDiscount}
										selectedPricingType={selectedPricingType}
										setErrors={setErrors}
										errors={errors}
										pricingForText={pricingData?.selected_pricing_for}
										roomsViewCategory={roomsViewCategory}

									/>
								) : null}
								{selectedPricingType === "breakdown" ? (
									<BreakdownPricing
										pricingData={pricingData}
										setPricingData={setPricingData}
										toggleVatDiscount={toggleVatDiscount}
										selectedPricingType={selectedPricingType}
										pricingForText={pricingData?.selected_pricing_for}
										errors={errors}
										setErrors={setErrors}
										roomsViewCategory={roomsViewCategory}
									/>
								) : null}
								<div className="d-flex justify-content-end gap-10">
									{pricingData?.pricing_for?.id === "B2B_B2C" && pricingData.selected_pricing_for === "b2b"
										?
										<button className="btn save-pricing"
											onClick={() => {
												setPricingData({ ...pricingData, selected_pricing_for: "b2c" });
												pricingSelectedForRef.current.scrollIntoView({ block: "start", behavior: "smooth" })
											}}
											disabled={hasKeyContainingString("b2b")}

										>
											Next
										</button>
										:
										<>
											<button className="btn save-pricing apply-to-all"
												onClick={() => savePricing(true)}
											>
												<SaveIcon color="#707170" />
												{packagePlanner.applyForAllTimes}
											</button>

											<button className="btn save-pricing"
												onClick={() => savePricing(false)}
											>
												<SaveIcon color="#D4A655" />
												{packagePlanner.savePrice}
											</button>
										</>

									}
								</div>
							</>
						) : null}
					</div>
				) : null}
			</div>
		</div>
	);
}
