import PhoneField from 'components/Form/phonField/PhoneField';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import { useSBSState } from 'context/global';
import validate, { isFormValid } from 'helpers/validate';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import { CopyIcon } from 'modules/UmrahOperations/shared/Icons';
import { useState } from 'react';
import { sendStatementByEmail, SendUmrahCompanyDetails } from 'services/operationStatement';

export function SendOperationForm({
  data,
  setData,
  companiesList,
  setIsCompanySelectView,
  handleCopyUrl,
  statementRefNumber,
  setIsOpen,
  listTripOperations
}) {
  const { allCountries } = useSBSState()
  const [errors, setErrors] = useState({})

  function checkFormErrors() {
    const requiredKeys = ["email", "phone", "phone_code"]
    let submitError = {};
    Object.keys(data).forEach(key => {
      if (!requiredKeys.includes(key)) return
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: data[key] },
          { required: true, email: key === "email", phone: key === "phone" }
        )
      }
    });
    setErrors(() => submitError);
    return submitError;
  }

  const handleOnChange = ({ key, value, validation = {} }) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));

    setErrors((prev) => {
      return ({
        ...prev,
        ...validate({ name: key, value }, validation),
      })
    });
  }

  async function sendOperationStatement() {
    const payload = {
      email: data.email,
      phone: data.phone,
      phone_code: data.phone_code?.phone_code
    }

    const res = await sendStatementByEmail(statementRefNumber, payload);
    return res;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) return
    const sendByEmailRes = await sendOperationStatement()
    const sendDetailsRes = await SendUmrahCompanyDetails(statementRefNumber, { licence_number: data.company?.licence_number });

    if (sendDetailsRes?.status === 200 && sendByEmailRes?.status === 200) {
      await listTripOperations({})
      handleResponesNotification({ alertType: "success", message: "Statement sent to email" });
      setIsOpen(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='company-select-row'>
        <div className='company-select'>
          <SelectField
            label="Select Umrah Company"
            placeholder="Select Company"
            options={companiesList}
            value={data?.company?.name || ''}
            disabled
          />
        </div>
        <div>
          <button className='primary-button' type="button" onClick={() => setIsCompanySelectView(true)}>
            Change Umrah Operator
          </button>
        </div>
      </div>
      <div className='generated-link-row'>
        <span>Operation Statement Link</span>
        <div className='generated-link'>
          {data?.operation_link}
          <button className='primary-button copy-btn' onClick={handleCopyUrl}>
            <CopyIcon /> <span>Copy</span>
          </button>
        </div>
      </div>
      <div className='send-by'>
        <p><b>OR</b> Send Operating Statement by : </p>

        <div className='fields'>
          <div>
            <TextField
              label="Email Address"
              placeholder="Enter Email"
              value={data?.email}
              onChange={(e) =>
                handleOnChange({
                  key: "email",
                  value: e.target.value,
                  validation: { required: true, email: true }
                })}
              errors={errors?.email}
              color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
            />
          </div>

          <div className="phone">
            <PhoneField
              label="Phone Number"
              phoneCode={data?.phone_code}
              phone={data?.phone}
              countriesLookup={allCountries}
              onSelectPhoneCode={(e) => handleOnChange({ key: "phone_code", value: e, validation: { required: true } })}
              onChangePhone={(e) => handleOnChange({ key: "phone", value: e.target.value, validation: { required: true, phone: true } })}
              phoneCodeColor={errors?.phone_code?.required ? "danger" : ""}
              phoneCodeError={errors?.phone_code?.required}
              phoneColor={errors?.phone?.required ? "danger" : ""}
              phoneError={errors?.phone}

            />
          </div>
        </div>

        <button className='primary-button mt-4'>
          Send
        </button>
      </div>
    </form>
  )
}