import React, { useState } from 'react';
import CalenderSm from 'modules/market-place/NewMarketplace/icons/calenderSm';
import LocationIcon from 'modules/market-place/NewMarketplace/icons/location';

import { TabContent, TabPane } from 'reactstrap';
import { ReactComponent as ClockIcon } from "assets/images/Tds/clock.svg";
import moment from 'moment';
import ImageGallery from './shared/ImageGallery';
import Locale from 'translations';
import { useSBSState } from 'context/global';
import { ReactComponent as CheckMarkIcon } from "assets/images/market-place/checkmark-icon.svg";


export default function PackageAttractionsView({ attractionData }) {
  const { locale } = useSBSState();
  const { packages, productsBuilder ,marketPlace} = Locale;
  const shortDescription = attractionData?.description ? attractionData?.description.substring(0, 255) : null;
  const [description, setDescription] = useState(shortDescription);
  const attractionDuration = attractionData?.duration && `${attractionData?.duration[0] === "0" ? attractionData?.duration.split(':')[0][1] : attractionData?.duration.split(':')[0]}h 
  ${(+attractionData?.duration.split(':')[1])}min`;


  const [activeTab, setActiveTab] = useState("1");

  return (
    <div className='package-view-container attraction'>
      <div className="d-flex flex-wrap flex-md-nowrap">
        <div className="package-view-img">
          <img src={attractionData?.photos?.[0]} alt={attractionData?.name} />
        </div>
        <div className="package-view-info mx-md-3">
          <h4 className='title'>{attractionData?.name?.[locale]}</h4>
          {attractionData?.location ?
            <div className='d-flex'>
              <LocationIcon />
              <span className='mx-1'>{attractionData?.location}</span>
            </div>
            :
            null
          }
          <div className='d-flex flex-wrap align-items-center'>
            <p className='d-flex align-items-center'>
              <CalenderSm />
              <span className='mx-1'>{moment(attractionData?.date).format("DD MMM YYYY")}</span>
            </p>
            <p className='d-flex align-items-center mx-md-3'>
              <ClockIcon />
              <span className='mx-1'>{attractionDuration}</span>
            </p>
          </div>


        </div>
      </div>

      <ul className='package-view-tabs'>
        <li className={`${activeTab === "1" ? "active" : ""}`} onClick={() => setActiveTab("1")}>
          {productsBuilder.overview}
        </li>
        <li className={`${activeTab === "2" ? "active" : ""}`} onClick={() => setActiveTab("2")}>
          {packages.gallery}
        </li>
        <li className={`${activeTab === "3" ? "active" : ""}`} onClick={() => setActiveTab("3")}>
          {packages.policies}
        </li>
      </ul>

      <TabContent activeTab={activeTab} >
        <TabPane tabId="1">
          <div className='mt-2'>
            {description &&
              <>
                <h4 className='title text-uppercase mb-2'>{marketPlace.DESCRIPTION}</h4>
                <p>
                  {description}
                </p>
                {attractionData?.description?.length > 255 ?
                  <button className='btn more-details-btn p-0 font-16 text-yellow'
                    onClick={() => setDescription((prev) => prev.length > 255 ? shortDescription : attractionData?.description)}
                  >
                    {description.length > 255 ? "Show less" : "Show more"}
                  </button>
                  :
                  null
                }
              </>

            }
            {/* 
            {attractionData?.is_include_transportation }
            <p>Transportation</p> */}
            {attractionData?.pickup && attractionData?.drop_off ?
              <div className='my-4'>
                <h4 className='title text-uppercase mb-2'>Transportation</h4>
                <p style={{ color: "#050001" }}>
                  <span className='font-weight-bold'>Pick-up :</span> {attractionData?.pickup}
                </p>
                <p style={{ color: "#050001" }}>
                  <span className='font-weight-bold'>Drop-off :</span> {attractionData?.drop_off}
                </p>
              </div>
              :
              null
            }

            {attractionData?.services && attractionData?.services?.length > 0 ?
              <div>
                <h4 className='title text-uppercase mb-2'>{marketPlace.IncludedServices}</h4>
                <div className='d-flex flex-wrap'>
                  {attractionData?.services?.map(service => {
                    return (
                      <p key={`attraction-${service}`} className='my-1 col-md-4 col-12'>
                        <CheckMarkIcon />
                        <span className='mx-1'>{service}</span>
                      </p>
                    )
                  })}
                </div>
              </div>
              :
              null
            }
          </div>
        </TabPane>

        <TabPane tabId="2">
          <ImageGallery images={attractionData?.photos || []} />
        </TabPane>

        <TabPane tabId="3">
          {attractionData?.policy}
        </TabPane>

      </TabContent>


    </div>
  )
}
