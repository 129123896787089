import moment from 'moment';
import { useState } from 'react';
import { getGroupPassengers, OnlineVisaGroups } from 'services/VisaOnline';

export default function useTravelersPassports() {

  // ** states

  const [passportsData, setPassportsData] = useState({
    source: "safavisa-groups",
    selectedGroup: null,
    selectedTag: null,
    passportInserted: false,
  })

  const [passportsGroups, setPassportsGroups] = useState({
    list: null,
    tags: null
  });

  const [allTravelersList, setAllTravelersList] = useState([]);
  const [insertedTravelers, setInsertedTravelers] = useState([]);

  const [addPassportModalOpen, setAddPassportModalOpen] = useState(false);

  // ********************************************* function ********************************************* //

  // toggle add passport modal
  function toggleAddPassportsModal() {
    setAddPassportModalOpen(!addPassportModalOpen);
    setPassportsData({
      ...passportsData,
      selectedGroup: null,
      selectedTag: null,
    });
    setAllTravelersList([]);
    if (!passportsGroups.list) {
      getSafaGroupsAndTags();
    }
  }

  function handleHeaderInputsChanges({ key, value }) {
    setPassportsData({ ...passportsData, [key]: value })
  }

  // handle select all available passport
  function handleSelectAllPassport(checked) {
    let passportsListClone = [];
    passportsListClone = allTravelersList.map(traveler => {
      return {
        ...traveler,
        selected: insertedTravelers.find(t => t?.passport_number === traveler?.passport_number) ? false : checked,
      }
    });
    setAllTravelersList(passportsListClone)
  }

  // select passport 
  function handleSelectPassport({ isPassportSelected, passportNumber }) {
    let passportsListClone = [...allTravelersList];
    let passportIndex = passportsListClone.findIndex(passport => passport?.passport_number === passportNumber)
    if (passportIndex > -1) {
      passportsListClone[passportIndex] = { ...passportsListClone[passportIndex], selected: !isPassportSelected }
    }
    setAllTravelersList(passportsListClone)
  }

  // // delete inserted passport by id
  function handleDeletePassport({ passportNumber }) {
    let passportsListClone = [...insertedTravelers];
    passportsListClone = passportsListClone.filter(passport => passport?.passport_number !== passportNumber);
    if (passportsListClone.length === 0) {
      setPassportsData({ ...passportsData, passportInserted: false });
      getSafaGroupsAndTags();
    }
    setInsertedTravelers(passportsListClone)
  }

  // // insert selected passpors to send it to backend
  function handleInsertSelectedPassprots(cb) {
    setInsertedTravelers([...insertedTravelers, ...allTravelersList?.filter(passport => passport?.selected)]);
    setPassportsData({ ...passportsData, passportInserted: true });
    setAllTravelersList([]);
    cb && cb()
  }

  // // get all possports from group 
  async function getPassportsListByGroupOrTag() {
    let groupOrTagId = passportsData.selectedGroup?.id || passportsData.selectedTag?.id;
    const res = await getGroupPassengers({}, groupOrTagId);
    if (res?.status === 200) {
      let formatSafaTravelers = res?.data?.data?.membersData?.filter(member => member?.PPNo)?.map(member => {
        return {
          ...member,
          first_name: member?.FirstName,
          last_name: member?.LastName,
          passport_number: member?.PPNo,
          passport_photo: member?.passport_image,
          gender: +member?.Gender === 1 ? "M" : "F",
          birth_date: member?.BirthDate,
          age: member?.BirthDate ? moment(moment()).diff(member?.BirthDate, "years") : null,
          country_id: member?.CAddCountry,
          nationality: member?.nationality_name?.name,
          nationality_id: member?.CNationality,
          relationship: member?.RelationWithSponser || member?.RelationWithSponser || null,
          national_id: member?.IdNumber
        }
      })
      setAllTravelersList(formatSafaTravelers);
    }
  }

  // get safa visa groups and tags
  async function getSafaGroupsAndTags() {
    const res = await OnlineVisaGroups({});
    if (res?.status === 200) {
      const formattedGroup = res.data.data.map((item) => {
        return {
          id: item.id,
          name: item.group_name,
        };
      });
      const formattedTags = res.data.data.map((item) => {
        return {
          id: item.id,
          name: item.tag,
        };
      });

      setPassportsGroups({ list: formattedGroup, tags: formattedTags })
    } else {
      setPassportsData({
        ...passportsData,
        selectedGroup: null,
        selectedTag: null,
      })
    }
  }

  return {
    passportsData,
    setPassportsData,
    passportsGroups,
    allTravelersList,
    insertedTravelers,
    setInsertedTravelers,
    addPassportModalOpen,

    toggleAddPassportsModal,
    handleHeaderInputsChanges,
    handleSelectAllPassport,
    handleSelectPassport,
    handleDeletePassport,
    handleInsertSelectedPassprots,
    getPassportsListByGroupOrTag,
    getSafaGroupsAndTags,
  }
}
