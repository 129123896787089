import React from "react";
import { ReactComponent as CountryIcon } from "assets/images/hotelAccommodation/country.svg";
import { ReactComponent as CityIcon } from "assets/images/hotelAccommodation/city.svg";
import { ReactComponent as CalendarIcon } from "assets/images/hotelAccommodation/calendar.svg";
import { ReactComponent as MoonIcon } from "assets/images/hotelAccommodation/moon.svg";
import { DuplicatedIcon } from './icon';

function HotelDetails({ hotelDetails, toggleModel }) {
	console.log("hotelDetails1111111",hotelDetails);
	return (
		<div className="d-flex">
			<div className="hotel-details-container w-100">
				<div className="hotel-key-value">
					<CountryIcon />
					<span className="key">Country</span>
					<span className="value">{hotelDetails?.hotel?.country}</span>
				</div>
				<div className="hotel-key-value separator">
					<CityIcon />
					<span className="key">City</span>
					<span className="value">{hotelDetails?.hotel?.city}</span>
				</div>
				<div />

				<div className="hotel-key-value">
					<CalendarIcon />
					<span className="key">Check in data </span>
					<span className="value">{hotelDetails?.check_in_at}</span>
				</div>
				<div className="hotel-key-value separator">
					<CalendarIcon />
					<span className="key">Check out data </span>
					<span className="value">{hotelDetails?.check_out_at}</span>
				</div>
				<div className="hotel-key-value separator">
					<MoonIcon />
					<span className="key">No. of nights </span>
					<span className="value">{hotelDetails?.night_count}</span>
				</div>
			</div>
			<button className="btn mt-5 btn-duplicate" onClick={() => toggleModel()} 
			// disabled={true}
			>
				<DuplicatedIcon />
				Duplicate
			</button>
		</div>
	);
}

export default HotelDetails;
