import React, { useEffect, useMemo, useState } from "react";
import Locale from "translations";
import PassengerForm from "./PassengerForm";
import ContactInfo from "./ContactInfo";
import FareRulesModel from "./Modals/FareRulesModel";
import FreeBagsModel from "./Modals/FreeBagsModel";
import PriceBooking from "./PriceBooking";
import validate, { isFormValid } from "helpers/validate";
import { useSBSState } from "context/global";
import { useMarketplaceState } from "context/marketplace";
import moment from "moment";
import { bookFlight, fetchFlightDetails, FlightCheckAvailability } from "services/marketplace";
// import FlightPaymentModal from './Modals/FlightPaymentModal';
import { store } from 'react-notifications-component';
import { useHistory, useParams } from 'react-router-dom';
import FlightSummarySegmensts from './FlightSummarySegmensts';
import NewPaymentAction from 'components/NewPaymentAction';

function FlightSummray() {
	const { status } = useParams()
	const { productsBuilder, marketPlace } = Locale;
	const { flightsSearch, outboundFlight, inboundFlight } =
		useMarketplaceState();
	const { allCountries, locale, currency } = useSBSState();
	const history = useHistory();
	const countries = allCountries
		? allCountries?.map((country) => {
			return { ...country, label: country?.name };
		})
		: null;
	const [flightData, setFlightData] = useState(null);
const [flightAvailability, setFlightAvailability] = useState(null);

const [actionType, setActionType] = useState('');
const [formData, setFormData] = useState({});

	const [contactInfoData, setContactInfoData] = useState({
		fullname: "",
		email: "",
		phoneCode: "",
		phone: "",
	});
	const [passengersData, setPassengersData] = useState({
		adults: [],
		children: [],
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [BagModel, setBagModel] = useState();
	const [RulesModel, setrulesModel] = useState();
	const [acceptedTerms, setAcceptTerms] = useState(false);
	const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
	const flightType = flightsSearch?.type === "1" ? productsBuilder.roundtrip : flightsSearch?.type === "2" ? marketPlace.oneway : flightsSearch?.type === "3" ? marketPlace.MultiDestination : "";
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK? JSON.parse(process.env.REACT_APP_OTP_CHECK):false

	const togglePaymentModal = () => {
		setActionType('')
		setFormData({})
		setPaymentModalIsOpen(!paymentModalIsOpen);
	};

	const toggleBag = () => {
		setBagModel(!BagModel);
	};
	const togglerules = () => {
		setrulesModel(!RulesModel);
	};

	function checkFormErrors() {
		let submitError = {};
		// check errors for contact information
		Object.keys(contactInfoData).forEach(key => {
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: contactInfoData[key] },
					{ required: true, email: key === "email" ? true : false, min: key === "phone" ? 9 : "", max: key === "phone" ? 13 : "" }
				),
			};
		});

		// check errors for adults 
		passengersData?.adults?.forEach((adult, index) => {
			Object.keys(adult).forEach(key => {
				if (key === "birthday" || key === "cardExpiredDate") {
					Object.keys(adult[key]).forEach(subKey => {
						submitError = {
							...submitError,
							...validate(
								{ name: [`adults-${key}-${subKey}-${index}`], value: adult[key][subKey], },
								{ required: true }
							),
						}
					});
				} else {
					submitError = {
						...submitError,
						...validate(
							{ name: [`adults-${key}-${index}`], value: adult[key], },
							{ required: key !== "id" ? true : false }
						),
					}
				}
			})
		});
		// check erros for childrens
		passengersData?.children?.forEach((child, index) => {
			Object.keys(child).forEach(key => {
				if (key === "birthday" || key === "cardExpiredDate") {
					Object.keys(child[key]).forEach(subKey => {
						submitError = {
							...submitError,
							...validate(
								{ name: [`children-${key}-${subKey}-${index}`], value: child[key][subKey], },
								{ required: true }
							),
						}
					});
				} else {
					submitError = {
						...submitError,
						...validate(
							{ name: [`children-${key}-${index}`], value: child[key], },
							{ required: key !== "id" ? true : false }
						),
					}
				}
			})
		});
		setErrors(() => submitError);
	}

	const passengerInitalObj = {
		firstName: "",
		lastName: "",
		sex: "",
		birthday: { day: "", month: "", year: "" },
		nationality: "",
		// idType: "",
		cardNum: "",
		cardExpiredDate: { day: "", month: "", year: "" },
		psgType: ""
	}
	// create adults and childs array from adults and child counts
	useEffect(() => {
		const adults = passengersData?.adults.length > 0
			? passengersData?.adults
			: [...Array(+flightsSearch?.adults).keys()].map(index => {
				return { ...passengerInitalObj, id: `adult-${index}`, psgType: "ADT" }
			});

		const children = passengersData?.children.length > 0
			? passengersData?.children
			: [...Array(+flightsSearch?.children).keys()].map(index => {
				return { ...passengerInitalObj, id: `child-${index}`, psgType: "CHD" }
			});
		setPassengersData({ adults, children })
	}, []);

	// get days and months and years for birthday and expiry date
	const calenderLookups = useMemo(() => {
		// adult start date, end date
		const adultMinAge = moment().subtract(12, 'years');
		const adultMaxAge = moment().subtract(100, 'years');
		const adultsDates = adultMinAge.startOf('year');
		// child start date, end date
		const childrenMinAge = moment().subtract(2, 'years');
		const childrenMaxAge = moment().subtract(12, 'years');
		const childrenDates = childrenMinAge.startOf('year');

		let adultYears = [];
		let childYears = [];
		let days = [...Array(31).keys()].map(day => {
			day = String(day + 1).length === 1 ? `0${String(day + 1)}` : String(day + 1);
			return { id: day, name: day }
		});
		let months = [...Array(12).keys()].map(month => {
			month = String(month + 1).length === 1 ? `0${String(month + 1)}` : String(month + 1);
			return { id: month, name: month }
		});
		// adults calender lookup
		if (+flightsSearch?.adults > 0) {
			do {
				adultYears.push(adultsDates.year())
			} while (adultsDates.subtract(1, 'year') > adultMaxAge);
			adultYears = adultYears.map(year => {
				return { id: year, name: String(year) }
			})
		}
		// children calender lookup
		if (+flightsSearch?.children > 0) {
			do {
				childYears.push(childrenDates.year())
			} while (childrenDates.subtract(1, 'year') > childrenMaxAge);
			childYears = childYears.map(year => {
				return { id: year, name: String(year) }
			})
		}
		return { days, months, adultYears, childYears };
	}, [flightsSearch?.adults, flightsSearch?.children]);

	// check if there is any errors in passengers form 
	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	// check Availability
	async function CheckAvailability() {
		const data = {
			cacheKey: flightsSearch?.cacheKey,
			solutionKey: outboundFlight?.solutionKey,
			journey_0: outboundFlight?.journeys?.[0]?.flightId,
			journey_1: inboundFlight?.journeys?.[0]?.flightId,
			passengers: [...passengersData?.adults, ...passengersData?.children].map((passanger) => {
				return {
					birthday: `${passanger?.birthday?.year?.name}-${passanger?.birthday?.month?.name}-${passanger?.birthday?.day?.name}`,
					firstName: passanger?.firstName,
					lastName: passanger?.lastName,
					nationality: passanger?.nationality?.code,
					// cardType: passanger.idType.value,
					cardType: "P",
					cardNum: passanger?.cardNum,
					cardExpiredDate: `${passanger?.cardExpiredDate?.year?.name}-${passanger?.cardExpiredDate?.month?.name}-${passanger?.cardExpiredDate?.day?.name}`,
					psgType: passanger?.psgType,
					sex: passanger?.sex?.name === "Male" ? "M" : "F",
				}
			}),
		}
		const checkFlightAbaliabilityRes = await FlightCheckAvailability(data);
		if (checkFlightAbaliabilityRes.status === 200) {
			setFlightAvailability(checkFlightAbaliabilityRes?.data)
			togglePaymentModal();
		}else if (checkFlightAbaliabilityRes?.status === 202){
      setActionType(checkFlightAbaliabilityRes?.data?.actionType)
      setFormData(checkFlightAbaliabilityRes?.data?.data?.formData)

    }
	}

	// handle book flight
	async function bookOnPayment(payment_method, currency,otp) {
		const data = {
			cacheKey: flightAvailability?.cacheKey,
			paymentMethod: payment_method,
			currency: currency,
			otp:otp,
			contactDetails: {
				name: contactInfoData?.fullname,
				email: contactInfoData?.email,
				phone: `${contactInfoData?.phoneCode}${contactInfoData?.phone}`,
			},
			passengers: [...passengersData?.adults, ...passengersData?.children].map((passanger) => {
				return {
					birthday: `${passanger?.birthday?.year?.name}-${passanger?.birthday?.month?.name}-${passanger?.birthday?.day?.name}`,
					firstName: passanger?.firstName,
					lastName: passanger?.lastName,
					nationality: passanger?.nationality?.code,
					// cardType: passanger.idType.value,
					cardType: "P",
					cardNum: passanger?.cardNum,
					cardExpiredDate: `${passanger?.cardExpiredDate?.year?.name}-${passanger?.cardExpiredDate?.month?.name}-${passanger?.cardExpiredDate?.day?.name}`,
					psgType: passanger?.psgType,
					sex: passanger?.sex?.name === "Male" ? "M" : "F",
				}
			}),
		}
		const bookRes = await bookFlight(data);
		
		if (bookRes?.status === 200 || bookRes?.status === 201) {
			togglePaymentModal();
			store.addNotification({
				title: "info!",
				message: "Book successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});

			history.push('/inventory/flights');
		}else if (bookRes?.status === 202){
      setActionType(bookRes?.data?.actionType)
      setFormData(bookRes?.data?.data?.formData)

    }
	}

	// get flight details
	useEffect(() => {
		async function getFlightDetails() {
				let data={}
			if (+flightsSearch.type===2) {
				data ={
					cacheKey: flightsSearch?.cacheKey,
					solutionKey: outboundFlight?.solutionKey,
					journey_0: outboundFlight?.out_key,
					journey_1: inboundFlight?.journeys?.[0]?.flightId
				}
			}else{
				data =		{
					cacheKey: flightsSearch?.cacheKey,
					solutionKey: inboundFlight?.solutionKey,
					journey_0: inboundFlight?.out_key,
					journey_1: inboundFlight?.journeys?.[0]?.flightId
				}
			}


			
			const viewFlightRes = await fetchFlightDetails(data);
			if (viewFlightRes?.status >= 200 && viewFlightRes?.status < 300) {
				setFlightData(viewFlightRes?.data);
			} else {
				history.push(`/${status}/outbound-view`);
			}
		}
		if (!flightData) {
			getFlightDetails();
		}
	}, []);


	// if there is no error in passenger form open payment modal and CheckAvailability
	useEffect(() => {
		if (isFormValid(errors)) {
			CheckAvailability();
		}
	}, [isErrorLoaded]);


	return (
		<>
			{flightData ?
				<>
					<div className="container summary-flight">
						<div className="row">
							<div className="col-12 col-md-8">
								<div className="d-flex align-items-center mb-3">
									<p className="sum-type me-2 text-capitalize">
										{flightType}
									</p>
									{flightsSearch?.type !== "3"
										?
										<div className="d-flex align-items-center">
											<p className="bold">{flightsSearch?.from?.names?.[locale]}</p>

											{flightsSearch?.type === "2" ? <i className="fas fa-long-arrow-alt-right mx-2"></i> : <i className="fas fa-exchange-alt mx-2"></i>}

											<p className="bold">{flightsSearch?.to?.names?.[locale]}</p>
										</div>
										:
										<div className='d-flex flex-column'>
											{flightsSearch?.airLegs?.map(airLeg => (
												<div className="d-flex align-items-center mb-1" key={airLeg?.from?.id}>
													<p className="bold">{airLeg?.from?.label}</p>
													<i className="fas fa-exchange-alt mx-2"></i>
													<p className="bold">{airLeg?.to?.label}</p>
												</div>
											))}
										</div>
									}
								</div>

								{/* segmenests */}
								{flightData?.journey_0?.[0] ?
									<FlightSummarySegmensts solution={flightData} journyData={flightData?.journey_0?.[0]} />
									:
									null
								}
								{flightData?.journey_1?.[0] ?
									<FlightSummarySegmensts solution={flightData} journyData={flightData?.journey_1?.[0]} />
									:
									null
								}

								{/* <div className="mt-4">
									<p className="p-2">
										{marketPlace.BookingCodeSelected}
										<strong style={{ color: "#D29D4D" }}>TL</strong>{" "}
									</p>
									<div className="d-flex align-items-center justify-content-between">
										<MinIssue />
									</div>
								</div> */}

								{countries ? (
									<div className="mt-4">
										<p style={{ color: "#0F3E5E" }} className="bold">
											{marketPlace.contactInformtion}
										</p>
										<ContactInfo
											countries={countries}
											contactInfoData={contactInfoData}
											setContactInfoData={setContactInfoData}
											errors={errors}
											setErrors={setErrors}
										/>

										{/* passengers list */}
										<div className="mt-3">
											<div className="d-flex justify-content-between align-items-center">
												<h3 className='text-capitalize font-weight-bold'>{marketPlace.passanger}</h3>
												<div
													className="d-flex justify-content-between"
													style={{ columnGap: "1em" }}
												>
													{/* <span className="btn-popup">
														{marketPlace.servicesstandard}
													</span> */}
													<span
														className="btn-popup pointer"
														onClick={togglerules}
													>
														{marketPlace.farerules}
													</span>
													<span
														className="btn-popup pointer"
														onClick={toggleBag}
													>
														{marketPlace.freebaggage}
													</span>
												</div>
											</div>
											{passengersData?.adults?.length > 0 && passengersData?.adults?.map((adult, index) => {
												return (
													<PassengerForm
														index={index}
														passengerType={'adults'}
														passanger={adult}
														passengersData={passengersData}
														setPassengersData={setPassengersData}
														calenderLookups={calenderLookups}
														countries={countries}
														errors={errors}
														setErrors={setErrors}
													/>
												)
											})}
											{passengersData?.children?.length > 0 && passengersData?.children?.map((child, index) => {
												return (
													<PassengerForm
														index={index}
														passengerType={'children'}
														passanger={child}
														passengersData={passengersData}
														setPassengersData={setPassengersData}
														calenderLookups={calenderLookups}
														countries={countries}
														errors={errors}
														setErrors={setErrors}
													/>
												)
											})}
										</div>
										<div className='d-flex mt-4 align-items-center justify-content-between mb-3 flex-wrap'>
											<div>
												<input type="checkbox" name="terms" id="terms" value={acceptedTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
												<label htmlFor='terms' className='mx-1 pointer'>{marketPlace.acceptedTerms}</label>
											</div>
											<button
												className="btn flight-checkout-btn"
												onClick={submit}
												disabled={!acceptedTerms}
											>
												{marketPlace.submit}
											</button>
										</div>
									</div>
								) : null}
							</div>
							{/* booking summary  */}
							<div className="col-12 col-md-4">
								<div className='filter-head terms-color text-uppercase'>
									<h6 className='font-weight-bold h6 hotel-found my-3'>{marketPlace.messages.bookingSummary}</h6>
									<PriceBooking
										flightData={flightData}
									/>
								</div>
							</div>
						</div>
					</div>
					<FreeBagsModel
						title={marketPlace.freebaggage}
						toggle={toggleBag}
						isOpen={BagModel}
						flightData={flightData}
					/>
					<FareRulesModel
						title={marketPlace.farerules}
						toggle={togglerules}
						isOpen={RulesModel}
						flightData={flightData}
					/>


{paymentModalIsOpen && (
				<NewPaymentAction
					isOpen={paymentModalIsOpen}
					toggleModal={togglePaymentModal}
					canPayLater={flightAvailability?.canPayLater}
					formData={formData} 
					actionType={actionType}
					Price={flightAvailability?.exchange_rate_price}
					currency={flightAvailability?.exchange_rate_currency}
					secondCurrency={currency}
					OTP={OTP_CHECK}
					onPayment={bookOnPayment}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}

				</>
				: null
			}
		</>
	);
}

export default FlightSummray;
