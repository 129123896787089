import { ArrivalInvoices } from 'components/Printing/OperationInvoice/Arrival'
// import PrintLogo from 'assets/images/umrah-operations/logo.svg'
import imagePlaceholderSrc from "assets/images/image_placeholder.png";

import CallIcon from 'assets/images/umrah-operations/invoice/call.svg'
import MailIcon from 'assets/images/umrah-operations/invoice/sms.svg'
import Global from 'assets/images/umrah-operations/invoice/global.svg'
import ValidIcon from 'assets/images/umrah-operations/invoice/valid.svg'
import UserIcon from 'assets/images/umrah-operations/invoice/user.svg'
import XIcon from 'assets/images/umrah-operations/invoice/icon.svg'
import CloseFile from 'assets/images/umrah-operations/invoice/folder.svg'
import OpenFile from 'assets/images/umrah-operations/invoice/file.svg'
import { HotelInvoice } from 'components/Printing/OperationInvoice/HotelsInvoice'
import { TransportationInvoice } from 'components/Printing/OperationInvoice/TransportationInvoice'
import { VisaInvoice } from 'components/Printing/OperationInvoice/VisaInvoice'
import { CateringInvoice } from 'components/Printing/OperationInvoice/CateringInvoice'
import { AttractionsInvoice } from 'components/Printing/OperationInvoice/AttractionsInvoice'
import { OtherServicesInvoice } from 'components/Printing/OperationInvoice/OtherServicesInvoice'
import { OperationStatement } from 'components/Printing/OperationInvoice/OperationStatement'
import {
	arrivalInvoice,
	attractionInvoice,
	cateringInvoice,
	fetchOperationStatementInvoice,
	hotelInvoice,
	operationInvoice,
	otherServicesInvoice,
	transportationInvoice,
	visaInvoice
} from 'services/operationStatement'
import moment from 'moment'
import { getOperationsLookup } from '../helper/operationsLookup'
import { roomTypesLookup } from 'modules/products-builder/Requestes/RequestView/helpers/localLookups'
import Locale from 'translations';
import { OperationInvoicePrinting } from 'components/Printing/OperationInvoice/OperationInvoice';

function usePrintingInvoices() {
	const imageUrl = process.env.REACT_APP_API_URL + "/v1/files/fetch/";
	const { citiesLookup, statementTypeList } = getOperationsLookup(Locale);


	// full package Invoice
	const printFullPackageInvoice = async (id) => {
		const res = await operationInvoice(id)
		if (res?.status === 200) {
			console.log({ res })
			const arrivalData = `
			${res?.data?.data?.trip
					? `
					<div class="arrival-container">
					<h3 class="operation-invoice-heading">Arrival & Departure</h3>
			<div class="arrivalAndDeparture">
			<div class="d-flex align-items-center">
				<p class="prgramType-text mr-2">Arrival To</p>
				<h3>${res?.data?.data?.trip?.arrival_port?.name} - ${res?.data?.data?.trip?.arrival_hall?.name ?? ""
					}</h3>
			</div>
			<div class="d-flex">
				<div class="col-4 p-0">
					<div class="d-flex align-items-center">
						<p class="prgramType-text">AirLine:</p>
						<h3>${res?.data?.data?.trip?.arrival_carrier?.name}</h3>
					</div>
				</div>
				<div class="col-2">
					<div class="d-flex align-items-center">
						<p class="prgramType-text">No.</p>
						<h3>${res?.data?.data?.trip?.arrival_trip_number ?? "-"}</h3>
					</div>
				</div>
				<div class="col-3">
					<div class="d-flex align-items-center">
						<p class="prgramType-text">Date &ensp;</p>
						<h3>${res?.data?.data?.trip?.arrival_date ? moment(res?.data?.data?.trip?.arrival_date).format('DD/MM/YYYY') : "-"}</h3>
					</div>
				</div>
				<div class="col-3 d-flex justify-content-end">
					<div class="d-flex align-items-center">
						<p class="prgramType-text">Time &ensp;</p>
						<h3>${res?.data?.data?.trip?.arrival_date ? moment(res?.data?.data?.trip?.arrival_date).format('hh:mm a') : "-"}</h3>
					</div>
				</div>
			</div>
		</div>
		<hr
		style="
			background-color: #e2e8f0;
			width: 95%;
			display: block;
			margin: 15px auto;
			opacity: 0.2;
		"
	/>
			
			`
					: ''
				}
	
			${res?.data?.data?.trip?.departures?.length > 0
					? res?.data?.data?.trip?.departures?.map((ele) => {
						return `
				<div class="arrivalAndDeparture">
				<div class="d-flex align-items-center">
					<p class="prgramType-text mr-2">Departure From</p>
					<h3>${ele?.port?.name ? `${ele?.port?.name} - ${ele?.hall?.name ?? ""}` : '-'}</h3>
				</div>
				<div class="d-flex">
					<div class="col-4 p-0">
						<div class="d-flex align-items-center">
							<p class="prgramType-text">AirLine:</p>
							<h3>${ele?.carrier?.name ? ele?.carrier?.name : '-'}</h3>
						</div>
					</div>
					<div class="col-2">
						<div class="d-flex align-items-center">
							<p class="prgramType-text">No.</p>
							<h3>${ele?.trip_number ?? "-"}</h3>
						</div>
					</div>
					<div class="col-3">
						<div class="d-flex align-items-center">
							<p class="prgramType-text">Date &ensp;</p>
							<h3>${moment(ele?.date).format('DD/MM/YYYY')}</h3>
						</div>
					</div>
					<div class="col-3 d-flex justify-content-end">
						<div class="d-flex align-items-center">
							<p class="prgramType-text">Time &ensp;</p>
							<h3>${moment(ele?.date).format('hh:mm a')}</h3>
						</div>
					</div>
				</div>
			</div>
			</div>
				`
					})
					: ''
				}
			
			`
			let hotelsData =
				res?.data?.data?.hotels?.length > 0
					? res?.data?.data?.hotels?.map((ele, index) => {
						return `
							<div class="arrival-container">
							<h3 class="operation-invoice-heading">${index === 0 ? "Hotels Details" : " "}</h3> 
					<div class="arrivalAndDeparture">
						<div class="d-flex align-items-center">
							<p class="prgramType-text mr-2">${ele?.city_name}</p>
							<h3 style="font-weight:bold">${ele?.hotel?.name}</h3>
						</div>
						
						<div class="d-flex">
						<div class="col-3 p-0">
							<div class="d-flex align-items-center">
								<p class="prgramType-text mr-2">Nights&ensp;</p>
								<h3>${ele?.nights}</h3>
							</div>
						</div>
						<div class="col-3 p-0">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">Meals&ensp;</p>
								<h3>${ele?.meal_type}</h3>
							</div>
						</div>
						<div class="col-3">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">Check In&ensp;</p>
								<h3>${moment(ele?.check_in_date).format('DD/MM/YYYY')}</h3>
							</div>
						</div>
						<div class="col-3 d-flex justify-content-end">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">Check Out&ensp;</p>
								<h3>${moment(ele?.check_out_date).format('DD/MM/YYYY')}</h3>
							</div>
						</div>

					
					</div>
					</div>
					</div>			
			`
					})
					: []

			const cateringDetails =
				res?.data?.data?.catering?.length > 0
					? res?.data?.data?.catering?.map((ele) => {
						return `
			
			<div class="arrival-container">
			<div class="arrivalAndDeparture">
				<div class="d-flex align-items-center">
					 <h3>${ele?.hotel?.name}</h3>
				</div>

				<div class="d-flex">
					<div class="col-3 p-0">
						<div class="d-flex align-items-center">
							<p class="prgramType-text">City&ensp;</p>
							<h3>${ele?.city_id?.name}</h3>
						</div>
					</div>
				
					<div class="col-3 d-flex">
						<div class="d-flex align-items-center">
							<p class="prgramType-text">Nights &ensp;</p>
							<h3>${ele?.nights}</h3>
						</div>
					</div>
				</div>


				${ele?.catering?.length > 0
								? ele?.catering?.map((data) => {
									return `
					<table>
						<thead>
							<tr>
								<th>Supplier</th>
								<th>Meals</th>
								<th>Pax</th>
								
							</tr>
						</thead>
						<tbody>
						<tr>
						<td>${data?.supplier_name}</td>
						<td>${data?.meal_type}</td>
						<td>${data?.pax}</td>
					
					</tr>
						</tbody>
					</table>
								
								`
								})
								: ''
							}

			</div>
		</div> 
			
			`
					})
					: []

			const attractionsDetails =
				res?.data?.data?.attractions?.length > 0
					? res?.data?.data?.attractions?.map((ele) => {
						return `
				<div class="arrivalAndDeparture">

						<div class="d-flex">
							<div class="col-4 p-0">
								<h3 style="font-weight: bolder">${ele?.attraction?.name}&ensp;</h3>
							</div>
							<div class="col-4">
								<div class="d-flex align-items-center">
									<p class="prgramType-text">City&ensp;</p>
									<h3>
										${citiesLookup.find((city) => city?.id === ele?.city_id).name}
									</h3>
								</div>
							</div>

							<div class="col-4 p-0">
								<div class="d-flex align-items-center">
									<p class="prgramType-text mr-2">Date&ensp;</p>
									<h3>${moment(ele?.date_time).format('DD/MM/YYYY - hh:mm a')}</h3>
								</div>
							</div>
						</div>
					</div>
					<hr
						style="
							background-color: #e2e8f0;
							width: 95%;
							display: block;
							margin: 15px auto;
							opacity: 0.2;
						"
					/>
				
				`
					})
					: []

			const transportationList =
				res?.data?.data?.transportation?.movements?.length > 0
					? res?.data?.data?.transportation?.movements?.map((ele) => {
						return `
			
					<tr>
					<td>${moment(ele?.movement_date).format('DD/MM/YYYY')}</td>
					<td>${ele?.movement_type}</td>
					<td>${ele?.pickup_points?.length > 0 && ele?.pickup_points[0]?.title
								? `${ele?.pickup_points[0]?.title}`
								: '-'
							}</td>
					<td>${ele?.destinations?.length > 0 ? ele?.destinations[0]?.title : '-'}</td>
					<td>${moment(ele?.movement_date).format('hh:mm a')}</td>
				</tr>
					
				`
					})
					: []

			let transportationData = res?.data?.data?.transportation
				? `
			<div class="arrival-container">
			<div class="arrivalAndDeparture">
				
				
				<div class="d-flex">
				<div class="col-4 p-0">
					<div class="d-flex align-items-center">
						<h3>${res?.data?.data?.transportation?.transporter?.name}</h3>
					</div>
				</div>
				<div class="col-4 p-0">
					<div class="d-flex align-items-center">
						<p class="prgramType-text">Cycle type&ensp;</p>
						<h3>${res?.data?.data?.transportation?.cycle_type}</h3>
					</div>
				</div>
				<div class="col-2">
					<div class="d-flex align-items-center">
						<p class="prgramType-text">No. of vehicle &ensp;</p>
						<h3>${res?.data?.data?.transportation?.vehicles?.length}</h3>
					</div>
				</div>
				<div class="col-2 d-flex justify-content-end">
					<div class="d-flex align-items-center">
						<p class="prgramType-text">Model&ensp;</p>
						<h3>${res?.data?.data?.transportation?.vehicles?.length > 0
					? res?.data?.data?.transportation?.vehicles[0]?.vehicle_model
					: '-'
				}</h3>
					</div>
				</div>
			</div>
				<table>

				<thead>
					<tr>
						<th>Date</th>
						<th>Type</th>
						<th>Pickup Point</th>
						<th>Destination</th>
						<th>Pickup Time</th>
					</tr>
				</thead>
				<tbody>

						[transport_rows]
						
				</tbody>
			</table>
			</div>
		</div> 
	`
				: []

			const visaDetails =
				res?.data?.data?.visas?.length > 0
					? res?.data?.data?.visas?.map((ele) => {
						return `
		<div class="arrival-container">
		<h3 class="operation-invoice-heading">Visa Details</h3>
		<div class="arrivalAndDeparture">

		<div class="d-flex align-items-center">
			<h3 class="mr-2" style="font-weight: bolder">${ele?.visa_type}&ensp;</h3>

			<p class="prgramType-text visa-text-invoice" style="font-stretch: ultra-condensed;">
				Pax <span> ${ele?.adults + ele?.children + ele?.infants} Adult(${ele?.adults}) | ${ele?.children
							} child  | ${ele?.infants} infant </span
			</p>
		</div>
	</div>
	</div>
		
		`
					})
					: []

			const otherServices =
				res?.data?.data?.other_services?.length > 0
					? res?.data?.data?.other_services?.map((ele) => {
						return `
		<div class="arrival-container" style="margin-bottom: 1rem;">
	  <h3 class="operation-invoice-heading">Other Service Details</h3>
		<div class="arrivalAndDeparture">

		<div class="d-flex align-items-center">
			<h3 class="mr-2" style="font-weight: bolder">
				${ele?.name}&ensp;
			</h3>

			<p class="prgramType-text visa-text-invoice" style="font-stretch: ultra-condensed;">Pax  <span> ${ele?.pax} </span></p>
		</div>
	</div>

	</div>
		
		`
					})
					: []

			let repos = OperationInvoicePrinting
			const statementType = statementTypeList.find(
				(type) => type?.id === res?.data?.data?.statement_type
			)
			const keyValuePair = {
				'[print_logo]': res?.data?.data?.company_header_image ? `${imageUrl}${res?.data?.data?.company_header_image}` : imagePlaceholderSrc,
				'[logo_position]': res?.data?.data?.company_header_image_setting || "left",
				'[invoice_number]': res?.data?.data?.id,
				'[invoice_date]': moment(res?.data?.created_at).format('DD/MM/YYYY') ?? "-",
				'[agent_name]': res?.data?.data?.agent?.name ?? "-",
				'[statement_type]': statementType.label ?? "-",
				'[program_type_icon]': XIcon,
				'[program_type_name]': statementType.label ?? "-",
				'[user_icon]': UserIcon,
				'[number_of_people]': `${res?.data?.data?.children +
					res?.data?.data?.infants +
					res?.data?.data?.adults
					} (${res?.data?.data?.adults} Adult - ${res?.data?.data?.children
					} Child - ${res?.data?.data?.infants} Infant)`,
				'[file_icon]': CloseFile,
				'[open_folder_icon]': OpenFile,
				'[operation_no]':
					res?.data?.data?.company_reference_number == null
						? 0
						: res?.data?.data?.company_reference_number,
				'[file_number]':
					res?.data?.data?.file_number === null ? 0 : res?.data?.data?.file_number,
				'[valid_icon]': ValidIcon,
				'[travel_agent_name]': res?.data?.data?.agent?.name ?? "-",
				'[global_icon]': Global,
				'[country_name]': res?.data?.data?.agent?.country?.name ?? "-",
				'[phone_icon]': CallIcon,
				'[phone_number]': res?.data?.data?.agent?.phone ?? "-",
				'[mail_icon]': MailIcon,
				'[mail]': res?.data?.data?.agent?.email ?? "-",
				'[arrival_city]': `${res?.data?.data?.arrival_port?.name} - ${res?.data?.data?.arrival_port?.code}`,
				'[airline_name]': `${res?.data?.data?.arrival_carrier?.name} - ${res?.data?.data?.arrival_carrier?.type}`,
				'[airline_number]': res?.data?.data?.data?.arrival_trip_number ?? "-",
				'[arrival_date]': res?.data?.data?.arrival_date ? moment(res?.data?.data?.arrival_date).format(
					'DD/MM/YYYY'
				) : "-",
				'[arrival_time]': res?.data?.data?.arrival_date ? moment(res?.data?.data?.arrival_date).format('hh:mm a') : "-",
				'[operation_number]': '12345',
				'[departure_from]': res?.data?.data?.data?.arrival_hall?.name?.code ?? "-",
				'[airline_from]': 'Saudi AirLine',
				'[price]': res?.data?.data?.selling_cost ?? "-",
				'[tax]':
					res?.data?.data?.with_taxes === 1
						? res?.data?.data?.selling_cost * 0.15
						: 0,
				'[total_price]': res?.data?.data?.total_price,
				'[arrival_details]': arrivalData,
				'[hotels_details]': hotelsData?.join(''),
				'[catering_details]': cateringDetails.join(''),
				'[attraction_details]': attractionsDetails.join(''),
				'[transportation_details]': transportationData,
				'[visa_details]': visaDetails.join(''),
				'[other_services]': otherServices.join(''),
				'[transport_rows]': transportationList.join(''),
				'[footer]': res?.data?.company_footer_details || ""
			}

			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {

						size: A4;
						
						margin:15px;			
						border: 1px solid black;
						
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${repos}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	// Arrival Invoice
	const printArrivalInvoice = async (id) => {
		const res = await arrivalInvoice(id)
		if (res?.status === 200) {
			console.log({ res })
			let repos = ArrivalInvoices
			const arrivalData =
				res?.data?.data?.arrival !== null ?
					`
					<div class="arrivalAndDeparture">
						<div class="d-flex align-items-center">
							<p class="prgramType-text mr-2">Arrival To</p>
							<h3>${res?.data?.data?.arrival.arrival_to ? res?.data?.data?.arrival?.arrival_to : '-'}</h3>
						</div>

						<div class="d-flex">
							<div class="col-3 p-0">
								<div class="d-flex align-items-center">
									<p class="prgramType-text">AirLine:</p>
									<h3>${res?.data?.data?.arrival?.airline ? res?.data?.data?.arrival?.airline : '-'}</h3>
								</div>
							</div>

							<div class="col-3">
								<div class="d-flex align-items-center">
									<p class="prgramType-text">No.</p>
									<h3>${res?.data?.data?.arrival?.trip_number}</h3>
								</div>
							</div>

							<div class="col-3">
								<div class="d-flex align-items-center">
									<p class="prgramType-text">Date &ensp;</p>
									<h3>${res?.data?.data?.arrival?.date}</h3>
								</div>
							</div>

							<div class="col-3 d-flex justify-content-end">
								<div class="d-flex align-items-center">
									<p class="prgramType-text">Time &ensp;</p>
									<h3>${res?.data?.data?.arrival?.time}</h3>
								</div>
							</div>
							
						</div>
					</div>
					`
					: {}

			const depData =
				res?.data?.data?.departure?.length > 0 ? res?.data?.data?.departure?.map((ele) => {
					return `
						<div class="arrivalAndDeparture">
							<div class="d-flex align-items-center">
								<p class="prgramType-text mr-2">Departure From</p>
								<h3>${ele?.departure_to}</h3>
							</div>
							<div class="d-flex">
								<div class="col-3 p-0">
									<div class="d-flex align-items-center">
										<p class="prgramType-text">AirLine:</p>
										<h3>${ele?.airline}</h3>
									</div>
								</div>
								<div class="col-3">
									<div class="d-flex align-items-center">
										<p class="prgramType-text">No.</p>
										<h3>${ele?.trip_number}</h3>
									</div>
								</div>
								<div class="col-3">
									<div class="d-flex align-items-center">
										<p class="prgramType-text">Date &ensp;</p>
										<h3>${ele?.date}</h3>
									</div>
								</div>
								<div class="col-3 d-flex justify-content-end">
									<div class="d-flex align-items-center">
										<p class="prgramType-text">Time &ensp;</p>
										<h3>${ele?.time}</h3>
									</div>
								</div>
							</div>
						`
				}) : []

			const tablePricing = res?.data?.data?.pricing ?
				`
				<div class="arrival-container">
					<div class="arrivalAndDeparture">
						<h3 class="operation-invoice-heading">Pricing</h3>

						<table>
							<thead>
								<tr>
									<th>Type</th>
									<th>Count</th>
									<th>Price For Person</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Adult</td>
									<td>${res?.data?.adults} Adult</td>
									<td>${res?.data?.data?.pricing?.selling_adult_price}</td>
									<td style="font-weight:bold;">${res?.data?.data?.pricing?.selling_adult_price * res?.data?.adults}</td>
								</tr>

								<tr>
									<td>Child</td>
									<td>${res?.data?.children} Child</td>
									<td>${res?.data?.data?.pricing?.selling_child_price}</td>
									<td style="font-weight:bold;">${res?.data?.data?.pricing?.selling_child_price * res?.data?.children}</td>
								</tr>

								<tr>
									<td>Infant</td>
									<td>${res?.data?.infants} Infant</td>
									<td>${res?.data?.data?.pricing?.selling_infant_price}</td>
									<td style="font-weight:bold;">${res?.data?.data?.pricing?.selling_infant_price * res?.data?.infants}</td>
								</tr>

							</tbody>
						</table>
					</div>
				</div>
				`
				:
				''
			const statementType = statementTypeList.find((type) => type?.id === res?.data?.statement_type)

			const keyValuePair = {
				'[print_logo]': res?.data?.company_header_image ? `${imageUrl}${res?.data?.company_header_image}` : imagePlaceholderSrc,
				'[logo_position]': res?.data?.company_header_image_setting || "left",
				'[invoice_number]': res?.data?.id,
				'[invoice_date]': moment(res?.data?.created_at).format('DD/MM/YYYY'),
				'[agent_name]': res?.data?.agent?.name,
				'[statement_type]': statementType?.label,
				'[program_type_icon]': XIcon,
				'[program_type_name]': statementType?.label,
				'[user_icon]': UserIcon,
				'[number_of_people]': `${res?.data?.children + res?.data?.infants + res?.data?.adults
					} (${res?.data?.adults} Adult - ${res?.data?.children} Child - ${res?.data?.infants
					} Infant)`,
				'[file_icon]': CloseFile,
				'[open_folder_icon]': OpenFile,
				'[operation_no]':
					res?.data?.company_reference_number == null
						? 0
						: res?.data?.company_reference_number,
				'[file_number]':
					res?.data?.file_number === null ? 0 : res?.data?.file_number,
				'[valid_icon]': ValidIcon,
				'[travel_agent_name]': res?.data?.agent?.name,
				'[global_icon]': Global,
				'[country_name]': res?.data?.agent?.country?.name,
				'[phone_icon]': CallIcon,
				'[phone_number]': res?.data?.agent?.phone,
				'[mail_icon]': MailIcon,
				'[mail]': res?.data?.agent?.email,
				'[arrival_city]': `${res?.data?.arrival_port?.name} - ${res?.data?.arrival_port?.code}`,
				'[airline_name]': `${res?.data?.arrival_carrier?.name} - ${res?.data?.arrival_carrier?.type}`,
				'[airline_number]': res?.data?.data?.data?.arrival_trip_number,
				'[arrival_date]': moment(res?.data?.data?.arrival_date).format('DD/MM/YYYY'),
				'[arrival_time]': moment(res?.data?.data?.arrival_date).format('hh:mm'),
				'[operation_number]': '12345',
				'[departure_from]': res?.data?.data?.data?.arrival_hall?.name?.code,
				'[airline_from]': 'Saudi AirLine',
				'[price]': `${res?.data?.price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[tax]': `${res?.data?.taxs} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[total_price]': `${res?.data?.total_price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[arrival_data]': arrivalData,
				'[dep_data]': depData.join(''),
				'[pricing_details]': tablePricing,
				'[footer]': res?.data?.company_footer_details || ""
			}

			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {

						size: A4;
						
						margin:15px;			
						border: 1px solid black;
						
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${repos}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	// Hotel invoice
	const printHotelInvoice = async (id) => {
		const res = await hotelInvoice(id)
		if (res?.status === 200) {
			let tableData =
				res?.data?.data?.length > 0
					? res?.data?.data?.map((ele) => {
						return `
						<div class="arrival-container">
						<div class="arrivalAndDeparture">
							<div class="d-flex align-items-center">
								<p class="prgramType-text mr-2">${ele?.city_name}</p>
								<h3 style="font-weight:bold">${ele?.hotel?.name}</h3>
							</div>
							
							<div class="d-flex">
							<div class="col-3 p-0">
								<div class="d-flex align-items-center">
									<p class="prgramType-text mr-2">Nights&ensp;</p>
									<h3>${ele?.nights}</h3>
								</div>
							</div>
							<div class="col-3 p-0">
								<div class="d-flex align-items-center">
									<p class="prgramType-text">Meals&ensp;</p>
									<h3>${ele?.meal_type}</h3>
								</div>
							</div>
							<div class="col-3">
								<div class="d-flex align-items-center">
									<p class="prgramType-text">Check In&ensp;</p>
									<h3>${moment(ele?.check_in_date).format('DD/MM/YYYY')}</h3>
								</div>
							</div>
							<div class="col-3 d-flex justify-content-end">
								<div class="d-flex align-items-center">
									<p class="prgramType-text">Check Out&ensp;</p>
									<h3>${moment(ele?.check_out_date).format('DD/MM/YYYY')}</h3>
								</div>
							</div>

						
						</div>

						<table>
						<thead>
							<tr>
								<th>Rome Type</th>
								<th>X1</th>
								<th>X2</th>
								<th>X3</th>
								<th>X4</th>
								<th>X5</th>
								<th>X6</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Count</td>
								<td>${ele?.rooms_count?.single}</td>
								<td>${ele?.rooms_count?.double}</td>
								<td>${ele?.rooms_count?.triple}</td>
								<td>${ele?.rooms_count?.quadruple}</td>
								<td>${ele?.rooms_count?.quadruple}</td>
								<td>${ele?.rooms_count?.quadruple}</td>
							</tr>
							<tr>
								<td>Rate</td>
								${[...Array.from({ length: 6 })].map((_, index) => {
							const currentRoom = ele?.pricing?.find(
								(price) =>
									price?.room_type ===
									roomTypesLookup[index + 1]?.toLowerCase()
							)
							return `
											<td>${currentRoom?.selling_price || 0}</td>
										`
						}).join('')}
							</tr>
							<tr>
								<td>Total</td>
									${[...Array.from({ length: 6 })].map((_, index) => {
							const currentRoom = ele?.pricing?.find(
								(price) =>
									price?.room_type ===
									roomTypesLookup[index + 1]?.toLowerCase()
							)
							return `
												<td>${currentRoom?.total_selling_price || 0}</td>
											`
						}).join('')}
							</tr>
						</tbody>
					</table>
						</div>
						
						
						
					</div>
				`
					})
					: []
			console.log({ res })
			let repos = HotelInvoice
			const statementType = statementTypeList.find(
				(type) => type?.id === res?.data?.statement_type
			)
			const keyValuePair = {
				'[print_logo]': res?.data?.company_header_image ? `${imageUrl}${res?.data?.company_header_image}` : imagePlaceholderSrc,
				'[logo_position]': res?.data?.company_header_image_setting || "left",
				'[invoice_number]': res?.data?.id,
				'[invoice_date]': moment(res?.data?.created_at).format('DD/MM/YYYY'),
				'[agent_name]': res?.data?.agent?.name,
				'[statement_type]': statementType.label,
				'[program_type_icon]': XIcon,
				'[program_type_name]': statementType.label,
				'[user_icon]': UserIcon,
				'[number_of_people]': `${res?.data?.children + res?.data?.infants + res?.data?.adults
					} (${res?.data?.adults} Adult - ${res?.data?.children} Child - ${res?.data?.infants
					} Infant)`,
				'[file_icon]': CloseFile,
				'[open_folder_icon]': OpenFile,
				'[operation_no]':
					res?.data?.company_reference_number == null
						? 0
						: res?.data?.company_reference_number,
				'[valid_icon]': ValidIcon,
				'[travel_agent_name]': res?.data?.agent?.name,
				'[global_icon]': Global,
				'[country_name]': res?.data?.agent?.country?.name,
				'[phone_icon]': CallIcon,
				'[phone_number]': res?.data?.agent?.phone,
				'[mail_icon]': MailIcon,
				'[mail]': res?.data?.agent?.email,
				'[arrival_city]': 'Jeddah airport - Hall3',
				'[airline_name]': 'Saudi AirLine',
				'[airline_number]': 'SV2265',
				'[arrival_date]': '02/01/2024',
				'[arrival_time]': '16:20',
				'[operation_number]': res?.data?.company_reference_number ?? 0,
				'[file_number]': res?.data?.file_number ?? 0,
				'[departure_from]': 'Jeddah airport - Hall3',
				'[airline_from]': 'Saudi AirLine',
				'[price]': `${res?.data?.price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[tax]': `${res?.data?.taxs} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[total_price]': `${res?.data?.total_price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[hotel_details]': tableData?.join(''),
				'[footer]': res?.data?.company_footer_details || ""
			}

			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {

						size: A4;
						
						margin:15px;			
						border: 1px solid black;
						
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${repos}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	// Transportation invoice
	const printTransportationInvoice = async (id) => {
		const res = await transportationInvoice(id)
		if (res?.status === 200) {
			console.log({ res })
			const transportationList =
				res?.data?.data?.movements?.length > 0
					? res?.data?.data?.movements?.map((ele) => {
						return `
		
				<tr>
				<td>${ele?.movement_date}</td>
				<td>${ele?.movement_type ? ele?.movement_type.replace(/_/g, " ") : "-"}</td>
				<td>${ele?.pickup_points ? ele?.pickup_points : '-'}</td>
				<td>${ele?.destinations ? ele?.destinations : '-'}</td>

				<td>${ele?.pickup_time}</td>
			</tr>
				
			`
					})
					: []

			let tableData = `
					<div class="arrival-container">
					<div class="arrivalAndDeparture">
						
						
						<div class="d-flex">
						<div class="col-4 p-0">
							<div class="d-flex align-items-center">
								<h3>${res?.data?.data?.transporter_name}</h3>
							</div>
						</div>
						<div class="col-4 p-0">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">Cycle type&ensp;</p>
								<h3>${res?.data?.data?.cycle_type}</h3>
							</div>
						</div>
						<div class="col-2">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">No. of vehicle &ensp;</p>
								<h3>${res?.data?.data?.vehicles_count}</h3>
							</div>
						</div>
						<div class="col-2 d-flex justify-content-end">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">Model&ensp;</p>
								<h3>${res?.data?.data?.vehicles?.length > 0
					? res?.data?.data?.vehicles[0]?.vehicle_model
					: '-'
				}</h3>
							</div>
						</div>
					</div>

						<table>
						<thead>
							<tr>
								<th>Date</th>
								<th>Type</th>
								<th>Pickup Point</th>
								<th>Destination</th>
								<th>Pickup Time</th>
							</tr>
						</thead>
						<tbody>
							[transport_rows]
						</tbody>
					</table>
					</div>
				</div> 
			
			
			`
			let repos = TransportationInvoice
			const statementType = statementTypeList.find(
				(type) => type?.id === res?.data?.statement_type
			)
			const keyValuePair = {
				'[print_logo]': res?.data?.company_header_image ? `${imageUrl}${res?.data?.company_header_image}` : imagePlaceholderSrc,
				'[logo_position]': res?.data?.company_header_image_setting || "left",
				'[invoice_number]': res?.data?.id,
				'[invoice_date]': moment(res?.data?.created_at).format('DD/MM/YYYY'),
				'[agent_name]': res?.data?.agent?.name,
				'[statement_type]': statementType.label,
				'[program_type_icon]': XIcon,
				'[program_type_name]': statementType.label,
				'[user_icon]': UserIcon,
				'[number_of_people]': `${res?.data?.children + res?.data?.infants + res?.data?.adults
					} (${res?.data?.adults} Adult - ${res?.data?.children} Child - ${res?.data?.infants
					} Infant)`,
				'[file_icon]': CloseFile,
				'[open_folder_icon]': OpenFile,
				'[operation_no]':
					res?.data?.company_reference_number == null
						? 0
						: res?.data?.company_reference_number,
				'[file_number]': res?.data?.file_number ? res?.data?.file_number : 0,
				'[valid_icon]': ValidIcon,
				'[travel_agent_name]': res?.data?.agent?.name,
				'[global_icon]': Global,
				'[country_name]': res?.data?.agent?.country?.name,
				'[phone_icon]': CallIcon,
				'[phone_number]': res?.data?.agent?.phone,
				'[mail_icon]': MailIcon,
				'[mail]': res?.data?.agent?.email,
				'[arrival_city]': 'Jeddah airport - Hall3',
				'[airline_name]': 'Saudi AirLine',
				'[airline_number]': 'SV2265',
				'[arrival_date]': '02/01/2024',
				'[arrival_time]': '16:20',
				'[operation_number]': '12345',
				'[departure_from]': 'Jeddah airport - Hall3',
				'[airline_from]': 'Saudi AirLine',
				'[price]': `${res?.data?.price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[tax]': `${res?.data?.taxs} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[total_price]': `${res?.data?.total_price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[transportation_details]': tableData,
				'[transport_rows]': transportationList.join(''),
				'[footer]': res?.data?.company_footer_details || ""
			}

			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {

						size: A4;
						
						margin:15px;			
						border: 1px solid black;
						
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${repos}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	// Visa invoice
	const printVisaInvoice = async (id) => {
		const res = await visaInvoice(id)
		const visas = res?.data
		console.log({ res })
		if (res?.status === 200) {
			const tableData =
				visas?.data?.length > 0
					? visas?.data?.map((visa) => {
						return `
					<div class="arrival-container">
							<div class="arrivalAndDeparture">
								<div class="d-flex align-items-center">
									<p class="prgramType-text">Visa Type &ensp;</p>
									<h3>${visa?.visa_type}</h3>
								</div>

								<div class="d-flex">
									<div class="col-6 p-0">
										<div class="d-flex align-items-center">
											<p class="prgramType-text">Issued By&ensp;</p>
											<h3>${visa?.issued_by ? visa?.issued_by : '-'}</h3>
										</div>
									</div>
								
									<div class="col-6 d-flex justify-content-end">
										<div class="d-flex align-items-center">
											<p class="prgramType-text">Pax &ensp;</p>
											<h3>${visa?.adults} Adult(s)  |  ${visa?.children} child / children  |  ${visa?.infants
							} infant</h3>
										</div>
									</div>
								</div>
							</div>
					  </div> 

						<div class="arrival-container">
							<div class="arrivalAndDeparture">
								<h3 class="operation-invoice-heading">Cost Per PAX</h3>
								<table>
								<thead>
									<tr>
										<th>Count</th>
										<th>Price</th>
										<th>Total</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>${visa?.total_count}</td>
										<td>${visa?.pricing === null ? 0 : visa?.pricing?.selling_price}</td>
										<td>${visa?.total_price}</td>
									</tr>
								</tbody>
							</table>
							</div>
						</div>
				`
					})
					: []

			let repos = VisaInvoice
			const keyValuePair = {
				'[print_logo]': res?.data?.company_header_image ? `${imageUrl}${res?.data?.company_header_image}` : imagePlaceholderSrc,
				'[logo_position]': res?.data?.company_header_image_setting || "left",
				'[invoice_number]': visas?.id,
				'[invoice_date]': moment(visas?.created_at).format('DD/MM/YYYY'),
				'[agent_name]': visas?.agent?.name,
				'[program_type_icon]': XIcon,
				'[program_type_name]': visas?.statement_type,
				'[user_icon]': UserIcon,
				'[number_of_people]': `${visas.children + visas.infants + visas.adults
					} (${visas.adults} Adult - ${visas.children} Child - ${visas.infants
					} Infant)`,
				'[file_icon]': CloseFile,
				'[file_no]': visas?.file_number ?? 0,
				'[open_folder_icon]': OpenFile,
				'[operation_no]':
					visas?.company_reference_number === null
						? 0
						: visas?.company_reference_number,
				'[valid_icon]': ValidIcon,
				'[travel_agent_name]': visas?.agent?.name,
				'[global_icon]': Global,
				'[country_name]': visas?.agent?.country?.name,
				'[phone_icon]': CallIcon,
				'[phone_number]': visas?.agent?.phone,
				'[mail_icon]': MailIcon,
				'[mail]': visas?.agent?.email,
				'[arrival_city]': 'Jeddah airport - Hall3',
				'[airline_name]': 'Saudi AirLine',
				'[airline_number]': 'SV2265',
				'[arrival_date]': '02/01/2024',
				'[arrival_time]': '16:20',
				'[operation_number]': visas?.company_reference_number === null ? 0 : visas?.company_reference_number,
				'[file_number]': visas?.file_number,
				'[departure_from]': 'Jeddah airport - Hall3',
				'[airline_from]': 'Saudi AirLine',
				'[price]': `${res?.data?.price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[tax]': `${res?.data?.taxs} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[total_price]': `${res?.data?.total_price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[visa_details]': tableData?.join(''),
				'[footer]': res?.data?.company_footer_details || ""
			}

			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
			 			<html>
			 			<head>
			 			<title> print Invoice</title>
			 			<style>
			 			@page {

			 				size: A4;

			 				margin:15px;			
			 				border: 1px solid black;

			 				}
			 			</style>
			 			</head>
			 			<body onLoad="{()=>window.print()}">${repos}</body>
			 			</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	// Catering Invoice
	const printCateringInvoice = async (id) => {
		const res = await cateringInvoice(id)
		if (res?.status === 200) {

			let tableData
			tableData =
				res?.data?.data?.length > 0
					? res?.data?.data?.map((ele) => {
						return `
				<div class="arrival-container">
				<div class="arrivalAndDeparture">
					<div class="d-flex align-items-center">
						 <h3>${ele?.hotel?.name}</h3>
					</div>

					<div class="d-flex">
						<div class="col-3 p-0">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">City&ensp;</p>
								<h3>${ele?.city_id?.name}</h3>
							</div>
						</div>
					
						<div class="col-3 d-flex">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">Nights &ensp;</p>
								<h3>${ele?.nights}</h3>
							</div>
						</div>
					</div>


					${ele?.catering?.map((data) => {
							return `
				<table>
					<thead>
						<tr>
							<th width="25%">Supplier</th>
							<th width="30%">Meals</th>
							<th width="15%">Pax</th>
							<th width="15%">Price</th>
							<th width="15%">Total</th>
						</tr>
					</thead>
					<tbody>
					<tr>
					<td>${data?.supplier_name}</td>
					<td>${data?.meal_type}</td>
					<td>${data?.pax}</td>
					<td>${data?.pricing === null ? 0 : data?.pricing?.selling_price}</td>
					<td>${data?.pricing === null ? 0 : data?.pricing?.total_selling_price}</td>
				</tr>
					</tbody>
				</table>
							
							`
						}).join('')}

				</div>
			</div> 
				
				`
					})
					: []
			let repos = CateringInvoice
			const statementType = statementTypeList.find(
				(type) => type?.id === res?.data?.statement_type
			)
			const keyValuePair = {
				'[print_logo]': res?.data?.company_header_image ? `${imageUrl}${res?.data?.company_header_image}` : imagePlaceholderSrc,
				'[logo_position]': res?.data?.company_header_image_setting || "left",
				'[invoice_number]': res?.data?.id,
				'[invoice_date]': moment(res?.data?.created_at).format('DD/MM/YYYY'),
				'[agent_name]': res?.data?.agent?.name,
				'[statement_type]': statementType.label,
				'[program_type_icon]': XIcon,
				'[program_type_name]': statementType.label,
				'[user_icon]': UserIcon,
				'[number_of_people]': `${res?.data?.children + res?.data?.infants + res?.data?.adults
					} (${res?.data?.adults} Adult - ${res?.data?.children} Child - ${res?.data?.infants
					} Infant)`,
				'[file_icon]': CloseFile,
				'[open_folder_icon]': OpenFile,
				'[operation_no]': res?.data?.company_reference_number == null ? 0 : res?.data?.company_reference_number,
				'[file_number]': res?.data?.file_number ?? 0,
				'[valid_icon]': ValidIcon,
				'[travel_agent_name]': res?.data?.agent?.name,
				'[global_icon]': Global,
				'[country_name]': res?.data?.agent?.country?.name,
				'[phone_icon]': CallIcon,
				'[phone_number]': res?.data?.agent?.phone,
				'[mail_icon]': MailIcon,
				'[mail]': res?.data?.agent?.email,
				'[arrival_city]': 'Jeddah airport - Hall3',
				'[airline_name]': 'Saudi AirLine',
				'[airline_number]': 'SV2265',
				'[arrival_date]': '02/01/2024',
				'[arrival_time]': '16:20',
				'[operation_number]': '12345',
				'[departure_from]': 'Jeddah airport - Hall3',
				'[airline_from]': 'Saudi AirLine',
				'[price]': `${res?.data?.price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[tax]': `${res?.data?.taxs} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[total_price]': `${res?.data?.total_price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[catering_details]': tableData.join(''),
				'[footer]': res?.data?.company_footer_details || ""
			}

			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {

						size: A4;
						
						margin:15px;			
						border: 1px solid black;
						
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${repos}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	// Attractions Invoice
	const printAttractionsInvoice = async (id) => {
		const res = await attractionInvoice(id)
		if (res?.status === 200) {
			const attractionsDetails =
				res?.data?.data?.length > 0
					? res?.data?.data?.map((ele) => {
						return `
				<div class="arrivalAndDeparture">
					<div class="d-flex align-items-center">
						<h3>${ele?.attraction?.name}</h3>
					</div>
					<div class="d-flex">
						<div class="col-4 p-0">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">City &ensp;</p>
								<h3>${citiesLookup.find((city) => city?.id === ele?.city_id).name}</h3>
							</div>
						</div>
						<div class="col-5">
							<div class="d-flex align-items-center">
								<p class="prgramType-text">Date &ensp;</p>
								<h3>${moment(ele?.date_time).format('DD/MM/YYYY')} - ${moment(
							ele?.date_time
						).format('hh:mm')}</h3>
							</div>
						</div>
					</div>
				</div>
				<hr
					style="
						background-color: #e2e8f0;
						width: 95%;
						display: block;
						margin: 15px auto;
						opacity: 0.2;
					"
				/>
				
				`
					})
					: []
			let repos = AttractionsInvoice
			const statementType = statementTypeList.find(
				(type) => type?.id === res?.data?.statement_type
			)
			const keyValuePair = {
				'[print_logo]': res?.data?.company_header_image ? `${imageUrl}${res?.data?.company_header_image}` : imagePlaceholderSrc,
				'[logo_position]': res?.data?.company_header_image_setting || "left",
				'[invoice_number]': res?.data?.id,
				'[invoice_date]': moment(res?.data?.created_at).format('DD/MM/YYYY'),
				'[agent_name]': res?.data?.agent?.name,
				'[statement_type]': statementType.label,
				'[program_type_icon]': XIcon,
				'[program_type_name]': statementType.label,
				'[user_icon]': UserIcon,
				'[number_of_people]': `${res?.data?.children + res?.data?.infants + res?.data?.adults
					} (${res?.data?.adults} Adult - ${res?.data?.children} Child - ${res?.data?.infants
					} Infant)`,
				'[file_icon]': CloseFile,
				'[open_folder_icon]': OpenFile,
				'[operation_no]':
					res?.data?.company_reference_number == null
						? 0
						: res?.data?.company_reference_number,
				'[file_number]': res?.data?.file_number ? res?.data?.file_number : 0,
				'[valid_icon]': ValidIcon,
				'[travel_agent_name]': res?.data?.agent?.name,
				'[global_icon]': Global,
				'[country_name]': res?.data?.agent?.country?.name,
				'[phone_icon]': CallIcon,
				'[phone_number]': res?.data?.agent?.phone,
				'[mail_icon]': MailIcon,
				'[mail]': res?.data?.agent?.email,
				'[arrival_city]': 'Jeddah airport - Hall3',
				'[airline_name]': 'Saudi AirLine',
				'[airline_number]': 'SV2265',
				'[arrival_date]': '02/01/2024',
				'[arrival_time]': '16:20',
				'[operation_number]': res?.data?.company_reference_number ? res?.data?.company_reference_number : 0,
				'[departure_from]': 'Jeddah airport - Hall3',
				'[airline_from]': 'Saudi AirLine',
				'[price]': `${res?.data?.price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[tax]': `${res?.data?.taxs} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[total_price]': `${res?.data?.total_price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[attractions_details]': attractionsDetails?.join(''),
				'[footer]': res?.data?.company_footer_details || ""
			}

			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {

						size: A4;
						
						margin:15px;			
						border: 1px solid black;
						
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${repos}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	// Othe rServices Invoice
	const printOtherServicesInvoice = async (id) => {
		const res = await otherServicesInvoice(id)
		console.log({ res })
		if (res?.status === 200) {
			const otherServicesDetails =
				res?.data?.data?.length > 0
					? res?.data?.data.map((ele) => {
						return `
				<div class="arrival-container">
				<div class="arrivalAndDeparture">
						<table>
						<thead>
							<tr>
								<th>Service Name</th>
								<th>Pax</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="font-weight-bold">${ele?.name}</td>
								<td>${ele?.pax}</td>
							</tr>
							
						</tbody>
					</table>

				</div>
			</div>
				
				`
					})
					: []

			let repos = OtherServicesInvoice
			const statementType = statementTypeList.find(
				(type) => type?.id === res?.data?.statement_type
			)
			const keyValuePair = {
				'[print_logo]': res?.data?.company_header_image ? `${imageUrl}${res?.data?.company_header_image}` : imagePlaceholderSrc,
				'[logo_position]': res?.data?.company_header_image_setting || "left",
				'[invoice_number]': res?.data?.id,
				'[invoice_date]': moment(res?.data?.created_at).format('DD/MM/YYYY'),
				'[agent_name]': res?.data?.agent?.name,
				'[statement_type]': statementType.label,
				'[program_type_icon]': XIcon,
				'[program_type_name]': statementType.label,
				'[user_icon]': UserIcon,
				'[number_of_people]': `${res?.data?.children + res?.data?.infants + res?.data?.adults
					} (${res?.data?.adults} Adult - ${res?.data?.children} Child - ${res?.data?.infants
					} Infant)`,
				'[file_icon]': CloseFile,
				'[open_folder_icon]': OpenFile,
				'[operation_no]':
					res?.data?.company_reference_number == null
						? 0
						: res?.data?.company_reference_number,
				'[file_number]':
					res?.data?.file_number === null ? 0 : res?.data?.file_number,
				'[valid_icon]': ValidIcon,
				'[travel_agent_name]': res?.data?.agent?.name,
				'[global_icon]': Global,
				'[country_name]': res?.data?.agent?.country?.name,
				'[phone_icon]': CallIcon,
				'[phone_number]': res?.data?.agent?.phone,
				'[mail_icon]': MailIcon,
				'[mail]': res?.data?.agent?.responsible_email,
				'[arrival_city]': 'Jeddah airport - Hall3',
				'[airline_name]': 'Saudi AirLine',
				'[airline_number]': 'SV2265',
				'[arrival_date]': '02/01/2024',
				'[arrival_time]': '16:20',
				'[reference_number]': res?.data?.reference_number,
				'[departure_from]': 'Jeddah airport - Hall3',
				'[airline_from]': 'Saudi AirLine',
				'[price]': `${res?.data?.price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[tax]': `${res?.data?.taxs} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[total_price]': `${res?.data?.total_price} ${res?.data?.currency ? res?.data?.currency : ''}`,
				'[other_service]': otherServicesDetails.join(''),
				'[footer]': res?.data?.company_footer_details || ""
			}

			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {

						size: A4;
						
						margin:15px;			
						border: 1px solid black;
						
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${repos}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	// Operation Statement Invoice
	const printOperationStatementInvoice = async (id) => {
		debugger
		const res = await fetchOperationStatementInvoice(id)
		if (res?.status === 200) {
			let repos = OperationStatement
			console.log("resssss", res);
			const data = res?.data?.data
			const transportation =
				data?.transportation?.length > 0
					? data.transportation.map(
						(item) =>
							`<tr>
								<td class="txt-bold">${item?.movement_type}</td>
								<td>${item?.count}</td>
								<td>${item?.from}</td>
								<td>${item?.to}</td>
								<td>${item?.movement_date ? moment(item?.movement_date).format("DD/MM/YYYY hh:mm A") : "-"}</td>
							</tr>`
					)
					: []

			const flightArrival = data?.trip?.arrival
				? `
			<tr>
			<td class="txt-bold">Arrival</td>
			<td>${data?.trip?.arrival?.air_line}</td>
			<td>${data?.trip?.arrival?.trip_number ?? "-"}</td>
			<td>${data?.trip?.arrival?.date ? moment(data?.trip?.arrival?.date).format("DD/MM/YYYY hh:mm A") : "-"}</td>
			<td>${data?.trip?.arrival?.port}</td>
		</tr>
			`
				: ''
			const flightDeparture =
				data?.trip?.departures?.length > 0
					? data.trip.departures.map(
						(item) =>
							`<tr>
								<td class="txt-bold">Departure</td>
								<td>${item?.air_line}</td>
								<td>${item?.trip_number ?? "-"}</td>
								<td>${item?.date ? moment(item?.date).format("DD/MM/YYYY hh:mm A") : "-"}</td>
								<td>${item?.port}</td>
							</tr>`
					)
					: []
			const numOfRoomMakkahHotel = data?.makkah_hotel?.rooms_count
				? Object.values(data?.makkah_hotel?.rooms_count).reduce(function (
					accumulator,
					currentValue
				) {
					return accumulator + currentValue
				},
					0)
				: '-'
			const makkahHotel =
				Object.keys(data?.makkah_hotel).length > 0
					? `
			<tr>
			<td class="txt-bold">Makkah</td>
			<td>${data?.makkah_hotel?.hotel?.name}</td>
			<td>${numOfRoomMakkahHotel}</td>
			<td>${data?.makkah_hotel?.nights}</td>
			<td>${data?.makkah_hotel?.check_in_date
						? moment(data?.makkah_hotel?.check_in_date).format('DD/MM/YYYY')
						: '-'
					}</td>
			<td>${data?.makkah_hotel?.check_out_date
						? moment(data?.makkah_hotel?.check_out_date).format('DD/MM/YYYY')
						: '-'
					}</td>

		</tr>
			`: ''
			const numOfRoomMadinaHotel = data?.madinah_hotel?.rooms_count
				? Object.values(data?.madinah_hotel?.rooms_count).reduce(function (
					accumulator,
					currentValue
				) {
					return accumulator + currentValue
				},
					0)
				: '-'
			const madinahHotel =
				Object.keys(data?.madinah_hotel).length > 0
					? `
				<tr>
				<td  class="txt-bold">Madinah</td>
				<td>${data?.madinah_hotel?.hotel?.name}</td>
				<td>${numOfRoomMadinaHotel}</td>
				<td>${data?.madinah_hotel?.nights}</td>
				<td>${data?.madinah_hotel?.check_in_date
						? moment(data?.madinah_hotel?.check_in_date).format('DD/MM/YYYY')
						: '-'
					}</td>
				<td>${data?.madinah_hotel?.check_out_date
						? moment(data?.madinah_hotel?.check_out_date).format('DD/MM/YYYY')
						: '-'
					}</td>
	
			</tr>
				`
					: '';
			const rawdaReservation = data?.rawda_reservation_date ?
				`	<tr>
			<td>${moment(data?.rawda_reservation_date).format('DD/MM/YYYY')}</td>
			<td>${data?.rawda_males_count}</td>
			<td>${data?.rawda_females_count}</td>

			</tr>`: "-"
			const keyValuePair = {
				'[print_logo]': data?.company_header_image ? `${imageUrl}${data?.company_header_image}` : imagePlaceholderSrc,
				'[logo_position]': data?.company_header_image_setting || "left",
				'[invoice_number]': data?.company_reference_number ?? '-',
				'[agent_name]': data?.agent?.name ?? '-',
				'[agent_phone]': ` ${data?.agent?.phone_code} ${data?.agent?.phone}`,
				'[agent_email]': data?.agent?.email ?? '-',
				'[agent_country]': data?.agent?.country?.name ?? '-',
				'[date_invoice]': data?.created_at
					? moment(data?.created_at).format('DD/MM/YYYY')
					: '-',
				'[program_type_icon]': XIcon,
				'[program_type_name]': 'Umrah',
				'[user_icon]': UserIcon,
				'[number_of_people]': `${+data?.adults + +data?.children + +data?.infants
					} (${data?.adults} Adult - ${data?.children} Child - ${data?.infants
					} Infant)`,
				'[file_icon]': CloseFile,
				'[file_no]': data?.file_number ?? '-',
				'[open_folder_icon]': OpenFile,
				'[operation_no]': data?.company_reference_number ?? '-',
				'[valid_icon]': ValidIcon,
				'[global_icon]': Global,
				'[phone_icon]': CallIcon,
				'[mail_icon]': MailIcon,
				'[makkahHotel]': makkahHotel,
				'[madinahHotel]': madinahHotel,
				'[transportation]': transportation?.join(''),
				'[flightDeparture]': flightDeparture?.join(''),
				'[flightArrival]': flightArrival,
				'[company_name]': data?.company_name,
				'[rawdaRes]': rawdaReservation,
				'[footer]': data?.company_footer_details || ""
			}
			for (const key in keyValuePair) {
				repos = repos.replace(key, keyValuePair[key])
			}

			var popupWin = window.open('', ' ', 'top=0,left=0,height=100%,width=auto')
			popupWin.document.open()
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>
					@page {
						size: A4;
						margin:15px;			
						border: 1px solid black;
						}
						@media print {
							.footer{
								position: absolute;
								bottom: 0;
								width: 100%;
								height: auto;
								padding: 8px;
							}
											
						}
					</style>
					</head>
					<body onLoad="{()=>window.print()}">${repos}</body>
					</html>`)
			popupWin.document.close()

			setTimeout(() => {
				popupWin.print()
			}, 100)
		}
	}

	return {
		printFullPackageInvoice,
		printArrivalInvoice,
		printHotelInvoice,
		printTransportationInvoice,
		printVisaInvoice,
		printCateringInvoice,
		printAttractionsInvoice,
		printOtherServicesInvoice,
		printOperationStatementInvoice
	}
}

export default usePrintingInvoices
