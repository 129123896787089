export default function B2CView() {
  return (
    <div className='b2c_hajj view'>
      <div className='image-cards'>
        <div className='image-card'>
          <img className='image-card-img' src='https://via.placeholder.com/150' alt='placeholder' />
        </div>
        <div className='image-card'>
          <img className='image-card-img' src='https://via.placeholder.com/150' alt='placeholder' />
        </div>
      </div>

      <div className='info'>
        <div className='info-cards'>
          <div className='info-card package-info'>
            <h2 className='info-card-title'>Package Info</h2>
            <div className='info-card-body'>
              <div className='info-card-item'>
                <label className='info-card-label'>Hajj Rate</label>
                <span className='info-card-value'>Standard</span>
              </div>
            </div>
          </div>

          <div className='info-card contact-info'>
            <h2 className='info-card-title'>Contact Info</h2>
            <div className='info-card-body'>
              <div className='info-card-item'>
                <label className='info-card-label'>Phone</label>
                <span className='info-card-value'>+916 123456789</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Email</label>
                <span className='info-card-value'>name@domain.com</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Country</label>
                <span className='info-card-value'>USA</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>City</label>
                <span className='info-card-value'>City Name</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Address</label>
                <span className='info-card-value'>15 Main St. From other street seventh zoon </span>
              </div>
            </div>
          </div>

          <div className='info-card passport-info'>
            <h2 className='info-card-title'>Passport Info</h2>
            <div className='info-card-body'>
              <div className='info-card-item'>
                <label className='info-card-label'>Name</label>
                <span className='info-card-value'>Ahmed Mohamed Ibrahim Ali</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Gender</label>
                <span className='info-card-value'>Male</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Passport No.</label>
                <span className='info-card-value'>12345677893212342</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Passport Type</label>
                <span className='info-card-value'>Type Name</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Nationality</label>
                <span className='info-card-value'>USA</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Birthday Country</label>
                <span className='info-card-value'>Egypt</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Issue Country</label>
                <span className='info-card-value'>USA</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Issue Place</label>
                <span className='info-card-value'>17</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Birth Place</label>
                <span className='info-card-value'>USA</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Birthday</label>
                <span className='info-card-value'>15-11-2020</span>
              </div>

              <div className='info-card-item'>
                <label className='info-card-label'>Issue Date</label>
                <span className='info-card-value'>12-03-2024</span>
              </div>
             
              <div className='info-card-item'>
                <label className='info-card-label'>Expiry Date</label>
                <span className='info-card-value'>12-03-2026</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}