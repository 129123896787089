import TextField from 'components/Form/TextField/TextField'
import React, { useState } from 'react'
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";
import { ReactComponent as SearchIcon } from "assets/images/umrah-operations/search-icon.svg";
import Locale from 'translations';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import moment from 'moment';
import SelectField from 'components/Form/SelectField/SelectField';

export default function DelegationManagementFilter({ filters, getOfficersList, officersList, handleFiltersChanges, getDelegationsList, resetFilters }) {
  const { marketPlace, payment, productElements, } = Locale;

  const todayDate = moment().format("YYYY-MM-DD")
  const yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD")
  const tomorrowDate = moment().add(1, 'days').format("YYYY-MM-DD")

  const [active, setActive] = useState()


  return (
    <div className="row mx-0 align-items-center">
      {/* search */}
      <div className="col-md-2 col-12">
        <TextField
          hasLabel={false}
          type="text"
          name="delegation-search"
          id="delegation-search"
          placeholder={Locale.movementManagement.search}
          isImage={true}
          image={SeachIcon}
          prependImage={true}
          value={filters.search}
          onChange={(e) => handleFiltersChanges({ key: "search", value: e.target.value })}
        />
      </div>
      {/* deleageation date */}
      <div className="col-md-2 col-12">
        <DatePickerField
          hasLabel={false}
          name="delegation-date"
          id="delegation-date"
          placeholder="DD/MM/YYYY"
          date={filters.date}
          onChangeDate={(filterDate) => handleFiltersChanges({ key: "date", value: filterDate })}
          isOutsideRange={() => false}
        />
      </div>

      <div className='col-md-3 h-100 quick-date-search'>
        <span
          className={`${active === 'yesterday' && 'active-date'}`}
          onClick={() => {
            getDelegationsList({ date: yesterdayDate })
            setActive('yesterday')
          }}
        >
          {productElements?.yesterday}
        </span>
        <span
          className={`${active === 'today' && 'active-date'}`}
          onClick={() => {
            getDelegationsList({ date: todayDate })
            setActive('today')
          }}
        >
          {productElements?.today}
        </span>
        <span
          className={`${active === 'tomorrow' && 'active-date'}`}
          onClick={() => {
            getDelegationsList({ date: tomorrowDate })
            setActive('tomorrow')
          }}
        >
          {productElements?.tommorow}
        </span>
        
      </div>

      <div className="col-md-2 h-100">
        <SelectField
          hasLabel={false}
          id="delegate_name"
          name="delegate_name"
          placeholder={productElements.delegate_name}
          value={filters?.officer?.name}
          onChange={(e) => {
            console.log(e)
            // getOfficersList()
            handleFiltersChanges({ key: "officer", value: e })
          }}
          searchable={true}
          options={officersList}
        />
      </div>
  
      {/* actions buttons */}
      <div className="col-3 d-flex justify-content-end mb-1">
        <button className="btn bg-nxt search-btn mt-0"
          onClick={() => {
            console.log(filters?.officer?.id)
            let formatedFitlers = {
              // ...filters,
              search: filters?.search,
              date: filters?.date ? moment(filters?.date).format("YYYY-MM-DD") : null,
              officer_id: filters?.officer?.id
            }
            // console.log(formatedFitlers)
            getDelegationsList(formatedFitlers)
          }}
        >
          <SearchIcon />
          <span className='mx-2'>{marketPlace.messages.search}</span>
        </button>

        <button
          className="btn reset-btn mx-2 mt-0"
          onClick={() => {
            resetFilters()
            setActive(null)
          }}
        >
          <ResetFilterIcon />
          <span className='mx-2'>{payment.messages.clear}</span>
        </button>
      </div>
    </div>
  )
}
