import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import React, { useState } from "react";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
// import { ServiceCardDetails } from "modules/WebBuilder-V2/shared/ServiceCardDetails";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "../../../interfaces/blockTypes";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import DragAndDropContainer from "modules/WebBuilder-V2/shared/DragAndDropContainer";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import Locale from 'translations';
import OtherCardDefault from './OtherCardDefault';

const transferBlocksType = "transferBlocksType";

export default function OtherServiceSection({ details, onFocus, focusContainer }) {
	const { id, type, body, title, items } = details;
	const { selectedLanguage } = useWebBuilderState();

	const dispatch = useWebBuilderDispatch();
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const { webBuilder } = Locale;

	const handleOpenAddModal = () => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null)
	};
	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};

	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};
	const addItemToOtherService = (item) => {
		updateItem
			? dispatch({
				type: "editItemInContainer",
				payload: {
					id,
					item,
					itemIndex: updateItem.index,
				},
			})
			: dispatch({
				type: "addItemToContainer",
				payload: {
					id,
					item,
				},
			});
		setUpdateItem(null);
	};
	const updateOtherService = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language
			},
		});
	};

	const sortOtherServiceItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};
	const selectedIds = items ? items.map((i) => i.id) : [];
	return (
		<EditBox editMode={focusContainer === type}>
			<div
				onClick={() => onFocus(type)}
				className="web-builder-content-other"
				id={id}
			>
				<div className="container">
					<div className="service-top-section">
						<ServicesContent
							header={title}
							body={body}
							changeHeader={(value) => updateOtherService({ value, name: "title", language: selectedLanguage })}
							changebody={(value) => updateOtherService({ value, name: "body", language: selectedLanguage })}
						/>
						<AddElement
							onClick={handleOpenAddModal}
							title={webBuilder.addMoreOther}
						/>
					</div>

					<div className="otherservice-cards-container">
						{items.map((item, index) => (
							<DragAndDropContainer
								accept={transferBlocksType}
								type={transferBlocksType}
								id={item.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortOtherServiceItems({ dragIndex, hoverIndex })
								}
								// onDrop={}
								key={item.id}
							>
								<EditBox
									editMode={focusContainer === type}
									actions={[
										...editItem(item, index),
										...itemsBoxActions(item.id),
									]}
								>
									<OtherCardDefault {...item} openEditModal={openEditModal} />
								</EditBox>
							</DragAndDropContainer>
						))}
					</div>
				</div>
				{openAdd && <AddService
					handleModalState={handleOpenAddModal}
					open={openAdd}
					item_id={8}
					label={"Service"}
					header={"Other Service"}
					onAdd={addItemToOtherService}
					details={updateItem}
					selectedIds={selectedIds}
				/>}
			</div>
		</EditBox>
	);
}
