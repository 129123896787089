import { roomTypesLookup } from 'modules/products-builder/Requestes/RequestView/helpers/localLookups';

export const sharedPaxRowObj = {
  roomType: null,
  roomCategory: null,
  roomMeal: null,
  adultsCount: 0,
  childrenCount: 0,
  price: 0
}


export function generateHotelsPax({ hotelsList, reservedRooms }) {
  let hotels = hotelsList.map(hotel => {
    let hotelId = hotel?.itemable?.id;
    return {
      hotelName: hotel?.itemable?.hotel?.name,
      hotelId,
      pax: reservedRooms && reservedRooms?.length > 0
        ?
        [...reservedRooms?.filter(room => room?.product_hotel_id === hotelId)].map(room => {
          return {
            roomType: { id: room?.room_type, name: roomTypesLookup?.[room?.room_type], label: roomTypesLookup?.[room?.room_type],  },
            roomCategory: { id: room?.room_category, name: room?.room_category, label: room?.room_category },
            roomMeal: { id: room?.meal_type, name: room?.meal_type, label: room?.meal_type },
            adultsCount: room?.adults_count,
            childrenCount: room?.children_count,
            price: 0
          }
        })
        :
        [sharedPaxRowObj],
    }
  });
  return hotels;
}

export function generateHotelsRoomTypesWithCategories({ hotelsList, roomsAvailabilities, category }) {
  let distinctRoomsTypes = [...new Set(roomsAvailabilities?.filter(room => room?.room_remaining_availability > 0 && (category === "shared" && room?.room_type !== 1))?.map(room => room?.room_type))].map(room => {
    return {
      id: room,
      name: roomTypesLookup?.[room],
      value: room,
      label: roomTypesLookup?.[room],
      room_type: room
    }
  });

  let hotels = hotelsList.map(hotel => {
    let hotelId = hotel?.itemable?.id;
    return {
      hotelName: hotel?.itemable?.hotel?.name,
      hotelId,
      rooms: distinctRoomsTypes?.map(availableRoom => {
        let roomTypeCategories = roomsAvailabilities?.filter(room => room?.room_type === availableRoom?.room_type && room?.product_hotel_id === hotelId);
        return {
          label: roomTypesLookup?.[availableRoom?.room_type],
          name: roomTypesLookup?.[availableRoom?.room_type],
          id: availableRoom?.room_type,
          value: availableRoom?.room_type,

          categories: roomTypeCategories?.map(roomCtegory => ({
            label: roomCtegory?.room_category,
            name: roomCtegory?.room_category,
            id: roomCtegory?.room_category,
            value: roomCtegory?.room_category,


            meals: roomTypeCategories.filter(room => room?.room_category === roomCtegory?.room_category).map(roomMeal => ({
              label: roomMeal?.meal_type,
              name: roomMeal?.meal_type,
              id: roomMeal?.meal_type,
              value: roomMeal?.meal_type,
              room_count: Math.floor(roomCtegory?.room_remaining_availability / availableRoom?.room_type),
            }))
          })),
        }
      })
    }
  })
  return hotels;
}


// function formatSharedRoomsPayload(PackagesDetails) {
//   let rooms_availability = [];
//   let totalHotels = PackagesDetails?.roomsData?.length;
//   let firstHotelId = PackagesDetails?.roomsData?.[0]?.hotelId;
//   let matchedRoomCombinations = [];
//   let b2bCombinations = PackagesDetails?.reservationDetails?.product_price_combinations?.filter(p => p?.pricing_for === "b2b");

//   // create Room Objects based on adults and children in each room
//   PackagesDetails?.roomsData?.forEach(hotel => {
//     hotel?.pax?.forEach(paxItem => {
//       if (+paxItem?.adultsCount > 0) {
//         [...Array.from({ length: +paxItem?.adultsCount })].forEach(() => rooms_availability.push(
//           {
//             isAdult: true,
//             hotelId: hotel?.hotelId,
//             roomType: paxItem?.roomType?.id,
//             roomCategory: paxItem?.roomCategory?.label,
//             roomMeal: paxItem?.roomMeal?.label,
//           }
//         ))
//       }
//       if (+paxItem?.childrenCount > 0) {
//         [...Array.from({ length: +paxItem?.childrenCount })].forEach(() => rooms_availability.push(
//           {
//             isAdult: false,
//             hotelId: hotel?.hotelId,
//             roomType: paxItem?.roomType?.id,
//             roomCategory: paxItem?.roomCategory?.label,
//             roomMeal: paxItem?.roomMeal?.label,
//           }
//         ))
//       }
//     })
//   })

//   // get combination id for each room based on hotel, room type, room category, room meal
//   b2bCombinations?.forEach(allCombinations => {
//     let roomsMatchCombination = [];
//     allCombinations?.combinations?.forEach((combination, index) => {
//       let matchedRooms = rooms_availability?.filter(r =>
//         r.hotelId === combination?.product_hotel_id &&
//         r?.roomType === combination?.room_type &&
//         r?.roomCategory === combination?.room_category &&
//         r?.roomMeal === combination?.meal_type
//       )
//       if (matchedRooms.length > 0) {
//         matchedRooms = matchedRooms.map(room => ({
//           ...room,
//           combination_id: allCombinations?.id
//         }))
//         roomsMatchCombination.push(matchedRooms)
//       }

//       if (roomsMatchCombination.length === totalHotels) {
//         matchedRoomCombinations.push(roomsMatchCombination.flat(2))
//       }
//       if (index + 1 === totalHotels) {
//         roomsMatchCombination = []
//       };
//     })
//   })
//   // map rooms
//   return matchedRoomCombinations.flat(1).filter(hotel => hotel?.hotelId === firstHotelId).map(room => ({
//     combination_id: room?.combination_id,
//     is_adult: room?.isAdult ? 1 : 0
//   }))
// }