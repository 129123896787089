import PassportsHeader from "modules/UmrahOperations/OperationStatement/View/OperationElements/Passports/Header";
import TravelersTable from "modules/UmrahOperations/OperationStatement/View/OperationElements/Passports/TravelersTable";
import useTravelersPassports from "modules/UmrahOperations/OperationStatement/View/OperationElements/Passports/useTravelersPassports";
import { useParams, useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";
import moment from "moment";
import { formatViewOperationStatement } from "modules/UmrahOperations/helper/formatUmrahOperations";
import { handleResponesNotification } from "modules/UmrahOperations/helper/accountingHelpers";
import { useEffect } from "react";
import { updateCreatePassports } from 'services/tripOperations';

export default function PassportForm({ formId, formState }) {
	const { onlineVisa, operationStatement } = Locale;
	const { id } = useParams();
	const location = useLocation();
	const isPublic = location.pathname.includes("public");
	const operationId = id

	const {
		passportsData,
		setPassportsData,
		passportsGroups,
		allTravelersList,
		insertedTravelers,
		setInsertedTravelers,
		addPassportModalOpen,

		toggleAddPassportsModal,
		handleHeaderInputsChanges,
		handleSelectAllPassport,
		handleSelectPassport,
		handleDeletePassport,
		handleInsertSelectedPassprots,
		getPassportsListByGroupOrTag,
		getSafaGroupsAndTags,
	} = useTravelersPassports();

	// save inserted passports
	async function saveSelectedPassports() {
		let travelersData = {
			travellers: insertedTravelers.map((passport) => ({
				first_name: passport?.first_name,
				last_name: passport?.last_name,
				passport_number: passport?.passport_number,
				passport_photo: passport?.passport_photo,
				gender: passport?.gender,
				birth_date: passport?.birth_date
					? moment(passport?.birth_date).format("YYYY-MM-DD")
					: passport?.birth_date,
				age: passport?.birth_date
					? moment(moment()).diff(passport?.birth_date, "years")
					: null,
				country_id: passport?.country_id,
				nationality_id: passport?.nationality_id,
				relationship:
					passport?.RelationWithSponser?.en ||
					passport?.RelationWithSponser ||
					null,
				national_id: passport?.national_id?.toString() || null,
			})),
		};
		const res = await updateCreatePassports(operationId, travelersData);
		if (res?.status === 200) {
			handleResponesNotification({
				alertType: "success",
				message: "Passports added succesfully",
				title: "Add Update Travelers",
			});
			await formatViewOperationStatement(res.data.data, Locale);
		}
	}

	useEffect(() => {
		if (!passportsGroups.list && formState?.travellers?.length === 0 && !isPublic) {
			getSafaGroupsAndTags();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState?.travellers]);

	useEffect(() => {
		if (formState?.travellers?.length > 0) {
			setInsertedTravelers(formState.travellers)
			setPassportsData({ ...passportsData, passportInserted: true })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState.travellers])

	const handleSubmit = (e) => {
		e.preventDefault();
		saveSelectedPassports()
	};

	return (
		<>
			<form id={formId} onSubmit={handleSubmit}>
				{!passportsData.passportInserted ? (
					<PassportsHeader
						passportsGroups={passportsGroups}
						passportsData={passportsData}
						handleHeaderInputsChanges={handleHeaderInputsChanges}
						getPassportsListByGroupOrTag={getPassportsListByGroupOrTag}
					/>
				) : null}

				{/* travelers list */}
				{(allTravelersList.length > 0 || insertedTravelers.length > 0) && (
					<>
						<div className="mt-3">
							<TravelersTable
								travelersList={
									passportsData.passportInserted
										? insertedTravelers
										: allTravelersList
								}
								insertedTravelers={insertedTravelers}
								handleSelectAllPassport={handleSelectAllPassport}
								handleSelectPassport={handleSelectPassport}
								handleDeletePassport={handleDeletePassport}
								isPassportInserted={passportsData.passportInserted}
							/>
						</div>
					</>
				)}

				{/* action buttons */}
				<div className="d-flex justify-content-end mt-3">
					{!passportsData.passportInserted && allTravelersList.length > 0 ? (
						<button
							className="btn accept-btn px-5"
							disabled={
								!allTravelersList.find((passport) => passport?.selected)
							}
							onClick={() => handleInsertSelectedPassprots()}
							type="button"
						>
							<span className="mx-2">{onlineVisa.INSERTSELECTED}</span>
						</button>
					) : null}
				</div>
			</form>

			<Modal
				isOpen={addPassportModalOpen}
				size="xl"
				centered
				className="operation-modal"
			>
				<ModalHeader toggle={toggleAddPassportsModal}>
					{operationStatement.chooseFromGroup}
				</ModalHeader>
				<ModalBody>
					<PassportsHeader
						passportsGroups={passportsGroups}
						passportsData={passportsData}
						handleHeaderInputsChanges={handleHeaderInputsChanges}
						getPassportsListByGroupOrTag={getPassportsListByGroupOrTag}
					/>
					{allTravelersList.length > 0 && (
						<TravelersTable
							travelersList={allTravelersList}
							insertedTravelers={insertedTravelers}
							handleSelectAllPassport={handleSelectAllPassport}
							handleSelectPassport={handleSelectPassport}
							handleDeletePassport={handleDeletePassport}
							isPassportInserted={false}
						/>
					)}
					<button
						className="btn bg-nxt mt-3 px-5 w-100"
						disabled={!allTravelersList.find((passport) => passport?.selected)}
						onClick={() =>
							handleInsertSelectedPassprots(toggleAddPassportsModal)
						}
					>
						<span className="mx-2">{onlineVisa.INSERTSELECTED}</span>
					</button>
				</ModalBody>
			</Modal>
		</>
	);
}
