// React-Route-Dom
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Translation
import Locale from 'translations';
// Shared Components
import ServiceBuilderHeader from './shared/Header'
import ServicesMainTabs from './shared/ServicesMainTabs';
// View
import ServiceBuilderAttractionForm from './view/AttractionForm';
import ServiceBuilderFlightForm from './view/FlightForm';
import ServiceBuilderHotelForm from './view/HotelForm';
import ServiceBuilderTransferForm from './view/TransferForm';
import ServiceBuilderPackagesForm from './view/PackagesForm';
import ServiceBuilderOthersForm from './view/OthersForm';
// Assets
import { ReactComponent as BackArrow } from "assets/images/serviceBuilder/backArrow.svg";
import { ReactComponent as HotelIcon } from "assets/images/serviceBuilder/hotel.svg";
import { ReactComponent as FlightIcon } from "assets/images/serviceBuilder/flight.svg";
import { ReactComponent as TransferIcon } from "assets/images/serviceBuilder/transfer.svg";
import { ReactComponent as AttractionIcon } from "assets/images/serviceBuilder/attraction.svg";
import { ReactComponent as PackageIcon } from "assets/images/serviceBuilder/package.svg";
import { ReactComponent as OthersIcon } from "assets/images/serviceBuilder/others.svg";
// Custom Hook
import useSharedData from './constant/useSharedData';
// ---------------------------------------------------------------------------------------------------------------


const ServiceBuilderViewEdit = () => {

  const { Service_Builder } = Locale;
  const { dir } = useSharedData()
  const history = useHistory()

  const servicesBuilderTabs = [
    {
      id: 1,
      title: Service_Builder.hotel,
      icon: <HotelIcon />,
      url: "hotel",
      element: <ServiceBuilderHotelForm />
    },
    {
      id: 2,
      title: Service_Builder.flight,
      icon: <FlightIcon />,
      url: "flight",
      element: <ServiceBuilderFlightForm />
    },
    {
      id: 3,
      title: Service_Builder.transfer,
      icon: <TransferIcon />,
      url: "transfer",
      element: <ServiceBuilderTransferForm />
    },
    {
      id: 4,
      title: Service_Builder.attraction,
      icon: <AttractionIcon />,
      url: "attraction",
      element: <ServiceBuilderAttractionForm />
    },
    {
      id: 5,
      title: Service_Builder.packages,
      icon: <PackageIcon />,
      url: "packages",
      element: <ServiceBuilderPackagesForm />
    },
    {
      id: 6,
      title: Service_Builder.others,
      icon: <OthersIcon />,
      url: "other",
      element: <ServiceBuilderOthersForm />
    },
  ];

  const { serviceName } = useParams();

  // ------- JSX Code ------
  return (
    <div className='service-builder-holder'>

      <ServiceBuilderHeader title={Service_Builder?.add_service}>
        <button
          onClick={() => history.push('/website/service-builder')}
          className='service-action-btn'
        >

          < BackArrow style={{ transform: `${dir === 'rtl' && 'rotate(180deg)'}` }} />
          {Service_Builder?.back_to_service}
        </button>
      </ServiceBuilderHeader>

      <ServicesMainTabs tabs={servicesBuilderTabs} />

      {servicesBuilderTabs?.map(tab =>
        <div>
          {tab?.url === serviceName && tab?.element}
        </div>
      )}

    </div>
  )
}

export default ServiceBuilderViewEdit