import React from "react";
import { Modal, ModalBody } from "reactstrap";

export default function CustomModal(props) {
	return (
		<>
			<Modal
				isOpen={props.modalIsOpen}
				onRequestClose={props.closeModal}
				className="confirmation-modal"
				overlayClassName="confirmation-overlay"
			>
				<section className='w-100 px-4'>
					<h3 className='py-3 font-weight-bolder'>{props.header}</h3>
					<p>{props.message}</p>
					<section className='d-flex justify-content-end mt-3 mb-2'>
						<button onClick={props.closeModal}>{props.cancelLabel}</button>
						<button className='btn btn-lg text-white  save-product-btn bg-nxt' onClick={props.onConfirm}>{props.confirmLabel}</button>

					</section>
				</section>
			</Modal>
		</>
	);
}
