import React from 'react'
import { ReactComponent as CheckMarkCircleIcon } from "assets/images/market-place/tick-circle.svg";
import { ReactComponent as StopIcon } from "assets/images/market-place/stop-icon.svg";
import FLightView from 'modules/WebBuilder/PreviewServices/Flights';
import { itineraryElementsIds } from '../helpers/formatServicesItems';
import PackageHotelView from './HotelView';
import PackageAttractionsView from './AttractionsView';
import Locale from 'translations';


export default function PackageItemsView({ reservationDetails }) {
  const { productsBuilder, inventory, marketPlace } = Locale;

  const packageContainAttraction = reservationDetails?.product_items.filter(item => item?.item?.id === itineraryElementsIds.sightseeing).length > 0;
  const packageContainHotel = reservationDetails?.product_items.filter(item => item?.item?.id === itineraryElementsIds.hotel).length > 0;
  // const packageContainFlight = reservationDetails?.product_items.filter(item => item?.item?.id === itineraryElementsIds.external).length > 0;


  const HotelElements = () => {
    return reservationDetails?.product_items?.map((element, index) => {
      if (element?.item?.id === itineraryElementsIds.hotel) {
        return <PackageHotelView hotelData={element?.itemable} key={index} />;
      }
      return null;
    });
  };

  const AttractionElements = () => {
    return reservationDetails?.product_items?.map((element, index) => {
      if (element?.item?.id === itineraryElementsIds.sightseeing) {
        return <PackageAttractionsView attractionData={element?.itemable} key={index} />;
      }
      return null;
    });
  };


  return (
    <>
      {/* flight */}
      <div id={`tab-${itineraryElementsIds.external}`}>
        {reservationDetails?.product_items?.filter(item => item?.item?.id === 1)?.map((item, index) => {
          return (
            <div className='public-page-preview-services mt-4' index={index} key={item?.itemable?.id}>
              <FLightView details={item} />
            </div>
          )
        })}
      </div>

      {/* Hotels */}
      {packageContainHotel ?
        <div className='package-item' id={`tab-${itineraryElementsIds.hotel}`}>
          <h2 className='package-title'>{inventory.messages.hotels}</h2>
          <HotelElements />
        </div>
        :
        null
      }

      {/* Attraction */}
      {packageContainAttraction ?
        <div className='package-item' id={`tab-${itineraryElementsIds.sightseeing}`}>
          <h2 className='package-title'>{productsBuilder.attraction}</h2>
          <AttractionElements />
        </div>
        :
        null
      }

      {/* services */}
      <div className='package-item'>
        <h2 className='package-title'>{marketPlace.Services}</h2>
        <div className='mt-4'>
          <p className="d-flex align-items-center mb-2">
            {reservationDetails?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.internal)?.length > 0
              ? <CheckMarkCircleIcon />
              : <StopIcon />
            }
            <span className="mx-1">{productsBuilder.internalTransportations}</span>
          </p>
          <p className="d-flex align-items-center mb-2">
            {reservationDetails?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.visa && item?.itemable?.included)?.length > 0
              ? <CheckMarkCircleIcon />
              : <StopIcon />
            }
            <span className="mx-1">{productsBuilder.visa}</span>
          </p>
          <p className="d-flex align-items-center mb-2">
            {reservationDetails?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.insurance && item?.itemable?.included)?.length > 0
              ? <CheckMarkCircleIcon />
              : <StopIcon />
            }
            <span className="mx-1">{productsBuilder.insurance}</span>
          </p>
          <p className="d-flex align-items-center mb-2">
            {reservationDetails?.product_items?.filter(item => item?.item?.id === itineraryElementsIds.otherServices)?.length > 0
              ? <CheckMarkCircleIcon />
              : <StopIcon />
            }
            <span className="mx-1">{productsBuilder.other}</span>
          </p>
        </div>

      </div>
    </>
  )
}
