// ** Imports
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Locale from 'translations'

// ** Components
import CRMTabs from './shared/CRMTabs'
import { ReactComponent as CRMMainIcon } from 'assets/images/crm/main.svg'
import { ReactComponent as CSRequestsIcon } from 'assets/images/crm/cs-requests.svg'
import { ReactComponent as UserIcon } from 'assets/images/crm/user.svg'
import { ReactComponent as AccountingIcon } from 'assets/images/crm/accounting.svg'

// ** UIs
import CRMMain from './Main'
import Accounting from './Accounting'
import CSRequests from './CSRequests'
import Customers from './Customers'
// import ViewCrmCustomer from './Customers/View'
import ShowSubscription from 'helpers/ShowSubscription';
import Travelers from './Travelers';

const CRM = () => {
	// ** hooks
	const { CRM, onlineVisa } = Locale;

	// ** Tabs
	const { tab, id } = useParams();

	const CRM_TABS = [
		{ title: CRM.cRM, icon: <CRMMainIcon />, url: 'main' },
		{
			title: CRM.accounting,
			icon: <AccountingIcon />,
			url: 'accounting'
		},
		{
			title: CRM.csRequests,
			icon: <CSRequestsIcon />,
			url: 'cs-requests'
		},
		{ title: CRM.customers, icon: <UserIcon />, url: 'customers' },
		{
			title: onlineVisa.Travelers,
			icon: <UserIcon />,
			url: 'travelers'
		}
	]

	// ** states
	const [activeTab, setActiveTab] = useState(!tab ? CRM_TABS[0].url : tab)

	// ** useEffect to set tabs
	useEffect(() => {
		if (id === undefined) {
			setActiveTab(tab)
		} else {
			setActiveTab(tab)
		}
	}, [tab])
	return (
		<ShowSubscription module={"crm"}>
			<div className=' crm'>
				<div className='bg-gray-50'>
					<CRMTabs
						tabs={CRM_TABS}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						classNames='px-4 col-md-12 col-12 mx-auto'
					/>
				</div>
				<div className='container-fluid umrah-operations-wrapper p-0 gap-10'>
					{activeTab === 'main' ? <CRMMain /> : null}
					{activeTab === 'accounting' ? <Accounting /> : null}
					{activeTab === 'cs-requests' ? <CSRequests /> : null}
					{/* {activeTab === 'customers' &&  pathname.split("/").pop() !== "customers"? <ViewCrmCustomer /> : null} */}
					{activeTab === 'customers' ? <Customers /> : null}
					{activeTab === 'travelers' ? <Travelers /> : null}

				</div>
			</div>
		</ShowSubscription>
	)
}

export default CRM
