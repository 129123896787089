import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { historyListCRM } from 'services/CRM';
import Locale from 'translations';

export default function HistoryLogs() {
	const [historyState, setHistoryState] = useState([]);
	const { id } = useParams();
	const { CRM } = Locale;

	useEffect(() => {
		async function getProductHistory() {
			const res = await historyListCRM(id);
			if (res?.status >= 200 && res?.status < 300) {
				setHistoryState(res?.data?.data);
			}
		}
		getProductHistory();
	}, []);

	return (
		<>
			<div className=" px-2">
				{historyState.length > 0
					? historyState.map((res) => {
						return (
							<div className="mb-3">
								<div className="head">
									<p className='d-flex align-items-center' style={{ gap: '3px' }}>
										<i class="fas fa-info-circle fa-2x" style={{ color: '#D4A655' }}></i>
										<span className='mx-1 crm-details-text-primary' style={{ color: '#707170' }}>{CRM[res.action] || res.action}</span>
									</p>
									<p className='crm-details-text-secondary' style={{ color: '#707170' }}>
										{" "}
										{res.created_at ?
											<>
												{moment(res.created_at).format("DD.MMM.YYYY")}
												<span className="mx-2">|</span>
												{moment(res.created_at).format("HH:mm")}
											</>
											:
											null
										}
									</p>
								</div>
								<p className="descrip crm-details-date-history" style={{ paddingLeft: '30px' }}>
									{res.user_name} {" "} {CRM[res.action === "changed" ? "heChange" : "heCreate"] || res.action} {" "} {CRM[res?.attribute] || res?.attribute} {CRM.to} {" "}
									<span className="text-capitalize">{CRM[res?.value] || res?.value}</span>
								</p>
							</div>
						);
					})
					: ""}


			</div>
		</>
	);
}
