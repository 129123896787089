
// Assets
import { ReactComponent as HotelIcon } from "assets/images/serviceBuilder/hotel1.svg";
import { ReactComponent as FlightIcon } from "assets/images/serviceBuilder/flight1.svg";
import { ReactComponent as TransferIcon } from "assets/images/serviceBuilder/transfer1.svg";
import { ReactComponent as AttractionIcon } from "assets/images/serviceBuilder/attraction1.svg";
// --------------------------------------------------------------------
const ServicesSubTabs = ({ tabs, activeSubTab, setActiveSubTab }) => {

  const defineServiceIcon = (serviceType) => {
    if (serviceType === 'builder_hotel') {
      return <HotelIcon />
    }
    if (serviceType === 'builder_flight') {
      return <FlightIcon />
    }
    if (serviceType === 'builder_transfer') {
      return <TransferIcon />
    }
    if (serviceType === 'builder_attraction') {
      return <AttractionIcon />
    }
  }

  // ---------- JSX Code -----------
  return (
    <div className='d-flex sub-tabs-holder '>
      {tabs?.map(tab =>
        <div className={`${activeSubTab?.id === tab?.id ? "active-sub-tab" : "sub-tab"} `} onClick={() => setActiveSubTab(tab)}>
          <span className='px-1 '>
            {defineServiceIcon(tab?.id)}
          </span>
          {tab?.name}
        </div>
      )}
    </div>
  )
}

export default ServicesSubTabs