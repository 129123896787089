import op_center_screen from "assets/images/dashboard/operation-center.png";
import { useHistory, Link } from "react-router-dom";
import Locale from 'translations';

export default function CarouselPlan({ bundleData }) {
  const { dashboard, subscriptionModule } = Locale;
  const history = useHistory();

  return (
    <div className="content d-flex gap-24 col-12">
      <div className="photos col-5 text-center">
        <img src={op_center_screen} className="img-fluid" alt="Bravo Plan" style={{ minHeight: "300px" }} />
      </div>

      <div className="plan-description col-6">
        <p className="title">{bundleData?.name}</p>
        <p className="text-muted">
          {subscriptionModule[bundleData?.category].description?.length > 150
            ? subscriptionModule[bundleData?.category]?.description?.substring(0, 150) + "..."
            : subscriptionModule[bundleData?.category]?.description
          }
          {/* introduces an online B2B portal tailored for the travel agents & experts.
          Our digital platform acts as a one stop shop that provides multi travel solutions & services */}
        </p>
        <div className="d-flex gap-24 flex-wrap">
          {bundleData?.modules?.slice(0, 4)?.map(module => {
            return (
              <div className="d-flex align-items-center mb- gap-10" key={module?.id}>
                <div className="done-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" stroke="white" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                  </svg>
                </div>
                <p>{module?.name}</p>
              </div>
            )
          })}
        </div>
        {!bundleData?.is_subscribed &&
          <div className="d-flex gap-10">
            <button className="btn filled-btn d-flex" onClick={() => history.push({ pathname: '/subscription/checkout', selectedBundle: bundleData })}>
              <span className="btn-title">{dashboard.messages.subscribe}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke="white" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
              </svg>
            </button>

            <Link to={`/plan-details/${bundleData?.category}`} className="btn outline-btn d-flex">
              <span className="btn-title"> {dashboard.messages.readMore}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" stroke="#D4A655" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
              </svg>
            </Link>
          </div>
        }
      </div>
    </div>
  )
}