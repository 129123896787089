import validate from 'helpers/validate';

export default function useTripPricing(pricingData, selectedPricingType, setErrors) {


  // get pricing object based on what user selected
  function getPricingObjBasedOnSelectedFor() {
    if (pricingData?.pricing_for?.id === "b2b") {
      return [pricingData?.pricing?.find(priceItem => priceItem?.for === "b2b")]
    } else if (pricingData?.pricing_for?.id === "b2c") {
      return [pricingData?.pricing?.find(priceItem => priceItem?.for === "b2c")]
    } else {
      return pricingData?.pricing
    }
  }

  // handle fullpackage payload before send to server
  function formatFullpackagePayload() {
    let productHasHotel = pricingData?.product_items?.find(item => item?.item?.id === 6);
    let product_price_combinations;
    let pricingObj = getPricingObjBasedOnSelectedFor();

    let fullpackageData = {
      pricing: pricingObj?.map(price => {
        if (productHasHotel) {
          product_price_combinations = price?.hotels[0].rooms_types?.map(roomType => roomType.rooms?.map(room => {
            return {
              breakdown_original_price: null,
              breakdown_price_after_discount: null,
              full_original_price: +room?.price_adult,
              full_price_after_discount: null,
              child_original_price: +room?.price_child,
              child_price_after_discount: null,
              combinations: [
                {
                  price_adult: 0,
                  price_child: 0,
                  product_hotel_id: price?.hotels[0]?.hotelId,
                  room_type: roomType?.type,
                  room_category: room?.category?.name,
                  meal_type: room?.meal_type?.name,
                  count: +room?.count,
                  max_child: +room?.max_child
                }
              ]
            }
          })).flat(1)
        } else {
          product_price_combinations = price?.product_price_combinations?.map(combination => {
            return {
              breakdown_original_price: null,
              breakdown_price_after_discount: null,
              full_original_price: +combination?.full_original_price,
              full_price_after_discount: null,
              child_original_price: +combination?.child_original_price,
              child_price_after_discount: null,
              combinations: []
            }
          });
        }

        return {
          for: price?.for,
          product_price_combinations,
          discounts: price?.hasDiscount ? [price?.discount] : [],
          vat: price?.hasVat ? +price?.vat : null
        }
      }),
      product_price_type_id: selectedPricingType === "fullpackage" ? 1 : 0,
    }

    return fullpackageData
  }


  // handle breakdown payload before send to server
  function formatBreakdownPayload() {
    let pricingObj = getPricingObjBasedOnSelectedFor();
    let breakdownData = {

      pricing: pricingObj?.map(price => ({
        for: price?.for,
        product_items: price?.product_items?.map(service => {
          if (service?.item?.id === 6) {
            return {
              ...service,
              itemable: {
                ...service?.itemable,
                rooms: price?.hotels?.find(hotel => service?.id === hotel?.id).rooms_types?.map(roomType => roomType.rooms?.map(room => {
                  return {
                    ...room,
                    room_category: room?.category?.name,
                    meal_type: room?.meal_type?.name,
                    room_type: roomType?.type
                  }
                })).flat(1)
              }
            }
          } else {
            return { ...service }
          }
        }),
        discounts: price?.hasDiscount ? [price?.discount] : [],
        vat: price?.hasVat ? +price?.vat : null
      })),
    }
    return breakdownData;
  }

  // check pricing errors (hotels, product items, prodcut combinations)
  function checkFormErrors() {

    let submitErrors = {};
    let pricingObj = getPricingObjBasedOnSelectedFor();
    const validateRoom = (room, prefix) => {
      let roomsPrefix = prefix.substring(prefix.indexOf("-") + 1)
      let roomsKeys = ["category", "meal_type", "count"]
      if (room) {
        Object.keys(room).forEach(key => {
          let currentPrefix = roomsKeys.includes(key) ? roomsPrefix : prefix;
          if (key === "price_adult" || roomsKeys.includes(key)) {
            submitErrors = {
              ...submitErrors,
              ...validate(
                { name: [`${key}-${currentPrefix}`], value: room[key] },
                { required: true }
              ),
            };
          }
        });
      }
    };

    const validateItem = (item, prefix) => {
      Object.keys(item?.itemable || {}).forEach(key => {
        if (key === "price_adult") {
          submitErrors = {
            ...submitErrors,
            ...validate({ name: [`${key}-${prefix}`], value: item?.itemable[key] }, { required: true }),
          };
        }
      });
    };


    pricingObj?.forEach(pricingItem => {
      let itemHasHotel = pricingItem?.product_items?.find(item => item?.item?.id === 6)
      if (itemHasHotel) {
        pricingItem?.hotels?.forEach(hotel => {
          hotel?.rooms_types?.forEach(roomType => {
            roomType?.rooms?.forEach((room, index) => validateRoom(room, `${pricingItem?.for}-${hotel?.id}-${roomType.type}-${index}`));
          });
          if (selectedPricingType === "breakdown") {
            pricingItem?.product_items?.filter(item => item?.item?.id !== 6)?.forEach(item => validateItem(item, `${pricingItem?.for}-${item?.id}`));
          }
        });
      }
      if (selectedPricingType === "breakdown" && !itemHasHotel) {
        pricingItem?.product_items?.filter(item => item?.item?.id !== 6)?.forEach(item => validateItem(item, `${pricingItem?.for}-${item?.id}`));
      }

      // handle errors if fullpackage and there is not hotel
      if (selectedPricingType === "fullpackage" && !itemHasHotel) {
        let combination = pricingItem?.product_price_combinations[0];
        Object.keys(combination).forEach(key => {
          if (key === "full_original_price") {
            submitErrors = {
              ...submitErrors,
              ...validate({ name: [`${key}-${pricingItem?.for}`], value: combination[key] }, { required: true }),
            };
          }
        })
      }
    });


    setErrors(() => submitErrors);
    return submitErrors;
  }

  return {
    formatFullpackagePayload,
    formatBreakdownPayload,
    checkFormErrors
  }
}
