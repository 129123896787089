import { useCallback, useState } from 'react'
import { attachOfficer, editPortHall, executeMovment, updateMovmentDrivers, updateMovmentSupervisor } from 'services/operationStatement';
import Locale from 'translations';
import { handleResponesNotification } from '../helper/accountingHelpers';
import AvailableRooms from 'modules/market-place/NewMarketplace/ViewServices/SpecialAllotment/SpecialData/Details/pricing/availableRooms';

const saudiFlagUUID = "f4e1fb09-cc9e-473a-828f-02fb764d8394";


export default function useOperationMovementsModals(reloadData) {

  const { operationStatement } = Locale;
  // ** states
  // supervisor Modal state
  const [supervisorModal, setSupervisorModal] = useState({
    isOpen: false,
    data: {
      name: "",
      phone_code: { flag: saudiFlagUUID, label: "+966", value: 966, },
      phone: "",
    },
  });
  // drivers Modal state
  const [driversModal, setDriversModal] = useState({
    isOpen: false,
    data: [
      {
        name: "",
        phone_code: { flag: saudiFlagUUID, label: "+966", value: 966, },
        phone: "",
        vehicle_no: "",
      },
    ],
  });
  // officer modal state
  const [officerModal, setOfficerModal] = useState({
    isOpen: false,
    data: {
      name: "",
      phone_code: { flag: saudiFlagUUID, label: "+966", value: 966, },
      phone: "",
    },
  });

  // hall modal state
  const [hallModal, setHallModal] = useState({
    isOpen: false,
    hall: {
      hall_id: "",
      hall_name: "",
    },
  });
  // execute modal in case movement is arrival or departure
  const [executeModalState, setExecuteModalState] = useState({ isOpen: false, movementId: null });

  // ************************************** toggle modals functions ************************************** //

  // toggle supervisor modal
  const toggleSupervisorModal = useCallback((type, supervisorData, movmentId) => {
    setSupervisorModal({
      isOpen: !supervisorModal?.isOpen,
      type,
      data: supervisorData
        ? { movmentId, ...supervisorData, phone_code: supervisorData?.phone_country }
        : {
          movmentId,
          name: "",
          phone_code: { flag: saudiFlagUUID, label: "+966", value: 966, },
          phone: "",
        }
    })
  }, [supervisorModal?.isOpen]);

  // toggle driver modal
  const toggleDriversModal = useCallback((type, driversData, movmentId, movmentVehicleCount) => {
    driversData = driversData?.map(driver => ({
      ...driver,
      phone_code: driver?.phone_country
    }))
    const vehiclesCount = movmentVehicleCount - driversData?.length;
    const driversArray = vehiclesCount > 0 ? [...driversData, ...Array.from({ length: vehiclesCount }).map(driver => {
      return {
        name: "",
        phone_code: { flag: saudiFlagUUID, label: "+966", value: 966, },
        phone: "",
        vehicle_no: "",
      }
    })] : []
    setDriversModal({
      isOpen: !driversModal?.isOpen,
      type,
      movmentId,
      data: type === "add" ? driversArray : driversData
    })
  }, [driversModal?.isOpen]);


  // TODO refactor Asap
  // toggle movment modal
  const toggleOfficerModal = useCallback((type, movementData, movmentId, movmentType) => {
    let data = {};
    if (type === "company") {
      data = { company: movementData?.delegation_company_name }
    } else if (movementData?.officer) {
      data = movementData?.officer
    } else if (movementData?.delegation_company_name) {
      data = { company: movementData?.delegation_company_name }
    } else {
      data = ""
    }

    setOfficerModal({
      isOpen: !officerModal?.isOpen,
      type,
      data: { ...data, movmentId, movmentType, },
    })
  }, [officerModal?.isOpen]);

  // toggle execute modal
  const toggleExecuteModal = useCallback(({ movementId = null }) => {
    setExecuteModalState({ isOpen: !executeModalState.isOpen, movementId })
  }, [executeModalState.isOpen]);

  // toggle hall modal
  const toggleHallModal = useCallback(({ movementId = null, type, index }) => {
    setHallModal({ isOpen: !hallModal.isOpen, movementId, type, index })
  }, [hallModal.isOpen]);


  // ************************************** send modal data to api ************************************** //
  // save officer
  async function saveOfficerData(data) {
    const payload = data.attach_type === "company"
      ? {
        attach_type: data.attach_type,
        company_id: data.company.id,
        attach_file: data.attach_file,
      }
      : {
        attach_type: data.attach_type,
        officer_id: data.officer.id,
        attach_file: data.attach_file,
      }


    const res = await attachOfficer(officerModal.data.movmentId, payload);

    if (res?.status === 200) {
      toggleOfficerModal();
      reloadData();
      handleResponesNotification({
        alertType: "success",
        message: operationStatement.officialMovementUpdatedSuccessfully,
        title: operationStatement.movementOfficial
      });
    }
  }

  // save supervisor data
  async function saveSupervisorData() {
    const data = {
      id: supervisorModal?.data?.id || null,
      name: supervisorModal?.data?.name,
      phone: supervisorModal?.data?.phone,
      phone_code: supervisorModal?.data?.phone_code?.id?.toString() || supervisorModal?.data?.phone_code?.value?.toString(),
    }
    const res = await updateMovmentSupervisor(supervisorModal?.data?.movmentId, data);
    if (res?.status === 200) {
      reloadData()
      toggleSupervisorModal();
      handleResponesNotification({
        alertType: "success",
        message: operationStatement.supervisorUpdatedSuccessfully,
        title: operationStatement.editSupervisor
      });
    }
  }

  // send hall data to api
  async function sendHallData(id, data) {

    const res = await editPortHall(id, data)

    if (res?.status === 200) {
      reloadData()
      toggleHallModal({ movementId: null });
      handleResponesNotification({
        alertType: "success",
        message: operationStatement.hallUpdatedSuccessfully,
        title: operationStatement.edit_hall
      });
    }
  }
  // save drivers data
  async function saveDriversData() {
    const data = {
      drivers: driversModal?.data?.map(driver => {
        return {
          id: driver?.id,
          name: driver?.name,
          phone: driver?.phone?.name || driver?.phone,
          phone_code: driver?.phone_code?.id?.toString() || driver?.phone_code?.value?.toString(),
          vehicle_no: driver?.vehicle_no
        }
      })
    }
    const res = await updateMovmentDrivers(driversModal?.movmentId, data);
    if (res?.status === 200) {
      reloadData()
      toggleDriversModal();
      handleResponesNotification({
        alertType: "success",
        message: operationStatement.vehiclesDriverUpdatedSuccessfully,
        title: operationStatement.vehiclesDriver
      });
    }
  }



  // execute movment
  async function handleExecuteMovement(data, id) {
    let payloadData = {};
    let movementId = id ? id : executeModalState.movementId;

    if (data) {
      payloadData = {
        entrance_stamp: data.entrance_stamp,
        bus_image: data.bus_image,
        // bus_image: data.bus_images[0],
      }
    }


    const res = await executeMovment(movementId, payloadData);
    if (res?.status === 200) {
      reloadData();
      executeModalState.isOpen && toggleExecuteModal({ movementId: null });
      handleResponesNotification({
        alertType: "success",
        message: operationStatement.movementUpdatedSuccessfully,
        title: operationStatement.makeExecute
      });
    }
  }

  return {
    toggleSupervisorModal,
    toggleOfficerModal,
    toggleDriversModal,
    toggleExecuteModal,
    supervisorModal,
    officerModal,
    driversModal,
    executeModalState,
    setSupervisorModal,
    setDriversModal,
    handleExecuteMovement,
    saveOfficerData,
    saveSupervisorData,
    saveDriversData,
    toggleHallModal,
    hallModal,
    sendHallData,
    setHallModal,
  }
}
