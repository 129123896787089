import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ChevronRight, MoreSquareIcon } from '../../icons';
import { useHistory } from 'react-router-dom';

export default function OperationsDropdown({ menuItems }) {
  const [mainDropdown, setMainDropdown] = useState(false);
  const toggleMain = () => setMainDropdown(prev => !prev);

  return (
    <Dropdown isOpen={mainDropdown} toggle={toggleMain} direction="left" className='operations-dropdown'>
      <DropdownToggle
        className="bg-transparent border-0 text-dark"
        style={{ all: "unset", cursor: "pointer" }}
      >
        <MoreSquareIcon />
      </DropdownToggle>
      <DropdownMenu className="p-0 shadow-sm" style={{ minWidth: '280px' }} positionFixed>
        {menuItems.map((section, index) => (
          <MenuSection
            key={index}
            title={section.title}
            items={section.items}
            parentOpen={mainDropdown}
          />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

const MenuSection = ({ title, items, parentOpen }) => (
  <>
    {
      title && (
        <div className="px-3 py-2">
          <small className="text-muted section-title">{title}</small>
        </div>)
    }
    {items.map((item, index) => (
      <MenuItem key={index} item={item} parentOpen={parentOpen} />
    ))}
  </>
);


const MenuItem = ({ item, parentOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(prev => !prev);
  const history = useHistory();

  if (item.children) {
    return (
      <div className="position-relative">
        <Dropdown isOpen={isOpen} toggle={toggle} direction="right">
          <DropdownToggle
            tag="div"
            className="d-flex align-items-center px-3 py-2 dropdown-item"
            style={{ cursor: "pointer" }}
          >
            {item.icon && <item.icon size={18} className="me-2 text-muted" />}
            <span>{item.label}</span>
            {
              item.children && (
                <span className='ml-auto'>
                  <ChevronRight />
                </span>
              )
            }
          </DropdownToggle>
          <DropdownMenu
            className="p-0 shadow-sm"
            style={{ minWidth: '200px', position: 'absolute', left: '100%', top: 0 }}
          >
            {item.children.map((child, index) => (
              <MenuItem key={index} item={child} parentOpen={isOpen} />
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }

  return (
    <DropdownItem
      className={`d-flex align-items-center px-3 py-2 ${item.className || ''}`}
      onClick={() => {
        if (item?.routePath) {
          history.push(item.routePath);
        }

        if (item.onClick) {
          item.onClick();
        }
      }}
      tag="div"
    >
      {item.icon && <item.icon size={18} className="me-2 text-muted" />}
      {item.label}
    </DropdownItem>
  );
};
