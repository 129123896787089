import React, { useEffect, useState } from 'react'
import ItineraryFlightView from './FlightView'
import { DepartureIcon } from 'modules/UmrahOperations/shared/Icons'
import SelectField from 'components/Form/SelectField/SelectField'
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField'
import { flightTypes } from 'modules/TripPlanner/helpers/tripPlannerLookups'
import { fetchTransporters, fetchPorts } from 'services/lookups';
import { useSBSState } from 'context/global';
import validate, { isFormValid } from 'helpers/validate';
import useItinerary from '../useItinerary'
import Locale from 'translations'


export default function TripPlannerItineraryFlight({
  tripPlannerData,
  selectedDay,
  setTripPlannerData,
  flight,
  itemIndex,
  allCountries,
}) {
  const { packagePlanner, operationStatement, companyDetails, productsBuilder } = Locale;
  const { locale } = useSBSState();
  const { saveFlightItem, removeSegment, canEdit } = useItinerary({ setTripPlannerData, selectedDay, tripPlannerData });
  // states 
  const [carrierList, setCarrierList] = useState();
  const [ports, setPorts] = useState({
    from: [],
    to: [],
  })
  const [currentView, setCurrentView] = useState(flight?.currentView);
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  // variables
  const isUpdatingFlight = flight?.id ? true : false;
  const airTransportationId = 2;



  // ** functions 
  async function getCarrierList(inputValue) {
    if (inputValue.length > 2) {
      const transportersRes = await fetchTransporters(2, inputValue);
      let result = [];
      transportersRes?.forEach((item) => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        });
      });
      setCarrierList(result);
    }
  };

  async function getFromPorts(id) {
    let fromPortsList = await fetchPorts(id, airTransportationId);
    setPorts({ ...ports, from: fromPortsList });
  };

  async function getToPorts(id) {
    let toPortsList = await fetchPorts(id, airTransportationId);
    setPorts({ ...ports, to: toPortsList });
  };


  // itinerary flight inputs changes
  function handleFlightInputs(keyName, value) {
    let tripPlannerClone = { ...tripPlannerData }
    let daysClone = tripPlannerClone.itinerary_days;
    let selectedDayIndex = selectedDay?.day - 1;
    let updatedDay = daysClone[selectedDayIndex];
    // reset air ports if country changes
    if (keyName === "from_country") {
      updatedDay.items[itemIndex] = { ...updatedDay.items[itemIndex], [keyName]: value, from_airport: null };
    } else if (keyName === "to_country") {
      updatedDay.items[itemIndex] = { ...updatedDay.items[itemIndex], [keyName]: value, to_airport: null };
    } else {
      updatedDay.items[itemIndex] = { ...updatedDay.items[itemIndex], [keyName]: value };
    }
    setTripPlannerData(tripPlannerClone);

    setErrors({
      ...errors,
      ...validate(
        { name: keyName, value },
        {
          required: (flight?.flight_type?.id === "direct" && keyName === "return_day")
            || (flight?.flight_type?.id === "round" && keyName === "return_day" && isUpdatingFlight)
            ? false
            : true
        }
      ),
    });
  }

  // check form errors
  function checkFormErrors() {
    let submitError = {}
    Object.keys(flight).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: flight[key] },
          {
            required: (flight?.flight_type?.id === "direct" && key === "return_day")
              || key === "isNew"
              || (flight?.flight_type?.id === "round" && key === "return_day" && isUpdatingFlight)
              ? false
              : true
          }
        ),
      }
    });
    setErrors(() => submitError)
  }


  function submitFlight() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }


  // delete flight
  function removeFlight() {
    removeSegment(tripPlannerData, itemIndex, flight?.id);
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      saveFlightItem(flight)
    }
  }, [isErrorLoaded]);

  // reset view when selected day changes
  useEffect(() => {
    if (!flight?.isNew) {
      setCurrentView("view");
    }
  }, [flight?.isNew, selectedDay]);

  return (
    <div className="itinerary-item">

      {currentView === "view" &&
        <ItineraryFlightView
          item={flight}
          setCurrentView={setCurrentView}
          removeItem={removeFlight}
          canEdit={canEdit}
        />
      }

      {/* add edit flight form */}
      {currentView === "form" &&
        <>
          <div className="form-header">
            <span className="d-flex align-items-center gap-10">
              <DepartureIcon color="#050001" />
              {packagePlanner.flight}
            </span>
            {canEdit &&
              <button className="btn p-0" onClick={removeFlight}>
                <svg width="11px" height="11px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 9L9 1M9 9L1 1" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </button>
            }
          </div>

          {/* flight form */}
          <div className="itinerary-item-form p-4">
            <div className="row m-0">
              {/* Flight Type */}
              <div className="col-md-3 col-12">
                <SelectField
                  label={packagePlanner.flightType}
                  id={`flight_type_${itemIndex}`}
                  name={`flight_type_${itemIndex}`}
                  options={flightTypes}
                  value={flight?.flight_type?.name}
                  onChange={(e) => {
                    handleFlightInputs("flight_type", e)
                  }}
                  color={errors?.flight_type?.required ? "danger" : ""}
                  errors={errors?.flight_type}
                  disabled={isUpdatingFlight}
                />
              </div>
              {/* Carrier */}
              <div className="col-md-3 col-12">
                <AutoCompleteField
                  label={operationStatement.CarrierName}
                  id={`flight_carrier_${itemIndex}`}
                  name={`flight_carrier_${itemIndex}`}
                  isSearchable={true}
                  listAuto={carrierList}
                  setListAuto={setCarrierList}
                  getListAuto={getCarrierList}
                  value={flight?.carrier_name?.name || ""}
                  onChange={(e) => {
                    handleFlightInputs("carrier_name", { name: e })
                  }}
                  onSelectValue={(e) => {
                    handleFlightInputs("carrier_name", { id: e?.value, name: e?.label })
                  }}
                  color={errors?.carrier_name?.required ? "danger" : ""}
                  errors={errors?.carrier_name}
                />
              </div>
              {/* Return Day */}
              {!isUpdatingFlight ?
                <div className="col-md-3 col-12">
                  <SelectField
                    label={packagePlanner.returnDay}
                    id={`flight_return_day_${itemIndex}`}
                    name={`flight_return_day_${itemIndex}`}
                    options={tripPlannerData.itinerary_days?.map((day, index) => {
                      return {
                        name: `${productsBuilder.day} ${index + 1}`,
                        id: day?.day,
                      }
                    })}
                    value={flight?.return_day?.name}
                    onChange={(e) => {
                      handleFlightInputs("return_day", e)
                    }}
                    color={errors?.return_day?.required ? "danger" : ""}
                    errors={errors?.return_day}
                    disabled={!flight?.flight_type || flight?.flight_type?.id === "direct"}
                  />
                </div>
                :
                null
              }
            </div>

            <div className="row mx-0 mt-2">
              {/* from country */}
              <div className="col-md-3 col-12">
                <SelectField
                  label={packagePlanner.fromCountry}
                  id={`from_country_${itemIndex}`}
                  name={`from_country_${itemIndex}`}
                  options={allCountries}
                  value={flight?.from_country?.name}
                  onChange={(e) => {
                    handleFlightInputs("from_country", e);
                    getFromPorts(e.id);
                  }}
                  color={errors?.from_country?.required ? "danger" : ""}
                  errors={errors?.from_country}
                />
              </div>
              {/* from airport */}
              <div className="col-md-3 col-12">
                <SelectField
                  label={packagePlanner.fromAirport}
                  id={`from_airport_${itemIndex}`}
                  name={`from_airport_${itemIndex}`}
                  options={ports.from}
                  value={flight?.from_airport?.name}
                  onChange={(e) => {
                    handleFlightInputs("from_airport", e);
                  }}
                  disabled={!flight?.from_country}
                  color={errors?.from_airport?.required ? "danger" : ""}
                  errors={errors?.from_airport}
                />
              </div>
              {/* to country */}
              <div className="col-md-3 col-12">
                <SelectField
                  label={packagePlanner.toCountry}
                  id={`to_country_${itemIndex}`}
                  name={`to_country_${itemIndex}`}
                  options={allCountries}
                  value={flight?.to_country?.name}
                  onChange={(e) => {
                    handleFlightInputs("to_country", e);
                    getToPorts(e.id);
                  }}
                  color={errors?.to_country?.required ? "danger" : ""}
                  errors={errors?.to_country}
                />
              </div>
              {/* to airport */}
              <div className="col-md-3 col-12">
                <SelectField
                  label={packagePlanner.toAirport}
                  id={`to_airport_${itemIndex}`}
                  name={`to_airport_${itemIndex}`}
                  options={ports.to}
                  value={flight?.to_airport?.name}
                  onChange={(e) => {
                    handleFlightInputs("to_airport", e);
                  }}
                  disabled={!flight?.to_country}
                  color={errors?.to_airport?.required ? "danger" : ""}
                  errors={errors?.to_airport}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <button className="btn px-5 py-2 mx-2 border"
                onClick={() => isUpdatingFlight ? setCurrentView("view") : removeFlight()}
              >
                {companyDetails.cancel}
              </button>
              <button className="btn px-5 py-2 bg-nxt"
                onClick={submitFlight}
              >
                {isUpdatingFlight ? operationStatement.Update : productsBuilder.Save}
              </button>
            </div>
          </div>

        </>
      }
    </div>
  )
}
