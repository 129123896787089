import React, { useEffect, useState, useRef } from 'react'
import Locale from 'translations';
import airportIcon from "assets/images/market-place/transferIcon/airportIcon.svg";
import shipIcon from "assets/images/market-place/transferIcon/shipIcon.svg";
import trainIcon from "assets/images/market-place/transferIcon/trainIcon.svg";
import HotelPlaceHolder from "assets/images/new-hotels.svg";
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import { fetchTransferHotels, fetchTransferHotelsPorts, fetchTransferPorts } from 'services/lookups';
import { useHistory } from 'react-router-dom';
import validate, { isFormValid } from 'helpers/validate';
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import SelectField from "components/Form/SelectField/SelectField";
import moment from "moment";
import { store } from "react-notifications-component";
import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import CustomModal from "../Transportation/components/Modal";
import { formatTransfer } from "modules/market-place/TransferResponse/helper/formatTransfer";
import { fetchTransfer } from "services/marketplace";
import AutocompleteModel from "modules/market-place/TransferResponse/Modals/AutocompleteModel";



const transferIcons = {
  Land: trainIcon,
  Air: airportIcon,
  Maritime: shipIcon,
};

export default function TransferSearchForm({ modify, closeModal }) {
  const { marketPlace, onlineVisa, productElements, messages } = Locale;

  const [errors, setErrors] = useState({});
  const [fromToModal, setFromToModal] = useState({ state: false, type: null });

  const [tripType, setTripType] = useState("round");

  const [transportationsData, setTransportationsData] = useState({
    from: null,
    to: null,
    departureDate: null,
    returnDate: null,
    adult: 0,
    children: { id: 0, value: 0, label: "0", name: "0" },
    ages: [],
  });

  const [listAutoFrom, setListAutoFrom] = useState([]);
  const [listAutoTo, setListAutoTo] = useState([]);
  const dispatch = useMarketplaceDispatch();
  const { transferCriteria,umrah } = useMarketplaceState();

  const roundType = tripType === "round";

  const getListAuto = async (inputValue, type) => {
    if (inputValue.length > 2) {
      const hotels = await fetchTransferHotelsPorts({ name: inputValue,country_id:umrah?	966:null})
      

      const formatHotel = hotels?.data&&hotels?.data.length>0?hotels?.data?.map((item) => {
        if (item.type=="Hotel") {
          return {
            ...item,
            id: item.id,
            name: item.name,
            value: item.id,
            label: item.name,
            type: "hotel",
            country: {
              flag: HotelPlaceHolder,
            },
          };
        }else{
          return {
            ...item,
            country: {
              flag: transferIcons[item?.type],
            },
            type: item.type,
          };
        }
        
      
        
      }):null;
      if (type === "from") {
        setListAutoFrom([...formatHotel]);
      } else {
        setListAutoTo([...formatHotel]);
      }
    }
  };

  const toggleAutoModel = (type) => {
    setFromToModal((prev) => ({ state: !prev.state, type }));
  };

  const childsAgesLookup = [...Array(18).keys()].map((age) => {
    return {
      id: age,
      value: age,
      label: age.toString(),
      name: age.toString(),
    };
  });

  const adultAndChildLookup = [...Array(16).keys()].map((adult) => {
    return {
      id: adult,
      value: adult,
      label: adult.toString(),
      name: adult.toString(),
    };
  });
  function handleChildsCount(e) {
    setTransportationsData({
      ...transportationsData,
      children: e,
      ages: [...Array(+e.value).keys()].map(() => {
        return {
          id: 0,
          value: 0,
          label: "0",
          name: "0",
        };
      }),
    });
  }

  function handleChildAge(e, ageIndex) {
    const ages = [...transportationsData?.ages];
    ages[ageIndex] = e;
    setTransportationsData({ ...transportationsData, ages: ages });
  }

  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const history = useHistory();

  const checkFormErrors = () => {
    let SubmitError = {};
    const validationKeys = ["from", "to", "departureDate", "adult"];

    if (roundType) {
      validationKeys.push("returnDate");
    }
    validationKeys.forEach((key) => {
      SubmitError = {
        ...SubmitError,
        ...validate(
          { name: key, value: transportationsData[key] },
          { required: true }
        ),
      };
    });
    setErrors(() => SubmitError);
  };

  const submit = async () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  const getFromAndTo = (data) => {
    const destination = fromToModal.type;
    ;
    data.value.country = {
      flag:
        data?.type === "hotel"
          ? HotelPlaceHolder
          : transferIcons[data.value?.transport_type],
    };
    setTransportationsData((prev) => ({
      ...prev,
      // from or to
      [destination]: data.value,

      // from_type or to_type
      [`${destination}_type`]: data.type,
    }));
  };

  useEffect(() => {
    if (modify && transferCriteria) {
      transferCriteria.departureDate = new Date(transferCriteria.departureDate);
      if (transferCriteria.returnDate) {
        transferCriteria.returnDate = new Date(transferCriteria.returnDate);
      } else {
        setTripType("onWay");
      }
      setTransportationsData(transferCriteria);
    }

    return () => { };
  }, []);

  useEffect(() => {
    if (isFormValid(errors)) {
      const handleSearch = async () => {
        const formatDate = formatTransfer(transportationsData);

        const transfers = await fetchTransfer(formatDate);
        if (transfers.status === 200) {
          const data = transfers.data;

          if (transfers.data.data.length > 0) {
            dispatch({
              type: "transferSearch",
              payload: {
                transferCriteria: transportationsData,
                searchData: data,
              },
            });
            if (modify) {
              closeModal();
            } else {
              history.push("/transfer");
            }
          } else {
            store.addNotification({
              title: messages.noResults,
              message: messages.noSearchResults,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
            });
          }
        }
      };
      handleSearch();
    } else {
    }
  }, [isErrorLoaded]);

  let fromPrevValue = useRef(null);
  function clearFromSearchText(e) {
    fromPrevValue.current = transportationsData.from;
    const value = e.target.value;
    if (value.length > 0) {
      setTransportationsData((prev) => ({ ...prev, from: null }));
    }
  }

  let toPrevValue = useRef(null);
  function clearToSearchText(e) {
    toPrevValue.current = transportationsData.to;
    const value = e.target.value;
    if (value.length > 0) {
      setTransportationsData((prev) => ({ ...prev, to: null }));
    }
  }

  const handleChange = (e) => {
    setTransportationsData((prev) => ({
      ...prev,
      [e.name]: e.value,
      [`${e.name}_type`]: e.value.type,
    }));
  };



  return (
    <div className="col-md-10 HotelSearch m-auto pb-1">
      <div className="row px-2">
        <div className="col-md-12">
          <AutoCompleteField
            openModal={() => toggleAutoModel("from")}
            hasLabel={true}
            flag={transportationsData.from?.country?.flag}
            imgFlag
            listAuto={listAutoFrom}
            setListAuto={setListAutoFrom}
            getListAuto={(e) => getListAuto(e, "from")}
            label={marketPlace.From}
            placeholder={marketPlace.From}
            isSearchable={true}
            //value={hotel.from?.name}
            value={transportationsData.from?.name || ""}
            onChange={(e) => {
              handleChange({ name: "from", value: { name: e } });
            }}
            onSelectValue={(e) => {
              handleChange({ name: "from", value: e });

              setErrors({
                ...errors,
                ...validate({ name: "from", value: e }, { required: true }),
              });
            }}
            onFocus={clearFromSearchText}
            onBlur={() =>
              setTransportationsData((prev) => ({
                ...prev,
                from: fromPrevValue.current,
              }))
            }
            color={errors?.from?.required ? "danger" : ""}
            errors={errors?.from}
          />
        </div>
        {/* cycle type */}
        <div className="col-md-12 my-1">
          <AutoCompleteField
            openModal={() => toggleAutoModel("to")}
            hasLabel={true}
            listAuto={listAutoTo}
            flag={transportationsData.to?.country?.flag}
            imgFlag
            setListAuto={setListAutoTo}
            getListAuto={(e) => getListAuto(e, "to")}
            label={marketPlace.to}
            placeholder={marketPlace.to}
            isSearchable={true}
            value={transportationsData.to?.name || ""}
            onChange={(e) => {
              handleChange({ name: "to", value: { name: e } });
            }}
            onSelectValue={(e) => {
              handleChange({ name: "to", value: e });

              setErrors({
                ...errors,
                ...validate({ name: "to", value: e }, { required: true }),
              });
            }}
            onFocus={clearToSearchText}
            onBlur={() =>
              setTransportationsData((prev) => ({
                ...prev,
                to: toPrevValue.current,
              }))
            }
            color={errors?.to?.required ? "danger" : ""}
            errors={errors?.to}
          />
        </div>

        <div className="col-md-12 d-flex">
          <div className="mx-1">
            <input
              className="mx-1"
              type="radio"
              name="roundTrip"
              id="roundId"
              value="round"
              checked={roundType}
              onChange={(e) => {
                setTripType(e.target.value);
              }}
            />
            <label className="text-center " htmlFor="roundId">
              {marketPlace.roundTrip}
            </label>
          </div>
          <div className="mx-1">
            <input
              className="mx-1"
              type="radio"
              name="onWayOnly"
              id="onWayId"
              value="onWay"
              checked={tripType === "onWay"}
              onChange={(e) => {
                setTripType(e.target.value);
                setTransportationsData((prev) => ({
                  ...prev,
                  returnDate: null,
                }));
              }}
            />
            <label className="text-center " htmlFor="onWayId">
              {marketPlace.onWay}
            </label>
          </div>
        </div>
        {/* Date */}
        <div
          className={`${tripType !== "round" || modify ? "col-md-12" : "col-md-6"
            }`}
        >
          <DateTimePickerField
            label={marketPlace.departureDate}
            date={transportationsData?.departureDate ?? ""}
            placeholder="DD/MM/YYYY"
            onChangeDate={(date) => {
              

              setTransportationsData((prev) => ({
                ...prev,
                departureDate: date,
                returnDate:
                  moment(date) >= moment(transportationsData?.returnDate)
                    ? null
                    : transportationsData?.returnDate,
              }));
              setErrors({
                ...errors,
                ...validate(
                  {
                    name: "departureDate",
                    value: date,
                  },
                  { required: true }
                ),
              });
            }}
            minDate={moment(new Date()).add(1, "d")["_d"]}
            color={errors?.departureDate?.required ? "danger" : ""}
            errors={errors?.departureDate}
            timeInputLabel={marketPlace.flightTime}
          />
        </div>

        {roundType && (
          <div className={`${modify ? "col-md-12" : "col-md-6"}`}>
            <DateTimePickerField
              label={marketPlace.returnDate}
              date={transportationsData.returnDate}
              placeholder="DD/MM/YYYY"
              onChangeDate={(date) => {
                
                setTransportationsData((prev) => ({
                  ...prev,
                  returnDate: date,
                  departureDate:
                    moment(date) <=
                      moment(transportationsData?.departureDate)
                      ? null
                      : transportationsData?.departureDate,
                }));
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "returnDate", value: date },
                    { required: true }
                  ),
                });
              }}
              minDate={
                moment(transportationsData?.departureDate).add(1, "d")["_d"]
              }
              color={errors?.returnDate?.required ? "danger" : ""}
              errors={errors?.returnDate}
              timeInputLabel={marketPlace.flightTime}
            />
          </div>
        )}

        {/* vehicles_count */}
        <div className="col-md-6">
          <SelectField
            hasLabel={true}
            options={adultAndChildLookup}
            placeholder={marketPlace.adult}
            label={marketPlace.adult}
            name="adult"
            value={transportationsData.adult?.label}
            onChange={(e) => {
              setTransportationsData({
                ...transportationsData,
                adult: e,
              });
              setErrors({
                ...errors,
                ...validate(
                  { name: "adult", value: e.value },
                  { required: true }
                ),
              });
            }}
            errors={errors?.adult}
            color={errors?.adult?.required ? "danger" : ""}
          />
        </div>

        <div className="col-md-6">
          <SelectField
            hasLabel={true}
            options={adultAndChildLookup}
            placeholder={marketPlace.children}
            label={
              <>
                {marketPlace.children}
                <i
                  class="fas fa-info-circle new-color"
                  title={marketPlace.messages.childrenAge}
                />
              </>
            }
            name="children"
            value={transportationsData?.children?.label}
            onChange={(e) => {
              handleChildsCount(e);
              setErrors({
                ...errors,
                ...validate(
                  { name: "children", value: e.value },
                  { required: true }
                ),
              });
            }}
          />
        </div>

        {transportationsData.children.value > 0 && (
          <div className="container-centerLabel col-12 px-2">
            <label className="m-0 w-100" htmlFor="">
              {marketPlace.ChildrenAges}
            </label>
          </div>
        )}

        {transportationsData?.ages?.map((age, index) => {
          return (
            <div className="col-2" key={index}>
              <SelectField
                hasLabel={false}
                options={childsAgesLookup}
                value={age?.label}
                onChange={(e) => handleChildAge(e, index)}
              />
            </div>
          );
        })}
        <div className="col-md-12">
          <button
            className="btn w-100 bg-nxt mb-3 mt-3 px-0"
            type="button"
            onClick={submit}
          >
            <p className="d-flex align-items-center justify-content-center">
              {onlineVisa.search}
            </p>
          </button>
        </div>
      </div>

      <CustomModal
        modalIsOpen={fromToModal.state}
        header={fromToModal.type === "to" ? marketPlace.To : marketPlace.From }
        toggle={() => toggleAutoModel(null)}
        size="md"
        centered={true}
      >
        <AutocompleteModel
          closeModal={() => toggleAutoModel(null)}
          onSubmit={getFromAndTo}
        />
      </CustomModal>
    </div>
  )
}
