import { HotelIcon } from 'modules/UmrahOperations/shared/Icons'
import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import Locale from 'translations';


export default function ItineraryHotelView({ item, setCurrentView, selectedDay, removeHotel, canEdit }) {
  const { productsBuilder, companyDetails } = Locale;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="itinerary-item-view-wrapper">
      <div className="item-view">
        <div className="item-info" onClick={() => setIsOpen(!isOpen)}>
          <span className="trip-planner-icon-wrapper hotel">
            <HotelIcon color="#FF9F43" />
          </span>
          <p className="d-flex flex-column">
            <span>{item?.hotel_name?.name}</span>
            <span className="secondry-text">{item?.country?.name} - {item?.city?.name}</span>
          </p>
          <span> - </span>
          <p className="d-flex flex-column">
            <span>{item?.meal_plan?.name}</span>
            <span className="secondry-text">{item?.nights} {productsBuilder.nights}</span>
          </p>
          {/* arrow */}
          {isOpen
            ? <i className="fas fa-chevron-up collapse-arrow"></i>
            : <i className="fas fa-chevron-down collapse-arrow"></i>
          }
        </div>
        <div className="item-action-btns">

          {item?.day === selectedDay &&
            <button className="btn shadow-none edit-btn" onClick={() => setCurrentView("form")}>
              <EditIcon />
            </button>
          }
          
          {item?.day === selectedDay && canEdit ?
            <button className="btn shadow-none delete-btn" onClick={() => removeHotel()}>
              <DeleteIcon />
            </button>
            :
            null
          }
        </div>

      </div>


      <Collapse isOpen={isOpen}>
        <div className="item-data hotel-data">
          <div className="d-flex gap-10">
            <span className="trip-planner-icon-wrapper hotel">
              <HotelIcon color="#FF9F43" />
            </span>
            <div className="d-flex flex-column">
              <span className="duration">{item?.nights} {productsBuilder.nights}</span>
              <span className="mt-1">{item?.hotel_name?.name} ({item?.country?.name} - {item?.city?.name}) </span>
              <span className="secondry-text mt-1">{item?.meal_plan?.name}</span>
            </div>
          </div>

          {/* hotel description */}
          {item?.description &&
            <>
              <p className="mt-3 font-weight-bold">{companyDetails.description}:</p>
              <p className="secondry-text">
                {item?.description}
              </p>
            </>
          }

        </div>
      </Collapse>
    </div>

  )
}
